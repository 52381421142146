import { errorHandler, getAxiosInstance } from "__utils/helpers";

import { API_ROOT } from "__utils/constants";
import { AxiosInstance, AxiosResponse } from "axios";
import { AddDocuSignEnvelopePayloadType } from "../__redux/types";
import { ADD_DOCUSIGN_ENVELOPE } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const addDocuSignEnvelopeApi = async (payload: AddDocuSignEnvelopePayloadType): Promise<AxiosResponse<any>> =>
	axios.post(`${ADD_DOCUSIGN_ENVELOPE}/`, payload).catch(errorHandler);
