import { DocumentDetailsType } from "__pages/RequestDocuments/__redux/types";
import { ApiErrorType } from "__utils/types";
import * as actionTypes from "./actionTypes";

export type DocTemplateCategoryType = {
	readonly name: string;
};

export type DocTemplateCategoryListType = Array<DocTemplateCategoryType>;

export interface TemplateDetailsType {
	readonly id: number;
	readonly category: string;
	readonly title: string;
	readonly description: string;
	readonly documents: Array<DocumentDetailsType>;
}

export type TemplatesType = Array<TemplateDetailsType>;

/**
 * Get template details
 */

export type GetTemplateDetailsType = {
	readonly templateDetails: TemplateDetailsType;
	readonly isGettingTemplateDetails: boolean;
	readonly isGetTemplateDetailsSuccess: boolean;
	readonly isGetTemplateDetailsError: boolean;
	readonly getTemplateDetailsError: { [key: string]: Array<string> };
};

export const getTemplateDetailsDefaultValue: GetTemplateDetailsType = {
	templateDetails: {
		id: 0,
		category: "",
		title: "",
		description: "",
		documents: [],
	},
	isGettingTemplateDetails: false,
	isGetTemplateDetailsSuccess: false,
	isGetTemplateDetailsError: false,
	getTemplateDetailsError: {},
};

export type GetTemplateDetailsPayloadType = {
	readonly id: number;
};

export type GetTemplateDetailsCtrType = {
	(payload: GetTemplateDetailsPayloadType): GetTemplateDetailsActionTypes;
};

type GetTemplateDetailsAction = {
	type: typeof actionTypes.GET_TEMPLATE_DETAILS;
	payload: GetTemplateDetailsPayloadType;
};

type GetTemplateDetailsSuccessAction = {
	type: typeof actionTypes.GET_TEMPLATE_DETAILS_SUCCESS;
};

type GetTemplateDetailsFailureAction = {
	type: typeof actionTypes.GET_TEMPLATE_DETAILS_FAILURE;
};

type GetTemplateDetailsResetAction = {
	type: typeof actionTypes.GET_TEMPLATE_DETAILS_RESET;
};

export type GetTemplateDetailsActionTypes =
	| GetTemplateDetailsAction
	| GetTemplateDetailsSuccessAction
	| GetTemplateDetailsFailureAction
	| GetTemplateDetailsResetAction;

/**
 * Get templates
 */

export type GetTemplatesType = {
	readonly templates: TemplatesType;
	readonly filtered: TemplatesType; // Filtered base
	readonly partial: TemplatesType; // Accumulated each scroll
	readonly categories: Array<DocTemplateCategoryType>;
	readonly limit: number;
	readonly currPage: number;
	readonly numOfPages: number;
	readonly isGettingTemplates: boolean;
	readonly isGetTemplatesSuccess: boolean;
	readonly isGetTemplatesError: boolean;
	readonly getTemplatesError: { [key: string]: Array<string> };
};

export type TemplatesListType = TemplatesType;

export const getTemplatesDefaultValue: GetTemplatesType = {
	templates: [],
	filtered: [],
	partial: [],
	categories: [],
	limit: 35,
	currPage: 1,
	numOfPages: 999,
	isGettingTemplates: false,
	isGetTemplatesSuccess: false,
	isGetTemplatesError: false,
	getTemplatesError: {},
};

export type GetTemplatesCtrType = {
	(): GetTemplatesActionTypes;
};

type GetTemplatesAction = {
	type: typeof actionTypes.GET_TEMPLATES;
};

type GetTemplatesSuccessAction = {
	type: typeof actionTypes.GET_TEMPLATES_SUCCESS;
};

type GetTemplatesFailureAction = {
	type: typeof actionTypes.GET_TEMPLATES_FAILURE;
};

type GetTemplatesResetAction = {
	type: typeof actionTypes.GET_TEMPLATES_RESET;
};

export type GetTemplatesActionTypes =
	| GetTemplatesResetAction
	| GetTemplatesAction
	| GetTemplatesSuccessAction
	| GetTemplatesFailureAction;

/**
 * Get templates on scroll
 */

export type GetTemplatesOnScrollPayloadType = {
	page: number;
};

export type GetTemplatesOnScrollCtrType = {
	(payload: GetTemplatesOnScrollPayloadType): GetTemplatesOnScrollActionTypes;
};

type GetTemplatesOnScrollAction = {
	type: typeof actionTypes.GET_TEMPLATES_ON_SCROLL;
	payload: GetTemplatesOnScrollPayloadType;
};

type GetTemplatesOnScrollSuccessAction = {
	type: typeof actionTypes.GET_TEMPLATES_ON_SCROLL_SUCCESS;
};

export type GetTemplatesOnScrollActionTypes = GetTemplatesOnScrollAction | GetTemplatesOnScrollSuccessAction;

/**
 * Filter templates
 */

export type TemplatesSearchFilterType = string;

export type TemplatesFiltersType = {
	readonly searchFilter: TemplatesSearchFilterType;
	// readonly prevSearchFilter: TemplatesSearchFilterType | undefined;
};

export interface FilterTemplatesType {
	readonly filters: TemplatesFiltersType;
	readonly isFiltering: boolean;
}

export const filterTemplatesDefaultValue: FilterTemplatesType = {
	filters: {
		searchFilter: "",
		// prevSearchFilter: undefined,
	},
	isFiltering: false,
};

export type FilterTemplatesPayloadType = {
	filters: TemplatesFiltersType;
	isFiltering: boolean;
};

export type FilterTemplatesCtrType = {
	(payload: FilterTemplatesPayloadType): FilterTemplatesActionTypes;
};

type FilterTemplatesAction = {
	type: typeof actionTypes.FILTER_TEMPLATES;
	payload: FilterTemplatesPayloadType;
};

type FilterTemplatesResetAction = {
	type: typeof actionTypes.FILTER_TEMPLATES_RESET;
};

export type FilterTemplatesActionTypes = FilterTemplatesAction | FilterTemplatesResetAction;

/**
 * delete Template
 */

export type DeleteTemplateType = {
	readonly id: number;
	readonly isDeletingTemplate: boolean;
	readonly isDeleteTemplateSuccess: boolean;
	readonly isDeleteTemplateError: boolean;
};

export const deleteTemplateDefaultValue: DeleteTemplateType = {
	id: 0,
	isDeletingTemplate: false,
	isDeleteTemplateSuccess: false,
	isDeleteTemplateError: false,
};

export type DeleteTemplatePayloadType = {
	id: number;
};

type DeleteTemplateAction = {
	type: typeof actionTypes.DELETE_TEMPLATE;
	payload: DeleteTemplatePayloadType;
};

type DeleteTemplateSuccessAction = {
	type: typeof actionTypes.DELETE_TEMPLATE_SUCCESS;
};

type DeleteTemplateFailureAction = {
	type: typeof actionTypes.DELETE_TEMPLATE_FAILURE;
};

type DeleteTemplateResetAction = {
	type: typeof actionTypes.DELETE_TEMPLATE_RESET;
};

export type DeleteTemplateActionTypes =
	| DeleteTemplateSuccessAction
	| DeleteTemplateFailureAction
	| DeleteTemplateAction
	| DeleteTemplateResetAction;

/**
 * Add template
 */

export type AddTemplateType = {
	readonly isAddingTemplate: boolean;
	readonly isAddTemplateSuccess: boolean;
	readonly isAddTemplateError: boolean;
	readonly addTemplateError: { [key: string]: Array<string> };
};

export const addTemplateDefaultValue: AddTemplateType = {
	isAddingTemplate: false,
	isAddTemplateSuccess: false,
	isAddTemplateError: false,
	addTemplateError: {},
};

export type AddTemplatePayloadType = {
	readonly category: string;
	readonly title: string;
	readonly description: string;
	readonly documents: Array<DocumentDetailsType>;
};

export type AddTemplate = {
	(payload: AddTemplatePayloadType): AddTemplateActionTypes;
};

export type AddTemplateReset = {
	(): AddTemplateActionTypes;
};

type AddTemplateAction = {
	type: typeof actionTypes.ADD_TEMPLATE;
	payload: AddTemplatePayloadType;
};

type AddTemplateSuccessAction = {
	type: typeof actionTypes.ADD_TEMPLATE_SUCCESS;
};

type AddTemplateFailureAction = {
	type: typeof actionTypes.ADD_TEMPLATE_FAILURE;
};

type AddTemplateResetAction = {
	type: typeof actionTypes.ADD_TEMPLATE_RESET;
};

export type AddTemplateActionTypes =
	| AddTemplateAction
	| AddTemplateSuccessAction
	| AddTemplateFailureAction
	| AddTemplateResetAction;

/**
 * Edit template
 */
export type EditTemplateType = {
	readonly isEditingTemplate: boolean;
	readonly isEditTemplateSuccess: boolean;
	readonly isEditTemplateError: boolean;
	readonly editTemplateError: { [key: string]: Array<string> };
};

export const editTemplateDefaultValue: EditTemplateType = {
	isEditingTemplate: false,
	isEditTemplateSuccess: false,
	isEditTemplateError: false,
	editTemplateError: {},
};

export type EditTemplatePayloadType = TemplateDetailsType;

export type EditTemplate = {
	(payload: EditTemplatePayloadType): EditTemplateActionTypes;
};

export type EditTemplateReset = {
	(): EditTemplateActionTypes;
};

type EditTemplateAction = {
	type: typeof actionTypes.EDIT_TEMPLATE;
	payload: EditTemplatePayloadType;
};

type EditTemplateSuccessAction = {
	type: typeof actionTypes.EDIT_TEMPLATE_SUCCESS;
};

type EditTemplateFailureAction = {
	type: typeof actionTypes.EDIT_TEMPLATE_FAILURE;
};

type EditTemplateResetAction = {
	type: typeof actionTypes.EDIT_TEMPLATE_RESET;
};

export type EditTemplateActionTypes =
	| EditTemplateAction
	| EditTemplateSuccessAction
	| EditTemplateFailureAction
	| EditTemplateResetAction;

/**
 * Get template categories
 */

export type GetTemplateCategoriesType = {
	readonly templateCategories: DocTemplateCategoryListType;
	readonly isGettingTemplateCategories: boolean;
	readonly isGetTemplateCategoriesSuccess: boolean;
	readonly isGetTemplateCategoriesError: boolean;
	readonly getTemplateCategoriesError: ApiErrorType;
};

export const getTemplateCategoriesDefaultValue: GetTemplateCategoriesType = {
	templateCategories: [],
	isGettingTemplateCategories: false,
	isGetTemplateCategoriesSuccess: false,
	isGetTemplateCategoriesError: false,
	getTemplateCategoriesError: {},
};

export type GetTemplateCategoriesCtrType = {
	(): GetTemplateCategoriesActionTypes;
};

type GetTemplateCategoriesAction = {
	type: typeof actionTypes.GET_TEMPLATE_CATEGORIES;
};

type GetTemplateCategoriesSuccessAction = {
	type: typeof actionTypes.GET_TEMPLATE_CATEGORIES_SUCCESS;
};

type GetTemplateCategoriesFailureAction = {
	type: typeof actionTypes.GET_TEMPLATE_CATEGORIES_FAILURE;
};

type GetTemplateCategoriesResetAction = {
	type: typeof actionTypes.GET_TEMPLATE_CATEGORIES_RESET;
};

export type GetTemplateCategoriesActionTypes =
	| GetTemplateCategoriesResetAction
	| GetTemplateCategoriesAction
	| GetTemplateCategoriesSuccessAction
	| GetTemplateCategoriesFailureAction;
