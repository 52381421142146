import { ApiErrorType } from "__utils/types";
import * as actionTypes from "./actionTypes";
/**
 * Create Payment
 */

export type AddPaymentType = {
	readonly isAddingPayment: boolean;
	readonly isAddPaymentSuccess: boolean;
	readonly isAddPaymentError: boolean;
	readonly addPaymentError: string | ApiErrorType;
};

export const addPaymentDefaultValue: AddPaymentType = {
	isAddingPayment: false,
	isAddPaymentSuccess: false,
	isAddPaymentError: false,
	addPaymentError: {},
};

export type AddPaymentPayloadType = {
	readonly id: number;
	readonly debitDate: string;
	readonly amount: string;
	readonly reference: string;
};

export type AddPayment = {
	(payload: AddPaymentPayloadType): AddPaymentActionTypes;
};

type AddPaymentAction = {
	type: typeof actionTypes.ADD_PAYMENT;
	payload: AddPaymentPayloadType;
};

type AddPaymentSuccessAction = {
	type: typeof actionTypes.ADD_PAYMENT_SUCCESS;
};

type AddPaymentFailureAction = {
	type: typeof actionTypes.ADD_PAYMENT_FAILURE;
};

type AddPaymentResetAction = {
	type: typeof actionTypes.ADD_PAYMENT_RESET;
};

export type AddPaymentActionTypes =
	| AddPaymentAction
	| AddPaymentSuccessAction
	| AddPaymentFailureAction
	| AddPaymentResetAction;
