import styled from "styled-components";

export const Container = styled.div`
	.ant-result-error {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 90vh;
	}
`;
