import styled from "styled-components";

export const DesktopHeaderContainer = styled.div`
	padding: 0 24px;
`;
export const Left = styled.div``;
export const Right = styled.div`
	display: flex;
	justify-content: flex-end;

	.header-btn {
		border: none;
		background: transparent;
		font-size: inherit;
		font-style: normal;
		font-weight: 500;
		box-shadow: none;
		color: ${(p) => p.theme.textColor};
		transition: ${(p) => p.theme.transition("all")};
	}
	.ant-badge-count {
		top: 4px;
		right: 10px;
		.ant-scroll-number-only span {
			font-size: 9px;
		}
	}

	&.dark-mode .header-btn {
		color: #fff;
	}

	.header-btn:hover,
	.header-btn:focus {
		background-color: rgba(255, 255, 255, 0.4);
		color: ${(p) => p.theme.textColor};
	}

	&.dark-mode .header-btn:hover,
	&.dark-mode .header-btn:focus {
		background-color: #291e80;
		color: #40a9ff;
	}
`;

export const LogoWrapper = styled.div`
	float: left;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 138px;
	height: 55px;

	margin-left: 6px;
	margin-right: 26px;

	img {
		width: auto;
	}
`;

export const SessionWidget = styled.div`
	margin-left: 3px;
	right: 16px;
	top: 0;
	.ant-dropdown-trigger {
		display: flex;
		cursor: pointer;
		height: 60px;

		.icon-wrapper {
			display: flex;
			align-items: center;
			.anticon-user {
				font-size: 20px;
			}
		}

		> div {
			margin-left: 12px;
		}

		.name-brand {
			display: flex;
			flex-direction: column;
			justify-content: center;

			> div {
				line-height: 16px;
			}

			> div.brand {
				font-size: 12px;
			}
		}

		.anticon-caret-down {
			color: #666;
		}
	}

	&.dark-mode {
		.name-brand > div {
			color: #fff;
		}

		.ant-dropdown-link .anticon-caret-down {
			color: #fff;
		}
	}
`;
