import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	margin-left: auto;
	border-radius: 20px;
	padding: 7px;
	color: white;
	text-align: right;
`;
