import * as actionTypes from "./actionTypes";
import { DEFAULT_LOGO_HEIGHT } from "../__utils/constants";

export type SetHeaderPreviewType = {
	previewOn: boolean;
	darkMode: boolean;
	logo: string;
	logoHeight: number;
};

export const setHeaderPreviewDefaultValue = {
	previewOn: false,
	darkMode: false,
	logo: "",
	logoHeight: DEFAULT_LOGO_HEIGHT,
};

export type SetHeaderPreviewPayloadType = {
	previewOn: boolean;
	darkMode: boolean;
	logo: string;
	logoHeight: number;
};

export type SetHeaderPreviewAction = {
	type: typeof actionTypes.SET_HEADER_PREVIEW;
	payload: SetHeaderPreviewPayloadType;
};

export type SetHeaderPreviewResetAction = {
	type: typeof actionTypes.SET_HEADER_PREVIEW_RESET;
};

export type SetHeaderPreviewActionTypes = SetHeaderPreviewAction | SetHeaderPreviewResetAction;
