import { call, put, takeLatest, select } from "redux-saga/effects";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";
import { getActivitiesSlr } from "./selectors";
import { GetActivitiesType } from "./types";

const getActivities = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getActivitiesApi, payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_ACTIVITIES_SUCCESS,
				activities: data.results,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_ACTIVITIES_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_ACTIVITIES_FAILURE });
	}
};

export function* getActivitiesSaga(): any {
	yield takeLatest(actionTypes.GET_ACTIVITIES, getActivities);
}

const addActivityNote = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.addActivityNoteApi, payload);
		const { status, data } = response;

		if (status === 201) {
			const { activities }: GetActivitiesType = yield select(getActivitiesSlr);

			yield put({
				type: actionTypes.GET_ACTIVITIES_SUCCESS,
				activities: [data, ...activities],
			});

			yield put({
				type: actionTypes.ADD_ACTIVITY_NOTE_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.ADD_ACTIVITY_NOTE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.ADD_ACTIVITY_NOTE_FAILURE });
	}
};

export const addActivityNoteSaga = function* (): any {
	yield takeLatest(actionTypes.ADD_ACTIVITY_NOTE, addActivityNote);
};
