export const TITLE_LABEL = "Title";
export const FIRST_NAME_LABEL = "First Name";
export const LAST_NAME_LABEL = "Last Name";
export const MIDDLE_LABEL = "Middle Name";
export const EMAIL_LABEL = "Email";
export const PHONE_LABEL = "Phone";
export const DOB_LABEL = "Date of Birth";
export const TFN_LABEL = "Tax File Number";
export const OCCUPATION_TYPE_LABEL = "Occupation Type";
export const OCCUPATION_CATEGORY_LABEL = "Occupation Category";
export const RESIDENTIAL_ADDRESS_LABEL = "Residential Address";
export const UNIT_LABEL = "Unit #";
export const SUBURB_LABEL = "Suburb";
export const STATE_LABEL = "State";
export const POSTCODE_LABEL = "Postcode";
export const STREET_NUMBER_LABEL = "Street Number";
export const STREET_ONLY_LABEL = "Street Only";
export const POSTAL_LABEL = "Postal address is different to residential";
export const PHONE_MAX_LEN_ERROR = "Ensure this field has no more than 10 characters.";
export const DIRECTOR_LEN_ERROR = "Ensure this field has no more than 15 characters.";
export const COUNTRY_LABEL = "Country Of Birth";
export const STATE_BIRTH_LABEL = "State of Birth";
export const TOWN_LABEL = "Town / City of Birth";
export const IDENTIFICATION_TYPE_LABEL = "Identification Type";
export const IDENTIFICATION_NUMBER_LABEL = "Identification Number";
export const STATE_ISSUED_LABEL = "State Issued";
export const BUSINESS_NAME_LABEL = "Business Name";
export const ABN_OR_ACN_OF_BUSINESS_LABEL = "ABN or ACN of Business";
export const BUSINESS_ADDRESS_LABEL = "Business Address";
export const BUSINESS_SUBURB_LABEL = "Business Suburb";
export const BUSINESS_STATE_LABEL = "Business State";
export const BUSINESS_POSTCODE_LABEL = "Business Postcode";
export const BUSINESS_COUNTRY_LABEL = "Business Country";
export const DIRECTOR_ID_LABEL = "Director Id";

export const POSTAL_ADDRESS_LABEL = "Postal Address";
export const POSTAL_UNIT_LABEL = "Unit #";
export const POSTAL_SUBURB_LABEL = "Suburb";
export const POSTAL_STATE_LABEL = "State";
export const POSTAL_POSTCODE_LABEL = "Postcode";

export const ADD_MEMBER_LABEL = "Add Members";
export const EDIT_MEMBER_LABEL = "Edit";
export const DELETE_MEMBER_LABEL = "Delete";

export const DELETE_MEMBER_MSG = "Do you really want to delete this member?";

export const MEMBER_ADD_SUCCESS_MESSAGE = "Member added successfully";
export const MEMBER_EDIT_SUCCESS_MESSAGE = "Member edited successfully";
export const MEMBER_DELETE_SUCCESS_MESSAGE = "Member deleted successfully";
export const MEMBER_VIEW = "View Member";
export const MEMBER_EDIT = "Edit Member";

export const TITLE_NAME = "title";
export const FIRST_NAME_NAME = "firstName";
export const LAST_NAME_NAME = "lastName";
export const MIDDLE_NAME = "middleName";
export const EMAIL_NAME = "email";
export const PHONE_NAME = "phone";
export const DOB_NAME = "dateOfBirth";
export const TFN_NAME = "tfn";
export const OCCUPATION_TYPE_NAME = "occupationType";
export const OCCUPATION_CATEGORY_NAME = "occupationCategory";
export const RESIDENTIAL_ADDRESS_NAME = "residentialAddress";
export const UNIT_NAME = "residentialUnit";
export const SUBURB_NAME = "residentialSuburb";
export const STATE_NAME = "residentialState";
export const POSTCODE_NAME = "residentialPostcode";
export const STREET_NUMBER = "residentialStreetNumber";
export const STREET_ONLY = "residentialStreetOnly";
export const POSTAL_NAME = "notUseResidentialAsMailing";
export const IDENTIFICATION_TYPE = "identificationType";
export const IDENTIFICATION_NUMBER = "identificationNumber";
export const STATE_ISSUED = "identificationStateIssued";
export const BUSINESS_NAME = "tradingName";
export const ABN_OR_ACN_OF_BUSINESS = "businessRegistrationNumber";
export const BUSINESS_ADDRESS = "businessAddress";
export const BUSINESS_SUBURB = "businessSuburb";
export const BUSINESS_STATE = "businessState";
export const BUSINESS_POSTCODE = "businessPostcode";
export const BUSINESS_COUNTRY = "businessCountry";
export const DIRECTOR_ID = "directorId";
