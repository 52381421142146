import Form from "antd/es/form";
import dayjs from "dayjs";
import React, { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import MemberForm from "__pages/FundDetails/__pages/Account/Fund/Members/__common/MemberForm";
import { addMemberCtr, addMemberResetCtr } from "__pages/FundDetails/__pages/Account/Fund/Members/__redux/actions";
import { addMemberSlr } from "__pages/FundDetails/__pages/Account/Fund/Members/__redux/selectors";
import { AddMemberType } from "__pages/FundDetails/__pages/Account/Fund/Members/__redux/types";
import { GetFundDetailsType } from "__pages/FundDetails/__redux/types";
import { processErrors } from "__utils/helpers";

type Props = {
	fund: GetFundDetailsType;
	setShowMemberForm: Dispatch<SetStateAction<boolean>>;
};

const AddMember: React.FC<Props> = ({ fund, setShowMemberForm }: Props) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { resetFields, setFields } = form;
	const {
		account: { id },
		members,
	} = fund;
	const addMember: AddMemberType = useSelector(addMemberSlr);
	const { isAddingMember, isAddMemberSuccess, isAddMemberError, addError } = addMember;

	React.useEffect(() => {
		if (!isAddMemberError && isAddMemberSuccess) {
			resetFields();
			setShowMemberForm(false);
			dispatch(addMemberResetCtr());
		}
	}, [resetFields, isAddMemberError, isAddMemberSuccess, dispatch, setShowMemberForm]);

	React.useEffect(() => {
		if (isAddMemberError) {
			setFields(processErrors(addError));
			dispatch(addMemberResetCtr());
		}
	}, [isAddMemberError, addError, setFields, dispatch]);

	const onFinish = (values: any): void => {
		const convertDate = dayjs(values.dateOfBirth).format("YYYY-MM-DD");
		const countryOfBirth = values.countryOfBirth === undefined ? "" : values.countryOfBirth;
		const cityOfBirth = values.cityOfBirth === undefined ? "" : values.cityOfBirth;
		const payload: any = {
			...values,
			notUseResidentialAsMailing: !!values.notUseResidentialAsMailing,
			dateOfBirth: convertDate,
			countryOfBirth,
			cityOfBirth,
		};

		dispatch(addMemberCtr({ id, body: payload }));
	};

	const onCancel = (): void => {
		setShowMemberForm(false);
	};

	return (
		<MemberForm
			form={form}
			fund={fund}
			isLoading={isAddingMember}
			onFinish={onFinish}
			onCancel={onCancel}
			numOfMembers={members.length}
		/>
	);
};

export default AddMember;
