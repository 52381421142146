import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { AddFundStructurePayloadType, EditFundStructurePayloadType } from "../__redux/types";
import { ADD_STRUCTURE, EDIT_STRUCTURE } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const addFundStructureApi = async ({
	id,
	structure,
}: AddFundStructurePayloadType): Promise<AxiosResponse<any>> =>
	axios.post(`${ADD_STRUCTURE}${id}/`, structure).catch(errorHandler);

export const editFundStructureApi = async ({
	id,
	structure,
}: EditFundStructurePayloadType): Promise<AxiosResponse<any>> =>
	axios.post(`${EDIT_STRUCTURE}${id}/`, structure).catch(errorHandler);
