import styled from "styled-components";

export const Container = styled.div<any>`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	top: -7px;

	.arrow {
		background: white;
		width: 13px;
		height: 13px;
		border-top: 1px solid ${(p: any) => p.theme.dropdownBorderColor};
		border-left: 1px solid ${(p: any) => p.theme.dropdownBorderColor};

		&.arrow-up {
			transform: rotate(45deg);
		}
		&.arrow-down {
			transform: rotate(225deg);
		}
	}
`;
