import { BookOutlined, CreditCardOutlined, FilePdfOutlined, SendOutlined, UserOutlined } from "@ant-design/icons";
import { RoutesType } from "__utils/types";
import React from "react";

const Account = React.lazy(() => import("./__pages/Account"));
const Accounting = React.lazy(() => import("./__pages/Accounting"));
const Billing = React.lazy(() => import("./__pages/Billing"));
const Documents = React.lazy(() => import("./__pages/Documents"));
const Requests = React.lazy(() => import("./__pages/Requests"));
const CreateRequest = React.lazy(() => import("./__pages/Requests/__pages/CreateRequest"));
const EditRequest = React.lazy(() => import("./__pages/Requests/__pages/EditRequest"));
const CloneRequest = React.lazy(() => import("./__pages/Requests/__pages/CloneRequest"));

export const routes: RoutesType = [
	{
		key: "0",
		path: ["/fund-details/:id", "/fund-details/:id/", "/fund-details/:id/account"],
		trailingPath: "account",
		exact: true,
		label: "Account",
		title: "View Account",
		icon: UserOutlined,
		component: Account,
		accessLevels: [1, 2, 3, 4, 5, 6],
		shouldDisplay: true,
		disabledByDefault: false,
	},

	{
		key: "1",
		path: "/fund-details/:id/accounting",
		trailingPath: "accounting",
		label: "Accounting",
		title: "View Accounting",
		icon: BookOutlined,
		component: Accounting,
		accessLevels: [1, 2, 3, 4, 5, 6],
		shouldDisplay: true,
		disabledByDefault: true,
	},
	{
		key: "2",
		path: "/fund-details/:id/billing",
		trailingPath: "billing",
		label: "Billing",
		title: "View Billing",
		icon: CreditCardOutlined,
		component: Billing,
		accessLevels: [1, 2],
		shouldDisplay: true,
		disabledByDefault: true,
	},
	{
		key: "3",
		path: "/fund-details/:id/documents",
		trailingPath: "documents",
		label: "Documents",
		title: "View Documents",
		icon: FilePdfOutlined,
		component: Documents,
		accessLevels: [1, 2, 3, 4, 5, 6],
		shouldDisplay: true,
		disabledByDefault: true,
	},
	{
		key: "4",
		path: "/fund-details/:id/requests",
		exact: true,
		trailingPath: "requests",
		label: "Requests",
		title: "View Requests",
		icon: SendOutlined,
		component: Requests,
		accessLevels: [1, 2, 3, 4],
		shouldDisplay: true,
		disabledByDefault: true,
	},
	{
		key: "5",
		parentKey: "4",
		path: "/fund-details/:id/requests/create",
		trailingPath: "create",
		component: CreateRequest,
		accessLevels: [1],
		shouldDisplay: false,
	},
	{
		key: "6",
		parentKey: "4",
		path: "/fund-details/:id/requests/edit/:docRequestId",
		trailingPath: "edit",
		component: EditRequest,
		accessLevels: [1],
		shouldDisplay: false,
	},
	{
		key: "7",
		parentKey: "4",
		path: "/fund-details/:id/requests/clone/:docRequestId",
		trailingPath: "clone",
		component: CloneRequest,
		accessLevels: [1],
		shouldDisplay: false,
	},
];

export default routes;
