import { GetFundDetailsType } from "__pages/FundDetails/__redux/types";
import * as actionTypes from "./actionTypes";

/**
 * Add fund details
 */
export type AddFundStructureType = {
	readonly isAdding: boolean;
	readonly isSuccess: boolean;
	readonly isError: boolean;
	readonly error: { [key: string]: Array<string> };
};

export const addFundStructureDefaultValue: AddFundStructureType = {
	isAdding: false,
	isSuccess: false,
	isError: false,
	error: {},
};

export type AddFundStructurePayloadType = {
	id: number;
	structure: {
		fundOrigin: string;
		fundType: string;
		fundName: string;
		jurisdiction: string;
		companyName?: string;
		abn?: string;
		acn?: string;
		tfn?: string;
	};
	fund?: GetFundDetailsType;
};

export type AddFundStructure = {
	(payload: AddFundStructurePayloadType): AddFundStructureActionTypes;
};

type AddFundStructureAction = {
	type: typeof actionTypes.ADD_FUND_STRUCTURE;
	payload: AddFundStructurePayloadType;
};

type AddFundStructureSuccessAction = {
	type: typeof actionTypes.ADD_FUND_STRUCTURE_SUCCESS;
};

type AddFundStructureFailureAction = {
	type: typeof actionTypes.ADD_FUND_STRUCTURE_FAILURE;
};

type AddFundStructureResetAction = {
	type: typeof actionTypes.ADD_FUND_STRUCTURE_RESET;
};

export type AddFundStructureActionTypes =
	| AddFundStructureAction
	| AddFundStructureSuccessAction
	| AddFundStructureFailureAction
	| AddFundStructureResetAction;

/**
 * Edit fund details
 */
export type EditFundStructureType = {
	readonly isEditing: boolean;
	readonly isSuccess: boolean;
	readonly isError: boolean;
	readonly error: { [key: string]: Array<string> };
};

export const editFundStructureDefaultValue: EditFundStructureType = {
	isEditing: false,
	isSuccess: false,
	isError: false,
	error: {},
};

export type EditFundStructurePayloadType = AddFundStructurePayloadType;

export type EditFundStructure = {
	(payload: EditFundStructurePayloadType): EditFundStructureActionTypes;
};

type EditFundStructureAction = {
	type: typeof actionTypes.EDIT_FUND_STRUCTURE;
	payload: EditFundStructurePayloadType;
};

type EditFundStructureSuccessAction = {
	type: typeof actionTypes.EDIT_FUND_STRUCTURE_SUCCESS;
};

type EditFundStructureFailureAction = {
	type: typeof actionTypes.EDIT_FUND_STRUCTURE_FAILURE;
};

type EditFundStructureResetAction = {
	type: typeof actionTypes.EDIT_FUND_STRUCTURE_RESET;
};

export type EditFundStructureActionTypes =
	| EditFundStructureAction
	| EditFundStructureSuccessAction
	| EditFundStructureFailureAction
	| EditFundStructureResetAction;
