import { ObjectType } from "__utils/types";
import * as actionTypes from "./actionTypes";

/**
 * Edit tags
 */

export type TagsType = Array<string>;

export type EditTagsType = {
	isEditingTags: boolean; // Sending request to the API for edits
	isEditingTagsInUi: boolean; // Edit modal is being shown
	isEditingTagsError: boolean;
	isEditingTagsSuccess: boolean;
	editTagsError: ObjectType;
};

export const editTagsDefaultValue: EditTagsType = {
	isEditingTags: false,
	isEditingTagsInUi: false,
	isEditingTagsError: false,
	isEditingTagsSuccess: false,
	editTagsError: {},
};

export type EditTagsPayloadType = {
	id: number;
	tags: ObjectType;
};

export type EditTagsInUiPayloadType = boolean;

export type EditTagsCtrType = {
	(payload: EditTagsPayloadType): EditTagsActionTypes;
};

export type EditTagsResetCtrType = {
	(): EditTagsActionTypes;
};

export type EditTagsInUiCtrType = {
	(payload: EditTagsInUiPayloadType): EditTagsActionTypes;
};

type EditTagsAction = {
	type: typeof actionTypes.EDIT_TAGS;
	payload: EditTagsPayloadType;
};

type EditTagsSuccessAction = {
	type: typeof actionTypes.EDIT_TAGS_SUCCESS;
};

type EditTagsFailureAction = {
	type: typeof actionTypes.EDIT_TAGS_FAILURE;
};

type EditTagsResetAction = {
	type: typeof actionTypes.EDIT_TAGS_RESET;
};

type EditTagsInUiAction = {
	type: typeof actionTypes.EDIT_TAGS_IN_UI;
	payload: EditTagsInUiPayloadType;
};

export type EditTagsActionTypes =
	| EditTagsAction
	| EditTagsSuccessAction
	| EditTagsFailureAction
	| EditTagsResetAction
	| EditTagsInUiAction;
