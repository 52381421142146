import Col from "antd/es/col";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Row from "antd/es/row";
import Select from "antd/es/select";
import { FormInstance } from "antd/lib/form";
import React from "react";
import DropdownRender from "__common/DropdownRender";
import { FloatLabel } from "__common/FloatLabel";
import * as lang from "__utils/lang";
import { countries, statesFullName } from "../../../__utils/constants";
import * as createLang from "../../../__utils/lang";

type Props = {
	form: FormInstance;
	isDisabled: boolean;
};

const CorporateFields: React.FC<Props> = ({ form, isDisabled }: Props) => {
	const { getFieldValue, setFieldsValue } = form;
	const [ausState, setAusState] = React.useState<string[]>([]);
	const [isAustralia, setAustralia] = React.useState<boolean>(false);
	const onSetAuState = (e: string): void => {
		if (e === "Australia") {
			setAusState(statesFullName);
			setAustralia(true);
		} else {
			setAustralia(false);
			setFieldsValue({ stateOfBirth: "" });
		}
	};

	React.useEffect(() => {
		document.querySelectorAll(".ant-select-selection-search-input").forEach((e) => {
			e.setAttribute("autocomplete", "chrome-off");
			// Update value from time to time as Google like fuck web developers :-D
		});
	}, []);

	return (
		<>
			<Row gutter={[16, 8]}>
				<Col xs={24} xl={8}>
					<FloatLabel
						label={createLang.DIRECTOR_ID_LABEL}
						getValue={() => getFieldValue(createLang.DIRECTOR_ID)}
					>
						<Form.Item
							name={createLang.DIRECTOR_ID}
							rules={[
								{ required: false },
								{ max: 15, message: createLang.DIRECTOR_LEN_ERROR },
								({ getFieldValue }) => ({
									validator(_, value) {
										const getDirectorIdValue = getFieldValue(createLang.DIRECTOR_ID);
										if (getDirectorIdValue) {
											const isValidPhoneNum = getDirectorIdValue.startsWith("036");
											if (!value || isValidPhoneNum) {
												return Promise.resolve();
											}
											return Promise.reject(new Error("Director Id should start with 036"));
										}
										return Promise.resolve();
									},
								}),
							]}
						>
							<Input disabled={isDisabled} autoComplete="chrome-off" maxLength={15} />
						</Form.Item>
					</FloatLabel>
				</Col>
			</Row>
			<Row gutter={[16, 8]}>
				<Col xs={24} xl={8}>
					<FloatLabel label={createLang.COUNTRY_LABEL} getValue={() => getFieldValue("countryOfBirth")}>
						<Form.Item
							name="countryOfBirth"
							rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
						>
							<Select
								getPopupContainer={(trigger: any) => trigger.parentElement}
								dropdownRender={(menu: any) => <DropdownRender>{menu}</DropdownRender>}
								onChange={onSetAuState}
								disabled={isDisabled}
								showAction={["click", "focus"]}
								showSearch
							>
								{countries.map((country, idx) => (
									<Select.Option key={idx} value={country}>
										{country}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</FloatLabel>
				</Col>
				<Col xs={24} xl={8}>
					<FloatLabel label={createLang.STATE_BIRTH_LABEL} getValue={() => getFieldValue("stateOfBirth")}>
						<Form.Item
							name="stateOfBirth"
							rules={[{ required: isAustralia, message: lang.REQUIRED_FIELD_ERROR }]}
						>
							<Select
								disabled={!isAustralia || isDisabled}
								getPopupContainer={(trigger: any) => trigger.parentElement}
								dropdownRender={(menu: any) => <DropdownRender>{menu}</DropdownRender>}
								showAction={["click", "focus"]}
								showSearch
							>
								{ausState.map((type: string, idx: number) => (
									<Select.Option key={idx} value={type}>
										{type}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</FloatLabel>
				</Col>
				<Col xs={24} xl={8}>
					<FloatLabel label={createLang.TOWN_LABEL} getValue={() => getFieldValue("cityOfBirth")}>
						<Form.Item name="cityOfBirth" rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}>
							<Input disabled={isDisabled} autoComplete="chrome-off" />
						</Form.Item>
					</FloatLabel>
				</Col>
			</Row>
		</>
	);
};

export default CorporateFields;
