import { ApiErrorType } from "__utils/types";
import * as actionTypes from "./actionTypes";

export type DocuSignEnvelopeDetailsType = {
	readonly title: string;
	readonly id: string;
	readonly status: string;
};

export type DocuSignEnvelopesType = DocuSignEnvelopeDetailsType[];

/**
 * Add docuSign envelope
 */

export type AddDocuSignEnvelopeType = {
	readonly isAddingDocuSignEnvelope: boolean;
	readonly isAddDocuSignEnvelopeSuccess: boolean;
	readonly isAddDocuSignEnvelopeError: boolean;
	readonly addDocuSignEnvelopeError: ApiErrorType;
};

export const addDocuSignEnvelopeDefaultValue: AddDocuSignEnvelopeType = {
	isAddingDocuSignEnvelope: false,
	isAddDocuSignEnvelopeSuccess: false,
	isAddDocuSignEnvelopeError: false,
	addDocuSignEnvelopeError: {},
};

export type AddDocuSignEnvelopePayloadType = {
	readonly memberId: number;
	readonly envelopeId: string;
	readonly envelopeName: string;
};

export type AddDocuSignEnvelopeCtrType = {
	(payload: AddDocuSignEnvelopePayloadType): AddDocuSignEnvelopeActionTypes;
};

type AddDocuSignEnvelopeAction = {
	type: typeof actionTypes.ADD_DOCUSIGN_ENVELOPE;
	payload: AddDocuSignEnvelopePayloadType;
};

type AddDocuSignEnvelopeSuccessAction = {
	type: typeof actionTypes.ADD_DOCUSIGN_ENVELOPE_SUCCESS;
};

type AddDocuSignEnvelopeFailureAction = {
	type: typeof actionTypes.ADD_DOCUSIGN_ENVELOPE_FAILURE;
};

type AddDocuSignEnvelopeResetAction = {
	type: typeof actionTypes.ADD_DOCUSIGN_ENVELOPE_RESET;
};

export type AddDocuSignEnvelopeActionTypes =
	| AddDocuSignEnvelopeAction
	| AddDocuSignEnvelopeSuccessAction
	| AddDocuSignEnvelopeFailureAction
	| AddDocuSignEnvelopeResetAction;
