import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { IDeleteTrustPayload, IAddTrustPayload, IEditTrustPayload } from "../__redux/types";
import { ADD_TRUST, DELETE_TRUST, EDIT_TRUST } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const addTrustApi = async ({ memberId, trust }: IAddTrustPayload): Promise<AxiosResponse<any>> => {
	const url = ADD_TRUST.replace(":memberId", `${memberId}`);
	return axios.post(url, trust).catch(errorHandler);
};

export const editTrustApi = async ({ memberId, trust, trustId }: IEditTrustPayload): Promise<AxiosResponse<any>> => {
	const url = EDIT_TRUST.replace(":memberId", `${memberId}`).replace(":trustId", `${trustId}`);
	return axios.patch(url, trust).catch(errorHandler);
};

export const deleteTrustApi = async ({ trustId, memberId }: IDeleteTrustPayload): Promise<AxiosResponse<any>> => {
	const url = DELETE_TRUST.replace(":memberId", `${memberId}`).replace(":trustId", `${trustId}`);
	return axios.delete(url).catch(errorHandler);
};
