import { call, put, select, takeLatest } from "redux-saga/effects";
import { ResponseGeneratorType } from "__utils/types";
import { getFundDetailsSlr } from "__pages/FundDetails/__redux/selectors";
import { GetFundDetailsType } from "__pages/FundDetails/__redux/types";
import * as fundDetailsActionTypes from "__pages/FundDetails/__redux/actionTypes";
import * as getFundsActionTypes from "../../../__redux/actionTypes";
import { fundsSlr } from "../../../__redux/selectors";
import { FundDetailsType, FundsType, GetFundsType } from "../../../__redux/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";

const setNewState = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.setNewStateApi, payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.SET_NEW_STATE_SUCCESS,
			});

			const getFundDetails: GetFundDetailsType = yield select(getFundDetailsSlr);
			// Save new state
			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_SUCCESS,
				...getFundDetails,
				states: data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.SET_NEW_STATE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.SET_NEW_STATE_FAILURE });
	}
};

export const setNewStateSaga = function* (): any {
	yield takeLatest(actionTypes.SET_NEW_STATE, setNewState);
};

const getFundStates = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getfundStatesApi, payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_FUND_STATES_SUCCESS,
			});

			const fundDetails: GetFundDetailsType = yield select(getFundDetailsSlr);

			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_SUCCESS,
				...fundDetails,
				states: data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_FUND_STATES_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_FUND_STATES_FAILURE });
	}
};

export const getFundStatesSaga = function* (): any {
	yield takeLatest(actionTypes.GET_FUND_STATES, getFundStates);
};

const updateItemInFundlist = function* (action: any): any {
	try {
		const {
			payload: { newState, memberId },
		} = action;

		// Update fund list
		const getFunds: GetFundsType = yield select(fundsSlr);
		const { funds, withNewState } = getFunds;
		const updatedFunds: FundsType = funds.reduce((acc: FundsType, curr: FundDetailsType) => {
			if (curr.id === memberId) {
				curr = { ...curr, state: newState };
				acc.push(curr);
			} else {
				acc.push(curr);
			}
			return acc;
		}, []);
		const fund: FundDetailsType = funds.find((item: FundDetailsType) => item.id === memberId) as FundDetailsType;
		const updatedFund: FundDetailsType = { ...fund, state: newState };
		const updatedWithNewState: FundsType = [...(withNewState as FundsType), updatedFund];

		yield put({
			type: getFundsActionTypes.GET_FUNDS_SUCCESS,
			...getFunds,
			raw: updatedFunds,
			withNewState: updatedWithNewState,
		});
	} catch (error) {
		yield put({
			type: getFundsActionTypes.GET_FUNDS_FAILURE,
		});
	}
};

export const updateItemInFundlistSaga = function* (): any {
	yield takeLatest(actionTypes.UPDATE_ITEM_IN_FUNDLIST, updateItemInFundlist);
};
