export const SET_NEW_STATE = "SET_NEW_STATE";
export const SET_NEW_STATE_SUCCESS = "SET_NEW_STATE_SUCCESS";
export const SET_NEW_STATE_FAILURE = "SET_NEW_STATE_FAILURE";
export const SET_NEW_STATE_RESET = "SET_NEW_STATE_RESET";

export const GET_FUND_STATES = "GET_FUND_STATES";
export const GET_FUND_STATES_SUCCESS = "GET_FUND_STATES_SUCCESS";
export const GET_FUND_STATES_FAILURE = "GET_FUND_STATES_FAILURE";

export const UPDATE_ITEM_IN_FUNDLIST = "UPDATE_ITEM_IN_FUNDLIST";
