import * as actionTypes from "./actionTypes";

/**
 * Get ReminderCount
 */

export type IGetReminderCount = {
	readonly reminderCount: number;
	readonly isGettingReminderCount: boolean;
	readonly isGetReminderCountSuccess: boolean;
	readonly isGetReminderCountError: boolean;
};

export const getReminderCountDefaultValue: IGetReminderCount = {
	reminderCount: 0,
	isGettingReminderCount: false,
	isGetReminderCountSuccess: false,
	isGetReminderCountError: false,
};

type IGetReminderCountAction = {
	type: typeof actionTypes.GET_REMINDERS_COUNT;
};

type IGetReminderCountSuccessAction = {
	type: typeof actionTypes.GET_REMINDERS_COUNT_SUCCESS;
};

type IGetReminderCountFailureAction = {
	type: typeof actionTypes.GET_REMINDERS_COUNT_FAILURE;
};

type IGetReminderCountResetAction = {
	type: typeof actionTypes.GET_REMINDERS_COUNT_RESET;
};

export type IGetReminderCountActions =
	| IGetReminderCountAction
	| IGetReminderCountSuccessAction
	| IGetReminderCountFailureAction
	| IGetReminderCountResetAction;
/**
 * Get Reminder
 */
export type IReminder = {
	readonly memberId: number;
	readonly fundName: string;
	readonly requestId: number;
	readonly requestTitle: string;
	readonly type: string;
};

export type IGetReminder = {
	readonly reminders: IReminder[];
	readonly isGettingReminder: boolean;
	readonly isGetReminderSuccess: boolean;
	readonly isGetReminderError: boolean;
};

export const getReminderDefaultValue: IGetReminder = {
	reminders: [],
	isGettingReminder: false,
	isGetReminderSuccess: false,
	isGetReminderError: false,
};

type IGetReminderAction = {
	type: typeof actionTypes.GET_REMINDERS;
};

type IGetReminderSuccessAction = {
	type: typeof actionTypes.GET_REMINDERS_SUCCESS;
};

type IGetReminderFailureAction = {
	type: typeof actionTypes.GET_REMINDERS_FAILURE;
};

type IGetReminderResetAction = {
	type: typeof actionTypes.GET_REMINDERS_RESET;
};

export type IGetReminderActions =
	| IGetReminderAction
	| IGetReminderSuccessAction
	| IGetReminderFailureAction
	| IGetReminderResetAction;

/**
 * Get ReminderDetails Details
 */

export type IReminderFile = {
	readonly fileName: string;
	readonly originalFileName: string;
	readonly key: string;
	readonly uploadDate: string;
};

export type IReminderDocument = {
	readonly id: number;
	readonly documentId: number;
	readonly title: string;
	readonly category: string;
	readonly information: string;
	readonly description: string;
	readonly activity: boolean;
	readonly isComplete: boolean;
	readonly availabilityDate: string;
	readonly difficulty: number;
	readonly files: IReminderFile[];
};

export type IReminderDetail = {
	readonly id: number;
	readonly title: string;
	readonly state: string;
	readonly movements: Array<string>;
	readonly description: string;
	readonly dueDate: string;
	readonly updatedDate: string;
	readonly documents: IReminderDocument[];
};
export type ICurrentReminder = {
	readonly memberId: number;
	readonly requestId: number;
};

export type IGetReminderDetails = {
	readonly reminderDetails: IReminderDetail[];
	readonly currReminder: ICurrentReminder;
	readonly isGettingReminderDetails: boolean;
	readonly isGetReminderDetailsSuccess: boolean;
	readonly isGetReminderDetailsError: boolean;
};

export const getReminderDetailsDefaultValue: IGetReminderDetails = {
	reminderDetails: [],
	currReminder: {
		memberId: 0,
		requestId: 0,
	},
	isGettingReminderDetails: false,
	isGetReminderDetailsSuccess: false,
	isGetReminderDetailsError: false,
};

export type IGetReminderDetailsPayload = {
	readonly memberId: number;
	readonly requestId: number;
};

type IGetReminderDetailsAction = {
	type: typeof actionTypes.GET_REMINDERS_DETAILS;
	payload: IGetReminderDetailsPayload;
};

type IGetReminderDetailsSuccessAction = {
	type: typeof actionTypes.GET_REMINDERS_DETAILS_SUCCESS;
};

type IGetReminderDetailsFailureAction = {
	type: typeof actionTypes.GET_REMINDERS_DETAILS_FAILURE;
};

type IGetReminderDetailsResetAction = {
	type: typeof actionTypes.GET_REMINDERS_DETAILS_RESET;
};

export type IGetReminderDetailsActions =
	| IGetReminderDetailsAction
	| IGetReminderDetailsSuccessAction
	| IGetReminderDetailsFailureAction
	| IGetReminderDetailsResetAction;

/**
 * RELEASE ALL REMINDERS
 */

export type IReleaseAllReminder = {
	readonly isReleasingAllReminder: boolean;
	readonly isReleaseAllReminderSuccess: boolean;
	readonly isReleaseAllReminderError: boolean;
};

export const releaseAllReminderDefaultValue: IReleaseAllReminder = {
	isReleasingAllReminder: false,
	isReleaseAllReminderSuccess: false,
	isReleaseAllReminderError: false,
};

type IReleaseAllReminderAction = {
	type: typeof actionTypes.RELEASE_ALL_REMINDER;
};

type IReleaseAllReminderSuccessAction = {
	type: typeof actionTypes.RELEASE_ALL_REMINDER_SUCCESS;
};

type IReleaseAllReminderFailureAction = {
	type: typeof actionTypes.RELEASE_ALL_REMINDER_FAILURE;
};

type IReleaseAllReminderResetAction = {
	type: typeof actionTypes.RELEASE_ALL_REMINDER_RESET;
};

export type IReleaseAllReminderActions =
	| IReleaseAllReminderAction
	| IReleaseAllReminderSuccessAction
	| IReleaseAllReminderFailureAction
	| IReleaseAllReminderResetAction;
