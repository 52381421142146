import Layout from "antd/es/layout";
import Result from "antd/es/result";
import React from "react";
import { useSelector } from "react-redux";
import AuthLoader from "__layout/AuthLoader";
import Content from "__layout/Content";
import AppHeader from "__layout/Header";
import { userAuthSlr, userProfileSlr } from "__pages/User/__redux/selectors";
import { UserProfileType, VerifyUserAuthType } from "__pages/User/__redux/types";

export const NotFound: React.FC = () => {
	const userProfile: UserProfileType = useSelector(userProfileSlr);
	const userAuth: VerifyUserAuthType = useSelector(userAuthSlr);
	const { isSuccess } = userAuth;
	const { isGetting } = userProfile;

	if (isGetting) {
		return <AuthLoader isUserAuthVerfied={isSuccess} />;
	}

	return (
		<Layout className="site-layout">
			<AppHeader />
			<Content>
				<Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." />
			</Content>
		</Layout>
	);
};

export default NotFound;
