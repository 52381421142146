import { call, put, takeLatest } from "redux-saga/effects";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";
import { UserProfileType } from "./types";

const getUserProfile = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getUserProfileApi);
		const { status, data } = response;
		if (status === 200) {
			const userData: UserProfileType = data;

			yield put({
				type: actionTypes.GET_USER_PROFILE_SUCCESS,
				...userData,
				isGetting: false,
				isSuccess: true,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_USER_PROFILE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_USER_PROFILE_FAILURE });
	}
};

export const getUserProfileSaga = function* (): any {
	yield takeLatest(actionTypes.GET_USER_PROFILE, getUserProfile);
};

const verifyUserAuth = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.verifyUserAuthApi);
		const { status } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.VERIFY_USER_AUTH_SUCCESS,
				isVerifying: false,
				isSuccess: true,
				isError: false,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.VERIFY_USER_AUTH_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.VERIFY_USER_AUTH_FAILURE });
	}
};

export const verifyUserAuthSaga = function* (): any {
	yield takeLatest(actionTypes.VERIFY_USER_AUTH, verifyUserAuth);
};
