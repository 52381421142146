import { combineReducers } from "redux";
import { invoiceReducer } from "./InvoicesList/__redux/reducers";
import { oneOffDebitReducer } from "./OneOffDebit/OneOffDebitForm/__redux/reducers";
import { recurringDebitsReducer } from "./RecurringDebits/RecurringDebitsForm/__redux/reducers";

export const billingReducer = combineReducers({
	invoice: invoiceReducer,
	oneOffDebit: oneOffDebitReducer,
	recurringDebits: recurringDebitsReducer,
});
