import React from "react";
import { ObjectType } from "__utils/types";
import { SpanStyled } from "./style";

type Props = {
	children: React.ReactNode;
	style?: ObjectType;
	ariaLabel?: string;
	className?: string;
};

const AntdifyIcon: React.FC<Props> = ({ children, style, ariaLabel, className }: Props) => (
	<SpanStyled role="img" className={`antdify anticon ${className || ""}`} aria-label={ariaLabel} style={{ ...style }}>
		{children}
	</SpanStyled>
);

AntdifyIcon.defaultProps = {
	style: {},
	ariaLabel: "",
	className: "",
};

export default AntdifyIcon;
