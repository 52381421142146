import * as actionTypes from "./actionTypes";

/**
 * Get user profile
 */

export type UserProfileType = {
	id: number;
	fullName: string;
	brandName: string;
	brandId: number;
	logo: string;
	level: number;
	hasDefaultEmail: boolean;
	darkMode: boolean;
	logoHeight: number;
	isGetting: boolean;
	isSuccess: boolean;
	isError: boolean;
};

export const userProfileDefaultValue: UserProfileType = {
	id: 0,
	fullName: "",
	brandName: "",
	brandId: 0,
	logo: "",
	level: 0,
	darkMode: false,
	logoHeight: 22,
	hasDefaultEmail: false,
	isGetting: true,
	isSuccess: false,
	isError: false,
};

// Defines the shape of GetUser action creator
export type GetUserProfile = {
	(): GetUserProfileActionTypes;
};

type GetUserProfileAction = {
	type: typeof actionTypes.GET_USER_PROFILE;
};

type GetUserProfileSuccessAction = {
	type: typeof actionTypes.GET_USER_PROFILE_SUCCESS;
};

type GetUserProfileFailureAction = {
	type: typeof actionTypes.GET_USER_PROFILE_FAILURE;
};

export type GetUserProfileActionTypes =
	| GetUserProfileAction
	| GetUserProfileSuccessAction
	| GetUserProfileFailureAction;

/**
 * Verify user auth
 */

export type VerifyUserAuthType = {
	readonly isVerifying: boolean;
	readonly isSuccess: boolean;
	readonly isError: boolean;
};

export const verifyUserAuthDefaultValue: VerifyUserAuthType = {
	isVerifying: true,
	isSuccess: false,
	isError: false,
};

// Defines the shape of VerifyUserAuth action creator
export type VerifyUserAuth = {
	(): VerifyUserAuthActionTypes;
};

type VerifyUserAuthAction = {
	type: typeof actionTypes.VERIFY_USER_AUTH;
};

type VerifyUserAuthSuccessAction = {
	type: typeof actionTypes.VERIFY_USER_AUTH_SUCCESS;
};

type VerifyUserAuthFailureAction = {
	type: typeof actionTypes.VERIFY_USER_AUTH_FAILURE;
};

export type VerifyUserAuthActionTypes =
	| VerifyUserAuthAction
	| VerifyUserAuthSuccessAction
	| VerifyUserAuthFailureAction;
