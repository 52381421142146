import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import {
	GetUserProfileActionTypes,
	userProfileDefaultValue,
	UserProfileType,
	VerifyUserAuthActionTypes,
	verifyUserAuthDefaultValue,
	VerifyUserAuthType,
} from "./types";

export const getUserProfileReducer = (
	state = userProfileDefaultValue,
	action: GetUserProfileActionTypes
): UserProfileType => {
	switch (action.type) {
		case actionTypes.GET_USER_PROFILE:
			return {
				...state,
				isGetting: true,
			};
		case actionTypes.GET_USER_PROFILE_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
			};
		case actionTypes.GET_USER_PROFILE_FAILURE:
			return {
				...state,
				isGetting: false,
				isSuccess: false,
				isError: true,
			};

		default:
			return state;
	}
};

export const verifyUserAuthReducer = (
	state = verifyUserAuthDefaultValue,
	action: VerifyUserAuthActionTypes
): VerifyUserAuthType => {
	switch (action.type) {
		case actionTypes.VERIFY_USER_AUTH:
			return {
				...state,
				isVerifying: true,
			};
		case actionTypes.VERIFY_USER_AUTH_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
			};
		case actionTypes.VERIFY_USER_AUTH_FAILURE:
			return {
				...state,
				isVerifying: false,
				isSuccess: false,
				isError: true,
			};

		default:
			return state;
	}
};

export const userReducer = combineReducers({
	profile: getUserProfileReducer,
	auth: verifyUserAuthReducer,
});
