import { ApiErrorType } from "__utils/types";
import * as actionTypes from "./actionTypes";

/**
 * Get activities
 */

export type ActivityDetailsType = {
	readonly id: number;
	readonly createdAt: number;
	readonly timestamp: number;
	readonly type: string;
	readonly active: boolean;
	readonly body?: string;
	readonly html?: string;
	readonly text?: string;
	readonly subject?: string;
};

export type ActivitiesType = Array<ActivityDetailsType>;

export type GetActivitiesType = {
	readonly activities: ActivitiesType;
	readonly isGettingActivities: boolean;
	readonly isGetActivitiesSuccess: boolean;
	readonly isGetActivitiesError: boolean;
	readonly getActivitiesError: ApiErrorType;
};

export const getActivitiesDefaultValue: GetActivitiesType = {
	activities: [],
	isGettingActivities: false,
	isGetActivitiesSuccess: false,
	isGetActivitiesError: false,
	getActivitiesError: {},
};

export type GetActivitiesPayloadType = {
	hubSpotId: number;
};

export type GetActivitiesCtrType = {
	(payload: GetActivitiesPayloadType): GetActivitiesActionTypes;
};

export type GetActivitiesResetCtrType = {
	(): GetActivitiesActionTypes;
};

type GetActivitiesAction = {
	type: typeof actionTypes.GET_ACTIVITIES;
	payload: GetActivitiesPayloadType;
};

type GetActivitiesSuccessAction = {
	type: typeof actionTypes.GET_ACTIVITIES_SUCCESS;
};

type GetActivitiesFailureAction = {
	type: typeof actionTypes.GET_ACTIVITIES_FAILURE;
};

type GetActivitiesResetAction = {
	type: typeof actionTypes.GET_ACTIVITIES_RESET;
};

export type GetActivitiesActionTypes =
	| GetActivitiesAction
	| GetActivitiesSuccessAction
	| GetActivitiesFailureAction
	| GetActivitiesResetAction;

/**
 * Add activity note
 */
export type AddActivityNoteType = {
	readonly isAddingActivityNote: boolean;
	readonly isAddActivityNoteSuccess: boolean;
	readonly isAddActivityNoteError: boolean;
	readonly addActivityNoteError: ApiErrorType;
};

export const addActivityNoteDefaultValue: AddActivityNoteType = {
	isAddingActivityNote: false,
	isAddActivityNoteSuccess: false,
	isAddActivityNoteError: false,
	addActivityNoteError: {},
};

export type AddActivityNotePayloadType = {
	hubSpotId: number;
	body: string;
};

export type AddActivityNoteCtrType = {
	(payload: AddActivityNotePayloadType): AddActivityNoteActionTypes;
};

export type AddActivityNoteResetCtrType = {
	(): AddActivityNoteActionTypes;
};

type AddActivityNoteAction = {
	type: typeof actionTypes.ADD_ACTIVITY_NOTE;
	payload: AddActivityNotePayloadType;
};

type AddActivityNoteSuccessAction = {
	type: typeof actionTypes.ADD_ACTIVITY_NOTE_SUCCESS;
};

type AddActivityNoteFailureAction = {
	type: typeof actionTypes.ADD_ACTIVITY_NOTE_FAILURE;
};

type AddActivityNoteResetAction = {
	type: typeof actionTypes.ADD_ACTIVITY_NOTE_RESET;
};

export type AddActivityNoteActionTypes =
	| AddActivityNoteAction
	| AddActivityNoteSuccessAction
	| AddActivityNoteFailureAction
	| AddActivityNoteResetAction;
