import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

import { EditBankDetailsActionTypes, EditBankDetailsType, editBankDetailsDefaultValue } from "./types";

export const editBankDetailsReducer = (
	state = editBankDetailsDefaultValue,
	action: EditBankDetailsActionTypes
): EditBankDetailsType => {
	switch (action.type) {
		case actionTypes.EDIT_BANK_DETAILS:
			return {
				...state,
				isEditing: true,
			};
		case actionTypes.EDIT_BANK_DETAILS_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isError: false,
				isSuccess: true,
				isEditing: false,
			};
		case actionTypes.EDIT_BANK_DETAILS_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isEditing: false,
				isError: true,
				isSuccess: false,
			};
		case actionTypes.EDIT_BANK_DETAILS_RESET:
			return {
				...state,
				...editBankDetailsDefaultValue,
			};
		default:
			return state;
	}
};

export const bankDetailsReducer = combineReducers({
	editBankDetails: editBankDetailsReducer,
});
