import React from "react";
import { Container } from "./style";

type Props = {
	background: string;
	children: React.ReactNode;
	className?: string;
};

const IconWrapper: React.FC<Props> = ({ children, background, className }: Props) => (
	<Container style={{ background }} className={className}>
		{children}
	</Container>
);

export default IconWrapper;
