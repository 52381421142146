import styled from "styled-components";

export const Container = styled.div<any>`
	.ant-input {
		border-radius: 0;
	}
	.ant-input-number {
		border-radius: 0;
		width: 100%;
	}
	.numberInput {
		width: 50%;
	}

	.tick-icon {
		position: absolute;
		z-index: 1;
		bottom: 25px;
		right: 12px;

		&.error-mode {
			bottom: 37px;
		}

		svg {
			font-size: 16px;
		}

		.icon-check {
			fill: #52c41a;
		}

		.icon-close {
			fill: #ff4d4f;
		}
		.icon-loading {
			color: ${(p: any) => p.theme.primaryColor};
		}
	}

	${(p) =>
		p.isDisabled &&
		`
        .ant-input {
            background: transparent;
            color: #000000d9;
        }

        .ant-select {
            .ant-select-selector {
                background: transparent !important;
                color: #000000d9 !important;
            }

            .ant-select-arrow .anticon {
                display: none;
            }
        }
    `}

	.fund-structure-form-buttons-wrapper {
		text-align: right;
		button:not(:last-child) {
			margin-right: 5px;
		}
	}
`;
