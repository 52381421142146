import { sortObjects } from "__utils/helpers";
import { IStates } from "__common/Workflow/__redux/types";
import {
	FundDetailsType,
	FundsType,
	IFundsById,
	RequestStatusesDataType,
	RequestSummaryDetailsType,
	RequestSummaryType,
	IAlert,
	IFundsUserMeta,
} from "../__redux/types";

/**
 * Determines if any of the props in FundItem being provided has changed
 * @param otherArray
 */
export const compareByFund = (otherArray: FundsType) => (current: FundDetailsType) =>
	otherArray.filter(
		(other: FundDetailsType) => other.id === current.id && JSON.stringify(other) !== JSON.stringify(current)
	).length > 0;

/**
 * Determines if the FundItem being provided does not exist
 * @param otherArray
 */
export const compareById = (otherArray: FundsType) => (current: FundDetailsType) =>
	otherArray.filter((other: FundDetailsType) => other.id === current.id).length === 0;

/**
 * Determines if the state prop of the FundItem being provided has changed
 * @param otherArray
 */
export const compareByIdAndState = (otherArray: FundsType) => (current: FundDetailsType) =>
	otherArray.filter((other: FundDetailsType) => other.id === current.id && other.state !== current.state).length > 0;

/**
 * Returns newly addded funds
 * @param oldFunds
 * @param newFunds
 * @param compare
 */
export const getNewFunds = (oldFunds: FundsType, newFunds: FundsType, compare: any): FundsType => {
	if (!oldFunds.length) {
		return [];
	}

	const onlyInOld: FundsType = oldFunds.filter(compare(newFunds));
	const onlyInNew: FundsType = newFunds.filter(compare(oldFunds));

	return onlyInOld.concat(onlyInNew);
};

/**
 * Return funds with changed prop value
 * @param oldFunds
 * @param newFunds
 * @param compare
 */
export const getChangedFunds = (
	oldFunds: FundsType,
	newFunds: FundsType,
	compare: (param: FundsType) => (current: FundDetailsType) => boolean
): FundsType => {
	if (!oldFunds.length) {
		return [];
	}

	return oldFunds.filter(compare(newFunds));
};

/**
 * Returns funds with favorites on the top of the list
 * @param funds
 */
export const bubbleFavourites = (funds: FundsType): FundsType => {
	const favourites: FundsType = funds.filter((item: FundDetailsType) => item.favourite === true);
	const sortedFavourites: FundsType = sortObjects<FundDetailsType>(favourites, "id", -1);
	const withoutFavourites: FundsType = funds.filter((item: FundDetailsType) => item.favourite === false);

	return [...sortedFavourites, ...withoutFavourites];
};

const getAlertValue = (requests: RequestSummaryType, fund: FundDetailsType): IAlert => {
	let alert: IAlert = { alertType: {}, color: "" };
	const { balanceIndicator, id } = fund;

	requests?.forEach((req: RequestSummaryDetailsType) => {
		if (req.alert === "Activity") {
			alert = {
				alertType: {
					"Request Activity": { name: "Request", color: "orange", url: `/fund-details/${id}/requests` },
				},
				color: "orange",
			};
		} else if (req.alert === "Overdue" && alert?.color !== "orange") {
			alert = {
				alertType: {
					"Request Overdue": { name: "Request", color: "red", url: `/fund-details/${id}/requests` },
				},
				color: "red",
			};
		}
	});

	const { alertType, color } = alert;
	if (balanceIndicator === "ALERT") {
		if (color === "red") {
			alert = {
				alertType: { "Balance Alert": { url: "", color: "red", name: "Balance" }, ...alertType },
				color: "red",
			};
		} else {
			alert = {
				alertType: { ...alertType, "Balance Alert": { url: "", color: "red", name: "Balance" } },
				color: "red",
			};
		}
	} else if (balanceIndicator === "WARNING") {
		if (color === "red") {
			alert = {
				alertType: { "Balance Warning": { url: "", color: "orange", name: "Balance" }, ...alertType },
				color: color === "red" ? color : "orange",
			};
		} else {
			alert = {
				alertType: { ...alertType, "Balance Warning": { url: "", color: "orange", name: "Balance" } },
				color: color === "red" ? color : "orange",
			};
		}
	}

	return alert;
};

/**
 * Adds requests, alert, and string version of state props to funds
 * @param funds
 * @param requestStatuses
 * @returns
 */
export const addCustomPropsProps = (funds: FundsType, requestStatusesData: RequestStatusesDataType): FundsType => {
	const { requestStatuses } = requestStatusesData;

	return funds.reduce((acc: FundsType, curr: FundDetailsType) => {
		const requests: RequestSummaryType = requestStatuses[`${curr.id}`];

		acc.push({ ...curr, requests, alert: getAlertValue(requests, curr) });
		return acc;
	}, []);
};

export const addUserMeta = (funds: FundsType, fundsUserMeta: IFundsUserMeta): FundsType => {
	return funds.reduce((acc: FundsType, curr: FundDetailsType) => {
		if (fundsUserMeta[`${curr.id}`]) {
			const { phone, email, name } = fundsUserMeta[`${curr.id}`];
			acc.push({ ...curr, email, phone, fullName: name });
		} else {
			acc.push({
				...curr,
				email: [curr.email as unknown as string],
				phone: [curr.phone as unknown as string],
				fullName: [curr.fullName as unknown as string],
			});
		}
		return acc;
	}, []);
};

export const buildFundsById = (funds: FundsType): IFundsById => {
	let byID: IFundsById = {};
	for (const fund of funds) {
		byID = { ...byID, [fund.id]: fund };
	}

	return byID;
};

export const filterOutAssignedToWorkflow = (fundIds: number[], fundsById: IFundsById): number[] =>
	fundIds.reduce((acc: number[], id: number) => {
		const fund: FundDetailsType = fundsById[id];
		if (!fund?.state && fund?.status === "Active") {
			acc.push(id);
		}
		return acc;
	}, []);

/**
 * Get states id
 * @param states
 * @param stateTitle
 * @returns
 */

export const getStateId = (states: IStates, stateTitle: string): number[] => {
	const ids: number[] = [];
	Object.keys(states).forEach((keyName) => {
		if (states[keyName].title.toUpperCase() === stateTitle.toUpperCase()) {
			ids.push(states[keyName].id);
		}
	});

	return ids;
};
