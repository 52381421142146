import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import {
	AddTemplateActionTypes,
	addTemplateDefaultValue,
	AddTemplateType,
	DeleteTemplateActionTypes,
	deleteTemplateDefaultValue,
	DeleteTemplateType,
	EditTemplateActionTypes,
	editTemplateDefaultValue,
	EditTemplateType,
	GetTemplateCategoriesActionTypes,
	getTemplateCategoriesDefaultValue,
	GetTemplateCategoriesType,
	GetTemplateDetailsActionTypes,
	getTemplateDetailsDefaultValue,
	GetTemplateDetailsType,
	GetTemplatesActionTypes,
	getTemplatesDefaultValue,
	GetTemplatesType,
} from "./types";

export const getTemplateDetailsReducer = (
	state = getTemplateDetailsDefaultValue,
	action: GetTemplateDetailsActionTypes
): GetTemplateDetailsType => {
	switch (action.type) {
		case actionTypes.GET_TEMPLATE_DETAILS:
			return {
				...state,
				isGettingTemplateDetails: true,
			};
		case actionTypes.GET_TEMPLATE_DETAILS_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isGetTemplateDetailsError: false,
				isGetTemplateDetailsSuccess: true,
				isGettingTemplateDetails: false,
				getTemplateDetailsError: {},
			};
		case actionTypes.GET_TEMPLATE_DETAILS_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isGettingTemplateDetails: false,
				isGetTemplateDetailsError: true,
				isGetTemplateDetailsSuccess: false,
			};

		case actionTypes.GET_TEMPLATE_DETAILS_RESET:
			return {
				...getTemplateDetailsDefaultValue,
			};

		default:
			return state;
	}
};

export const getTemplatesReducer = (
	state = getTemplatesDefaultValue,
	action: GetTemplatesActionTypes
): GetTemplatesType => {
	switch (action.type) {
		case actionTypes.GET_TEMPLATES:
			return {
				...state,
				isGettingTemplates: true,
			};
		case actionTypes.GET_TEMPLATES_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isGetTemplatesError: false,
				isGetTemplatesSuccess: true,
				isGettingTemplates: false,
				getTemplatesError: {},
			};
		case actionTypes.GET_TEMPLATES_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isGettingTemplates: false,
				isGetTemplatesError: true,
				isGetTemplatesSuccess: false,
			};
		case actionTypes.GET_TEMPLATES_RESET:
			return {
				...state,
				...getTemplatesDefaultValue,
			};

		default:
			return state;
	}
};

export const deleteTemplateReducer = (
	state = deleteTemplateDefaultValue,
	action: DeleteTemplateActionTypes
): DeleteTemplateType => {
	switch (action.type) {
		case actionTypes.DELETE_TEMPLATE:
			return {
				...state,
				isDeletingTemplate: true,
			};

		case actionTypes.DELETE_TEMPLATE_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isDeleteTemplateError: false,
				isDeleteTemplateSuccess: true,
				isDeletingTemplate: false,
			};
		case actionTypes.DELETE_TEMPLATE_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isDeletingTemplate: false,
				isDeleteTemplateError: true,
			};

		case actionTypes.DELETE_TEMPLATE_RESET:
			return {
				...state,
				...deleteTemplateDefaultValue,
			};
		default:
			return state;
	}
};

export const addTemplateReducer = (
	state = addTemplateDefaultValue,
	action: AddTemplateActionTypes
): AddTemplateType => {
	switch (action.type) {
		case actionTypes.ADD_TEMPLATE:
			return {
				...state,
				isAddingTemplate: true,
			};
		case actionTypes.ADD_TEMPLATE_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isAddTemplateError: false,
				isAddTemplateSuccess: true,
				isAddingTemplate: false,
				addTemplateError: {},
			};
		case actionTypes.ADD_TEMPLATE_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isAddingTemplate: false,
				isAddTemplateError: true,
				isAddTemplateSuccess: false,
			};
		case actionTypes.ADD_TEMPLATE_RESET:
			return {
				...state,
				...addTemplateDefaultValue,
			};
		default:
			return state;
	}
};

export const editTemplateReducer = (
	state = editTemplateDefaultValue,
	action: EditTemplateActionTypes
): EditTemplateType => {
	switch (action.type) {
		case actionTypes.EDIT_TEMPLATE:
			return {
				...state,
				isEditingTemplate: true,
			};
		case actionTypes.EDIT_TEMPLATE_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isEditTemplateError: false,
				isEditTemplateSuccess: true,
				isEditingTemplate: false,
				editTemplateError: {},
			};
		case actionTypes.EDIT_TEMPLATE_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isEditingTemplate: false,
				isEditTemplateError: true,
				isEditTemplateSuccess: false,
			};
		case actionTypes.EDIT_TEMPLATE_RESET:
			return {
				...state,
				...editTemplateDefaultValue,
			};
		default:
			return state;
	}
};

export const getTemplateCategoriesReducer = (
	state = getTemplateCategoriesDefaultValue,
	action: GetTemplateCategoriesActionTypes
): GetTemplateCategoriesType => {
	switch (action.type) {
		case actionTypes.GET_TEMPLATE_CATEGORIES:
			return {
				...state,
				isGettingTemplateCategories: true,
			};
		case actionTypes.GET_TEMPLATE_CATEGORIES_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isGetTemplateCategoriesError: false,
				isGetTemplateCategoriesSuccess: true,
				isGettingTemplateCategories: false,
				getTemplateCategoriesError: {},
			};
		case actionTypes.GET_TEMPLATE_CATEGORIES_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isGettingTemplateCategories: false,
				isGetTemplateCategoriesError: true,
				isGetTemplateCategoriesSuccess: false,
			};
		case actionTypes.GET_TEMPLATE_CATEGORIES_RESET:
			return {
				...state,
				...getTemplateCategoriesDefaultValue,
			};
		default:
			return state;
	}
};

export const templateReducer = combineReducers({
	getTemplates: getTemplatesReducer,
	getTemplateDetails: getTemplateDetailsReducer,
	addTemplate: addTemplateReducer,
	editTemplate: editTemplateReducer,
	getTemplateCategories: getTemplateCategoriesReducer,
	deleteTemplate: deleteTemplateReducer,
});
