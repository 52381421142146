import Modal from "antd/es/modal";
import styled, { createGlobalStyle } from "styled-components";

export const Container = styled.div``;

export const ModalStyled = styled(Modal)`
	h4 {
		margin-bottom: 0;
	}

	.add-member {
		display: none;
	}
`;

export const GlobalStyle = createGlobalStyle`
    .establish-fund-modal { 
        transition: ${(p: any) => p.theme.transition};

    }
`;
