import styled from "styled-components";

export const DeleteModalContent = styled.div`
	display: flex;

	.icon span {
		margin-right: 16px;
		font-size: 22px;
	}
`;
