import cx from "classnames";
import React from "react";
import { outerHeight, outerWidth } from "__utils/helpers";
import { SpanStyled } from "./style";

const getSideLength = (anticon: HTMLElement | undefined | null): number => {
	const targetElWidth = outerWidth(anticon);
	const targetElHeight = outerHeight(anticon);

	return Math.max(targetElWidth, targetElHeight);
};

type Props = {
	children: React.ReactNode;
	disabled?: boolean;
	padding?: number;
	className?: string;
};

const IconButtonBg: React.FC<Props> = ({ children, disabled, className = "", padding = 14 }: Props) => {
	const ref = React.useRef<HTMLElement>();
	const [sideLen, setSideLen] = React.useState<string>();

	React.useEffect(() => {
		const _sideLen = `${getSideLength(ref.current?.querySelector(":scope .anticon")) + padding}px`;
		setSideLen(_sideLen);
	}, [padding]);

	return (
		<SpanStyled
			ref={ref}
			className={cx(`btn-bg ${className}`, { disabled })}
			style={{ width: sideLen, height: sideLen }}
		>
			{children}
		</SpanStyled>
	);
};

export default IconButtonBg;
