import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import {
	GetFundStatesActionTypes,
	getFundStatesDefaultValue,
	GetFundStatesType,
	SetNewStateActionTypes,
	setNewStateDefaultValue,
	SetNewStateType,
} from "./types";

export const setNewStateReducer = (
	state = setNewStateDefaultValue,
	action: SetNewStateActionTypes
): SetNewStateType => {
	switch (action.type) {
		case actionTypes.SET_NEW_STATE:
			return {
				...state,
				memberId: (action as any).payload.memberId,
				isSettingNewState: true,
			};
		case actionTypes.SET_NEW_STATE_SUCCESS:
			return {
				...state,
				isSettingNewState: false,
				isSetNewStateSuccess: true,
				isSetNewStateError: false,
			};
		case actionTypes.SET_NEW_STATE_FAILURE:
			return {
				...state,
				isSettingNewState: false,
				isSetNewStateSuccess: false,
				isSetNewStateError: true,
			};
		case actionTypes.SET_NEW_STATE_RESET:
			return {
				...state,
				...setNewStateDefaultValue,
			};
		default:
			return state;
	}
};

export const getFundStatesReducer = (
	state = getFundStatesDefaultValue,
	action: GetFundStatesActionTypes
): GetFundStatesType => {
	switch (action.type) {
		case actionTypes.GET_FUND_STATES:
			return {
				...state,
				isGettingFundStates: true,
			};
		case actionTypes.GET_FUND_STATES_SUCCESS:
			return {
				...state,
				isGettingFundStates: false,
				isGetFundStatesSuccess: true,
				isGetFundStatesError: false,
			};
		case actionTypes.GET_FUND_STATES_FAILURE:
			return {
				...state,
				isGettingFundStates: false,
				isGetFundStatesSuccess: false,
				isGetFundStatesError: true,
			};
		default:
			return state;
	}
};

export const fundStatesReducer = combineReducers({
	setNewState: setNewStateReducer,
	getFundStates: getFundStatesReducer,
});
