export const GET_REMINDERS_COUNT = "GET_REMINDERS_COUNT";
export const GET_REMINDERS_COUNT_SUCCESS = "GET_REMINDERS_COUNT_SUCCESS";
export const GET_REMINDERS_COUNT_FAILURE = "GET_REMINDERS_COUNT_FAILURE";
export const GET_REMINDERS_COUNT_RESET = "GET_REMINDERS_COUNT_RESET";

export const GET_REMINDERS = "GET_REMINDERS";
export const GET_REMINDERS_SUCCESS = "GET_REMINDERS_SUCCESS";
export const GET_REMINDERS_FAILURE = "GET_REMINDERS_FAILURE";
export const GET_REMINDERS_RESET = "GET_REMINDERS_RESET";

export const GET_REMINDERS_DETAILS = "GET_REMINDERS_DETAILS";
export const GET_REMINDERS_DETAILS_SUCCESS = "GET_REMINDERS_DETAILS_SUCCESS";
export const GET_REMINDERS_DETAILS_FAILURE = "GET_REMINDERS_DETAILS_FAILURE";
export const GET_REMINDERS_DETAILS_RESET = "GET_REMINDERS_DETAILS_RESET";

export const RELEASE_ALL_REMINDER = "RELEASE_ALL_REMINDER";
export const RELEASE_ALL_REMINDER_SUCCESS = "RELEASE_ALL_REMINDER_SUCCESS";
export const RELEASE_ALL_REMINDER_FAILURE = "RELEASE_ALL_REMINDER_FAILURE";
export const RELEASE_ALL_REMINDER_RESET = "RELEASE_ALL_REMINDER_RESET";
