import Form from "antd/es/form";
import React, { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandDetailsType, GetBrandsType } from "__pages/Administration/__redux/types";
import AccountForm from "__pages/FundDetails/__pages/Account/AccountDetails/PrimaryAccount/EditAccountModal/AccountForm";
import { addAccountCtr } from "__pages/FundDetails/__pages/Account/AccountDetails/PrimaryAccount/__redux/actions";
import { addAccountSlr } from "__pages/FundDetails/__pages/Account/AccountDetails/PrimaryAccount/__redux/selectors";
import { AddAccountType } from "__pages/FundDetails/__pages/Account/AccountDetails/PrimaryAccount/__redux/types";
import { AccountType } from "__pages/FundDetails/__redux/types";
import { processErrors } from "__utils/helpers";

type Props = {
	account: AccountType;
	getbrands: GetBrandsType;
	isSubmitAddAccount: boolean;
	setSubmitAddAccount: Dispatch<SetStateAction<boolean>>;
};

const AddAccount: React.FC<Props> = ({ account, getbrands, isSubmitAddAccount, setSubmitAddAccount }: Props) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { setFields, validateFields } = form;
	const addAccount: AddAccountType = useSelector(addAccountSlr);
	const { isError, error } = addAccount;
	const { brands } = getbrands;

	React.useEffect(() => {
		if (isSubmitAddAccount) {
			validateFields()
				.then((values: any) => {
					const _brand = brands.filter((data: BrandDetailsType) => data.title === values.administratorBrand);
					const _account: AddAccountType = { ...values, administratorBrand: _brand[0].id };
					dispatch(addAccountCtr(_account));
				})
				.catch(() => {
					setSubmitAddAccount(false);
				});
		}
	}, [brands, isSubmitAddAccount, dispatch, validateFields, setSubmitAddAccount]);

	React.useEffect(() => {
		if (isError) {
			setFields(processErrors(error));
			setSubmitAddAccount(false);
		}
	}, [isError, error, setFields, setSubmitAddAccount]);

	if (!brands.length) {
		return null;
	}

	return <AccountForm form={form} account={account} getbrands={getbrands} />;
};

export default AddAccount;
