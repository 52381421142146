import React, { Dispatch, SetStateAction } from "react";
import { GetFundDetailsType } from "__pages/FundDetails/__redux/types";
import AddMember from "./AddMember";
import EditMember from "./EditMember";
import { Container } from "./style";

type Props = {
	fund: GetFundDetailsType;
	setShowMemberForm: Dispatch<SetStateAction<boolean>>;
	memberId: number;
};

const Member: React.FC<Props> = ({ fund, setShowMemberForm, memberId }: Props) => (
	<Container>
		{memberId === 0 ? (
			<AddMember fund={fund} setShowMemberForm={setShowMemberForm} />
		) : (
			<EditMember fund={fund} setShowMemberForm={setShowMemberForm} memberId={memberId} />
		)}
	</Container>
);

export default Member;
