// Get Brand Data
export const GET_BRANDS = "GET_BRANDS";
export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS";
export const GET_BRANDS_FAILURE = "GET_BRANDS_FAILURE";
export const GET_BRANDS_RESET = "GET_BRANDS_RESET";

// get action levels
export const GET_ADMIN_LEVELS = "GET_ADMIN_LEVELS";
export const GET_ADMIN_LEVELS_SUCCESS = "GET_ADMIN_LEVELS_SUCCESS";
export const GET_ADMIN_LEVELS_FAILURE = "GET_ADMIN_LEVELS_FAILURE";
export const GET_ADMIN_LEVELS_RESET = "GET_ADMIN_LEVELS_RESET";

// Add Brand
export const ADD_BRAND = "ADD_BRAND";
export const ADD_BRAND_SUCCESS = "ADD_BRAND_SUCCESS";
export const ADD_BRAND_FAILURE = "ADD_BRAND_FAILURE";
export const ADD_BRAND_RESET = "ADD_BRAND_RESET";

// Edit Brand
export const EDIT_BRAND = "EDIT_BRAND";
export const EDIT_BRAND_SUCCESS = "EDIT_BRAND_SUCCESS";
export const EDIT_BRAND_FAILURE = "EDIT_BRAND_FAILURE";
export const EDIT_BRAND_RESET = "EDIT_BRAND_RESET";

// Create User
export const ADD_ADMIN = "ADD_ADMIN";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS";
export const ADD_ADMIN_FAILURE = "ADD_ADMIN_FAILURE";
export const ADD_ADMIN_RESET = "ADD_ADMIN_RESET";

// Edit Admin
export const EDIT_ADMIN = "EDIT_ADMIN";
export const EDIT_ADMIN_SUCCESS = "EDIT_ADMIN_SUCCESS";
export const EDIT_ADMIN_FAILURE = "EDIT_ADMIN_FAILURE";
export const EDIT_ADMIN_RESET = "EDIT_ADMIN_RESET";

// Delete Admin
export const DELETE_ADMIN = "DELETE_ADMIN";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAILURE = "DELETE_ADMIN_FAILURE";
export const DELETE_ADMIN_RESET = "DELETE_ADMIN_RESET";

// Get brands on scroll
export const GET_BRANDS_ON_SCROLL = "GET_BRANDS_ON_SCROLL";
export const GET_BRANDS_ON_SCROLL_SUCCESS = "GET_BRANDS_ON_SCROLL_SUCCESS";
