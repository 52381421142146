import * as actionTypes from "./actionTypes";

import {
	IGetReminderCountActions,
	IGetReminderActions,
	IGetReminderDetailsActions,
	IGetReminderDetailsPayload,
	IReleaseAllReminderActions,
} from "./types";

// Get Reminder Count
export const getReminderCountCtr = (): IGetReminderCountActions => ({
	type: actionTypes.GET_REMINDERS_COUNT,
});

// Get Reminder Count Reset
export const getReminderCountResetCtr = (): IGetReminderCountActions => ({
	type: actionTypes.GET_REMINDERS_COUNT_RESET,
});

// Get Reminder
export const getReminderCtr = (): IGetReminderActions => ({
	type: actionTypes.GET_REMINDERS,
});

// Get Reminder Reset
export const getReminderResetCtr = (): IGetReminderActions => ({
	type: actionTypes.GET_REMINDERS_RESET,
});

// Get Reminder Details
export const getReminderDetailsCtr = (payload: IGetReminderDetailsPayload): IGetReminderDetailsActions => ({
	type: actionTypes.GET_REMINDERS_DETAILS,
	payload,
});

// Get Reminder Details Reset
export const getReminderDetailsResetCtr = (): IGetReminderDetailsActions => ({
	type: actionTypes.GET_REMINDERS_DETAILS_RESET,
});

// Release All Reminders
export const releaseAllReminderCtr = (): IReleaseAllReminderActions => ({
	type: actionTypes.RELEASE_ALL_REMINDER,
});

//  Release All Reminders Reset
export const releaseAllReminderResetCtr = (): IReleaseAllReminderActions => ({
	type: actionTypes.RELEASE_ALL_REMINDER_RESET,
});
