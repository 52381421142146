import {
	EditAccountType,
	AddAccountType,
	ArchiveFundDetailsType,
	RestoreFundDetailsType,
	DeleteFundDetailsType,
	OverrideLowBalanceFlagType,
	GetLiveFundValueType,
	DeactivateFundDetailsType,
} from "./types";

export const editAccountSlr = (state: any): EditAccountType => state.fundDetails.account.accountDetails.editAccount;
export const addAccountSlr = (state: any): AddAccountType => state.fundDetails.account.accountDetails.addAccount;
export const archiveFundDetailsSlr = (state: any): ArchiveFundDetailsType =>
	state.fundDetails.account.accountDetails.archiveFundDetails;
export const restoreFundDetailsSlr = (state: any): RestoreFundDetailsType =>
	state.fundDetails.account.accountDetails.restoreFundDetails;
export const deleteFundDetailsSlr = (state: any): DeleteFundDetailsType =>
	state.fundDetails.account.accountDetails.deleteFundDetails;
export const deactivateFundDetailsSlr = (state: any): DeactivateFundDetailsType =>
	state.fundDetails.account.accountDetails.deactivateFundDetails;
export const overrideLowBalanceFlagSlr = (state: any): OverrideLowBalanceFlagType =>
	state.fundDetails.account.accountDetails.overrideLowBalanceFlag;
export const getLiveFundValueSlr = (state: any): GetLiveFundValueType =>
	state.fundDetails.account.accountDetails.getLiveFundValue;
