import { AxiosResponse, AxiosInstance } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { ObjectType } from "__utils/types";
import { DownloadReportPayloadType } from "../__redux/types";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);
const axiosOptions: ObjectType = {
	responseType: "blob",
	timeout: 30000,
};

export const downloadReportApi = async ({ url }: DownloadReportPayloadType): Promise<AxiosResponse<any>> =>
	axios.get(url, axiosOptions).catch(errorHandler);
