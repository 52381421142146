import styled from "styled-components";

export const Container = styled.div`
	.ant-input {
		border-radius: 0;
	}

	.ant-select {
		width: 100%;
	}

	.add-members {
		margin-bottom: 20px;

		.ant-row .new-member-section {
			border-color: #4538d1;
			color: #4538d1;
		}

		&--icon {
			font-size: 30px;
			padding: 0;
			margin: 0;
			margin-bottom: 0px;
		}

		&--card {
			text-align: center;
			color: #777;
		}

		&--link {
			margin: 0px;
		}

		&--blank-icon {
			color: #fff !important;
			cursor: default !important;
		}
	}

	.disabled {
		color: #ddd;
	}
`;
