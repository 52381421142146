export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
export const GET_DOCUMENTS_FAILURE = "GET_DOCUMENTS_FAILURE";
export const GET_DOCUMENTS_RESET = "GET_DOCUMENTS_RESET";

export const FILTER_DOCUMENTS = "FILTER_DOCUMENTS";
export const FILTER_DOCUMENTS_RESET = "FILTER_DOCUMENTS_RESET";

export const GET_DOCUMENTS_ON_SCROLL = "GET_DOCUMENTS_ON_SCROLL";
export const GET_DOCUMENTS_ON_SCROLL_SUCCESS = "GET_DOCUMENTS_ON_SCROLL";

export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILURE = "DELETE_DOCUMENT_FAILURE";
export const DELETE_DOCUMENT_RESET = "DELETE_DOCUMENT_RESET";

export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const ADD_DOCUMENT_FAILURE = "ADD_DOCUMENT_FAILURE";
export const ADD_DOCUMENT_RESET = "ADD_DOCUMENT_RESET";

export const EDIT_DOCUMENT = "EDIT_DOCUMENT";
export const EDIT_DOCUMENT_SUCCESS = "EDIT_DOCUMENT_SUCCESS";
export const EDIT_DOCUMENT_FAILURE = "EDIT_DOCUMENT_FAILURE";
export const EDIT_DOCUMENT_RESET = "EDIT_DOCUMENT_RESET";

export const GET_DOC_CATEGORIES = "GET_DOC_CATEGORIES";
export const GET_DOC_CATEGORIES_SUCCESS = "GET_DOC_CATEGORIES_SUCCESS";
export const GET_DOC_CATEGORIES_FAILURE = "GET_DOC_CATEGORIES_FAILURE";
export const GET_DOC_CATEGORIES_RESET = "GET_DOC_CATEGORIES_RESET";

export const GET_DOCUMENT_DETAILS = "GET_DOCUMENT_DETAILS";
export const GET_DOCUMENT_DETAILS_SUCCESS = "GET_DOCUMENT_DETAILS_SUCCESS";
export const GET_DOCUMENT_DETAILS_FAILURE = "GET_DOCUMENT_DETAILS_FAILURE";
export const GET_DOCUMENT_DETAILS_RESET = "GET_DOCUMENT_DETAILS_RESET";
