import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const style = css`
	cursor: pointer;

	&.disabled {
		color: #ccc;
		cursor: not-allowed;
	}
`;

export const SpanStyled = styled.span`
	${style}

	color: ${(p: any) => p.theme.primaryColor};
`;

export const LinkStyled = styled(Link)`
	${style}

	color: ${(p: any) => p.theme.primaryColor};
`;
