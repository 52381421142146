import { css } from "styled-components";

export const ellipsis = css`
	width: 100%;
	display: block;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

export const headerStyle = css`
	position: fixed;
	right: 0;
	top: 0;
	left: 0;
	z-index: 1;
	background: #eee;
	border-bottom: 1px solid #ddd;
	width: 100%;
	height: 60px;
	line-height: 60px;
	padding: 0;
	z-index: 200;
`;

export const cardStyle = css`
	.ant-card {
		border: none;
		background-color: #fff;
		box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.05);
	}
`;

export const wrapperStyle = css`
	width: 100%;
	height: 100%;
	padding: 25px;
`;

export const selectCustomStyle = css`
	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		border-top: none;
		border-left: none;
		border-right: none;
		background: none;
		border-radius: 0;
	}

	.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-arrow {
		color: rgba(77, 71, 102, 1);
	}

	.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
		.ant-select-selector {
		box-shadow: none;
	}

	.ant-select-single.ant-select-open .ant-select-selection-item,
	.ant-select-single.ant-select-open .ant-select-selection-item {
		color: rgba(77, 71, 102, 1);
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		padding: 2px;
	}

	.ant-select-arrow {
		right: 2px;
	}
	.ant-select svg {
		font-size: inherit !important;
	}
`;

export const blueBoxStyle = css`
	background: #fafcff;
	padding: 24px;
	text-align: center;
`;
