import styled from "styled-components";
import { headerStyle } from "__styles/common";

export const Container = styled.div`
	height: 100vh;
	display: flex;

	.header {
		${headerStyle}
	}

	.content {
		padding-top: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
	}
`;
