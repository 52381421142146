import { call, cancel, delay, fork, put, take, takeLatest } from "redux-saga/effects";
import { ResponseGeneratorType, TaskGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";
import { IGetWorkflowSummary } from "./types";

const moveWorkflowState = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.moveWorkflowStateApi, payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_WORKFLOW_UPDATE_SUCCESS,
				workflowUpdate: data,
			});

			yield delay(50);

			yield put({
				type: actionTypes.MOVE_WORKFLOW_STATE_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.MOVE_WORKFLOW_STATE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.MOVE_WORKFLOW_STATE_FAILURE });
	}
};

export const moveWorkflowStateSaga = function* (): any {
	yield takeLatest(actionTypes.MOVE_WORKFLOW_STATE, moveWorkflowState);
};

const getWorkflowSummary = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getWorkflowSummaryApi);
		const { status, data } = response;

		if (status === 200) {
			const { workflowSummary }: IGetWorkflowSummary = data;

			yield put({
				type: actionTypes.GET_WORKFLOW_SUMMARY_SUCCESS,
				workflowSummary: { ...workflowSummary },
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_WORKFLOW_SUMMARY_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_WORKFLOW_SUMMARY_FAILURE });
	}
};

export const getWorkflowSummarySaga = function* (): any {
	while (true) {
		const getAction: ResponseGeneratorType = yield take(actionTypes.GET_WORKFLOW_SUMMARY);
		const task: Array<TaskGeneratorType> = yield fork(getWorkflowSummary);
		const action: ResponseGeneratorType = yield take([
			actionTypes.GET_WORKFLOW_SUMMARY_SUCCESS,
			actionTypes.GET_WORKFLOW_SUMMARY_CANCEL,
			actionTypes.GET_WORKFLOW_SUMMARY_FAILURE,
		]);

		if (action.type === actionTypes.GET_WORKFLOW_SUMMARY_CANCEL) {
			yield cancel(task);
		}
	}
};

const getWorkflowUpdate = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getWorkflowUpdateApi, payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_WORKFLOW_UPDATE_SUCCESS,
				workflowUpdate: data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_WORKFLOW_UPDATE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_WORKFLOW_UPDATE_FAILURE });
	}
};

export const getWorkflowUpdateSaga = function* (): any {
	yield takeLatest(actionTypes.GET_WORKFLOW_UPDATE, getWorkflowUpdate);
};

const addToWorkflow = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.addToWorkflowApi, payload);
		const { status } = response;

		if (status === 201) {
			yield put({
				type: actionTypes.ADD_TO_WORKFLOW_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.ADD_TO_WORKFLOW_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.ADD_TO_WORKFLOW_FAILURE });
	}
};

export function* addToWorkflowSaga(): any {
	yield takeLatest(actionTypes.ADD_TO_WORKFLOW, addToWorkflow);
}

const getWorkflowHistory = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getWorkflowHistoryApi, payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_WORKFLOW_HISTORY_SUCCESS,
				workflowHistory: [...data.data],
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_WORKFLOW_HISTORY_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_WORKFLOW_HISTORY_FAILURE });
	}
};

export function* getWorkflowHistorySaga(): any {
	yield takeLatest(actionTypes.GET_WORKFLOW_HISTORY, getWorkflowHistory);
}

const overrideState = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.overrideStateApi, payload);
		const { status } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.OVERRIDE_STATE_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.OVERRIDE_STATE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.OVERRIDE_STATE_FAILURE });
	}
};

export function* overrideStateSaga(): any {
	yield takeLatest(actionTypes.OVERRIDE_STATE, overrideState);
}
