import { ObjectType } from "__utils/types";
import { INextWorkflowState } from "__common/Workflow/__redux/types";
import dayjs from "dayjs";
import * as actionTypes from "./actionTypes";

/**
 * Workflow funds for rendering
 */

export type IWorkflowFundRender = { [key: string]: number | ObjectType };

/**
 * Get workflow funds
 */

export type IWorkflowFundNote = {
	readonly id: number;
	readonly text: string;
	readonly modifiedAt: string;
	readonly isComplete: boolean;
	readonly createdBy: string;
};

export type IWorkflowFundStateHistory = { [key: string]: { readonly status: string } };

export type IWorkflowFund = {
	readonly id: number;
	readonly fundId: number;
	readonly fundName: string;
	readonly tag: string;
	readonly person: string;
	readonly dueDate: string;
	readonly stateHistory: IWorkflowFundStateHistory;
	readonly next: INextWorkflowState;
	readonly notes: IWorkflowFundNote[];
};
export type IAdministrator = {
	readonly id: number;
	readonly fullName: string;
};

export type IGetWorkflowFunds = {
	readonly workflowId: number;
	readonly tag: string;
	readonly isOnPoll: boolean;
	readonly workflowFunds: IWorkflowFund[];
	readonly administrators: IAdministrator[];
	readonly gettingWorkflowFunds: boolean;
	readonly getWorkflowFundsSuccess: boolean;
	readonly getWorkflowFundsFailure: boolean;
};

export const getWorkflowFundsDefaultValue: IGetWorkflowFunds = {
	workflowId: 0,
	tag: "",
	isOnPoll: false,
	workflowFunds: [],
	administrators: [],
	gettingWorkflowFunds: false,
	getWorkflowFundsSuccess: false,
	getWorkflowFundsFailure: false,
};

export type IGetWorkflowFundsPayload = {
	readonly workflowId: number;
	readonly tag: string;
	readonly isOnPoll: boolean;
};

export type IGetWorkflowFundsAction = {
	readonly type: typeof actionTypes.GET_WORKFLOW_FUNDS;
	readonly payload: IGetWorkflowFundsPayload;
};

export type IGetWorkflowFundsSuccessAction = {
	readonly type: typeof actionTypes.GET_WORKFLOW_FUNDS_SUCCESS;
};

export type IGetWorkflowFundsFailureAction = {
	readonly type: typeof actionTypes.GET_WORKFLOW_FUNDS_FAILURE;
};

export type IGetWorkflowFundsResetAction = {
	readonly type: typeof actionTypes.GET_WORKFLOW_FUNDS_RESET;
};

export type IGetWorkflowFundsActions =
	| IGetWorkflowFundsAction
	| IGetWorkflowFundsSuccessAction
	| IGetWorkflowFundsFailureAction
	| IGetWorkflowFundsResetAction;

/**
 * Get workflow funds update
 */

export type IWorkflowFundUpdate = {
	readonly id: number;
	readonly stateHistory: IWorkflowFundStateHistory;
	readonly next: INextWorkflowState;
};

export type IGetWorkflowFundsUpdate = {
	readonly workflowFundsUpdate: IWorkflowFundUpdate[];
	readonly gettingWorkflowFundsUpdate: boolean;
	readonly getWorkflowFundsUpdateSuccess: boolean;
	readonly getWorkflowFundsUpdateFailure: boolean;
};

export const getWorkflowFundsUpdateDefaultValue: IGetWorkflowFundsUpdate = {
	workflowFundsUpdate: [],
	gettingWorkflowFundsUpdate: false,
	getWorkflowFundsUpdateSuccess: false,
	getWorkflowFundsUpdateFailure: false,
};

export type IGetWorkflowFundsUpdatePayload = {
	readonly workflowId: number;
	readonly tag: string;
};

export type IGetWorkflowFundsUpdateAction = {
	type: typeof actionTypes.GET_WORKFLOW_FUNDS_UPDATE;
	payload: IGetWorkflowFundsUpdatePayload;
};

export type IGetWorkflowFundsUpdateSuccessAction = {
	type: typeof actionTypes.GET_WORKFLOW_FUNDS_UPDATE_SUCCESS;
};

export type IGetWorkflowFundsUpdateFailureAction = {
	type: typeof actionTypes.GET_WORKFLOW_FUNDS_UPDATE_FAILURE;
};

export type IGetWorkflowFundsUpdateResetAction = {
	type: typeof actionTypes.GET_WORKFLOW_FUNDS_UPDATE_RESET;
};

export type IGetWorkflowFundsUpdateActions =
	| IGetWorkflowFundsUpdateAction
	| IGetWorkflowFundsUpdateSuccessAction
	| IGetWorkflowFundsUpdateFailureAction
	| IGetWorkflowFundsUpdateResetAction;

/**
 * Move workflow fund state
 */

export type IMoveWorkflowFundState = {
	readonly showToaster: boolean;
	readonly newState: number;
	readonly status: string;
	readonly workflowFundId: number;
	readonly movingWorkflowFundSate: boolean;
	readonly moveWorkflowFundStateSuccess: boolean;
	readonly moveWorkflowFundStateFailure: boolean;
};

export const moveWorkflowFundStateDefaultValue: IMoveWorkflowFundState = {
	showToaster: false,
	newState: -1,
	status: "",
	workflowFundId: -1,
	movingWorkflowFundSate: false,
	moveWorkflowFundStateSuccess: false,
	moveWorkflowFundStateFailure: false,
};

export type IMoveWorkflowFundStatePayload = {
	readonly newState: number;
	readonly status: string;
	readonly workflowFundId: number;
};

export type IMoveWorkflowFundStateAction = {
	type: typeof actionTypes.MOVE_WORKFLOW_FUND_STATE;
	payload: IMoveWorkflowFundStatePayload;
};

export type IMoveWorkflowFundStateSuccessAction = {
	type: typeof actionTypes.MOVE_WORKFLOW_FUND_STATE_SUCCESS;
};

export type IMoveWorkflowFundStateFailureAction = {
	type: typeof actionTypes.MOVE_WORKFLOW_FUND_STATE_FAILURE;
};

export type IMoveWorkflowFundStateResetAction = {
	type: typeof actionTypes.MOVE_WORKFLOW_FUND_STATE_RESET;
};

export type IMoveWorkflowFundStateActions =
	| IMoveWorkflowFundStateAction
	| IMoveWorkflowFundStateSuccessAction
	| IMoveWorkflowFundStateFailureAction
	| IMoveWorkflowFundStateResetAction;

/**
 * Get notes
 */

export type IGetWorkflowFundNotes = {
	readonly notes: IWorkflowFundNote[];
	readonly gettingWorkflowFundNotes: boolean;
	readonly getWorkflowFundNotesSuccess: boolean;
	readonly getWorkflowFundNotesFailure: boolean;
};

export const getWorkflowFundNotesDefaultValue: IGetWorkflowFundNotes = {
	notes: [],
	gettingWorkflowFundNotes: false,
	getWorkflowFundNotesSuccess: false,
	getWorkflowFundNotesFailure: false,
};

export type IGetWorkflowFundNotesPayload = {
	readonly workflowFundId: number;
};

export type IGetWorkflowFundNotesAction = {
	type: typeof actionTypes.GET_WORKFLOW_FUND_NOTES;
	payload: IGetWorkflowFundNotesPayload;
};

export type IGetWorkflowFundNotesSuccessAction = {
	type: typeof actionTypes.GET_WORKFLOW_FUND_NOTES_SUCCESS;
};

export type IGetWorkflowFundNotesFailureAction = {
	type: typeof actionTypes.GET_WORKFLOW_FUND_NOTES_FAILURE;
};

export type IGetWorkflowFundNotesResetAction = {
	type: typeof actionTypes.GET_WORKFLOW_FUND_NOTES_RESET;
};

export type IGetWorkflowFundNotesActions =
	| IGetWorkflowFundNotesAction
	| IGetWorkflowFundNotesSuccessAction
	| IGetWorkflowFundNotesFailureAction
	| IGetWorkflowFundNotesResetAction;

/**
 * Add note
 */

export type IAddWorkflowFundNote = {
	readonly addingWorkflowFundNote: boolean;
	readonly addWorkflowFundNoteSuccess: boolean;
	readonly addWorkflowFundNoteFailure: boolean;
};

export const addWorkflowFundNoteDefaultValue: IAddWorkflowFundNote = {
	addingWorkflowFundNote: false,
	addWorkflowFundNoteSuccess: false,
	addWorkflowFundNoteFailure: false,
};

export type IAddWorkflowFundNotePayload = {
	readonly workflowFundId: number;
	readonly text: string;
};

export type IAddWorkflowFundNoteAction = {
	type: typeof actionTypes.ADD_WORKFLOW_FUND_NOTE;
	payload: IAddWorkflowFundNotePayload;
};

export type IAddWorkflowFundNoteSuccessAction = {
	type: typeof actionTypes.ADD_WORKFLOW_FUND_NOTE_SUCCESS;
};

export type IAddWorkflowFundNoteFailureAction = {
	type: typeof actionTypes.ADD_WORKFLOW_FUND_NOTE_FAILURE;
};

export type IAddWorkflowFundNoteResetAction = {
	type: typeof actionTypes.ADD_WORKFLOW_FUND_NOTE_RESET;
};

export type IAddWorkflowFundNoteActions =
	| IAddWorkflowFundNoteAction
	| IAddWorkflowFundNoteSuccessAction
	| IAddWorkflowFundNoteFailureAction
	| IAddWorkflowFundNoteResetAction;

/**
 * Complete note
 */

export type ICompleteWorkflowFundNote = {
	readonly noteId: number;
	readonly completingWorkflowFundNote: boolean;
	readonly completeWorkflowFundNoteSuccess: boolean;
	readonly completeWorkflowFundNoteFailure: boolean;
};

export const completeWorkflowFundNoteDefaultValue: ICompleteWorkflowFundNote = {
	noteId: 0,
	completingWorkflowFundNote: false,
	completeWorkflowFundNoteSuccess: false,
	completeWorkflowFundNoteFailure: false,
};

export type ICompleteWorkflowFundNotePayload = {
	readonly noteId: number;
	readonly isComplete: boolean;
};

export type ICompleteWorkflowFundNoteAction = {
	type: typeof actionTypes.COMPLETE_WORKFLOW_FUND_NOTE;
	payload: ICompleteWorkflowFundNotePayload;
};

export type ICompleteWorkflowFundNoteSuccessAction = {
	type: typeof actionTypes.COMPLETE_WORKFLOW_FUND_NOTE_SUCCESS;
};

export type ICompleteWorkflowFundNoteFailureAction = {
	type: typeof actionTypes.COMPLETE_WORKFLOW_FUND_NOTE_FAILURE;
};

export type ICompleteWorkflowFundNoteResetAction = {
	type: typeof actionTypes.COMPLETE_WORKFLOW_FUND_NOTE_RESET;
};

export type ICompleteWorkflowFundNoteActions =
	| ICompleteWorkflowFundNoteAction
	| ICompleteWorkflowFundNoteSuccessAction
	| ICompleteWorkflowFundNoteFailureAction
	| ICompleteWorkflowFundNoteResetAction;

/**
 * UPDATE WORKFLOW PERSON
 */

export type IUpdateWorkflowPerson = {
	readonly updatingWorkflowPerson: boolean;
	readonly updateWorkflowPersonSuccess: boolean;
	readonly updateWorkflowPersonFailure: boolean;
};

export const updateWorkflowPersonDefaultValue: IUpdateWorkflowPerson = {
	updatingWorkflowPerson: false,
	updateWorkflowPersonSuccess: false,
	updateWorkflowPersonFailure: false,
};

export type IUpdateWorkflowPersonPayload = {
	readonly workflowId: number;
	readonly adminID: number;
};

export type IUpdateWorkflowPersonAction = {
	type: typeof actionTypes.UPDATE_WORKFLOW_PERSON;
	payload: IUpdateWorkflowPersonPayload;
};

export type IUpdateWorkflowPersonSuccessAction = {
	type: typeof actionTypes.UPDATE_WORKFLOW_PERSON_SUCCESS;
};

export type IUpdateWorkflowPersonFailureAction = {
	type: typeof actionTypes.UPDATE_WORKFLOW_PERSON_FAILURE;
};

export type IUpdateWorkflowPersonResetAction = {
	type: typeof actionTypes.UPDATE_WORKFLOW_PERSON_RESET;
};

export type IUpdateWorkflowPersonActions =
	| IUpdateWorkflowPersonAction
	| IUpdateWorkflowPersonSuccessAction
	| IUpdateWorkflowPersonFailureAction
	| IUpdateWorkflowPersonResetAction;

/**
 * GET Workflow
 */
export type IWorkflow = {
	readonly id: number;
	readonly title: string;
	readonly count: number;
	readonly tags: string[];
};

export type IGetWorkflows = {
	readonly workflows: IWorkflow[];
	readonly gettingWorkflow: boolean;
	readonly getWorkflowSuccess: boolean;
	readonly getWorkflowFailure: boolean;
};

export const getWorkflowDefaultValue: IGetWorkflows = {
	workflows: [],
	gettingWorkflow: false,
	getWorkflowSuccess: false,
	getWorkflowFailure: false,
};

export type IGetWorkflowsAction = {
	type: typeof actionTypes.GET_WORKFLOWS;
};

export type IGetWorkflowsSuccessAction = {
	type: typeof actionTypes.GET_WORKFLOWS_SUCCESS;
};

export type IGetWorkflowsFailureAction = {
	type: typeof actionTypes.GET_WORKFLOWS_FAILURE;
};

export type IGetWorkflowsResetAction = {
	type: typeof actionTypes.GET_WORKFLOWS_RESET;
};

export type IGetWorkflowsActions =
	| IGetWorkflowsAction
	| IGetWorkflowsSuccessAction
	| IGetWorkflowsFailureAction
	| IGetWorkflowsResetAction;
