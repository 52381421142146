import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

import { AddPaymentActionTypes, AddPaymentType, addPaymentDefaultValue } from "./types";

export const addPaymentReducer = (state = addPaymentDefaultValue, action: AddPaymentActionTypes): AddPaymentType => {
	switch (action.type) {
		case actionTypes.ADD_PAYMENT:
			return {
				...state,
				isAddingPayment: true,
			};
		case actionTypes.ADD_PAYMENT_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isAddingPayment: false,
				isAddPaymentSuccess: true,
				isAddPaymentError: false,
			};
		case actionTypes.ADD_PAYMENT_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isAddPaymentSuccess: false,
				isAddingPayment: false,
				isAddPaymentError: true,
			};

		case actionTypes.ADD_PAYMENT_RESET:
			return {
				...state,
				...addPaymentDefaultValue,
			};

		default:
			return state;
	}
};

export const oneOffDebitReducer = combineReducers({
	addPayment: addPaymentReducer,
});
