import { call, cancel, cancelled, fork, put, select, take, takeLatest } from "redux-saga/effects";
import * as fundsActiontypes from "__pages/Funds/__redux/actionTypes";
import { fundsSlr } from "__pages/Funds/__redux/selectors";
import { FundDetailsType, GetFundsType } from "__pages/Funds/__redux/types";
import { ResponseGeneratorType, TaskGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";
import { GetFundDetailsPayloadType, GetFundDetailsType, MemberType } from "./types";

const getFundDetails = function* (payload: GetFundDetailsPayloadType): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getFundApi, payload);
		const { status, data } = response;

		if (status === 200) {
			const fund: GetFundDetailsType = data;
			let members = [];
			// check is id exist on members object
			if ("members" in data) {
				members = data.members.map((member: MemberType, i: number) => {
					if (!("id" in member)) {
						return { ...(member as MemberType), id: i + 1 };
					}
					return member;
				});
			}

			yield put({
				type: actionTypes.GET_FUND_DETAILS_SUCCESS,
				...fund,
				members,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_FUND_DETAILS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_FUND_DETAILS_FAILURE });
	} finally {
		const isCancelled: ResponseGeneratorType = yield cancelled();
		if (isCancelled) {
			//
		}
	}
};

export const getFundDetailsSaga = function* (): any {
	while (true) {
		const getAction: ResponseGeneratorType = yield take(actionTypes.GET_FUND_DETAILS);
		const task: Array<TaskGeneratorType> = yield fork(getFundDetails, getAction.payload);
		const action: ResponseGeneratorType = yield take([
			actionTypes.GET_FUND_DETAILS_SUCCESS,
			actionTypes.GET_FUND_DETAILS_CANCEL,
		]);

		if (action.type === actionTypes.GET_FUND_DETAILS_CANCEL) {
			yield cancel(task);
		}
	}
};

const getFundDetailsForModal = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getFundApi, payload);
		const { status, data } = response;
		if (status === 200) {
			const fund: GetFundDetailsType = data;
			const _fund = {
				...fund,
				isGetting: false,
				isSuccess: true,
				isError: false,
			};

			yield put({
				type: actionTypes.GET_FUND_DETAILS_FOR_MODAL_SUCCESS,
				..._fund,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_FUND_DETAILS_FOR_MODAL_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_FUND_DETAILS_FOR_MODAL_FAILURE });
	}
};

export const getFundDetailsForModalSaga = function* (): any {
	yield takeLatest(actionTypes.GET_FUND_DETAILS_FOR_MODAL, getFundDetailsForModal);
};

const createFund = function* (action: any): any {
	try {
		const { payload } = action;
		const getFunds: GetFundsType = yield select(fundsSlr);
		const { funds } = getFunds;
		const updatedFunds = funds.map((fund: FundDetailsType) => {
			if (fund.id === payload.id) {
				return { ...fund, resumable: false };
			}
			return fund;
		});

		const response: ResponseGeneratorType = yield call(api.createFundApi, payload);
		const { status } = response;

		if (status === 200) {
			yield put({
				type: fundsActiontypes.GET_FUNDS_SUCCESS,
				...updatedFunds,
			});

			yield put({
				type: actionTypes.CREATE_FUND_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({
				type: actionTypes.CREATE_FUND_FAILURE,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.CREATE_FUND_FAILURE,
		});
	}
};

export const createFundSaga = function* (): any {
	yield takeLatest(actionTypes.CREATE_FUND, createFund);
};
