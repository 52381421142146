import { administrationReducer } from "__pages/Administration/__redux/reducers";
import { combineReducers } from "redux";
import { documentReducer } from "__pages/RequestDocuments/__redux/reducers";
import { fundDetailsReducer } from "__pages/FundDetails/__redux/reducers";
import { fundsReducer } from "__pages/Funds/__redux/reducers";
import { headerReducer } from "__layout/Header/__redux/reducers";
import { templateReducer } from "__pages/RequestTemplates/__redux/reducers";
import { userReducer } from "__pages/User/__redux/reducers";
import { workflowReducer } from "__common/Workflow/__redux/reducers";
import { workflowDashboardReducer } from "__pages/WorkflowDashboard/rootReducer";
import { remindersReducer } from "__pages/ManageReminders/__redux/reducers";
import { moveWorkflowFundDetailsGenerateDocsStateReducer } from "__redux/workflowFundDetails/reducers";
import { moveWorkflowDashboardGenerateDocsStateReducer } from "__redux/workflowDashboard/reducers";

const rootReducer = combineReducers({
	user: userReducer,
	funds: fundsReducer,
	administration: administrationReducer,
	fundDetails: fundDetailsReducer,
	document: documentReducer,
	template: templateReducer,
	header: headerReducer,
	workflow: workflowReducer,
	reminders: remindersReducer,
	workflowDashboard: workflowDashboardReducer,
	moveWorkflowFundDetailsGenerateDocsState: moveWorkflowFundDetailsGenerateDocsStateReducer,
	moveWorkflowDashboardGenerateDocsState: moveWorkflowDashboardGenerateDocsStateReducer,
});

export default rootReducer;
