import * as actionTypes from "./actionTypes";
import {
	AddFundStructureActionTypes,
	AddFundStructurePayloadType,
	EditFundStructureActionTypes,
	EditFundStructurePayloadType,
} from "./types";

export const addFundStructureCtr = (payload: AddFundStructurePayloadType): AddFundStructureActionTypes => ({
	type: actionTypes.ADD_FUND_STRUCTURE,
	payload,
});

export const addFundStructureResetCtr = (): AddFundStructureActionTypes => ({
	type: actionTypes.ADD_FUND_STRUCTURE_RESET,
});

export const editFundStructureCtr = (payload: EditFundStructurePayloadType): EditFundStructureActionTypes => ({
	type: actionTypes.EDIT_FUND_STRUCTURE,
	payload,
});

export const editFundStructureResetCtr = (): EditFundStructureActionTypes => ({
	type: actionTypes.EDIT_FUND_STRUCTURE_RESET,
});
