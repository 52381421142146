import { errorHandler, getAxiosInstance } from "__utils/helpers";

import { API_ROOT } from "__utils/constants";
import { AxiosInstance, AxiosResponse } from "axios";
import { GET_REMINDERS_COUNT, GET_REMINDERS, GET_REMINDER_DETAILS, RELEASE_ALL_REMINDER } from "./constants";
import { IGetReminderDetailsPayload } from "../__redux/types";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getRemindersCountAPI = async (): Promise<AxiosResponse<any>> => {
	return axios.get(GET_REMINDERS_COUNT).catch(errorHandler);
};

export const getRemindersAPI = async (): Promise<AxiosResponse<any>> => {
	return axios.get(GET_REMINDERS).catch(errorHandler);
};

export const getReminderDetailsAPI = async ({
	requestId,
	memberId,
}: IGetReminderDetailsPayload): Promise<AxiosResponse<any>> => {
	const url = GET_REMINDER_DETAILS.replace(":memberId", `${memberId}`).replace(":requestId", `${requestId}`);
	return axios.get(url).catch(errorHandler);
};

export const releaseAllReminderAPI = async (): Promise<AxiosResponse<any>> => {
	return axios.post(RELEASE_ALL_REMINDER).catch(errorHandler);
};
