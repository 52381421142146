import { call, put, select, takeLatest } from "redux-saga/effects";
import * as actionTypesFundDetails from "__pages/FundDetails/__redux/actionTypes";
import { getFundDetailsSlr } from "__pages/FundDetails/__redux/selectors";
import { GetFundDetailsType } from "__pages/FundDetails/__redux/types";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";

const getClass = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getClassApi, payload);
		const { status, data } = response;

		if (status === 200) {
			// Save state
			yield put({
				type: actionTypes.GET_CLASS_SUCCESS,
				fundClass: data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_CLASS_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_CLASS_FAILURE });
	}
};

export function* getClassSaga(): any {
	yield takeLatest(actionTypes.GET_CLASS, getClass);
}

const editClass = function* ({ payload }: any): any {
	const getFund: GetFundDetailsType = yield select(getFundDetailsSlr);
	try {
		const response: ResponseGeneratorType = yield call(api.editClassApi, payload);
		const { status, data } = response;

		if (status === 200) {
			const _integration = {
				...getFund,
				integration: { ...getFund.integration, inClass: true },
			};

			yield put({
				type: actionTypesFundDetails.GET_FUND_DETAILS_SUCCESS,
				..._integration,
			});

			// Save state
			yield put({
				type: actionTypes.EDIT_CLASS_SUCCESS,
			});

			// Update
			yield put({
				type: actionTypes.GET_CLASS_SUCCESS,
				fundClass: { fundCodeName: payload.fundCodeName },
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.EDIT_CLASS_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.EDIT_CLASS_FAILURE });
	}
};

export function* editClassSaga(): any {
	yield takeLatest(actionTypes.EDIT_CLASS, editClass);
}
