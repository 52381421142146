export const ADD_FUND_STRUCTURE_MODAL_TITLE = "Add Fund Structure";
export const ADD_FUND_STRUCTURE_SUCCESS = "Fund structure added successfully";
export const ADD_FUND_STRUCTURE_FAILURE = "Add fund structure failed";
export const EDIT_FUND_STRUCTURE_MODAL_TITLE = "Edit Fund Structure";
export const EDIT_FUND_STRUCTURE_SUCCESS = "Fund structure edited successfully";
export const EDIT_FUND_STRUCTURE_FAILURE = "Edit fund structure failed";

export const FUND_STRUCTURE_ORIGIN_LABEL = "Origin";
export const FUND_STRUCTURE_TRUSTEE_LABEL = "Trustee Type";
export const FUND_STRUCTURE_SMSF_NAME_LABEL = "Fund Name";
export const FUND_STRUCTURE_JURISDICTION_LABEL = "Jurisdiction";
export const FUND_STRUCTURE_COMPANY_NAME_LABEL = "Company Name";
export const FUND_STRUCTURE_ASIC_REGISTERED_NAME_LABEL = "ASIC Registered Name";
export const FUND_STRUCTURE_ABN_LABEL = "ABN";
export const FUND_STRUCTURE_ACN_LABEL = "ACN";
export const FUND_STRUCTURE_TFN_LABEL = "TFN";

export const FUND_STRUCTURE_ORIGIN_NAME = "fundOrigin";
export const FUND_STRUCTURE_TRUSTEE_NAME = "fundType";
export const FUND_STRUCTURE_SMSF_NAME_NAME = "fundName";
export const FUND_STRUCTURE_JURISDICTION_NAME = "jurisdiction";
export const FUND_STRUCTURE_COMPANY_NAME_NAME = "companyName";
export const FUND_STRUCTURE_ASIC_REGISTERED_NAME_NAME = "asicCompanyName";
export const FUND_STRUCTURE_ABN_NAME = "abn";
export const FUND_STRUCTURE_ACN_NAME = "acn";
export const FUND_STRUCTURE_TFN_NAME = "tfn";
