import { call, put, takeEvery, select } from "redux-saga/effects";
import { ResponseGeneratorType } from "__utils/types";
import * as workflowDashboardActionTypes from "__pages/WorkflowDashboard/WorkflowFundList/__redux/actionTypes";
import {
	IGetWorkflowFunds,
	IWorkflowFund,
	IWorkflowFundUpdate,
} from "__pages/WorkflowDashboard/WorkflowFundList/__redux/types";
import { getWorkflowFundsSlr } from "__pages/WorkflowDashboard/WorkflowFundList/__redux/selectors";

import * as api from "__api";
import * as actionTypes from "./actionTypes";

const moveWorkflowDashboard = function* ({ payload }: any): any {
	try {
		const { workflowFunds }: IGetWorkflowFunds = yield select(getWorkflowFundsSlr);
		const response: ResponseGeneratorType = yield call(api.moveWorkflowDashboardApi, payload);
		const { status, data } = response;

		if (status === 201) {
			// Success
			yield put({
				type: actionTypes.MOVE_WORKFLOW_DASHBOARD_GENERATE_DOCS_SUCCESS,
			});

			// Update our workflow funds
			const workflowFundsUpdated: IWorkflowFund[] = workflowFunds.reduce(
				(acc: IWorkflowFund[], curr: IWorkflowFund) => {
					const found: IWorkflowFundUpdate = data.find((item: IWorkflowFundUpdate) => item.id === curr.id);

					if (found) {
						curr = { ...curr, stateHistory: found.stateHistory, next: found.next };
					}

					acc.push(curr);
					return acc;
				},
				[]
			);

			yield put({
				type: workflowDashboardActionTypes.GET_WORKFLOW_FUNDS_SUCCESS,
				workflowFunds: workflowFundsUpdated,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({
				type: actionTypes.MOVE_WORKFLOW_DASHBOARD_GENERATE_DOCS_FAILURE,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.MOVE_WORKFLOW_DASHBOARD_GENERATE_DOCS_FAILURE,
		});
	}
};

export const moveWorkflowDashboardSaga = function* (): any {
	yield takeEvery(actionTypes.MOVE_WORKFLOW_DASHBOARD_GENERATE_DOCS, moveWorkflowDashboard);
};
