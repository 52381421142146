import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

import { AddDocuSignEnvelopeActionTypes, AddDocuSignEnvelopeType, addDocuSignEnvelopeDefaultValue } from "./types";

export const addDocuSignEnvelopeReducer = (
	state = addDocuSignEnvelopeDefaultValue,
	action: AddDocuSignEnvelopeActionTypes
): AddDocuSignEnvelopeType => {
	switch (action.type) {
		case actionTypes.ADD_DOCUSIGN_ENVELOPE:
			return {
				...state,
				isAddingDocuSignEnvelope: true,
			};
		case actionTypes.ADD_DOCUSIGN_ENVELOPE_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isAddingDocuSignEnvelope: false,
				isAddDocuSignEnvelopeSuccess: true,
				isAddDocuSignEnvelopeError: false,
				addDocuSignEnvelopeError: {},
			};
		case actionTypes.ADD_DOCUSIGN_ENVELOPE_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isAddingDocuSignEnvelope: false,
				isAddDocuSignEnvelopeSuccess: false,
				isAddDocuSignEnvelopeError: true,
				addDocuSignEnvelopeError: {},
			};
		case actionTypes.ADD_DOCUSIGN_ENVELOPE_RESET:
			return {
				...state,
				...addDocuSignEnvelopeDefaultValue,
			};
		default:
			return state;
	}
};

export const docuSignEnvelopesReducer = combineReducers({
	addDocuSignEnvelope: addDocuSignEnvelopeReducer,
});
