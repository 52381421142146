export const ADD_TRUST = "ADD_TRUST";
export const ADD_TRUST_SUCCESS = "ADD_TRUST_SUCCESS";
export const ADD_TRUST_FAILURE = "ADD_TRUST_FAILURE";
export const ADD_TRUST_RESET = "ADD_TRUST_RESET";

export const EDIT_TRUST = "EDIT_TRUST";
export const EDIT_TRUST_SUCCESS = "EDIT_TRUST_SUCCESS";
export const EDIT_TRUST_FAILURE = "EDIT_TRUST_FAILURE";
export const EDIT_TRUST_RESET = "EDIT_TRUST_RESET";

export const DELETE_TRUST = "DELETE_TRUST";
export const DELETE_TRUST_SUCCESS = "DELETE_TRUST_SUCCESS";
export const DELETE_TRUST_FAILURE = "DELETE_TRUST_FAILURE";
export const DELETE_TRUST_RESET = "DELETE_TRUST_RESET";
