import { UserOutlined } from "@ant-design/icons";
import Dropdown from "antd/es/dropdown";
import Space from "antd/es/space";
import cx from "classnames";
import React from "react";
import { BsCardChecklist, BsListTask } from "react-icons/bs";
import { GrUserAdmin } from "react-icons/gr";
import { RiFolderReceivedLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import AntdifyIcon from "__common/AntdifyIcon";
import ClickableIcon from "__common/ClickableIcon";
import IconWrapper from "__common/IconWrapper";
import { UserProfileType } from "__pages/User/__redux/types";
import { isUserAdmin } from "__utils/helpers";
import Badge from "antd/es/badge";
import EstablishFundModal from "../../EstablishFundModal";
import Logo from "../../__assets/icons/selfmade-logo-mobile.png";
import { SetHeaderPreviewType } from "../../__redux/types";
import { MobileHeaderContainer, SessionWidget } from "./style";

type Props = {
	userMenu: React.ReactElement;
	requestDropMenu: React.ReactElement;
	headerPreview: SetHeaderPreviewType;
	userProfile: UserProfileType;
	reminderCount: number;
};

const MobileHeader: React.FC<Props> = ({
	userMenu,
	requestDropMenu,
	headerPreview,
	userProfile,
	reminderCount,
}: Props) => {
	const { darkMode, level } = userProfile;
	const { darkMode: previewDarkMode } = headerPreview;
	const history = useHistory();
	return (
		<MobileHeaderContainer>
			<div className="left-section">
				<ClickableIcon tooltip="Fund List" onClick={() => history.push("/")}>
					<img src={Logo} alt="logo" />
				</ClickableIcon>
			</div>
			<div className="right-section">
				<Space className="icons-list">
					<EstablishFundModal />

					{isUserAdmin(level) && (
						<>
							<Dropdown
								overlay={requestDropMenu}
								className="header-btn"
								placement="bottomCenter"
								trigger={["click"]}
								overlayClassName="request-dropdown"
								arrow
							>
								<ClickableIcon tooltip="Requests">
									<AntdifyIcon className="header-icon">
										<Badge count={reminderCount} size="small" showZero={false}>
											<RiFolderReceivedLine />
										</Badge>
									</AntdifyIcon>
								</ClickableIcon>
							</Dropdown>

							<ClickableIcon tooltip="Workflow" onClick={() => history.push("/workflow-dashboard")}>
								<AntdifyIcon className="header-icon">
									<BsCardChecklist />
								</AntdifyIcon>
							</ClickableIcon>
						</>
					)}
					{[1, 3, 5].includes(level) && (
						<ClickableIcon tooltip="Administration" onClick={() => history.push("/administration")}>
							<AntdifyIcon className="header-icon admin-icon">
								<GrUserAdmin />
							</AntdifyIcon>
						</ClickableIcon>
					)}
				</Space>
				<SessionWidget className={cx({ "dark-mode": previewDarkMode || darkMode })}>
					<Dropdown
						overlay={userMenu}
						trigger={["click"]}
						overlayClassName="session-dropdown-mobile"
						placement="bottomRight"
						arrow
					>
						<div>
							<div className="icon-wrapper">
								<IconWrapper background="#979797" className="profile-icon">
									<UserOutlined />
								</IconWrapper>
							</div>
						</div>
					</Dropdown>
				</SessionWidget>
			</div>
		</MobileHeaderContainer>
	);
};

export default MobileHeader;
