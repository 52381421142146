import styled from "styled-components";
import { selectCustomStyle } from "__styles/common";

export const Container = styled.div`
	.float-label {
		position: relative;
		margin-bottom: 12px;

		.ant-input,
		.ant-input-password,
		.ant-input-number {
			border-top: none;
			border-left: none;
			border-right: none;
			box-shadow: none;
		}

		.ant-select-dropdown {
			top: 37.9993px !important;
		}

		.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
			margin-left: -10px;
		}

		.label {
			color: rgba(0, 0, 0, 0.5);
			font-size: ${(p) => p.theme.defaultFontSize};
			font-weight: normal;
			position: absolute;
			pointer-events: none;
			left: 2px;
			top: 5px;
			transition: ${(p) => p.theme.transition};
			z-index: 99;
		}

		.label-float {
			color: ${(p) => p.theme.primaryColor};
			top: -12px;
			font-size: 10px;
		}

		.ant-form-item-has-error + .label-float {
			color: red;
		}

		// Select mods
		${selectCustomStyle}

		.ant-select {
			width: 100%;
		}

		.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
			height: 28px;
			.ant-select-selection-item {
				line-height: 24px;
			}
		}

		.ant-select-arrow {
			font-size: 10px;
			margin-top: -5px;
		}

		// Picker mods

		.ant-picker {
			padding: 3px 2px;
			border-top: none;
			border-left: none;
			border-right: none;
			box-shadow: none;
			width: 100%;
			border-radius: 0;
		}

		// Textfield mods

		.ant-input {
			font-size: 13px;
			padding: 2px;
			border-radius: 0;
		}

		.ant-form-item-has-error .ant-input:focus,
		.ant-form-item-has-error .ant-input-affix-wrapper:focus,
		.ant-form-item-has-error .ant-input-focused,
		.ant-form-item-has-error .ant-input-affix-wrapper-focused {
			box-shadow: none;
		}
	}
`;
