import * as actionTypes from "./actionTypes";
import {
	CreateFundActionTypes,
	CreateFundPayloadType,
	GetFundDetailsActionTypes,
	GetFundDetailsPayloadType,
	SetFundToResumeActionTypes,
	SetFundToResumePayloadType,
} from "./types";

export const getFundDetailsCtr = (payload: GetFundDetailsPayloadType): GetFundDetailsActionTypes => ({
	type: actionTypes.GET_FUND_DETAILS,
	payload,
});

export const getFundDetailsResetCtr = (): GetFundDetailsActionTypes => ({
	type: actionTypes.GET_FUND_DETAILS_RESET,
});

export const getFundDetailsCancelCtr = (): GetFundDetailsActionTypes => ({
	type: actionTypes.GET_FUND_DETAILS_CANCEL,
});

export const getFundDetailsForModalCtr = (payload: GetFundDetailsPayloadType): GetFundDetailsActionTypes => ({
	type: actionTypes.GET_FUND_DETAILS_FOR_MODAL,
	payload,
});

export const getFundDetailsForModalResetCtr = (): GetFundDetailsActionTypes => ({
	type: actionTypes.GET_FUND_DETAILS_FOR_MODAL_RESET,
});

export const createFundCtr = (payload: CreateFundPayloadType): CreateFundActionTypes => ({
	type: actionTypes.CREATE_FUND,
	payload,
});

export const createFundResetCtr = (): CreateFundActionTypes => ({
	type: actionTypes.CREATE_FUND_RESET,
});

export const setFundToResumeCtr = (payload: SetFundToResumePayloadType): SetFundToResumeActionTypes => ({
	type: actionTypes.SET_FUND_TO_RESUME,
	payload,
});

export const setFundToResumeResetCtr = (): SetFundToResumeActionTypes => ({
	type: actionTypes.SET_FUND_TO_RESUME_RESET,
});
