import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { SetNewStatePayloadType } from "../__redux/types";
import { GET_STATES, MOVE_STATE } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const setNewStateApi = async ({ memberId, newState }: SetNewStatePayloadType): Promise<AxiosResponse<any>> => {
	const url: string = MOVE_STATE.replace(":id", `${memberId}`);

	return axios.post(url, { newState }).catch(errorHandler);
};

export const getfundStatesApi = async ({ memberId }: SetNewStatePayloadType): Promise<AxiosResponse<any>> => {
	const url: string = GET_STATES.replace(":id", `${memberId}`);

	return axios.get(url).catch(errorHandler);
};
