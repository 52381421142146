import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import {
	AddFundStructureActionTypes,
	addFundStructureDefaultValue,
	AddFundStructureType,
	EditFundStructureActionTypes,
	editFundStructureDefaultValue,
	EditFundStructureType,
} from "./types";

export const addFundStructureReducer = (
	state = addFundStructureDefaultValue,
	action: AddFundStructureActionTypes
): AddFundStructureType => {
	switch (action.type) {
		case actionTypes.ADD_FUND_STRUCTURE:
			return {
				...state,
				isAdding: true,
			};
		case actionTypes.ADD_FUND_STRUCTURE_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isError: false,
				isSuccess: true,
				isAdding: false,
				error: {},
			};
		case actionTypes.ADD_FUND_STRUCTURE_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isAdding: false,
				isError: true,
				isSuccess: false,
			};
		case actionTypes.ADD_FUND_STRUCTURE_RESET:
			return {
				...state,
				...addFundStructureDefaultValue,
			};
		default:
			return state;
	}
};

export const editFundStructureReducer = (
	state = editFundStructureDefaultValue,
	action: EditFundStructureActionTypes
): EditFundStructureType => {
	switch (action.type) {
		case actionTypes.EDIT_FUND_STRUCTURE:
			return {
				...state,
				isEditing: true,
			};
		case actionTypes.EDIT_FUND_STRUCTURE_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isError: false,
				isSuccess: true,
				isEditing: false,
				error: {},
			};
		case actionTypes.EDIT_FUND_STRUCTURE_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isEditing: false,
				isError: true,
				isSuccess: false,
			};
		case actionTypes.EDIT_FUND_STRUCTURE_RESET:
			return {
				...state,
				...editFundStructureDefaultValue,
			};
		default:
			return state;
	}
};

export const fundStructureReducer = combineReducers({
	addFundStructure: addFundStructureReducer,
	editFundStructure: editFundStructureReducer,
});
