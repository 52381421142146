export const countries = [
	"Afghanistan",
	"Aland Islands",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cape Verde",
	"Cayman Islands",
	"Central African Republic",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands",
	"Colombia",
	"Comoros",
	"Congo",
	"Congo, The Democratic Republic of the",
	"Cook Islands",
	"Costa Rica",
	"Cote D'Ivoire",
	"Croatia",
	"Cuba",
	"Curacao",
	"Cyprus",
	"Czech Republic",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Ethiopia",
	"Falkland Islands (Malvinas)",
	"Faroe Islands",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories",
	"Gabon",
	"Gambia",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See (Vatican City State)",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran, Islamic Republic of",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea, Democratic People's Republic of",
	"Korea, Republic of",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Macedonia",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia",
	"Moldova, Republic of",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Reunion",
	"Romania",
	"Russian Federation",
	"Rwanda",
	"Saint Barthelemy",
	"Saint Helena, Ascension and Tristan Da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French Part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch Part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Swaziland",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates",
	"United Kingdom",
	"United States",
	"United States Minor Outlying Islands",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela",
	"Viet Nam",
	"Virgin Islands, British",
	"Virgin Islands, U.S.",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
];

export const statesFullName = [
	"Tasmania",
	"Victoria",
	"New South Wales",
	"Queensland",
	"Northern Territory",
	"South Australia",
	"Western Australia",
	"Australian Capital Territory",
];

export const identificationTypeList = [
	{ name: "Driver's License", value: "DriversLicence" },
	{ name: "Passport", value: "Passport" },
	{ name: "None Available", value: "DLPPnotAvailable" },
];

export const statesInitialName = ["TAS", "VIC", "NSW", "QLD", "NT", "SA", "WA", "ACT"];

export const occupationDict: any = {
	"Business Owner": [
		"Accommodation and Food Services",
		"Administrative and Support Services",
		"Arms or Weapons Manufacture or Distribution",
		"Arts and Recreation Services",
		"Bar or Licensed Club",
		"Betting, Bookmaking, Gambling and Gaming",
		"Cafe and Restaurant",
		"Charity Community or Social Services",
		"Construction",
		"Digital Currency Traders",
		"Education and Training",
		"Electricity, Gas, Water and Waste Services",
		"Farming and Agriculture",
		"Financial and Insurance Services",
		"Health Care and Social Assistance",
		"Hotel and Motel",
		"Information Media and Telecommunications",
		"Jewel, Gem and Precious Metals",
		"Mining, Gas, Oil and Petroleum",
		"Money Exchange or Foreign FX Services",
		"Pawn Brokers",
		"Professional, Scientific and Technical Services",
		"Public Brand and Safety",
		"Real Estate Agent",
		"Rental, Hiring and Real Estate Services",
		"Retail Trade",
		"Transport, Postal and Warehousing",
		"Wholesale Trade",
	],
	"Chief Executives, General Managers and Legislators": [
		"Accommodation and Food Services",
		"Administrative and Support Services",
		"Arms or Weapons Manufacture or Distribution",
		"Arts and Recreation Services",
		"Bar or Licensed Club",
		"Betting, Bookmaking, Gambling and Gaming",
		"Cafe and Restaurant",
		"Charity Community or Social Services",
		"Construction",
		"Digital Currency Traders",
		"Education and Training",
		"Electricity, Gas, Water and Waste Services",
		"Farming and Agriculture",
		"Financial and Insurance Services",
		"Health Care and Social Assistance",
		"Hotel and Motel",
		"Information Media and Telecommunications",
		"Jewel, Gem and Precious Metals",
		"Mining, Gas, Oil and Petroleum",
		"Money Exchange or Foreign FX Services",
		"Pawn Brokers",
		"Professional, Scientific and Technical Services",
		"Public Brand and Safety",
		"Real Estate Agent",
		"Rental, Hiring and Real Estate Services",
		"Retail Trade",
		"Transport, Postal and Warehousing",
		"Wholesale Trade",
	],
	"Clerical and administrative workers": [
		"Clerical and Administrative Workers",
		"Clerical and Office Support Workers",
		"General Clerical Workers",
		"Inquiry Clerks and Receptionists",
		"Numerical Clerks",
		"Office Managers and Program Administrators",
		"Personal Assistants and Secretaries",
	],
	"Community and personal service workers": [
		"Carers and Aides",
		"Health and Welfare Support Workers",
		"Hospitality Workers",
		"Protective Service Workers",
		"Sports and Personal Service Workers",
	],
	Employees: [
		"Advertising, Public Relations and Sales",
		"Construction, Distribution and Production",
		"Education, Health and Welfare Services",
		"Farmers and Farm",
		"Hospitality, Retail and Service",
		"Information & Communication Technology",
	],
	Homemaker: ["Homemaker"],
	Labourers: [
		"Cleaners and Laundry Workers",
		"Construction and Mining Labourers",
		"Factory Process Workers",
		"Farm, Forestry and Garden Workers",
		"Food Preparation Assistants",
		"Labourers",
	],
	"Machinery operators and drivers": [
		"Machine and Stationary Plant Operators",
		"Mobile Plant Operators",
		"Road and Rail Drivers",
		"Storepersons",
	],
	Military: ["Military (enlisted)", "Military (officer)"],
	Professionals: [
		"Accountants, Auditors and Company Secretaries",
		"Arts and Media Professionals",
		"Aviation",
		"Business, Human Resource and Marketing Professionals",
		"Design, Engineering, Science and Transport Professionals",
		"Doctor, Veterinarian, Health Professionals",
		"Education Professionals",
		"Financial Brokers and Dealers, and Investment Advisers",
		"Information & Communication Technology Professionals",
		"Legal, Social and Welfare Professionals",
		"Real Estate Agent",
	],
	Retired: ["Retired"],
	"Sales workers": ["Sales Assistants and Salespersons", "Sales Representatives and Agents", "Sales Support Workers"],
	Student: ["Student"],
	"Technicians and trades workers": [
		"Automotive and Engineering Trades Workers",
		"Construction Trades Workers",
		"Electrotechnology and Telecommunications Trades Workers",
		"Engineering and Science Technicians",
		"Food Trades Workers",
		"Information & Communication Technology Technicians",
		"Other Technicians and Trades Workers",
		"Skilled Animal and Horticultural Workers",
	],
};
