import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import {
	AddAdminActionTypes,
	addAdminDefaultValue,
	AddAdminType,
	AddBrandActionTypes,
	addBrandDefaultValue,
	AddBrandType,
	GetAdminLevelsActionTypes,
	GetAdminLevelsType,
	getAdminLevelsDefaultValue,
	deleteAdminDefaultValue,
	DeleteAdminType,
	DeleteAminActionTypes,
	EditAdminActionTypes,
	editAdminDefaultValue,
	EditAdminType,
	EditBrandActionTypes,
	editBrandDefaultValue,
	EditBrandType,
	GetBrandActionTypes,
	getBrandsDefaultValue,
	GetBrandsType,
} from "./types";

export const getBrandsReducer = (state = getBrandsDefaultValue, action: GetBrandActionTypes): GetBrandsType => {
	switch (action.type) {
		case actionTypes.GET_BRANDS: {
			return {
				...state,
				isGettingBrands: true,
			};
		}
		case actionTypes.GET_BRANDS_SUCCESS: {
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
			};
		}
		case actionTypes.GET_BRANDS_FAILURE: {
			return {
				...state,
				isGettingBrands: false,
				isGetBrandsSuccess: false,
				isGetBrandsError: true,
			};
		}
		case actionTypes.GET_BRANDS_RESET: {
			return {
				...state,
				...getBrandsDefaultValue,
			};
		}
		default: {
			return state;
		}
	}
};

export const getAdminLevelsReducer = (
	state = getAdminLevelsDefaultValue,
	action: GetAdminLevelsActionTypes
): GetAdminLevelsType => {
	switch (action.type) {
		case actionTypes.GET_ADMIN_LEVELS:
			return {
				...state,
				isGettingAdminLevels: true,
			};
		case actionTypes.GET_ADMIN_LEVELS_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingAdminLevels: false,
				isGetAdminLevelSuccess: true,
				isGetAdminLevelError: false,
			};
		case actionTypes.GET_ADMIN_LEVELS_FAILURE:
			return {
				...state,
				isGettingAdminLevels: false,
				isGetAdminLevelSuccess: false,
				isGetAdminLevelError: true,
			};
		case actionTypes.GET_ADMIN_LEVELS_RESET:
			return {
				...state,
				...getAdminLevelsDefaultValue,
			};

		default:
			return state;
	}
};

export const addBrandReducer = (state = addBrandDefaultValue, action: AddBrandActionTypes): AddBrandType => {
	switch (action.type) {
		case actionTypes.ADD_BRAND:
			return {
				...state,
				isAddingBrand: true,
			};
		case actionTypes.ADD_BRAND_SUCCESS:
			return {
				...state,
				isAddingBrand: false,
				isAddBrandSuccess: true,
				isAddBrandError: false,
			};
		case actionTypes.ADD_BRAND_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isAddingBrand: false,
				isAddBrandSuccess: false,
				isAddBrandError: true,
			};

		case actionTypes.ADD_BRAND_RESET:
			return {
				...state,
				...addBrandDefaultValue,
			};

		default:
			return state;
	}
};

export const editBrandReducer = (state = editBrandDefaultValue, action: EditBrandActionTypes): EditBrandType => {
	switch (action.type) {
		case actionTypes.EDIT_BRAND:
			return {
				...state,
				isEditingBrand: true,
			};
		case actionTypes.EDIT_BRAND_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isEditingBrand: false,
				isEditBrandSuccess: true,
				isEditBrandError: false,
			};
		case actionTypes.EDIT_BRAND_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isEditingBrand: false,
				isEditBrandSuccess: false,
				isEditBrandError: true,
			};

		case actionTypes.EDIT_BRAND_RESET:
			return {
				...state,
				...editBrandDefaultValue,
			};

		default:
			return state;
	}
};

export const addAdminReducer = (state = addAdminDefaultValue, action: AddAdminActionTypes): AddAdminType => {
	switch (action.type) {
		case actionTypes.ADD_ADMIN:
			return {
				...state,
				isAddingAdmin: true,
			};

		case actionTypes.ADD_ADMIN_SUCCESS:
			const { type, ...payload } = action;
			return {
				...state,
				...payload,
			};
		case actionTypes.ADD_ADMIN_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isAddingAdmin: false,
				isAddAdminError: true,
				isAddAdminSuccess: false,
			};

		case actionTypes.ADD_ADMIN_RESET:
			return {
				...state,
				...addAdminDefaultValue,
			};
		default:
			return state;
	}
};

export const editAdminReducer = (state = editAdminDefaultValue, action: EditAdminActionTypes): EditAdminType => {
	switch (action.type) {
		case actionTypes.EDIT_ADMIN:
			return {
				...state,
				isEditingAdmin: true,
			};

		case actionTypes.EDIT_ADMIN_SUCCESS:
			return {
				...state,
				isEditingAdmin: false,
				isEditAdminSuccess: true,
				isEditAdminError: false,
			};
		case actionTypes.EDIT_ADMIN_FAILURE:
			const { type: failureType, ...failureRest } = action;
			return {
				...state,
				...failureRest,
				isEditingAdmin: false,
				isEditAdminError: true,
				isEditAdminSuccess: false,
			};
		case actionTypes.EDIT_ADMIN_RESET:
			return {
				...state,
				...editAdminDefaultValue,
			};

		default:
			return state;
	}
};

export const deleteAdminReducer = (state = deleteAdminDefaultValue, action: DeleteAminActionTypes): DeleteAdminType => {
	switch (action.type) {
		case actionTypes.DELETE_ADMIN:
			return {
				...state,
				deleteId: action.payload.id,
				isDeletingAdmin: true,
			};

		case actionTypes.DELETE_ADMIN_SUCCESS:
			const { type, ...payload } = action;
			return {
				...state,
				...payload,
			};
		case actionTypes.DELETE_ADMIN_FAILURE:
			const { type: failureType, ...failureRest } = action;
			return {
				...state,
				...failureRest,
				isDeletingAdmin: false,
				isDeletingAdminError: true,
				isDeletingAdminSuccess: false,
			};

		case actionTypes.DELETE_ADMIN_RESET:
			return {
				...state,
				...deleteAdminDefaultValue,
			};
		default:
			return state;
	}
};

export const administrationReducer = combineReducers({
	getBrands: getBrandsReducer,
	getAdminLevels: getAdminLevelsReducer,
	addBrand: addBrandReducer,
	editBrand: editBrandReducer,
	addAdmin: addAdminReducer,
	editAdmin: editAdminReducer,
	deleteAdmin: deleteAdminReducer,
});
