import { errorHandler, getAxiosInstance } from "__utils/helpers";

import { API_ROOT } from "__utils/constants";
import { AxiosInstance, AxiosResponse } from "axios";
import { ObjectType } from "__utils/types";
import {
	DeleteDocumentTreeFilePayloadType,
	DownloadDocumentPayloadType,
	GetDocumentTreePayloadType,
	GetDocsFolderDetailsPayloadType,
} from "../__redux/types";
import { DELETE_DOCUMENT_FILE, DOWNLOAD_DOCUMENT, FETCH_DOCUMENTS, FETCH_DOCUMENT_FOLDER_DETAILS } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);
const axiosOptions: ObjectType = {
	responseType: "blob",
	timeout: 30000,
};

export const getDocumentsApi = async ({ id }: GetDocumentTreePayloadType): Promise<AxiosResponse<any>> =>
	axios.get(`${FETCH_DOCUMENTS}${id}/`).catch(errorHandler);

export const deleteDocumentTreeFileApi = async ({
	memberId,
	key,
}: DeleteDocumentTreeFilePayloadType): Promise<AxiosResponse<any>> => {
	const url: string = DELETE_DOCUMENT_FILE.replace(":memberId", `${memberId}`).replace(":key", `${key}`);

	return axios.delete(url).catch(errorHandler);
};

export const downloadDocumentApi = async ({
	memberId,
	key,
}: DownloadDocumentPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = DOWNLOAD_DOCUMENT.replace(":memberId", `${memberId}`).replace(":key", `${key}`);

	return axios.get(url, axiosOptions).catch(errorHandler);
};

export const getDocumentFolderDocumentDetailsApi = async ({
	memberId,
	folderKey,
}: GetDocsFolderDetailsPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = FETCH_DOCUMENT_FOLDER_DETAILS.replace(":memberId", `${memberId}`).replace(
		":folderKey",
		`${folderKey}`
	);
	return axios.get(url).catch(errorHandler);
};
