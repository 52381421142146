import { call, put, select, takeLatest } from "redux-saga/effects";
import { search } from "__utils/helpers";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";
import { getTemplatesSlr } from "./selectors";
import { GetTemplatesType, TemplateDetailsType, TemplatesType } from "./types";

const getTemplateDetails = function* ({ payload }: any): any {
	const { id } = payload;

	try {
		const response: ResponseGeneratorType = yield call(api.getTemplateDetailsApi, id);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_TEMPLATE_DETAILS_SUCCESS,
				templateDetails: data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_TEMPLATE_DETAILS_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_TEMPLATE_DETAILS_FAILURE });
	}
};

export const getTemplateDetailsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_TEMPLATE_DETAILS, getTemplateDetails);
};

const getTemplates = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getTemplatesApi);
		const { status, data } = response;
		const { templates, categories } = data || {};

		if (status === 200) {
			const { limit }: GetTemplatesType = yield select(getTemplatesSlr);
			const numOfPages: number = Math.ceil(templates.length / limit);
			const partial: TemplatesType = templates.slice(0, 1 * limit);
			yield put({
				type: actionTypes.GET_TEMPLATES_SUCCESS,
				templates,
				partial,
				numOfPages,
				categories,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_TEMPLATES_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_TEMPLATES_FAILURE });
	}
};

export const getTemplatesSaga = function* (): any {
	yield takeLatest(actionTypes.GET_TEMPLATES, getTemplates);
};

const getTemplatesOnScroll = function* ({ payload }: any): any {
	try {
		const { page } = payload;
		const { templates, numOfPages, limit }: GetTemplatesType = yield select(getTemplatesSlr);

		if (page <= numOfPages) {
			const partial: TemplatesType = templates.slice(0, page * limit);

			yield put({
				type: actionTypes.GET_TEMPLATES_SUCCESS,
				partial,
				numOfPages,
				currPage: page,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.GET_TEMPLATES_FAILURE,
		});
	}
};

export const getTemplatesOnScrollSaga = function* (): any {
	yield takeLatest(actionTypes.GET_TEMPLATES_ON_SCROLL, getTemplatesOnScroll);
};

const deleteTemplate = function* (action: any): any {
	const { payload } = action;
	const getTemplate: GetTemplatesType = yield select(getTemplatesSlr);
	const { templates } = getTemplate;

	try {
		const response: ResponseGeneratorType = yield call(api.deleteTemplateApi, payload);
		const { status } = response; // data

		if (status === 200) {
			const filteredTemplates = templates.filter((obj: TemplateDetailsType) => obj.id !== payload.id);

			yield put({
				type: actionTypes.GET_TEMPLATES_SUCCESS,
				partial: filteredTemplates,
			});

			yield put({
				type: actionTypes.DELETE_TEMPLATE_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			// errorDeleting
			yield put({
				type: actionTypes.DELETE_TEMPLATE_FAILURE,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.DELETE_TEMPLATE_FAILURE,
		});
	}
};

export const deleteTemplateSaga = function* (): any {
	yield takeLatest(actionTypes.DELETE_TEMPLATE, deleteTemplate);
};

const addTemplate = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.addTemplateApi, payload);
		const { status, data } = response;

		if (status === 201) {
			yield put({
				type: actionTypes.ADD_TEMPLATE_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.ADD_TEMPLATE_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.ADD_TEMPLATE_FAILURE });
	}
};

export const addTemplateSaga = function* (): any {
	yield takeLatest(actionTypes.ADD_TEMPLATE, addTemplate);
};

const editTemplate = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.editTemplateApi, payload);
		const { status, data } = response;
		if (status === 204) {
			yield put({
				type: actionTypes.EDIT_TEMPLATE_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.EDIT_TEMPLATE_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.EDIT_TEMPLATE_FAILURE });
	}
};

export const editTemplateSaga = function* (): any {
	yield takeLatest(actionTypes.EDIT_TEMPLATE, editTemplate);
};

const getTemplateCategories = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getTemplateCategoriesApi);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_TEMPLATE_CATEGORIES_SUCCESS,
				templateCategories: data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_TEMPLATE_CATEGORIES_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_TEMPLATE_CATEGORIES_FAILURE });
	}
};

export const getTemplateCategoriesSaga = function* (): any {
	yield takeLatest(actionTypes.GET_TEMPLATE_CATEGORIES, getTemplateCategories);
};

const filterTemplates = function* ({ payload }: any): any {
	try {
		const { filters } = payload;
		const { templates, currPage, limit }: GetTemplatesType = yield select(getTemplatesSlr);

		const result: TemplatesType = search(templates, filters.searchFilter);
		const _docRequests: TemplatesType = filters.searchFilter ? result : templates;
		const partial: TemplatesType = _docRequests.slice(0, currPage * limit);

		yield put({
			type: actionTypes.GET_TEMPLATES_SUCCESS,
			partial,
		});
	} catch (error) {
		yield put({
			type: actionTypes.GET_TEMPLATES_FAILURE,
		});
	}
};

export const filterTemplatesSaga = function* (): any {
	yield takeLatest(actionTypes.FILTER_TEMPLATES, filterTemplates);
};
