export const ACCOUNT_EDITED_MESSAGE = "Account edited successfully";

export const FIRST_NAME_NAME = "firstName";
export const LAST_NAME_NAME = "lastName";
export const EMAIL_NAME = "email";
export const PHONE_NAME = "phone";
export const ADMIN_BRAND_NAME = "administratorBrand";

export const ARCHIVE_FUND_MSG = "Do you really want to archive the fund?";
export const ARCHIVE_SUCCESS_MESSAGE = "Fund Archive Successfully";
export const ARCHIVE = "archive";

export const RESTORE_FUND_MSG = "Do you really want to restore the fund?";
export const RESTORE_SUCCESS_MESSAGE = "Fund Restored Successfully";
export const RESTORE = "restore";

export const DELETE_FUND_MSG = "Do you really want to delete this fund?";
export const DELETE_SUCCESS_MESSAGE = "Fund Deleted Successfully";
export const DELETE = "delete";

export const DEACTIVATE_FUND_MSG = "Do you really want to deactivate this fund?";
export const DEACTIVATE_SUCCESS_MESSAGE = "Fund Deactivated Successfully";
export const DEACTIVATE = "deactivate";

export const OVERRIDE_BALANCE_FLAG_MSG = "Do you really want to Override the balance of this fund?";
export const OVERRIDE_BALANCE_FLAG_SUCCESS_MESSAGE = "Override Balance Successfully";
