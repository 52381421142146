import {
	GetDocumentTreeType,
	DeleteDocumentTreeFileType,
	DownloadDocumentType,
	GetDocsFolderDetailsType,
} from "./types";

export const getDocumentTreeSlr = (state: any): GetDocumentTreeType =>
	state.fundDetails.documents.fileManager.getDocumentTree;
export const deleteDocumentTreeFileSlr = (state: any): DeleteDocumentTreeFileType =>
	state.fundDetails.documents.fileManager.deleteDocumentTreeFile;
export const downloadDocumentSlr = (state: any): DownloadDocumentType =>
	state.fundDetails.documents.fileManager.downloadDocument;

export const getDocumentFolderDetailsSlr = (state: any): GetDocsFolderDetailsType =>
	state.fundDetails.documents.fileManager.getDocumentFolderDetails;
