import { MemberType } from "__pages/FundDetails/__redux/types";
import { ObjectType } from "__utils/types";
import * as actionTypes from "./actionTypes";

/**
 * Add admin
 */

export type AddMemberType = {
	readonly newMemberEmail: string;
	readonly isAddingMember: boolean;
	readonly isAddMemberSuccess: boolean;
	readonly isAddMemberError: boolean;
	readonly addError: ObjectType;
};

export const addMemberDefaultValue: AddMemberType = {
	newMemberEmail: "",
	isAddingMember: false,
	isAddMemberSuccess: false,
	isAddMemberError: false,
	addError: {},
};

export type AddMember = {
	(payload: MemberType): AddMemberType;
};

export type AddMemberReset = {
	(): AddMemberType;
};

export type MemberPayloadType = {
	id: number;
	body: MemberType;
};

type AddMemberAction = {
	type: typeof actionTypes.ADD_MEMBER;
	payload: MemberPayloadType;
};

type AddMemberSuccessAction = {
	type: typeof actionTypes.ADD_MEMBER_SUCCESS;
	payload: MemberPayloadType;
};

type AddMemberFailureAction = {
	type: typeof actionTypes.ADD_MEMBER_FAILURE;
	payload: MemberPayloadType;
};

type AddMemberResetAction = {
	type: typeof actionTypes.ADD_MEMBER_RESET;
};

export type AddMemberActionTypes =
	| AddMemberAction
	| AddMemberSuccessAction
	| AddMemberFailureAction
	| AddMemberResetAction;

/**
 * Update Member
 */

export type EditMemberType = {
	readonly id: number;
	readonly isEditingMember: boolean;
	readonly isEditMemberSuccess: boolean;
	readonly isEditMemberError: boolean;
	readonly editError: ObjectType;
};

export const editMemberDefaultValue: EditMemberType = {
	id: 0,
	isEditingMember: false,
	isEditMemberSuccess: false,
	isEditMemberError: false,
	editError: {},
};

export type EditMember = {
	(payload: MemberType): AddMemberType;
};

export type EditMemberPayloadType = {
	id: number;
	body: MemberType;
};

type EditMemberAction = {
	type: typeof actionTypes.EDIT_MEMBER;
	payload: EditMemberPayloadType;
};

type EditMemberSuccessAction = {
	type: typeof actionTypes.EDIT_MEMBER_SUCCESS;
};

type EditMemberFailureAction = {
	type: typeof actionTypes.EDIT_MEMBER_FAILURE;
};

type EditMemberResetAction = {
	type: typeof actionTypes.EDIT_MEMBER_RESET;
};

export type EditMemberActionTypes =
	| EditMemberAction
	| EditMemberSuccessAction
	| EditMemberFailureAction
	| EditMemberResetAction;

/**
 * delete Member
 */

export type DeleteMemberType = {
	readonly deletingId: number;
	readonly isDeletingMember: boolean;
	readonly isDeleteMemberSuccess: boolean;
	readonly isDeleteMemberError: boolean;
};

export const deleteMemberDefaultValue: DeleteMemberType = {
	deletingId: 0,
	isDeletingMember: false,
	isDeleteMemberSuccess: false,
	isDeleteMemberError: false,
};

export type DeleteMember = {
	(payload: MemberType): DeleteMemberType;
};

export type DeleteMemberPayloadType = {
	deletingId: number;
};

type DeleteMemberAction = {
	type: typeof actionTypes.DELETE_MEMBER;
	payload: DeleteMemberPayloadType;
};

type DeleteMemberSuccessAction = {
	type: typeof actionTypes.DELETE_MEMBER_SUCCESS;
};

type DeleteMemberFailureAction = {
	type: typeof actionTypes.DELETE_MEMBER_FAILURE;
};

type DeleteMemberResetAction = {
	type: typeof actionTypes.DELETE_MEMBER_RESET;
};

export type DeleteMemberActionTypes =
	| DeleteMemberAction
	| DeleteMemberSuccessAction
	| DeleteMemberFailureAction
	| DeleteMemberResetAction;

/** Edit DirectorID
 */

export type IEditDirectorsID = {
	readonly editingId: number;
	readonly isEditingDirectorID: boolean;
	readonly isEditDirectorIDSuccess: boolean;
	readonly isEditDirectorIDFailed: boolean;
	readonly isEditDirectorIDError: { [key: string]: Array<string> };
};

export const editDirectorIDDefaultValue: IEditDirectorsID = {
	editingId: 0,
	isEditingDirectorID: false,
	isEditDirectorIDSuccess: false,
	isEditDirectorIDFailed: false,
	isEditDirectorIDError: {},
};

export type IEditDirectorsPayloadType = {
	editingId: number;
	directorId: string;
};

type IEditDirectorsAction = {
	type: typeof actionTypes.EDIT_DIRECTOR_ID;
	payload: IEditDirectorsPayloadType;
};

type IEditDirectorsSuccessAction = {
	type: typeof actionTypes.EDIT_DIRECTOR_ID_SUCCESS;
};

type IEditDirectorsFailureAction = {
	type: typeof actionTypes.EDIT_DIRECTOR_ID_FAILURE;
};

type IEditDirectorsResetAction = {
	type: typeof actionTypes.EDIT_DIRECTOR_ID_RESET;
};

export type IEditDirectorsActions =
	| IEditDirectorsAction
	| IEditDirectorsSuccessAction
	| IEditDirectorsFailureAction
	| IEditDirectorsResetAction;
