import * as actionTypes from "./actionTypes";
import {
	moveWorkflowFundDetailsGenerateDocsStateDefaultValue,
	IMoveWorkflowFundDetailsGenerateDocsState,
	IMoveWorkflowFundDetailsGenerateDocsStateActionTypes,
} from "./types";

export const moveWorkflowFundDetailsGenerateDocsStateReducer = (
	state = moveWorkflowFundDetailsGenerateDocsStateDefaultValue,
	action: IMoveWorkflowFundDetailsGenerateDocsStateActionTypes
): IMoveWorkflowFundDetailsGenerateDocsState => {
	switch (action.type) {
		case actionTypes.MOVE_WORKFLOW_FUND_DETAILS_GENERATE_DOCS:
			return {
				...state,
				movingWorkflowFundDetailsGenerateDocsState: true,
			};
		case actionTypes.MOVE_WORKFLOW_FUND_DETAILS_GENERATE_DOCS_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				movingWorkflowFundDetailsGenerateDocsState: false,
				moveWorkflowFundDetailsGenerateDocsStateSuccess: true,
				moveWorkflowFundDetailsGenerateDocsStateFailure: false,
				moveWorkflowFundDetailsGenerateDocsStateError: {},
			};
		case actionTypes.MOVE_WORKFLOW_FUND_DETAILS_GENERATE_DOCS_FAILURE:
			return {
				...state,
				movingWorkflowFundDetailsGenerateDocsState: false,
				moveWorkflowFundDetailsGenerateDocsStateSuccess: false,
				moveWorkflowFundDetailsGenerateDocsStateFailure: true,
				moveWorkflowFundDetailsGenerateDocsStateError: {},
			};
		case actionTypes.MOVE_WORKFLOW_FUND_DETAILS_GENERATE_DOCS_RESET:
			return {
				...state,
				...moveWorkflowFundDetailsGenerateDocsStateDefaultValue,
			};
		default:
			return state;
	}
};
