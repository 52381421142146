import { DocumentDetailsType, DocumentsType } from "__pages/RequestDocuments/__redux/types";

import { DocRequestsType, DocSigningRequestDetailsType } from "../__redux/types";

/**
 * Remaps id and documentId
 * @param docs
 * @returns
 */
export const remapDocumentIDs = (docs: DocumentsType): DocumentsType =>
	docs.reduce((acc: DocumentsType, curr: DocumentDetailsType) => {
		if ("documentId" in curr) {
			acc.push(curr);
		} else {
			acc.push({
				...curr,
				id: 0,
				documentId: curr.id,
			});
		}
		return acc;
	}, []);

/**
 * Simple utility that generates
 * @returns
 */
export const uniqueNumber = (): number => {
	const nums: Set<number> = new Set();
	while (nums.size !== 8) {
		nums.add(Math.floor(Math.random() * 100) + 1);
	}
	return parseInt([...nums].join(""));
};

/**
 * Adds compositeId prop for each item
 * @param requests
 * @returns
 */
export const compositeId = (requests: DocRequestsType): DocRequestsType => {
	const arr: DocRequestsType = [];
	requests.forEach((r: DocSigningRequestDetailsType) => {
		arr.push({ ...r, compositeId: `${r.type}${r.id}` });
	});

	return arr;
};
