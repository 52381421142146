import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import {
	IAddTrustActions,
	addTrustDefaultValue,
	IAddTrust,
	IDeleteTrustActions,
	deleteTrustDefaultValue,
	IDeleteTrust,
	IEditTrustActions,
	editTrustDefaultValue,
	IEditTrust,
} from "./types";

export const addTrustReducer = (state = addTrustDefaultValue, action: IAddTrustActions): IAddTrust => {
	switch (action.type) {
		case actionTypes.ADD_TRUST:
			return {
				...state,
				isAddingTrust: true,
			};

		case actionTypes.ADD_TRUST_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isAddingTrust: false,
				isAddTrustSuccess: true,
				isAddTrustFailed: false,
			};
		case actionTypes.ADD_TRUST_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isAddingTrust: false,
				isAddTrustSuccess: false,
				isAddTrustFailed: true,
			};

		case actionTypes.ADD_TRUST_RESET:
			return {
				...addTrustDefaultValue,
			};
		default:
			return state;
	}
};

export const editTrustReducer = (state = editTrustDefaultValue, action: IEditTrustActions): IEditTrust => {
	switch (action.type) {
		case actionTypes.EDIT_TRUST:
			return {
				...state,
				isEditingTrust: true,
			};

		case actionTypes.EDIT_TRUST_SUCCESS:
			const { type: successType, ...successRest } = action;

			return {
				...state,
				...successRest,
				isEditingTrust: false,
				isEditTrustSuccess: true,
				isEditTrustFailed: false,
			};
		case actionTypes.EDIT_TRUST_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isEditingTrust: false,
				isEditTrustSuccess: false,
				isEditTrustFailed: true,
			};

		case actionTypes.EDIT_TRUST_RESET:
			return {
				...editTrustDefaultValue,
			};
		default:
			return state;
	}
};

export const deleteTrustReducer = (state = deleteTrustDefaultValue, action: IDeleteTrustActions): IDeleteTrust => {
	switch (action.type) {
		case actionTypes.DELETE_TRUST:
			return {
				...state,
				isDeletingTrust: true,
			};

		case actionTypes.DELETE_TRUST_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isDeletingTrust: false,
				isDeleteTrustSuccess: true,
				isDeleteTrustError: false,
			};
		case actionTypes.DELETE_TRUST_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isDeletingTrust: false,
				isDeleteTrustSuccess: false,
				isDeleteTrustError: true,
			};

		case actionTypes.DELETE_TRUST_RESET:
			return {
				...deleteTrustDefaultValue,
			};
		default:
			return state;
	}
};

export const trustsReducer = combineReducers({
	editTrust: editTrustReducer,
	addTrust: addTrustReducer,
	deleteTrust: deleteTrustReducer,
});
