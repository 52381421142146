import { call, put, select, takeLatest } from "redux-saga/effects";
import * as fundsActionTypes from "__pages/Funds/__redux/actionTypes";
import { fundsSlr } from "__pages/Funds/__redux/selectors";
import { FundDetailsType, FundsType, GetFundsType } from "__pages/Funds/__redux/types";
import { ObjectType, ResponseGeneratorType } from "__utils/types";
import { getFundDetailsSlr } from "__pages/FundDetails/__redux/selectors";
import { GetFundDetailsType } from "__pages/FundDetails/__redux/types";
import { GET_FUND_DETAILS_SUCCESS } from "__pages/FundDetails/__redux/actionTypes";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";
import { TagsType } from "./types";

const prepareTags = (tags: TagsType): ObjectType => tags.map((tag: string) => ({ title: tag }));

const editTags = function* ({ payload }: any): any {
	try {
		const { id, tags } = payload;
		const response: ResponseGeneratorType = yield call(api.editTagsApi, { id, tags: prepareTags(tags) });
		const { status } = response;

		if (status === 201) {
			// Funds
			const getFunds: GetFundsType = yield select(fundsSlr);
			const { funds } = getFunds;

			const updatedFunds: FundsType = funds.map((item: FundDetailsType) => {
				if (item.id !== id) {
					return item;
				}
				return { ...item, tags };
			});

			// Update funds
			yield put({
				type: fundsActionTypes.GET_FUNDS_SUCCESS,
				...getFunds,
				funds: updatedFunds,
			});

			yield put({
				type: actionTypes.EDIT_TAGS_SUCCESS,
			});

			// Update Fund Details
			const getFundDetails: GetFundDetailsType = yield select(getFundDetailsSlr);
			const { account } = getFundDetails;
			const { tags: oldTags } = account;

			yield put({
				type: GET_FUND_DETAILS_SUCCESS,
				...{ ...getFundDetails, account: { ...account, tags: Array.from(new Set([...oldTags, ...tags])) } },
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.EDIT_TAGS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.EDIT_TAGS_FAILURE });
	}
};

export function* editTagsSaga(): any {
	yield takeLatest(actionTypes.EDIT_TAGS, editTags);
}
