import React from "react";
import DropdownArrow from "../DropdownArrow";

type Props = {
	children: any;
};

const DropdownRender: React.FC<Props> = ({ children }: Props) => (
	<div>
		<DropdownArrow />
		{children}
	</div>
);

export default DropdownRender;
