import {
	GetFundsType,
	DeleteFundType,
	ArchiveFundType,
	RestoreFundType,
	GetRequestSummaryType,
	IGetSMSFComplyingFundsCount,
	IGetFundsUserMeta,
	IGetTags,
	DeactivateFundType,
} from "./types";

export const fundsSlr = (state: any): GetFundsType => state.funds.getFunds;

export const deletefundSlr = (state: any): DeleteFundType => state.funds.deleteFund;

export const archivefundSlr = (state: any): ArchiveFundType => state.funds.archiveFund;

export const restorefundSlr = (state: any): RestoreFundType => state.funds.restoreFund;

export const deactivateFundSlr = (state: any): DeactivateFundType => state.funds.deactivateFund;

export const getRequestSummarySlr = (state: any): GetRequestSummaryType => state.funds.getRequestSummary;

export const getSMSFComplyingFundsCountSlr = (state: any): IGetSMSFComplyingFundsCount =>
	state.funds.getSMSFComplyingFundsCount;

export const getFundsUserMetaSlr = (state: any): IGetFundsUserMeta => state.funds.getFundsUserMeta;

export const getTagsSlr = (state: any): IGetTags => state.funds.getTags;
