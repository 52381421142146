import styled from "styled-components";

export const Container = styled.div`
	div.ant-typography {
		margin-top: 10px;
		font-size: 14px;
	}

	.check-box {
		text-align: right;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.review-fund-footer {
		text-align: right;
	}
`;
