import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { AddBankDetailsPayloadType } from "../__redux/types";
import { EDIT_BANK_DETAILS } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const editBankDetailsApi = async (payload: AddBankDetailsPayloadType): Promise<AxiosResponse<any>> => {
	const { memberId, ...rest } = payload;

	return axios.post(`${EDIT_BANK_DETAILS}${memberId}/`, { ...rest }).catch(errorHandler);
};
