import { ApiErrorType } from "__utils/types";
import * as actionTypes from "./actionTypes";
/**
 * Create Recurring Payment
 */

export type AddRecurringPaymentType = {
	readonly isAddingRecurringPayment: boolean;
	readonly isAddRecurringPaymentSuccess: boolean;
	readonly isAddRecurringPaymentError: boolean;
	readonly addRecurringPaymentError: string | ApiErrorType;
};

export const addRecurringPaymentDefaultValue: AddRecurringPaymentType = {
	isAddingRecurringPayment: false,
	isAddRecurringPaymentSuccess: false,
	isAddRecurringPaymentError: false,
	addRecurringPaymentError: {},
};

export type AddRecurringPaymentPayloadType = {
	readonly id: number;
	readonly debitDate: string;
	readonly amount: string;
	readonly reference: string;
};

export type AddRecurringPayment = {
	(payload: AddRecurringPaymentPayloadType): AddRecurringPaymentActionTypes;
};

type AddRecurringPaymentAction = {
	type: typeof actionTypes.ADD_RECURRING_PAYMENT;
	payload: AddRecurringPaymentPayloadType;
};

type AddRecurringPaymentSuccessAction = {
	type: typeof actionTypes.ADD_RECURRING_PAYMENT_SUCCESS;
	payload: AddRecurringPaymentPayloadType;
};

type AddRecurringPaymentFailureAction = {
	type: typeof actionTypes.ADD_RECURRING_PAYMENT_FAILURE;
	payload: AddRecurringPaymentPayloadType;
};

type AddRecurringPaymentResetAction = {
	type: typeof actionTypes.ADD_RECURRING_PAYMENT_RESET;
};

export type AddRecurringPaymentActionTypes =
	| AddRecurringPaymentAction
	| AddRecurringPaymentSuccessAction
	| AddRecurringPaymentFailureAction
	| AddRecurringPaymentResetAction;
