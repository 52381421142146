import React from "react";
import { Container } from "./style";

type Props = {
	label: string;
	getValue: () => any;
};

export const FloatLabel: React.FC<Props> = ({ label, children, getValue }) => {
	const [focus, setFocus] = React.useState<boolean>(false);
	const value = getValue();
	const labelClass = focus || !!value ? "label label-float" : "label";

	return (
		<Container>
			<div className="float-label" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
				{children}
				<span className={labelClass}>{label}</span>
			</div>
		</Container>
	);
};

export default FloatLabel;
