import Col from "antd/es/col";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Row from "antd/es/row";
import Select from "antd/es/select";
import { FormInstance } from "antd/lib/form";
import { isEmpty } from "lodash";
import React from "react";
import { VscLocation } from "react-icons/vsc";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import AntdifyIcon from "__common/AntdifyIcon";
import DropdownRender from "__common/DropdownRender";
import { FloatLabel } from "__common/FloatLabel";
import * as lang from "__utils/lang";
import { ObjectType } from "__utils/types";
import { statesInitialName } from "../../../__utils/constants";
import { getPlaces } from "../../../__utils/helper";
import * as memberLang from "../../../__utils/lang";

const getSuggestionsStyle = (active: boolean): ObjectType => {
	if (active) {
		return {
			backgroundColor: "#ddd",
		};
	}
	return {};
};

type Props = {
	form: FormInstance;
	isDisabled: boolean;
};

const PostalFields: React.FC<Props> = ({ form, isDisabled }: Props) => {
	const { getFieldValue, setFieldsValue } = form;
	const [address, setAddress] = React.useState("");
	const [postalAutoAddress, setPostalAddress] = React.useState<ObjectType>({});

	React.useEffect(() => {
		if (isEmpty(!postalAutoAddress)) {
			setFieldsValue({ ...postalAutoAddress });
		}
	}, [postalAutoAddress, setFieldsValue]);

	const onPlaceSelect = async (value: string): Promise<void> => {
		const result = await geocodeByAddress(value);
		const getPlacesData: ObjectType = (await getPlaces(result)) as ObjectType;
		const { address, postCode, state, suburb, streetNumber, streetOnly } = getPlacesData;
		setPostalAddress({
			...postalAutoAddress,
			postalAddress: address,
			postalPostcode: postCode,
			postalSuburb: suburb,
			postalState: state,
			postalStreetOnly: streetOnly,
			postalStreetNumber: streetNumber,
		});
	};

	const handleChange = (value: string): void => {
		setAddress(value);
	};

	return (
		<Row gutter={[16, 8]}>
			<Col xs={24} xl={8}>
				<FloatLabel label={memberLang.POSTAL_ADDRESS_LABEL} getValue={() => getFieldValue("postalAddress")}>
					<PlacesAutocomplete
						value={address}
						onChange={handleChange}
						onSelect={onPlaceSelect}
						// use to search AUS Only
						searchOptions={{ componentRestrictions: { country: "aus" } }}
					>
						{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
							<div>
								<Form.Item
									name="postalAddress"
									rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
								>
									<Input {...getInputProps()} autoComplete="nope" disabled={isDisabled} />
								</Form.Item>

								{loading && <div>Loading...</div>}
								{suggestions.length > 0 && (
									<div className="suggestion-list">
										{suggestions.map((suggestion, idx) => {
											const className = "suggestion-item";
											const style = getSuggestionsStyle(suggestion.active);

											return (
												<div
													{...getSuggestionItemProps(suggestion, {
														className,
														style,
													})}
													key={idx}
												>
													<AntdifyIcon ariaLabel="location icon" style={{ fontSize: "16px" }}>
														<VscLocation />
													</AntdifyIcon>{" "}
													<strong>{suggestion.formattedSuggestion.mainText}</strong>{" "}
													<small>{suggestion.formattedSuggestion.secondaryText}</small>
												</div>
											);
										})}
									</div>
								)}
							</div>
						)}
					</PlacesAutocomplete>
				</FloatLabel>
			</Col>
			<Col xs={24} xl={8}>
				<Row gutter={[8, 4]}>
					<Col xs={24} xl={12}>
						<FloatLabel label={memberLang.POSTAL_UNIT_LABEL} getValue={() => getFieldValue("postalUnit")}>
							<Form.Item name="postalUnit">
								<Input disabled={isDisabled} autoComplete="nope" />
							</Form.Item>
						</FloatLabel>
					</Col>
					<Col xs={24} xl={12}>
						<FloatLabel
							label={memberLang.POSTAL_SUBURB_LABEL}
							getValue={() => getFieldValue("postalSuburb") || postalAutoAddress.postalSuburb}
						>
							<Form.Item
								name="postalSuburb"
								rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
							>
								<Input disabled={isDisabled} autoComplete="nope" />
							</Form.Item>
						</FloatLabel>
					</Col>
				</Row>
			</Col>
			<Col xs={24} xl={8}>
				<Row gutter={[8, 4]}>
					<Col xs={24} xl={12}>
						<FloatLabel
							label={memberLang.POSTAL_STATE_LABEL}
							getValue={() => getFieldValue("postalState") || postalAutoAddress.postalState}
						>
							<Form.Item
								name="postalState"
								rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
							>
								<Select
									getPopupContainer={(trigger: any) => trigger.parentElement}
									dropdownRender={(menu: any) => <DropdownRender>{menu}</DropdownRender>}
									disabled={isDisabled}
								>
									{statesInitialName.map((type, idx) => (
										<Select.Option key={idx} value={type}>
											{type}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</FloatLabel>
					</Col>
					<Col xs={24} xl={12}>
						<FloatLabel
							label={memberLang.POSTAL_POSTCODE_LABEL}
							getValue={() => getFieldValue("postalPostcode") || postalAutoAddress.postalPostcode}
						>
							<Form.Item
								name="postalPostcode"
								rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
							>
								<Input disabled={isDisabled} autoComplete="nope" />
							</Form.Item>
						</FloatLabel>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default PostalFields;
