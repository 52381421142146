import { call, put, takeEvery } from "redux-saga/effects";
import { ResponseGeneratorType } from "__utils/types";
import fileDownload from "js-file-download";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";

const extractFilename = (raw: string): string => raw.split(" ")[1].split('"')[1];

const downloadReport = function* (action: any): any {
	const { name, ...rest } = action.payload;
	try {
		const response: ResponseGeneratorType = yield call(api.downloadReportApi, rest);
		const { status, data } = response;
		yield call(fileDownload, data, extractFilename(response.headers["content-disposition"]));

		if (status === 200) {
			yield put({
				type: actionTypes.DOWNLOAD_REPORT_SUCCESS,
				name,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.DOWNLOAD_REPORT_FAILURE, name });
		}
	} catch (error) {
		yield put({ type: actionTypes.DOWNLOAD_REPORT_FAILURE, name });
	}
};

export const downloadReportSaga = function* (): any {
	yield takeEvery(actionTypes.DOWNLOAD_REPORT, downloadReport);
};
