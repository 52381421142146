import { ApiErrorType } from "__utils/types";
import * as actionTypes from "./actionTypes";

/**
 * MoveWorkflowDashboard
 */
export type IMoveWorkflowDashboardGenerateDocsState = {
	readonly movingWorkflowDashboardGenerateDocsState: boolean;
	readonly moveWorkflowDashboardGenerateDocsStateSuccess: boolean;
	readonly moveWorkflowDashboardGenerateDocsStateFailure: boolean;
	readonly moveWorkflowDashboardGenerateDocsStateError: ApiErrorType;
};

export const moveWorkflowDashboardGenerateDocsStateDefaultValue: IMoveWorkflowDashboardGenerateDocsState = {
	movingWorkflowDashboardGenerateDocsState: false,
	moveWorkflowDashboardGenerateDocsStateSuccess: false,
	moveWorkflowDashboardGenerateDocsStateFailure: false,
	moveWorkflowDashboardGenerateDocsStateError: {},
};

export type IMoveWorkflowDashboardGenerateDocsStatePayload = {
	readonly workflowFundId: number;
	readonly envelopeId: string;
};

export type MoveWorkflowDashboardGenerateDocsState = {
	(payload: IMoveWorkflowDashboardGenerateDocsStatePayload): IMoveWorkflowDashboardGenerateDocsStateActionTypes;
};

export type IMoveWorkflowDashboardGenerateDocsStateAction = {
	type: typeof actionTypes.MOVE_WORKFLOW_DASHBOARD_GENERATE_DOCS;
	payload: IMoveWorkflowDashboardGenerateDocsStatePayload;
};

export type IMoveWorkflowDashboardGenerateDocsStateSuccessAction = {
	type: typeof actionTypes.MOVE_WORKFLOW_DASHBOARD_GENERATE_DOCS_SUCCESS;
};

export type IMoveWorkflowDashboardGenerateDocsStateFailureAction = {
	type: typeof actionTypes.MOVE_WORKFLOW_DASHBOARD_GENERATE_DOCS_FAILURE;
};

export type IMoveWorkflowDashboardGenerateDocsStateResetAction = {
	type: typeof actionTypes.MOVE_WORKFLOW_DASHBOARD_GENERATE_DOCS_RESET;
};

export type IMoveWorkflowDashboardGenerateDocsStateActionTypes =
	| IMoveWorkflowDashboardGenerateDocsStateAction
	| IMoveWorkflowDashboardGenerateDocsStateSuccessAction
	| IMoveWorkflowDashboardGenerateDocsStateFailureAction
	| IMoveWorkflowDashboardGenerateDocsStateResetAction;
