import dayjs from "dayjs";
import { IWorkflowFundNote } from "../__redux/types";

export const sortNotesByDateDesc = (notes: IWorkflowFundNote[]): IWorkflowFundNote[] => {
	const sorted: IWorkflowFundNote[] = notes.sort((a: IWorkflowFundNote, b: IWorkflowFundNote) => {
		return dayjs(a.modifiedAt).isAfter(dayjs(b.modifiedAt)) ? -1 : 1;
	});

	const complete: IWorkflowFundNote[] = [];
	const active: IWorkflowFundNote[] = [];
	sorted.forEach((curr: IWorkflowFundNote) => {
		if (curr.isComplete) {
			complete.push(curr);
		} else {
			active.push(curr);
		}
	});

	return [...active, ...complete];
};
