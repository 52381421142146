import Col from "antd/es/col";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Row from "antd/es/row";
import { FormInstance } from "antd/lib/form";
import React from "react";
import { FloatLabel } from "__common/FloatLabel";
import * as lang from "__utils/lang";
import { ObjectType } from "__utils/types";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import AntdifyIcon from "__common/AntdifyIcon";
import { VscLocation } from "react-icons/vsc";
import Select from "antd/es/select";
import DropdownRender from "__common/DropdownRender";
import * as memberLang from "../../../__utils/lang";
import { getPlaces, getSuggestionsStyle } from "../../../__utils/helper";
import { statesInitialName } from "../../../__utils/constants";

type Props = {
	form: FormInstance;
	isDisabled: boolean;
};

const BusinessInformationFields: React.FC<Props> = ({ form, isDisabled }: Props) => {
	const { getFieldValue, setFieldsValue } = form;
	const [address, setAddress] = React.useState("");
	const [businessAutoAddress, setBusinessAddress] = React.useState<ObjectType>({});

	const onPlaceChange = async (value: string): Promise<void> => {
		const result = await geocodeByAddress(value);

		const getPlacesData: ObjectType = (await getPlaces(result)) as ObjectType;
		const { address, postCode, state, suburb, country } = getPlacesData || {};

		setBusinessAddress({
			...businessAutoAddress,
			businessAddress: address,
			businessSuburb: suburb,
			businessState: state,
			businessPostcode: postCode,
			businessCountry: country,
		});
		setFieldsValue({
			businessAddress: address,
			businessSuburb: suburb,
			businessState: state,
			businessPostcode: postCode,
			businessCountry: country,
		});
	};

	const onAddressChange = (value: string): void => {
		setAddress(value);
	};

	return (
		<>
			<Row gutter={[16, 8]}>
				<Col xs={24} xl={8}>
					<FloatLabel
						label={memberLang.BUSINESS_NAME_LABEL}
						getValue={() => getFieldValue(memberLang.BUSINESS_NAME)}
					>
						<Form.Item
							name={memberLang.BUSINESS_NAME}
							rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
						>
							<Input disabled={isDisabled} autoComplete="chrome-off" />
						</Form.Item>
					</FloatLabel>
				</Col>
				<Col xs={24} xl={8}>
					<FloatLabel
						label={memberLang.ABN_OR_ACN_OF_BUSINESS_LABEL}
						getValue={() => getFieldValue(memberLang.ABN_OR_ACN_OF_BUSINESS)}
					>
						<Form.Item
							name={memberLang.ABN_OR_ACN_OF_BUSINESS}
							rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
						>
							<Input disabled={isDisabled} autoComplete="chrome-off" />
						</Form.Item>
					</FloatLabel>
				</Col>
			</Row>
			<Row gutter={[16, 8]}>
				<Col xs={24} xl={8}>
					<FloatLabel
						label={memberLang.BUSINESS_ADDRESS_LABEL}
						getValue={() => getFieldValue(memberLang.BUSINESS_ADDRESS)}
					>
						<PlacesAutocomplete value={address} onChange={onAddressChange} onSelect={onPlaceChange}>
							{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
								<div>
									<Form.Item
										name={memberLang.BUSINESS_ADDRESS}
										rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
									>
										<Input {...getInputProps()} disabled={isDisabled} autoComplete="chrome-off" />
									</Form.Item>

									{loading && <div>Loading...</div>}
									{suggestions.length > 0 && (
										<div className="suggestion-list">
											{suggestions.map((suggestion, idx) => {
												const className = "suggestion-item";
												const style = getSuggestionsStyle(suggestion.active);

												return (
													<div
														{...getSuggestionItemProps(suggestion, {
															className,
															style,
														})}
														key={idx}
													>
														<AntdifyIcon
															ariaLabel="location icon"
															style={{ fontSize: "16px" }}
														>
															<VscLocation />
														</AntdifyIcon>{" "}
														<strong>{suggestion.formattedSuggestion.mainText}</strong>{" "}
														<small>{suggestion.formattedSuggestion.secondaryText}</small>
													</div>
												);
											})}
										</div>
									)}
								</div>
							)}
						</PlacesAutocomplete>
					</FloatLabel>
				</Col>
				<Col xs={24} xl={8}>
					<Row gutter={[8, 4]}>
						<Col xs={24} xl={12}>
							<FloatLabel
								label={memberLang.BUSINESS_SUBURB_LABEL}
								getValue={() =>
									getFieldValue(memberLang.BUSINESS_SUBURB) || businessAutoAddress.businessSuburb
								}
							>
								<Form.Item name={memberLang.BUSINESS_SUBURB}>
									<Input disabled={isDisabled} autoComplete="chrome-off" />
								</Form.Item>
							</FloatLabel>
						</Col>
						<Col xs={24} xl={12}>
							<FloatLabel
								label={memberLang.BUSINESS_STATE_LABEL}
								getValue={() =>
									getFieldValue(memberLang.BUSINESS_STATE) || businessAutoAddress.businessState
								}
							>
								<Form.Item
									name={memberLang.BUSINESS_STATE}
									rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
								>
									<Input disabled={isDisabled} autoComplete="chrome-off" />
								</Form.Item>
							</FloatLabel>
						</Col>
					</Row>
				</Col>
				<Col xs={24} xl={8}>
					<Row gutter={[8, 4]}>
						<Col xs={24} xl={12}>
							<FloatLabel
								label={memberLang.BUSINESS_POSTCODE_LABEL}
								getValue={() =>
									getFieldValue(memberLang.BUSINESS_POSTCODE) || businessAutoAddress.businessPostcode
								}
							>
								<Form.Item
									name={memberLang.BUSINESS_POSTCODE}
									rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
								>
									<Input disabled={isDisabled} autoComplete="chrome-off" />
								</Form.Item>
							</FloatLabel>
						</Col>
						<Col xs={24} xl={12}>
							<FloatLabel
								label={memberLang.BUSINESS_COUNTRY_LABEL}
								getValue={() =>
									getFieldValue(memberLang.BUSINESS_COUNTRY) || businessAutoAddress.businessCountry
								}
							>
								<Form.Item
									name={memberLang.BUSINESS_COUNTRY}
									rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
								>
									<Input disabled={isDisabled} autoComplete="chrome-off" />
								</Form.Item>
							</FloatLabel>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default BusinessInformationFields;
