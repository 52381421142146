import styled from "styled-components";

export const Container = styled.div`
	min-height: 148px;

	.spinner-wrapper {
		height: 125px;

		.ant-spin {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 70px;
		}
	}
`;
