import { call, put, select, takeLatest } from "redux-saga/effects";
import * as fundDetailsActionTypes from "__pages/FundDetails/__redux/actionTypes";
import { getFundDetailsSlr } from "__pages/FundDetails/__redux/selectors";
import { GetFundDetailsType } from "__pages/FundDetails/__redux/types";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";

const addMember = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.addMemberApi, payload);
		const { status, data } = response;

		if (status === 201) {
			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_SUCCESS,
				...data,
			});

			// save the latest fund data
			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_FOR_MODAL_SUCCESS,
				...data,
			});

			yield put({
				type: actionTypes.ADD_MEMBER_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({
				type: actionTypes.ADD_MEMBER_FAILURE,
				addError: data,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.ADD_MEMBER_FAILURE,
		});
	}
};

export const addMemberSaga = function* (): any {
	yield takeLatest(actionTypes.ADD_MEMBER, addMember);
};

const editMember = function* (action: any): any {
	const { payload } = action;

	try {
		const response: ResponseGeneratorType = yield call(api.editMemberApi, payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_SUCCESS,
				...data,
			});

			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_FOR_MODAL_SUCCESS,
				...data,
			});

			yield put({
				type: actionTypes.EDIT_MEMBER_SUCCESS,
				id: payload.id,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			// errorEditing
			yield put({
				type: actionTypes.EDIT_MEMBER_FAILURE,
				editError: data,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.EDIT_MEMBER_FAILURE,
		});
	}
};

export const editMemberSaga = function* (): any {
	yield takeLatest(actionTypes.EDIT_MEMBER, editMember);
};

const deleteMember = function* (action: any): any {
	const { payload } = action;
	const getFund: GetFundDetailsType = yield select(getFundDetailsSlr);

	try {
		const response: ResponseGeneratorType = yield call(api.deleteMemberApi, payload.deletingId);
		const { status, data } = response;
		if (status === 200) {
			// use remove  object
			const filteredMember = getFund.members.filter((obj: any) => obj.id !== payload.deletingId);

			const _members = {
				...getFund,
				members: filteredMember,
			};

			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_SUCCESS,
				..._members,
			});

			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_FOR_MODAL_SUCCESS,
				...data,
			});

			yield put({
				type: actionTypes.DELETE_MEMBER_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			// errorDeleting
			yield put({
				type: actionTypes.DELETE_MEMBER_FAILURE,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.DELETE_MEMBER_FAILURE,
		});
	}
};

export const deleteMemberSaga = function* (): any {
	yield takeLatest(actionTypes.DELETE_MEMBER, deleteMember);
};

const editDirectorID = function* (action: any): any {
	const { payload } = action;
	try {
		const response: ResponseGeneratorType = yield call(api.editDirectorIDApi, payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_SUCCESS,
				...data,
			});

			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_FOR_MODAL_SUCCESS,
				...data,
			});

			yield put({
				type: actionTypes.EDIT_DIRECTOR_ID_SUCCESS,
				id: payload.editingId,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			// error Editing
			yield put({
				type: actionTypes.EDIT_DIRECTOR_ID_FAILURE,
				isEditDirectorIDError: data,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.EDIT_DIRECTOR_ID_FAILURE,
		});
	}
};

export const editDirectorIDSaga = function* (): any {
	yield takeLatest(actionTypes.EDIT_DIRECTOR_ID, editDirectorID);
};
