import * as actionTypes from "./actionTypes";
import {
	AddAccountActionTypes,
	AddAccountPayloadType,
	ArchiveFundDetailsActionTypes,
	ArchiveFundDetailsPayloadType,
	DeleteFundDetailsActionTypes,
	DeleteFundDetailsPayloadType,
	EditAccountActionTypes,
	EditAccountPayloadType,
	OverrideLowBalanceFlagActionTypes,
	OverrideLowBalanceFlagPayloadType,
	RestoreFundDetailsActionTypes,
	RestoreFundDetailsPayloadType,
	GetLiveFundValueTypeActionTypes,
	GetLiveFundValueTypePayloadType,
	DeactivateFundDetailsPayloadType,
	DeactivateFundDetailsActionTypes,
} from "./types";

// Create
export const addAccountCtr = (payload: AddAccountPayloadType): AddAccountActionTypes => ({
	type: actionTypes.ADD_ACCOUNT,
	payload,
});

export const addAccountResetCtr = (): AddAccountActionTypes => ({
	type: actionTypes.ADD_ACCOUNT_RESET,
});

// Edit
export const editAccountCtr = (payload: EditAccountPayloadType): EditAccountActionTypes => ({
	type: actionTypes.EDIT_ACCOUNT,
	payload,
});

export const editAccountResetCtr = (): EditAccountActionTypes => ({
	type: actionTypes.EDIT_ACCOUNT_RESET,
});

// Archive
export const archiveFundDetailsCtr = (payload: ArchiveFundDetailsPayloadType): ArchiveFundDetailsActionTypes => ({
	type: actionTypes.ARCHIVE_FUND_DETAILS,
	payload,
});

export const archiveFundDetailsResetCtr = (): ArchiveFundDetailsActionTypes => ({
	type: actionTypes.ARCHIVE_FUND_DETAILS_RESET,
});

// Restore
export const restoreFundDetailsCtr = (payload: RestoreFundDetailsPayloadType): RestoreFundDetailsActionTypes => ({
	type: actionTypes.RESTORE_FUND_DETAILS,
	payload,
});

export const restoreFundDetailsResetCtr = (): RestoreFundDetailsActionTypes => ({
	type: actionTypes.RESTORE_FUND_DETAILS_RESET,
});

// Delete
export const deleteFundDetailsCtr = (payload: DeleteFundDetailsPayloadType): DeleteFundDetailsActionTypes => ({
	type: actionTypes.DELETE_FUND_DETAILS,
	payload,
});

export const deleteFundDetailsResetCtr = (): DeleteFundDetailsActionTypes => ({
	type: actionTypes.DELETE_FUND_DETAILS_RESET,
});

// Deactivate
export const deactivateFundDetailsCtr = (
	payload: DeactivateFundDetailsPayloadType
): DeactivateFundDetailsActionTypes => ({
	type: actionTypes.DEACTIVATE_FUND_DETAILS,
	payload,
});

export const deactivateFundDetailsResetCtr = (): DeactivateFundDetailsActionTypes => ({
	type: actionTypes.DEACTIVATE_FUND_DETAILS_RESET,
});

// OverrideLowBalanceFlag

export const overrideLowBalanceFlagCtr = (
	payload: OverrideLowBalanceFlagPayloadType
): OverrideLowBalanceFlagActionTypes => ({
	type: actionTypes.OVERRIDE_LOW_BALANCE_FLAG,
	payload,
});

export const overrideLowBalanceFlagResetCtr = (): OverrideLowBalanceFlagActionTypes => ({
	type: actionTypes.OVERRIDE_LOW_BALANCE_FLAG_RESET,
});

// Live fund value

export const getLiveFundValueCtr = (payload: GetLiveFundValueTypePayloadType): GetLiveFundValueTypeActionTypes => ({
	type: actionTypes.GET_LIVE_FUND_VALUE,
	payload,
});

export const getLiveFundValueResetCtr = (): GetLiveFundValueTypeActionTypes => ({
	type: actionTypes.GET_LIVE_FUND_VALUE_RESET,
});
