import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { ObjectType } from "../../../__utils/types";
import {
	ADD_BRAND,
	CREATE_ADMINISTRATOR,
	DELETE_ADMINISTRATOR,
	FETCH_ADMINISTRATOR_LEVELS,
	FETCH_BRANDS,
	UPDATE_ADMINISTRATOR,
	UPDATE_BRAND,
} from "./constants";

import { AddBrandPayloadType, AdminPayloadType, EditBrandPayloadType, DeleteAdminPayloadType } from "../__redux/types";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getAdminsLevelApi = async (): Promise<AxiosResponse<any>> =>
	axios.get(FETCH_ADMINISTRATOR_LEVELS).catch(errorHandler);

export const addAdminApi = async (payload: AdminPayloadType): Promise<AxiosResponse<any>> =>
	axios.post(CREATE_ADMINISTRATOR, payload).catch(errorHandler);

export const deleteAdminApi = async ({ id }: DeleteAdminPayloadType): Promise<AxiosResponse<any>> =>
	axios.post(DELETE_ADMINISTRATOR, { id }).catch(errorHandler);

export const editAdminApi = async (payload: AdminPayloadType): Promise<AxiosResponse<any>> => {
	const convertPayload = JSON.stringify(payload);
	return axios.put(UPDATE_ADMINISTRATOR, convertPayload).catch(errorHandler);
};

export const getBrandsApi = async (): Promise<AxiosResponse<any>> => axios.get(FETCH_BRANDS).catch(errorHandler);

export const addBrandApi = async (payload: AddBrandPayloadType): Promise<AxiosResponse<any>> =>
	axios.post(ADD_BRAND, payload).catch(errorHandler);

export const editBrandApi = async ({ formData, id }: EditBrandPayloadType): Promise<AxiosResponse<any>> =>
	axios.post(`${UPDATE_BRAND}${id}/`, formData).catch(errorHandler);
