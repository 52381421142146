import * as fundDetailsActionTypes from "__pages/FundDetails/__redux/actionTypes";
import * as getFundsActionTypes from "__pages/Funds/__redux/actionTypes";

import { BrandDetailsType, BrandsType, GetBrandsType } from "__pages/Administration/__redux/types";
import { FundDetailsType, FundsType, GetFundsType } from "__pages/Funds/__redux/types";
import { ResponseGeneratorType } from "__utils/types";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { fundsSlr } from "__pages/Funds/__redux/selectors";

import { bubbleFavourites } from "__pages/Funds/__utils/helpers";
import { getBrandsSlr } from "__pages/Administration/__redux/selectors";
import { sortObjects } from "__utils/helpers";
import { getFundDetailsSlr } from "../../../../../__redux/selectors";
import { GetFundDetailsType } from "../../../../../__redux/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";

const getStructuredFundData = (account: FundDetailsType, brands: BrandsType): FundDetailsType => {
	const { id, firstName, lastName, status, phone, email, administratorBrand } = account;
	const brand: BrandDetailsType = brands.find(
		(brand: BrandDetailsType) => administratorBrand === brand.id
	) as BrandDetailsType;

	return {
		id,
		status,
		fullName: [`${firstName} ${lastName}`],
		fundName: "",
		fundCode: "",
		phone,
		email,
		brand: brand.title,
		workflow: "",
		state: "",
		origin: "",
		type: "Individual",
		fundValue: 0,
		favourite: false,
		tags: [],
		requests: [],
		alert: { alertType: {}, color: "" },
		resumable: false,
		classUrl: "",
		hubspotUrl: "",
		balanceIndicator: "",
		hasTrust: false,
	};
};

const addAccount = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.addAccountApi, payload);

		const { status, data } = response;

		if (status === 201) {
			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_FOR_MODAL_SUCCESS,
				...data,
			});

			// Save state
			yield put({
				type: actionTypes.ADD_ACCOUNT_SUCCESS,
			});

			// Immediately add fund to the list
			const getFunds: GetFundsType = yield select(fundsSlr);
			const { newlyAdded, funds } = getFunds;
			const getBrands: GetBrandsType = yield select(getBrandsSlr);
			const { brands } = getBrands;
			const newFund: FundDetailsType = getStructuredFundData(data.account, brands);
			const sorted: FundsType = sortObjects<FundDetailsType>([...funds, newFund], "id", -1);
			const favoritesBubbled: FundsType = bubbleFavourites(sorted);

			yield put({
				type: getFundsActionTypes.GET_FUNDS_SUCCESS,
				...getFunds,
				funds: favoritesBubbled,
				newlyAdded: [...(newlyAdded as FundsType), newFund],
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({
				type: actionTypes.ADD_ACCOUNT_FAILURE,
				error: data,
				isCreating: false,
				isError: true,
			});
		}
	} catch (error) {
		yield put({ type: actionTypes.ADD_ACCOUNT_FAILURE });
	}
};

export function* addAccountSaga(): any {
	yield takeLatest(actionTypes.ADD_ACCOUNT, addAccount);
}

const editAccount = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.editAccountApi, payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_FOR_MODAL_SUCCESS,
				...data,
			});

			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_SUCCESS,
				...data,
			});

			yield put({
				type: actionTypes.EDIT_ACCOUNT_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.EDIT_ACCOUNT_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.EDIT_ACCOUNT_FAILURE });
	}
};

export const editAccountSaga = function* (): any {
	yield takeLatest(actionTypes.EDIT_ACCOUNT, editAccount);
};

const archiveFundDetails = function* (action: any): any {
	try {
		const { payload } = action;
		const response: ResponseGeneratorType = yield call(api.archiveFundDetailsApi, payload);
		const { status } = response;

		if (status === 204) {
			const fundDetails: GetFundDetailsType = yield select(getFundDetailsSlr);
			const updatedFundDetails: GetFundDetailsType = {
				...fundDetails,
				account: { ...fundDetails.account, status: "Archived" },
			};

			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_SUCCESS,
				...updatedFundDetails,
			});

			// Update archive state
			yield put({
				type: actionTypes.ARCHIVE_FUND_DETAILS_SUCCESS,
				isArchiving: false,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.ARCHIVE_FUND_DETAILS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.ARCHIVE_FUND_DETAILS_FAILURE });
	}
};

export const archiveFundDetailsSaga = function* (): any {
	yield takeLatest(actionTypes.ARCHIVE_FUND_DETAILS, archiveFundDetails);
};

const restoreFundDetails = function* (action: any): any {
	try {
		const { payload } = action;
		const fundDetails: GetFundDetailsType = yield select(getFundDetailsSlr);
		const response: ResponseGeneratorType = yield call(api.restoreFundDetailsApi, payload);
		const { status } = response;

		if (status === 204) {
			const _data: GetFundDetailsType = {
				...fundDetails,
				account: { ...fundDetails.account, status: "Active" },
			};
			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_SUCCESS,
				..._data,
			});

			// Update archive state
			yield put({
				type: actionTypes.RESTORE_FUND_DETAILS_SUCCESS,
				isRestoring: false,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.RESTORE_FUND_DETAILS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.RESTORE_FUND_DETAILS_FAILURE });
	}
};

export const restoreFundDetailsSaga = function* (): any {
	yield takeLatest(actionTypes.RESTORE_FUND_DETAILS, restoreFundDetails);
};

const deleteFundDetails = function* (action: any): any {
	try {
		const { payload } = action;
		const response: ResponseGeneratorType = yield call(api.deleteFundDetailsApi, payload);
		const { status } = response;
		if (status === 204) {
			// Update Delete funds
			yield put({
				type: actionTypes.DELETE_FUND_DETAILS_SUCCESS,
			});

			// Remove fund details data
			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_RESET,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.DELETE_FUND_DETAILS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.DELETE_FUND_DETAILS_FAILURE });
	}
};

export const deleteFundDetailsSaga = function* (): any {
	yield takeLatest(actionTypes.DELETE_FUND_DETAILS, deleteFundDetails);
};

const deactivateFundDetails = function* (action: any): any {
	try {
		const { payload } = action;
		const fundDetails: GetFundDetailsType = yield select(getFundDetailsSlr);
		const response: ResponseGeneratorType = yield call(api.deactivateFundDetailsApi, payload);
		const { status } = response;

		if (status === 204) {
			const _data: GetFundDetailsType = {
				...fundDetails,
				account: { ...fundDetails.account, status: "Inactive" },
			};
			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_SUCCESS,
				..._data,
			});
			// Update deactivate state
			yield put({
				type: actionTypes.DEACTIVATE_FUND_DETAILS_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.DEACTIVATE_FUND_DETAILS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.DEACTIVATE_FUND_DETAILS_FAILURE });
	}
};

export const deactivateFundDetailsSaga = function* (): any {
	yield takeLatest(actionTypes.DEACTIVATE_FUND_DETAILS, deactivateFundDetails);
};

const overrideLowBalanceFlag = function* ({ payload }: any): any {
	try {
		const fundDetails: GetFundDetailsType = yield select(getFundDetailsSlr);
		const response: ResponseGeneratorType = yield call(api.overrideLowBalanceFlagApi, payload);
		const { status } = response;

		if (status === 204) {
			const _data: GetFundDetailsType = {
				...fundDetails,
				fundStructure: { ...fundDetails.fundStructure, lowValueFund: false },
			};

			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_SUCCESS,
				..._data,
			});

			yield put({
				type: actionTypes.OVERRIDE_LOW_BALANCE_FLAG_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.OVERRIDE_LOW_BALANCE_FLAG_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.OVERRIDE_LOW_BALANCE_FLAG_FAILURE });
	}
};

export const overrideLowBalanceFlagSaga = function* (): any {
	yield takeLatest(actionTypes.OVERRIDE_LOW_BALANCE_FLAG, overrideLowBalanceFlag);
};

const getLiveFundValue = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getLiveFundValueApi, payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_LIVE_FUND_VALUE_SUCCESS,
				liveFundValue: { ...data.data },
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_LIVE_FUND_VALUE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_LIVE_FUND_VALUE_FAILURE });
	}
};

export const getLiveFundValueSaga = function* (): any {
	yield takeLatest(actionTypes.GET_LIVE_FUND_VALUE, getLiveFundValue);
};
