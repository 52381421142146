import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

import {
	CreateFundActionTypes,
	CreateFundType,
	GetFundDetailsActionTypes,
	GetFundDetailsType,
	SetFundToResumeActionTypes,
	SetFundToResumeType,
	createFundDefaultValue,
	getFundDetailsDefaultValue,
	setFundToResumeDefaultValue,
} from "./types";

import { accountReducer } from "../__pages/Account/rootReducer";
import { accountingReducer } from "../__pages/Accounting/rootReducer";
import { billingReducer } from "../__pages/Billing/rootReducer";
import { docRequestReducer } from "../__pages/Requests/__redux/reducers";
import { documentsReducer } from "../__pages/Documents/rootReducer";
import { fundReducer } from "../__pages/Account/Fund/rootReducer";

export const getFundDetailsReducer = (
	state = getFundDetailsDefaultValue,
	action: GetFundDetailsActionTypes
): GetFundDetailsType => {
	switch (action.type) {
		case actionTypes.GET_FUND_DETAILS:
			return {
				...state,
				isGetting: true,
			};
		case actionTypes.GET_FUND_DETAILS_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGetting: false,
				isSuccess: true,
				isError: false,
			};
		case actionTypes.GET_FUND_DETAILS_FAILURE:
			return {
				...state,
				isGetting: false,
				isSuccess: false,
				isError: true,
			};
		case actionTypes.GET_FUND_DETAILS_RESET:
		case actionTypes.GET_FUND_DETAILS_CANCEL:
			return {
				...state,
				...getFundDetailsDefaultValue,
			};
		default:
			return state;
	}
};

// Need to have a separate reducer for modal to avoid conflict with
// the reducer for the fund details page
export const getFundDetailsForModalReducer = (
	state = getFundDetailsDefaultValue,
	action: GetFundDetailsActionTypes
): GetFundDetailsType => {
	switch (action.type) {
		case actionTypes.GET_FUND_DETAILS_FOR_MODAL:
			return {
				...state,
				isGetting: true,
			};
		case actionTypes.GET_FUND_DETAILS_FOR_MODAL_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
			};
		case actionTypes.GET_FUND_DETAILS_FOR_MODAL_FAILURE:
			return {
				...state,
				isGetting: false,
				isSuccess: false,
				isError: true,
			};
		case actionTypes.GET_FUND_DETAILS_FOR_MODAL_RESET:
			return {
				...state,
				...getFundDetailsDefaultValue,
			};

		default:
			return state;
	}
};

export const createFundReducer = (state = createFundDefaultValue, action: CreateFundActionTypes): CreateFundType => {
	switch (action.type) {
		case actionTypes.CREATE_FUND:
			return {
				...state,
				isCreatingFund: true,
			};
		case actionTypes.CREATE_FUND_SUCCESS:
			return {
				...state,
				isCreatingFundError: false,
				isCreatingFundSuccess: true,
				isCreatingFund: false,
			};
		case actionTypes.CREATE_FUND_FAILURE:
			return {
				...state,
				isCreatingFund: false,
				isCreatingFundError: true,
			};

		case actionTypes.CREATE_FUND_RESET:
			return {
				...state,
				...createFundDefaultValue,
			};
		default:
			return state;
	}
};

export const setFundToResumeReducer = (
	state = setFundToResumeDefaultValue,
	action: SetFundToResumeActionTypes
): SetFundToResumeType => {
	switch (action.type) {
		case actionTypes.SET_FUND_TO_RESUME:
			return {
				...state,
				id: (action as any).payload.id,
			};
		case actionTypes.SET_FUND_TO_RESUME_RESET:
			return {
				...state,
				id: 0,
			};
		default:
			return state;
	}
};

export const fundDetailsReducer = combineReducers({
	getFundDetails: getFundDetailsReducer,
	getFundDetailsForModal: getFundDetailsForModalReducer,
	createFund: createFundReducer,
	setFundToResume: setFundToResumeReducer,
	billing: billingReducer,
	fund: fundReducer,
	docRequest: docRequestReducer,
	account: accountReducer,
	accounting: accountingReducer,
	documents: documentsReducer,
});
