import Col from "antd/es/col";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Row from "antd/es/row";
import Select from "antd/es/select";
import { FormInstance } from "antd/lib/form";
import React from "react";
import { useSelector } from "react-redux";
import DropdownRender from "__common/DropdownRender";
import { FloatLabel } from "__common/FloatLabel";
import { BrandDetailsType, BrandsType, GetBrandsType } from "__pages/Administration/__redux/types";
import { AccountType } from "__pages/FundDetails/__redux/types";
import * as fundsLang from "__pages/Funds/__utils/lang";
import { userProfileSlr } from "__pages/User/__redux/selectors";
import { UserProfileType } from "__pages/User/__redux/types";
import { isUserAdmin } from "__utils/helpers";
import * as lang from "__utils/lang";
import * as accountLang from "../../__utils/lang";
import { Container } from "./style";

const getBrandsDropDown = (brands: BrandsType, level: number): React.ReactNode => {
	if (!isUserAdmin(level)) {
		return null;
	}

	return brands.map((item: BrandDetailsType) => (
		<Select.Option value={item.title} key={item.id}>
			{item.title}
		</Select.Option>
	));
};

type Props = {
	account: AccountType;
	getbrands: GetBrandsType;
	form: FormInstance;
	onValuesChange?: (values: any) => void;
	isDisabled?: boolean;
	isBrandDisabled?: boolean;
};

const AccountForm: React.FC<Props> = ({
	account,
	form,
	getbrands,
	onValuesChange,
	isDisabled = false,
	isBrandDisabled = false,
}: Props) => {
	const { level }: UserProfileType = useSelector(userProfileSlr);
	const { brands, isGettingBrands } = getbrands;
	const { getFieldValue } = form;
	const { firstName } = account;

	const getInitialValues = (): AccountType => {
		let adminBrand: string;
		const { administratorBrand, ...rest } = account;
		if (account.id === 0) {
			adminBrand = level === 1 ? "" : brands[0]?.title;
		} else {
			adminBrand = brands.filter((brand: BrandDetailsType) => brand.id === administratorBrand)[0]?.title || "";
		}

		return account.id === 0 && isUserAdmin(level)
			? { ...rest, administratorBrand: "" }
			: { ...account, administratorBrand: adminBrand };
	};

	const inputRef = React.useRef<Input>(null);
	React.useEffect(() => {
		inputRef.current?.focus();
	}, []);

	return (
		<Container isDisabled={isDisabled} level={level === 1}>
			<Form
				layout="vertical"
				form={form}
				initialValues={getInitialValues()}
				onValuesChange={(values: any) => onValuesChange && onValuesChange(values)}
			>
				<Row gutter={[16, 8]}>
					<Col xs={24} xl={12}>
						<FloatLabel
							label={fundsLang.FIRST_NAME_LABEL}
							getValue={() => getFieldValue(accountLang.FIRST_NAME_NAME)}
						>
							<Form.Item
								name={accountLang.FIRST_NAME_NAME}
								rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
							>
								<Input disabled={isDisabled} autoComplete="chrome-off" autoFocus={!firstName} />
							</Form.Item>
						</FloatLabel>
					</Col>

					<Col xs={24} xl={12}>
						<FloatLabel
							label={fundsLang.LAST_NAME_LABEL}
							getValue={() => getFieldValue(accountLang.LAST_NAME_NAME)}
						>
							<Form.Item
								name={accountLang.LAST_NAME_NAME}
								rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
							>
								<Input disabled={isDisabled} autoComplete="chrome-off" />
							</Form.Item>
						</FloatLabel>
					</Col>
				</Row>

				<Row gutter={[16, 8]}>
					<Col xs={24} xl={12}>
						<FloatLabel
							label={fundsLang.EMAIL_LABEL}
							getValue={() => getFieldValue(accountLang.EMAIL_NAME)}
						>
							<Form.Item
								name={accountLang.EMAIL_NAME}
								rules={[{ required: true, type: "email", message: lang.REQUIRED_FIELD_ERROR }]}
							>
								<Input disabled={isDisabled} autoComplete="chrome-off" />
							</Form.Item>
						</FloatLabel>
					</Col>

					<Col xs={24} xl={12}>
						<FloatLabel
							label={fundsLang.PHONE_LABEL}
							getValue={() => getFieldValue(accountLang.PHONE_NAME)}
						>
							<Form.Item
								name={accountLang.PHONE_NAME}
								rules={[
									{ required: true, message: lang.REQUIRED_FIELD_ERROR },
									{ max: 10, message: fundsLang.PHONE_MAX_LEN_ERROR },
									({ getFieldValue }) => ({
										validator(_, value) {
											const isValidPhoneNum = getFieldValue(accountLang.PHONE_NAME).startsWith(
												"04"
											);
											if (!value || isValidPhoneNum) {
												return Promise.resolve();
											}
											return Promise.reject(new Error("Phone number should start with 04"));
										},
									}),
								]}
							>
								<Input disabled={isDisabled} maxLength={10} />
							</Form.Item>
						</FloatLabel>
					</Col>
				</Row>
				<Row gutter={[16, 8]} className="brand-column">
					<Col xs={24} xl={12}>
						<FloatLabel
							label={fundsLang.BRAND_LABEL}
							getValue={() => getFieldValue(accountLang.ADMIN_BRAND_NAME)}
						>
							<Form.Item
								name={accountLang.ADMIN_BRAND_NAME}
								rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
							>
								<Select
									loading={isGettingBrands}
									disabled={isGettingBrands || !isUserAdmin(level) || isDisabled || isBrandDisabled}
									getPopupContainer={(trigger: any) => trigger.parentElement}
									dropdownRender={(menu: any) => <DropdownRender>{menu}</DropdownRender>}
									showAction={["click", "focus"]}
									showSearch
								>
									{getBrandsDropDown(brands, level)}
								</Select>
							</Form.Item>
						</FloatLabel>
					</Col>
				</Row>
			</Form>
		</Container>
	);
};

export default AccountForm;
