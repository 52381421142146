import { ApiErrorType } from "__utils/types";
import * as actionTypes from "./actionTypes";

/**
 * Get class
 */
export type ClassType = {
	readonly fundCodeName: string;
};

export type GetClassType = {
	readonly fundClass: ClassType;
	readonly isGettingClass: boolean;
	readonly isGetClassSuccess: boolean;
	readonly isGetClassError: boolean;
};

export const getClassDefaultValue: GetClassType = {
	fundClass: {
		fundCodeName: "",
	},
	isGettingClass: false,
	isGetClassSuccess: false,
	isGetClassError: false,
};

export type GetClassPayloadType = {
	readonly memberId: number;
};

export type GetClassCtrType = {
	(payload: GetClassPayloadType): GetClassActionTypes;
};

export type GetClassResetCtrType = {
	(): GetClassActionTypes;
};

type GetClassAction = {
	type: typeof actionTypes.GET_CLASS;
	payload: GetClassPayloadType;
};

type GetClassSuccessAction = {
	type: typeof actionTypes.GET_CLASS_SUCCESS;
};

type GetClassFailureAction = {
	type: typeof actionTypes.GET_CLASS_FAILURE;
};

type GetClassResetAction = {
	type: typeof actionTypes.GET_CLASS_RESET;
};

export type GetClassActionTypes = GetClassAction | GetClassSuccessAction | GetClassFailureAction | GetClassResetAction;

/**
 * Edit Class
 */
export type EditClassType = {
	readonly isEditingClass: boolean;
	readonly isEditClassSuccess: boolean;
	readonly isEditClassError: boolean;
	readonly editClassError: ApiErrorType;
};

export const editClassDefaultValue: EditClassType = {
	isEditingClass: false,
	isEditClassSuccess: false,
	isEditClassError: false,
	editClassError: {},
};

export type EditClassPayloadType = {
	memberId: number;
	fundCodeName: string;
};

export type EditClass = {
	(payload: EditClassPayloadType): EditClassActionTypes;
};

type EditClassAction = {
	type: typeof actionTypes.EDIT_CLASS;
	payload: EditClassPayloadType;
};

type EditClassSuccessAction = {
	type: typeof actionTypes.EDIT_CLASS_SUCCESS;
};

type EditClassFailureAction = {
	type: typeof actionTypes.EDIT_CLASS_FAILURE;
};

type EditClassResetAction = {
	type: typeof actionTypes.EDIT_CLASS_RESET;
};

export type EditClassActionTypes =
	| EditClassAction
	| EditClassSuccessAction
	| EditClassFailureAction
	| EditClassResetAction;
