// App name
export const APP_NAME = "selfmade-admin-portal";

// API Root
export const API_ROOT = `${process.env.REACT_APP_APP_URL}/api`;

// Request API Root
export const REQUEST_API_ROOT = `${process.env.REACT_APP_APP_REQUEST_URL}/api`;

export const DUMMY_ENDPOINT = "https://jsonplaceholder.typicode.com";

// Media queries
export const BREAKPOINTS: { [key: string]: string } = {
	XS: "(max-width: 576px)",
	SM: "(max-width: 768px)",
	MD: "(max-width: 992px)",
	LG: "(max-width: 992px)",
	XL: "(max-width: 1200px)",
	XXL: "(max-width: 1600px)",
	RXXL: "(min-width: 1600px)",
};

// access levels
export const ACCESS_LEVELS: { [key: string]: number } = {
	selfmadeAdmin: 1,
	selfmadeUser: 2,
	adviserAdmin: 3,
	adviserUser: 4,
	agentAdmin: 5,
	agentUser: 6,
};

/* eslint-disable no-unused-vars */
export enum NEW_FUND {
	SignedUp = 1,
	Verified = 2,
	NewQuestionnaireComplete = 3,
	RegisteredFund = 4,
	DocumentsGenerated = 5,
	DocumentsSigned = 6,
	MacquarieCMACreated = 7,
	ClassAccountCreated = 8,
	EzidebitAccountCreated = 9,
	DebittedFullyOnboarded = 10,
}

/* eslint-disable no-unused-vars */
export enum EXISTING_FUND {
	SignedUp = 1,
	Verified = 2,
	ExistingQuestionnaireComplete = 3,
	EngagementURLGenerated = 4,
	EngagementTermsAccepted = 5,
	EzidebitAccountCreated = 6,
	DebittedFullyOnboarded = 7,
}
