import * as actionTypes from "./actionTypes";

/**
 * Edit bank details
 */
export type EditBankDetailsType = {
	readonly isEditing: boolean;
	readonly isSuccess: boolean;
	readonly isError: boolean;
	readonly error: { [key: string]: Array<string> };
};

export const editBankDetailsDefaultValue: EditBankDetailsType = {
	isEditing: false,
	isSuccess: false,
	isError: false,
	error: {},
};

export type AddBankDetailsPayloadType = {
	memberId: number;
	applicationId: string;
	accountName: string;
	bsb: string;
	accountNumber: string;
};

export type EditBankDetailsPayloadType = AddBankDetailsPayloadType;

export type EditBankDetails = {
	(payload: EditBankDetailsPayloadType): EditBankDetailsActionTypes;
};

type EditBankDetailsAction = {
	type: typeof actionTypes.EDIT_BANK_DETAILS;
	payload: EditBankDetailsPayloadType;
};

type EditBankDetailsSuccessAction = {
	type: typeof actionTypes.EDIT_BANK_DETAILS_SUCCESS;
};

type EditBankDetailsFailureAction = {
	type: typeof actionTypes.EDIT_BANK_DETAILS_FAILURE;
};

type EditBankDetailsResetAction = {
	type: typeof actionTypes.EDIT_BANK_DETAILS_RESET;
};

export type EditBankDetailsActionTypes =
	| EditBankDetailsAction
	| EditBankDetailsSuccessAction
	| EditBankDetailsFailureAction
	| EditBankDetailsResetAction;
