import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import {
	AddAccountPayloadType,
	ArchiveFundDetailsPayloadType,
	DeleteFundDetailsPayloadType,
	EditAccountPayloadType,
	OverrideLowBalanceFlagPayloadType,
	RestoreFundDetailsPayloadType,
	GetLiveFundValueTypePayloadType,
	DeactivateFundDetailsPayloadType,
} from "../__redux/types";
import {
	CREATE_ACCOUNT,
	EDIT_ACCOUNT,
	OVERRIDE_LOW_BALANCE_FLAG,
	GET_LIVE_FUND_VALUE,
	ARCHIVE_ACCOUNT,
	RESTORE_ACCOUNT,
	DELETE_ACCOUNT,
	DEACTIVATE_ACCOUNT,
} from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const addAccountApi = async (payload: AddAccountPayloadType): Promise<AxiosResponse<any>> =>
	axios.post(CREATE_ACCOUNT, payload).catch(errorHandler);

export const editAccountApi = async ({ id, body }: EditAccountPayloadType): Promise<AxiosResponse<any>> =>
	axios.post(`${EDIT_ACCOUNT}${id}/`, body).catch(errorHandler);

export const archiveFundDetailsApi = async ({ id }: ArchiveFundDetailsPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = ARCHIVE_ACCOUNT.replace(":id", `${id}`);
	return axios.put(url).catch(errorHandler);
};

export const restoreFundDetailsApi = async ({ id }: RestoreFundDetailsPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = RESTORE_ACCOUNT.replace(":id", `${id}`);
	return axios.put(url).catch(errorHandler);
};

export const deleteFundDetailsApi = async ({ id }: DeleteFundDetailsPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = DELETE_ACCOUNT.replace(":id", `${id}`);
	return axios.put(url).catch(errorHandler);
};

export const deactivateFundDetailsApi = async ({
	id,
}: DeactivateFundDetailsPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = DEACTIVATE_ACCOUNT.replace(":id", `${id}`);
	return axios.put(url).catch(errorHandler);
};

// OverrideLowBalanceFlag
export const overrideLowBalanceFlagApi = async ({
	memberId,
}: OverrideLowBalanceFlagPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = OVERRIDE_LOW_BALANCE_FLAG.replace(":memberId", `${memberId}`);

	return axios.put(url).catch(errorHandler);
};

export const getLiveFundValueApi = async ({ fundId }: GetLiveFundValueTypePayloadType): Promise<AxiosResponse<any>> =>
	axios.get(`${GET_LIVE_FUND_VALUE}/${fundId}/`).catch(errorHandler);
