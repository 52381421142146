import styled from "styled-components";

type Props = {
	showHide: boolean;
};

export const Container = styled.div<Props>`
	min-height: ${(p) => (p.showHide ? "300px" : "245px;")};
	transition: min-height 0.3s ease;
`;
