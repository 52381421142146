import Form from "antd/es/form";
import React, { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import FundStructureForm from "__pages/FundDetails/__pages/Account/Fund/FundStructure/EditFundStructureModal/FundStructureForm";
import { addFundStructureCtr } from "__pages/FundDetails/__pages/Account/Fund/FundStructure/__redux/actions";
import { addFundStructureSlr } from "__pages/FundDetails/__pages/Account/Fund/FundStructure/__redux/selectors";
import { AddFundStructureType } from "__pages/FundDetails/__pages/Account/Fund/FundStructure/__redux/types";
import * as fundLang from "__pages/FundDetails/__pages/Account/Fund/FundStructure/__utils/lang";
import { GetFundDetailsType, StructureType } from "__pages/FundDetails/__redux/types";
import { processErrors } from "__utils/helpers";
import { ObjectType } from "__utils/types";

type Props = {
	fund: GetFundDetailsType;
	isSubmitAddFundStructure: boolean;
	setFundStructureLocalValues: Dispatch<SetStateAction<StructureType>>;
	fundStructureLocalValues: StructureType;
	setSubmitAddFundStructure: Dispatch<SetStateAction<boolean>>;
};

const AddFundStructure: React.FC<Props> = ({
	fund,
	isSubmitAddFundStructure,
	setFundStructureLocalValues,
	fundStructureLocalValues,
	setSubmitAddFundStructure,
}: Props) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { setFields, validateFields, getFieldValue } = form;
	const addFundStructure: AddFundStructureType = useSelector(addFundStructureSlr);
	const { isAdding, isError, error } = addFundStructure;
	const [showACN, setShowACN] = React.useState(false);
	// const [origin, setOrigin] = React.useState('New');
	const [showABN, setShowABN] = React.useState(false);

	const {
		account: { id },
	} = fund;

	React.useEffect(() => {
		if (isSubmitAddFundStructure) {
			validateFields()
				.then((values: any) => {
					const { asicCompanyName, ..._structure } = values;
					dispatch(addFundStructureCtr({ id, structure: _structure }));
				})
				.catch(() => {
					setSubmitAddFundStructure(false);
				});
		}
	}, [id, isSubmitAddFundStructure, validateFields, setSubmitAddFundStructure, dispatch]);

	React.useEffect(() => {
		if (isError) {
			setFields(processErrors(error));
			setSubmitAddFundStructure(false);
		}
	}, [isError, error, setSubmitAddFundStructure, setFields]);

	const onValuesChange = (values: ObjectType): void => {
		setFundStructureLocalValues({ ...fundStructureLocalValues, ...values });
	};

	const onTypeOrOriginOrAcnChange = (): void => {
		const origin = getFieldValue(fundLang.FUND_STRUCTURE_ORIGIN_NAME);
		const type = getFieldValue(fundLang.FUND_STRUCTURE_TRUSTEE_NAME);

		if (origin === "Existing") {
			if (type === "Corporate") {
				setShowACN(true);
				setShowABN(true);
			} else if (type === "Individual") {
				setShowABN(true);
				setShowACN(false);
			}
		} else {
			setShowACN(false);
			setShowABN(false);
		}
	};

	return (
		<FundStructureForm
			form={form}
			isLoading={isAdding}
			fundStructure={fundStructureLocalValues}
			onValuesChange={onValuesChange}
			showACN={showACN}
			showABN={showABN}
			onTypeOrOriginOrAcnChange={onTypeOrOriginOrAcnChange}
		/>
	);
};

export default AddFundStructure;
