import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import {
	AddDocumentPayloadType,
	DeleteDocumentPayloadType,
	EditDocumentPayloadType,
	GetDocumentDetailsPayloadType,
} from "../__redux/types";
import {
	ADD_DOCUMENT,
	DELETE_DOCUMENT,
	EDIT_DOCUMENT,
	GET_CATEGORIES,
	GET_DOCUMENTS,
	GET_DOCUMENT_DETAILS,
} from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getDocumentDetailsApi = async ({ id }: GetDocumentDetailsPayloadType): Promise<AxiosResponse<any>> =>
	axios.get(`${GET_DOCUMENT_DETAILS}/${id}/`).catch(errorHandler);

export const addDocumentApi = async (payload: AddDocumentPayloadType): Promise<AxiosResponse<any>> =>
	axios.post(`${ADD_DOCUMENT}/`, payload).catch(errorHandler);

export const editDocumentApi = async (payload: EditDocumentPayloadType): Promise<AxiosResponse<any>> => {
	const { id } = payload;
	return axios.put(`${EDIT_DOCUMENT}/${id}/`, payload).catch(errorHandler);
};

export const getDocCategoriesApi = async (): Promise<AxiosResponse<any>> =>
	axios.get(GET_CATEGORIES).catch(errorHandler);

export const getDocumentsApi = async (): Promise<AxiosResponse<any>> =>
	axios.get(`${GET_DOCUMENTS}/`).catch(errorHandler);

export const deleteDocumentApi = async (payload: DeleteDocumentPayloadType): Promise<AxiosResponse<any>> =>
	axios.delete(`${DELETE_DOCUMENT}/${payload.id}/`).catch(errorHandler);
