import { Dayjs } from "dayjs";
import { DocTemplateCategoryType } from "__pages/RequestTemplates/__redux/types";
import { ApiErrorType, ObjectType } from "__utils/types";
import * as actionTypes from "./actionTypes";

/**
 *  document type
 */
export interface DocumentDetailsType extends ObjectType {
	readonly id: number;
	readonly originalId: number; // For duplicate documents, put original ID here and place a hash in id so they're all unique
	readonly documentId: number;
	readonly category: string;
	readonly title: string;
	readonly information: string;
	readonly description: string;
	readonly difficulty: number;
	readonly availabilityDate: string | Dayjs;
	readonly isComplete: boolean;
	readonly files: Array<{ [key: string]: string }>;
}

export type DocumentsType = Array<DocumentDetailsType>;

/**
 * Get document
 */

export type GetDocumentDetailsType = {
	readonly documentDetails: DocumentDetailsType;
	readonly isGettingDocumentDetails: boolean;
	readonly isGetDocumentDetailsSuccess: boolean;
	readonly isGetDocumentDetailsError: boolean;
	readonly getDocumentDetailsError: { [key: string]: Array<string> };
};

export const documentDefaultValue: GetDocumentDetailsType = {
	documentDetails: {
		id: 0,
		originalId: 0,
		documentId: 0,
		category: "",
		title: "",
		information: "",
		description: "",
		difficulty: 0,
		availabilityDate: "",
		isComplete: false,
		files: [],
	},
	isGettingDocumentDetails: false,
	isGetDocumentDetailsSuccess: false,
	isGetDocumentDetailsError: false,
	getDocumentDetailsError: {},
};

export type GetDocumentDetailsPayloadType = {
	id: number;
};

type GetDocumenDetailstAction = {
	type: typeof actionTypes.GET_DOCUMENT_DETAILS;
	payload: GetDocumentDetailsPayloadType;
};

type GetDocumentDetailsSuccessAction = {
	type: typeof actionTypes.GET_DOCUMENT_DETAILS_SUCCESS;
};

type GetDocumentDetailsFailureAction = {
	type: typeof actionTypes.GET_DOCUMENT_DETAILS_FAILURE;
};
type GetDocumentDetailsResetAction = {
	type: typeof actionTypes.GET_DOCUMENT_DETAILS_RESET;
};

export type DocumentActionTypes =
	| GetDocumenDetailstAction
	| GetDocumentDetailsSuccessAction
	| GetDocumentDetailsFailureAction
	| GetDocumentDetailsResetAction;

/**
 * Add document
 */

export type AddDocumentDetailsType = {
	readonly isAdding: boolean;
	readonly isAddSuccess: boolean;
	readonly isAddError: boolean;
	readonly addError: ApiErrorType;
};

export const addDocumentDefaultValue: AddDocumentDetailsType = {
	isAdding: false,
	isAddSuccess: false,
	isAddError: false,
	addError: {},
};

export type AddDocumentPayloadType = {
	readonly category: string;
	readonly title: string;
	readonly information: string;
	readonly description: string;
	readonly difficulty: number;
	readonly availabilityDate: string;
};

export type AddDocument = {
	(payload: AddDocumentPayloadType): AddDocumentActionTypes;
};

export type AddDocumentReset = {
	(): AddDocumentActionTypes;
};

type AddDocumentAction = {
	type: typeof actionTypes.ADD_DOCUMENT;
	payload: AddDocumentPayloadType;
};

type AddDocumentSuccessAction = {
	type: typeof actionTypes.ADD_DOCUMENT_SUCCESS;
};

type AddDocumentFailureAction = {
	type: typeof actionTypes.ADD_DOCUMENT_FAILURE;
};

type AddDocumentResetAction = {
	type: typeof actionTypes.ADD_DOCUMENT_RESET;
};

export type AddDocumentActionTypes =
	| AddDocumentAction
	| AddDocumentSuccessAction
	| AddDocumentFailureAction
	| AddDocumentResetAction;

/**
 * Edit document
 */
export type EditDocumentDetailsType = {
	readonly isEditing: boolean;
	readonly isEditSuccess: boolean;
	readonly isEditError: boolean;
	readonly editError: ApiErrorType;
};

export const editDocumentDefaultValue: EditDocumentDetailsType = {
	isEditing: false,
	isEditSuccess: false,
	isEditError: false,
	editError: {},
};

export type EditDocumentPayloadType = DocumentDetailsType;

export type EditDocument = {
	(payload: EditDocumentPayloadType): EditDocumentActionTypes;
};

export type EditDocumentReset = {
	(): EditDocumentActionTypes;
};

type EditDocumentAction = {
	type: typeof actionTypes.EDIT_DOCUMENT;
	payload: EditDocumentPayloadType;
};

type EditDocumentSuccessAction = {
	type: typeof actionTypes.EDIT_DOCUMENT_SUCCESS;
};

type EditDocumentFailureAction = {
	type: typeof actionTypes.EDIT_DOCUMENT_FAILURE;
};

type EditDocumentResetAction = {
	type: typeof actionTypes.EDIT_DOCUMENT_RESET;
};

export type EditDocumentActionTypes =
	| EditDocumentAction
	| EditDocumentSuccessAction
	| EditDocumentFailureAction
	| EditDocumentResetAction;

/**
 * Get document categories
 */

export type GetDocCategoriesType = {
	readonly docCategories: Array<DocTemplateCategoryType>;
	readonly isGettingDocCategories: boolean;
	readonly isGetDocCategoriesSuccess: boolean;
	readonly isGetDocCategoriesError: boolean;
	readonly getDocCategoriesError: ApiErrorType;
};

export const getDocCategoriesDefaultValue: GetDocCategoriesType = {
	docCategories: [],
	isGettingDocCategories: false,
	isGetDocCategoriesSuccess: false,
	isGetDocCategoriesError: false,
	getDocCategoriesError: {},
};

export type GetDocCategoriesCtrType = {
	(): GetDocCategoriesActionTypes;
};

type GetDocCategoriesAction = {
	type: typeof actionTypes.GET_DOC_CATEGORIES;
};

type GetDocCategoriesSuccessAction = {
	type: typeof actionTypes.GET_DOC_CATEGORIES_SUCCESS;
};

type GetDocCategoriesFailureAction = {
	type: typeof actionTypes.GET_DOC_CATEGORIES_FAILURE;
};

type GetDocCategoriesResetAction = {
	type: typeof actionTypes.GET_DOC_CATEGORIES_RESET;
};

export type GetDocCategoriesActionTypes =
	| GetDocCategoriesAction
	| GetDocCategoriesSuccessAction
	| GetDocCategoriesFailureAction
	| GetDocCategoriesResetAction;

/**
 * Get documents
 */

export type GetDocumentsType = {
	readonly documents: DocumentsType; // raw
	readonly filtered: DocumentsType; // Filtered base
	readonly partial: DocumentsType; // Accumulated each scroll
	readonly categories: Array<DocTemplateCategoryType>;
	readonly limit: number;
	readonly currPage: number;
	readonly numOfPages: number;
	readonly shouldAppendMore: boolean;
	readonly isGettingDocuments: boolean;
	readonly isGetDocumentsSuccess: boolean;
	readonly isGetDocumentsError: boolean;
	readonly getDocumentsError: { [key: string]: Array<string> };
};

export const getDocumentsDefaultValue: GetDocumentsType = {
	documents: [],
	filtered: [],
	partial: [],
	categories: [],
	limit: 35,
	currPage: 1,
	numOfPages: 999,
	shouldAppendMore: true,
	isGettingDocuments: false,
	isGetDocumentsSuccess: false,
	isGetDocumentsError: false,
	getDocumentsError: {},
};

export type GetDocumentsCtrType = {
	(): GetDocumentsActionTypes;
};

type GetDocumentsAction = {
	type: typeof actionTypes.GET_DOCUMENTS;
};

type GetDocumentsSuccessAction = {
	type: typeof actionTypes.GET_DOCUMENTS_SUCCESS;
};

type GetDocumentsFailureAction = {
	type: typeof actionTypes.GET_DOCUMENTS_FAILURE;
};

type GetDocumentsResetAction = {
	type: typeof actionTypes.GET_DOCUMENTS_RESET;
};

export type GetDocumentsActionTypes =
	| GetDocumentsResetAction
	| GetDocumentsAction
	| GetDocumentsSuccessAction
	| GetDocumentsFailureAction;

/**
 * Get documents on scroll
 */

export type GetDocumentsOnScrollPayloadType = {
	page: number;
};

export type GetDocumentsOnScrollCtrType = {
	(payload: GetDocumentsOnScrollPayloadType): GetDocumentsOnScrollActionTypes;
};

type GetDocumentsOnScrollAction = {
	type: typeof actionTypes.GET_DOCUMENTS_ON_SCROLL;
	payload: GetDocumentsOnScrollPayloadType;
};

type GetDocumentsOnScrollSuccessAction = {
	type: typeof actionTypes.GET_DOCUMENTS_ON_SCROLL_SUCCESS;
};

export type GetDocumentsOnScrollActionTypes = GetDocumentsOnScrollAction | GetDocumentsOnScrollSuccessAction;

/**
 * Filter documents
 */

export type DocumentsSearchFilterType = string;

export type DocumentsFiltersType = {
	readonly searchFilter: DocumentsSearchFilterType;
	// readonly prevSearchFilter: DocumentsSearchFilterType | undefined;
};

export interface FilterDocumentsType {
	readonly filters: DocumentsFiltersType;
	readonly isFiltering: boolean;
}

export const filterDocumentsDefaultValue: FilterDocumentsType = {
	filters: {
		searchFilter: "",
		// prevSearchFilter: undefined,
	},
	isFiltering: false,
};

export type FilterDocumentsPayloadType = {
	filters: DocumentsFiltersType;
	isFiltering: boolean;
};

export type FilterDocumentsCtrType = {
	(payload: FilterDocumentsPayloadType): FilterDocumentsActionTypes;
};

type FilterDocumentsAction = {
	type: typeof actionTypes.FILTER_DOCUMENTS;
	payload: FilterDocumentsPayloadType;
};

type FilterDocumentsResetAction = {
	type: typeof actionTypes.FILTER_DOCUMENTS_RESET;
};

export type FilterDocumentsActionTypes = FilterDocumentsAction | FilterDocumentsResetAction;

/**
 * delete Document
 */

export type DeleteDocumentDetailsType = {
	readonly id: number;
	readonly isDeletingDocument: boolean;
	readonly isDeleteDocumentSuccess: boolean;
	readonly isDeleteDocumentError: boolean;
};

export const deleteDocumentDefaultValue: DeleteDocumentDetailsType = {
	id: 0,
	isDeletingDocument: false,
	isDeleteDocumentSuccess: false,
	isDeleteDocumentError: false,
};

export type DeleteDocumentPayloadType = {
	id: number;
};

type DeleteDocumentAction = {
	type: typeof actionTypes.DELETE_DOCUMENT;
	payload: DeleteDocumentPayloadType;
};

type DeleteDocumentSuccessAction = {
	type: typeof actionTypes.DELETE_DOCUMENT_SUCCESS;
};

type DeleteDocumentFailureAction = {
	type: typeof actionTypes.DELETE_DOCUMENT_FAILURE;
};

type DeleteDocumentResetAction = {
	type: typeof actionTypes.DELETE_DOCUMENT_RESET;
};

export type DeleteDocumentActionTypes =
	| DeleteDocumentSuccessAction
	| DeleteDocumentFailureAction
	| DeleteDocumentAction
	| DeleteDocumentResetAction;
