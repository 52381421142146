import { QuestionCircleOutlined } from "@ant-design/icons";
import Form from "antd/es/form";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import React, { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "__common/ConfirmModal";
import MemberForm from "__pages/FundDetails/__pages/Account/Fund/Members/__common/MemberForm";
import {
	deleteMemberCtr,
	deleteMemberResetCtr,
	editMemberCtr,
	editMemberResetCtr,
} from "__pages/FundDetails/__pages/Account/Fund/Members/__redux/actions";
import { deleteMemberSlr, editMemberSlr } from "__pages/FundDetails/__pages/Account/Fund/Members/__redux/selectors";
import { DeleteMemberType, EditMemberType } from "__pages/FundDetails/__pages/Account/Fund/Members/__redux/types";
import * as lang from "__pages/FundDetails/__pages/Account/Fund/Members/__utils/lang";
import { GetFundDetailsType, MemberType } from "__pages/FundDetails/__redux/types";
import { processErrors } from "__utils/helpers";

type Props = {
	fund: GetFundDetailsType;
	setShowMemberForm: Dispatch<SetStateAction<boolean>>;
	memberId: number;
};

const EditMember: React.FC<Props> = ({ fund, setShowMemberForm, memberId }: Props) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { setFields } = form;
	const editMember: EditMemberType = useSelector(editMemberSlr);
	const deleteMember: DeleteMemberType = useSelector(deleteMemberSlr);
	const { id, isEditingMember, isEditMemberSuccess, isEditMemberError, editError } = editMember;
	const { isDeleteMemberSuccess, isDeleteMemberError } = deleteMember; // isDeletingMember
	const { members } = fund;
	const [showModal, setShowModal] = React.useState(false);
	const [idToDelete, setIdToDelete] = React.useState(0);

	React.useEffect(() => {
		if (!isEditMemberError && isEditMemberSuccess) {
			dispatch(editMemberResetCtr());
			setShowMemberForm(false);
		}
	}, [isEditMemberError, isEditMemberSuccess, dispatch, setShowMemberForm]);

	React.useEffect(() => {
		if (!isEmpty(editError)) {
			setFields(processErrors(editError));
		}
	}, [editError, setFields]);

	React.useEffect(() => {
		if (!isDeleteMemberError && isDeleteMemberSuccess) {
			dispatch(deleteMemberResetCtr());
			setShowMemberForm(false);
		}
	}, [isDeleteMemberError, isDeleteMemberSuccess, setShowMemberForm, dispatch]);

	const onFinish = (values: any): void => {
		const convertDate = dayjs(values.dateOfBirth).format("YYYY-MM-DD");
		const payload: any = {
			...values,
			dateOfBirth: convertDate,
			notUseResidentialAsMailing: !!values.notUseResidentialAsMailing,
		};

		dispatch(editMemberCtr({ ...payload, id: memberId }));
	};

	const getMember = (): MemberType => {
		const member = members.filter((values: MemberType) => values.id === memberId);
		return member[0];
	};

	const onCancel = (): void => {
		setShowMemberForm(false);
	};

	const onDeleteMember = (): void => {
		dispatch(deleteMemberCtr({ deletingId: idToDelete }));
		setShowModal(false);
	};

	return (
		<>
			<ConfirmModal
				text={lang.DELETE_MEMBER_MSG}
				icon={<QuestionCircleOutlined style={{ color: "#faad14" }} />}
				visible={showModal}
				onCancel={() => setShowModal(false)}
				onOkay={onDeleteMember}
			/>
			<MemberForm
				form={form}
				fund={fund}
				member={getMember()}
				isLoading={isEditingMember && getMember().id === id}
				onFinish={onFinish}
				onCancel={onCancel}
				onDelete={(id: number) => {
					setIdToDelete(id);
					setShowModal(true);
				}}
			/>
		</>
	);
};

export default EditMember;
