import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

import {
	getReminderCountDefaultValue,
	IGetReminderCount,
	IGetReminderCountActions,
	getReminderDefaultValue,
	IGetReminder,
	IGetReminderActions,
	getReminderDetailsDefaultValue,
	IGetReminderDetailsActions,
	IGetReminderDetails,
	releaseAllReminderDefaultValue,
	IReleaseAllReminder,
	IReleaseAllReminderActions,
} from "./types";

// REMINDER COUNT REDUCERS
export const getReminderCountReducer = (
	state = getReminderCountDefaultValue,
	action: IGetReminderCountActions
): IGetReminderCount => {
	switch (action.type) {
		case actionTypes.GET_REMINDERS_COUNT:
			return {
				...state,
				isGettingReminderCount: true,
			};
		case actionTypes.GET_REMINDERS_COUNT_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingReminderCount: false,
				isGetReminderCountSuccess: true,
				isGetReminderCountError: false,
			};

		case actionTypes.GET_REMINDERS_COUNT_FAILURE:
			return {
				...state,
				isGettingReminderCount: false,
				isGetReminderCountSuccess: false,
				isGetReminderCountError: true,
			};

		case actionTypes.GET_REMINDERS_COUNT_RESET:
			return {
				...getReminderCountDefaultValue,
			};
		default:
			return state;
	}
};

// REMINDER REDUCERS
export const getReminderReducer = (state = getReminderDefaultValue, action: IGetReminderActions): IGetReminder => {
	switch (action.type) {
		case actionTypes.GET_REMINDERS:
			return {
				...state,
				isGettingReminder: true,
			};
		case actionTypes.GET_REMINDERS_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingReminder: false,
				isGetReminderSuccess: true,
				isGetReminderError: false,
			};

		case actionTypes.GET_REMINDERS_FAILURE:
			return {
				...state,
				isGettingReminder: false,
				isGetReminderSuccess: false,
				isGetReminderError: true,
			};

		case actionTypes.GET_REMINDERS_RESET:
			return {
				...getReminderDefaultValue,
			};
		default:
			return state;
	}
};

// REMINDER DETAILS REDUCERS
export const getReminderDetailsReducer = (
	state = getReminderDetailsDefaultValue,
	action: IGetReminderDetailsActions
): IGetReminderDetails => {
	switch (action.type) {
		case actionTypes.GET_REMINDERS_DETAILS:
			return {
				...state,
				isGettingReminderDetails: true,
				currReminder: (action as any).payload,
			};
		case actionTypes.GET_REMINDERS_DETAILS_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingReminderDetails: false,
				isGetReminderDetailsSuccess: true,
				isGetReminderDetailsError: false,
			};

		case actionTypes.GET_REMINDERS_DETAILS_FAILURE:
			return {
				...state,
				isGettingReminderDetails: false,
				isGetReminderDetailsSuccess: false,
				isGetReminderDetailsError: true,
			};

		case actionTypes.GET_REMINDERS_DETAILS_RESET:
			return {
				...getReminderDetailsDefaultValue,
			};
		default:
			return state;
	}
};

// Release All Reminders
export const releaseAllReminderReducer = (
	state = releaseAllReminderDefaultValue,
	action: IReleaseAllReminderActions
): IReleaseAllReminder => {
	switch (action.type) {
		case actionTypes.RELEASE_ALL_REMINDER:
			return {
				...state,
				isReleasingAllReminder: true,
			};
		case actionTypes.RELEASE_ALL_REMINDER_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isReleasingAllReminder: false,
				isReleaseAllReminderSuccess: true,
				isReleaseAllReminderError: false,
			};

		case actionTypes.RELEASE_ALL_REMINDER_FAILURE:
			return {
				...state,
				isReleasingAllReminder: false,
				isReleaseAllReminderSuccess: false,
				isReleaseAllReminderError: true,
			};

		case actionTypes.RELEASE_ALL_REMINDER_RESET:
			return {
				...releaseAllReminderDefaultValue,
			};
		default:
			return state;
	}
};

export const remindersReducer = combineReducers({
	getReminderCount: getReminderCountReducer,
	getReminder: getReminderReducer,
	getReminderDetails: getReminderDetailsReducer,
	releaseAllReminder: releaseAllReminderReducer,
});
