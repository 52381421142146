import { EditAccountActionTypes } from "../../../AccountDetails/PrimaryAccount/__redux/types";
import * as actionTypes from "./actionTypes";
import {
	AddMemberActionTypes,
	DeleteMemberActionTypes,
	DeleteMemberPayloadType,
	EditMemberActionTypes,
	EditMemberPayloadType,
	MemberPayloadType,
	IEditDirectorsPayloadType,
	IEditDirectorsActions,
} from "./types";

// Add member
export const addMemberCtr = (payload: MemberPayloadType): AddMemberActionTypes => ({
	type: actionTypes.ADD_MEMBER,
	payload,
});

export const addMemberResetCtr = (): AddMemberActionTypes => ({
	type: actionTypes.ADD_MEMBER_RESET,
});

// Edit member
export const editMemberCtr = (payload: EditMemberPayloadType): EditMemberActionTypes => ({
	type: actionTypes.EDIT_MEMBER,
	payload,
});

export const editMemberResetCtr = (): EditMemberActionTypes => ({
	type: actionTypes.EDIT_MEMBER_RESET,
});

// Delete Member
export const deleteMemberCtr = (payload: DeleteMemberPayloadType): DeleteMemberActionTypes => ({
	type: actionTypes.DELETE_MEMBER,
	payload,
});

export const deleteMemberResetCtr = (): DeleteMemberActionTypes => ({
	type: actionTypes.DELETE_MEMBER_RESET,
});

// edit DirectorID
export const editDirectorIDCtr = (payload: IEditDirectorsPayloadType): IEditDirectorsActions => ({
	type: actionTypes.EDIT_DIRECTOR_ID,
	payload,
});

export const editDirectorIDResetCtr = (): IEditDirectorsActions => ({
	type: actionTypes.EDIT_DIRECTOR_ID_RESET,
});
