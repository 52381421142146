import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { DeleteMemberPayloadType, MemberPayloadType, IEditDirectorsPayloadType } from "../__redux/types";
import { ADD_MEMBER, DELETE_MEMBER, EDIT_MEMBER, EDIT_DIRECTOR_ID } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const addMemberApi = async ({ id, body }: MemberPayloadType): Promise<AxiosResponse<any>> =>
	axios.post(`${ADD_MEMBER}${id}/`, body).catch(errorHandler);

export const editMemberApi = async (payload: MemberPayloadType): Promise<AxiosResponse<any>> =>
	axios.post(`${EDIT_MEMBER}${payload.id}/`, payload).catch(errorHandler);

export const deleteMemberApi = async (payload: DeleteMemberPayloadType): Promise<AxiosResponse<any>> =>
	axios.delete(`${DELETE_MEMBER}${payload}/`).catch(errorHandler);

export const editDirectorIDApi = async ({
	editingId,
	directorId,
}: IEditDirectorsPayloadType): Promise<AxiosResponse<any>> =>
	axios.post(`${EDIT_DIRECTOR_ID}${editingId}/`, { directorId }).catch(errorHandler);
