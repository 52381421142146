import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { IMoveWorkflowFundDetailsGenerateDocsStatePayload } from "__redux/workflowFundDetails/types";
import { IMoveWorkflowDashboardGenerateDocsStatePayload } from "__redux/workflowDashboard/types";
import { MOVE_WORKFLOW_DASHBOARD, MOVE_WORKFLOW_FUND_DETAILS } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const moveWorkflowFundDetailsApi = async (
	payload: IMoveWorkflowFundDetailsGenerateDocsStatePayload
): Promise<AxiosResponse<any>> => axios.post(`${MOVE_WORKFLOW_FUND_DETAILS}`, payload).catch(errorHandler);

export const moveWorkflowDashboardApi = async (
	payload: IMoveWorkflowDashboardGenerateDocsStatePayload
): Promise<AxiosResponse<any>> => {
	const { workflowFundId, ...rest } = payload;
	const url = MOVE_WORKFLOW_DASHBOARD.replace(":workflow_id", `${workflowFundId}`);

	return axios.post(url, rest).catch(errorHandler);
};
