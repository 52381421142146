import { call, put, select, takeLatest } from "redux-saga/effects";
import * as fundDetailsActionTypes from "__pages/FundDetails/__redux/actionTypes";
import { getFundDetailsSlr } from "__pages/FundDetails/__redux/selectors";
import { GetFundDetailsType, ITrust } from "__pages/FundDetails/__redux/types";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";

const addTrust = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.addTrustApi, payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_SUCCESS,
				...data,
			});

			// save the latest fund data
			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_FOR_MODAL_SUCCESS,
				...data,
			});

			yield put({
				type: actionTypes.ADD_TRUST_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({
				type: actionTypes.ADD_TRUST_FAILURE,
				isAddTrustError: data,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.ADD_TRUST_FAILURE,
		});
	}
};

export const addTrustSaga = function* (): any {
	yield takeLatest(actionTypes.ADD_TRUST, addTrust);
};

const editTrust = function* (action: any): any {
	const { payload } = action;
	try {
		const response: ResponseGeneratorType = yield call(api.editTrustApi, payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_SUCCESS,
				...data,
			});
			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_FOR_MODAL_SUCCESS,
				...data,
			});

			yield put({
				type: actionTypes.EDIT_TRUST_SUCCESS,
				id: payload.id,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			// errorEditing
			yield put({
				type: actionTypes.EDIT_TRUST_FAILURE,
				isEditTrustError: data,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.EDIT_TRUST_FAILURE,
		});
	}
};

export const editTrustSaga = function* (): any {
	yield takeLatest(actionTypes.EDIT_TRUST, editTrust);
};

const deleteTrust = function* (action: any): any {
	const { payload } = action;
	const getFund: GetFundDetailsType = yield select(getFundDetailsSlr);
	try {
		const response: ResponseGeneratorType = yield call(api.deleteTrustApi, payload);
		const { status, data } = response;
		if (status === 204) {
			// use remove  object
			const filteredTrust = getFund.trust.filter((data: ITrust) => data.id !== payload.trustId);

			const _trust = {
				...getFund,
				trust: filteredTrust,
			};

			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_SUCCESS,
				..._trust,
			});

			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_FOR_MODAL_SUCCESS,
				...data,
			});

			yield put({
				type: actionTypes.DELETE_TRUST_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			// errorDeleting
			yield put({
				type: actionTypes.DELETE_TRUST_FAILURE,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.DELETE_TRUST_FAILURE,
		});
	}
};

export const deleteTrustSaga = function* (): any {
	yield takeLatest(actionTypes.DELETE_TRUST, deleteTrust);
};
