import { call, put, takeLatest } from "redux-saga/effects";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";

const addRecurringPayment = function* (action: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.addRecurringPaymentApi, action.payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.ADD_RECURRING_PAYMENT_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({
				type: actionTypes.ADD_RECURRING_PAYMENT_FAILURE,
				addRecurringPaymentError: data,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.ADD_RECURRING_PAYMENT_FAILURE,
		});
	}
};

export const addRecurringPaymentSaga = function* (): any {
	yield takeLatest(actionTypes.ADD_RECURRING_PAYMENT, addRecurringPayment);
};
