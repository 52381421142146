import Form from "antd/es/form";
import React, { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandDetailsType, GetBrandsType } from "__pages/Administration/__redux/types";
import AccountForm from "__pages/FundDetails/__pages/Account/AccountDetails/PrimaryAccount/EditAccountModal/AccountForm";
import { editAccountCtr } from "__pages/FundDetails/__pages/Account/AccountDetails/PrimaryAccount/__redux/actions";
import { editAccountSlr } from "__pages/FundDetails/__pages/Account/AccountDetails/PrimaryAccount/__redux/selectors";
import { EditAccountType } from "__pages/FundDetails/__pages/Account/AccountDetails/PrimaryAccount/__redux/types";
import { AccountType } from "__pages/FundDetails/__redux/types";
import { processErrors } from "__utils/helpers";

type Props = {
	account: AccountType;
	getbrands: GetBrandsType;
	isSubmitEditAccount: boolean;
	setSubmitEditAccount: Dispatch<SetStateAction<boolean>>;
};

const EditAccount: React.FC<Props> = ({ account, getbrands, isSubmitEditAccount, setSubmitEditAccount }: Props) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { setFields, validateFields } = form;
	const addAccount: EditAccountType = useSelector(editAccountSlr);
	const { isError, error } = addAccount;
	const { id } = account;
	const { brands } = getbrands;

	React.useEffect(() => {
		if (isSubmitEditAccount) {
			validateFields()
				.then((values: any) => {
					const _brand = brands.filter((data: BrandDetailsType) => data.title === values.administratorBrand);
					const _account: AccountType = { ...values, administratorBrand: _brand[0].id };
					dispatch(editAccountCtr({ id, body: _account }));
				})
				.catch(() => {
					setSubmitEditAccount(false);
				});
		}
	}, [brands, id, isSubmitEditAccount, validateFields, dispatch, setSubmitEditAccount]);

	React.useEffect(() => {
		if (isError) {
			setFields(processErrors(error));
			setSubmitEditAccount(false);
		}
	}, [isError, error, setFields, setSubmitEditAccount]);

	if (!brands.length) {
		return null;
	}

	return <AccountForm form={form} account={account} getbrands={getbrands} />;
};

export default EditAccount;
