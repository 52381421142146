import AppHeader from "__layout/Header";
import Content from "__layout/Content";
import Layout from "antd/es/layout";
import React from "react";
import Result from "antd/es/result";

export const NotAuthorized: React.FC = () => (
	<Layout className="site-layout">
		<AppHeader />
		<Content>
			<Result status="403" title="403" subTitle="Sorry, you are not authorized to access this page." />
		</Content>
	</Layout>
);

export default NotAuthorized;
