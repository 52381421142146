import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { GetClassPayloadType, EditClassPayloadType } from "../__redux/types";
import { GET_CLASS, EDIT_CLASS } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getClassApi = async ({ memberId }: GetClassPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = GET_CLASS.replace(":memberId", `${memberId}`);
	return axios.get(url).catch(errorHandler);
};

export const editClassApi = async ({ memberId, ...rest }: EditClassPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = EDIT_CLASS.replace(":memberId", `${memberId}`);
	return axios.post(url, rest).catch(errorHandler);
};
