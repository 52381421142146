import { call, put, select, takeLatest } from "redux-saga/effects";
import { ResponseGeneratorType } from "__utils/types";
import { sortObjects } from "__utils/helpers";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";
import { getBrandsSlr } from "./selectors";
import { AdminDetailsType, AdminLevelsType, BrandDetailsType, GetBrandsType, BrandsType } from "./types";

const getBrands = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getBrandsApi); // No payload because it's just GET
		const { status, data } = response;

		if (status === 200) {
			const brands: GetBrandsType = data;

			const _brands = {
				brands,
				isGettingBrands: false,
				isGetBrandsSuccess: true,
				isGetBrandsError: false,
			};
			yield put({
				type: actionTypes.GET_BRANDS_SUCCESS,
				..._brands,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_BRANDS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_BRANDS_FAILURE });
	}
};

export const getBrandsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_BRANDS, getBrands);
};

const getBrandsOnScroll = function* ({ payload }: any): any {
	try {
		const { page } = payload;
		const { brands, numOfPages, limit }: GetBrandsType = yield select(getBrandsSlr);

		if (page <= numOfPages) {
			const _documents: BrandsType = sortObjects<BrandDetailsType>(brands, "id", 0);
			const partial: BrandsType = _documents.slice(0, page * limit);

			yield put({
				type: actionTypes.GET_BRANDS_SUCCESS,
				partial,
				numOfPages,
				currPage: page,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.GET_BRANDS_FAILURE,
		});
	}
};

export const getBrandsOnScrollSaga = function* (): any {
	yield takeLatest(actionTypes.GET_BRANDS_ON_SCROLL, getBrandsOnScroll);
};

const getAdminLevels = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getAdminsLevelApi);
		const { status, data } = response;
		if (status === 200) {
			const adminLevels: AdminLevelsType = data;
			yield put({
				type: actionTypes.GET_ADMIN_LEVELS_SUCCESS,
				adminLevels,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_ADMIN_LEVELS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_ADMIN_LEVELS_FAILURE });
	}
};

export const getAdminLevelsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_ADMIN_LEVELS, getAdminLevels);
};

// Add Brand
const addBrand = function* (action: any): any {
	const { payload } = action;

	try {
		const response: ResponseGeneratorType = yield call(api.addBrandApi, payload);
		const { status, data } = response;

		if (status === 201) {
			yield put({
				type: actionTypes.ADD_BRAND_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({
				type: actionTypes.ADD_BRAND_FAILURE,
				addingBrandError: data,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.ADD_BRAND_FAILURE,
		});
	}
};

export const addBrandSaga = function* (): any {
	yield takeLatest(actionTypes.ADD_BRAND, addBrand);
};

// Edit Brand
const editBrand = function* (action: any): any {
	const { payload } = action;
	const BrandList: GetBrandsType = yield select(getBrandsSlr);
	const { brands } = BrandList;

	try {
		const response: ResponseGeneratorType = yield call(api.editBrandApi, payload);
		const { status, data } = response;

		if (status === 200) {
			const _editBrand = brands.map((item: BrandDetailsType) => {
				if (item.id === data.id) {
					return { ...data };
				}
				return item;
			});

			yield put({
				type: actionTypes.GET_BRANDS_SUCCESS,
				brands: _editBrand,
			});

			yield put({
				type: actionTypes.EDIT_BRAND_SUCCESS,
			});
		} else {
			yield put({ type: actionTypes.EDIT_BRAND_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.EDIT_BRAND_FAILURE });
	}
};

export const editBrandSaga = function* (): any {
	yield takeLatest(actionTypes.EDIT_BRAND, editBrand);
};

const addAdmin = function* (action: any): any {
	const { payload } = action;
	const BrandList: GetBrandsType = yield select(getBrandsSlr);
	const { brands } = BrandList;

	try {
		const response: ResponseGeneratorType = yield call(api.addAdminApi, payload);
		const { status, data } = response;

		if (status === 201) {
			const addNewAdmin = brands.map((brand: BrandDetailsType) => {
				if (brand.id === data.administratorBrand) {
					const newData = [...brand.administrators, data];
					return { ...brand, administrators: newData };
				}

				return brand;
			});

			yield put({
				type: actionTypes.GET_BRANDS_SUCCESS,
				brands: addNewAdmin,
			});

			yield put({
				type: actionTypes.ADD_ADMIN_SUCCESS,
				isAddingAdmin: false,
				isAddAdminSuccess: true,
				addAdminError: {},
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.ADD_ADMIN_FAILURE, addAdminError: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.ADD_ADMIN_FAILURE });
	}
};

export const AddAdminSaga = function* (): any {
	yield takeLatest(actionTypes.ADD_ADMIN, addAdmin);
};

// Edit Admin
const editAdmin = function* (action: any): any {
	try {
		const { payload } = action;

		const getBrands: GetBrandsType = yield select(getBrandsSlr);
		const { brands } = getBrands;
		const response: ResponseGeneratorType = yield call(api.editAdminApi, payload);
		const { status, data } = response;

		if (status === 200) {
			const editedAdmin = brands.map((brands: BrandDetailsType): BrandDetailsType => {
				if (brands.id === data.administratorBrand) {
					const _editedAdmin = brands.administrators.map((admin: AdminDetailsType) => {
						if (data.id === admin.id) {
							return data;
						}
						return admin;
					});
					return { ...brands, administrators: _editedAdmin };
				}
				return brands;
			});

			yield put({
				type: actionTypes.GET_BRANDS_SUCCESS,
				brands: editedAdmin,
			});

			yield put({
				type: actionTypes.EDIT_ADMIN_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.EDIT_ADMIN_FAILURE, editAdminError: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.EDIT_ADMIN_FAILURE });
	}
};

export const editAdminSaga = function* (): any {
	yield takeLatest(actionTypes.EDIT_ADMIN, editAdmin);
};

// delete admin
const deleteAdmin = function* (action: any): any {
	const { payload } = action;
	const { id, administratorBrand } = payload;
	const BrandList: GetBrandsType = yield select(getBrandsSlr);
	const { brands } = BrandList;

	try {
		const response: ResponseGeneratorType = yield call(api.deleteAdminApi, payload);
		const { status } = response;
		if (response.status === 200) {
			// remove object
			const deletedAdmin = brands.map((brands: BrandDetailsType) => {
				if (brands.id === administratorBrand) {
					const _deletedAdmin = brands.administrators.filter((admin: AdminDetailsType) => admin.id !== id);
					return { ...brands, administrators: _deletedAdmin };
				}
				return brands;
			});
			yield put({
				type: actionTypes.GET_BRANDS_SUCCESS,
				brands: deletedAdmin,
			});
			yield put({
				type: actionTypes.DELETE_ADMIN_SUCCESS,
				isDeletingAdmin: false,
				isDeletingAdminSuccess: true,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.DELETE_ADMIN_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.DELETE_ADMIN_FAILURE });
	}
};

export const deleteAdminSaga = function* (): any {
	yield takeLatest(actionTypes.DELETE_ADMIN, deleteAdmin);
};
