import { call, put, takeLatest, select } from "redux-saga/effects";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";
import { getWorkflowFundsSlr, moveWorkflowFundStateSlr } from "./selectors";
import { IGetWorkflowFunds, IWorkflowFund, IWorkflowFundUpdate, IMoveWorkflowFundState } from "./types";
import { sortNotesByDateDesc } from "../__utils/helpers";

const getWorkflowFunds = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getWorkflowFundsApi, payload);
		const { status, data } = response;
		const { data: fundData, administrators } = data;

		if (response.status === 200) {
			yield put({
				type: actionTypes.GET_WORKFLOW_FUNDS_SUCCESS,
				workflowFunds: fundData,
				administrators,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_WORKFLOW_FUNDS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_WORKFLOW_FUNDS_FAILURE });
	}
};

export const getWorkflowFundsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_WORKFLOW_FUNDS, getWorkflowFunds);
};

const moveWorkflowFundState = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.moveWorkflowFundStateApi, payload);
		const { status, data } = response;

		if (status === 200) {
			// Update our workflow funds
			const { workflowFunds }: IGetWorkflowFunds = yield select(getWorkflowFundsSlr);
			const workflowFundsUpdated: IWorkflowFund[] = workflowFunds.reduce(
				(acc: IWorkflowFund[], curr: IWorkflowFund) => {
					const found: IWorkflowFundUpdate = data.find((item: IWorkflowFundUpdate) => item.id === curr.id);

					if (found) {
						curr = { ...curr, stateHistory: found.stateHistory, next: found.next };
					}

					acc.push(curr);
					return acc;
				},
				[]
			);

			yield put({
				type: actionTypes.GET_WORKFLOW_FUNDS_SUCCESS,
				workflowFunds: workflowFundsUpdated,
			});

			// Update workflow summary

			// Update move workflow fund state to indicate whether or not to show toaster
			const { newState, workflowFundId } = payload;
			const toasterData: { showToaster: boolean; status: string } = data.reduce(
				(acc: { showToaster: boolean; status: string }, item: IWorkflowFundUpdate) => {
					const { id, stateHistory } = item;
					if (id === workflowFundId && stateHistory[`${newState}`].status) {
						acc = { showToaster: true, status: stateHistory[`${newState}`].status };
					}
					return acc;
				},
				false
			);

			yield put({
				type: actionTypes.MOVE_WORKFLOW_FUND_STATE_SUCCESS,
				...toasterData,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.MOVE_WORKFLOW_FUND_STATE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.MOVE_WORKFLOW_FUND_STATE_FAILURE });
	}
};

export const moveWorkflowFundStateSaga = function* (): any {
	yield takeLatest(actionTypes.MOVE_WORKFLOW_FUND_STATE, moveWorkflowFundState);
};

const getWorkflowFundsUpdate = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getWorkflowFundsUpdateApi, payload);
		const {
			status,
			data: { data },
		} = response;

		if (response.status === 200) {
			yield put({
				type: actionTypes.GET_WORKFLOW_FUNDS_UPDATE_SUCCESS,
				workflowFundsUpdate: data,
			});

			// Update our workflow funds
			const { workflowFunds }: IGetWorkflowFunds = yield select(getWorkflowFundsSlr);
			const workflowFundsUpdated: IWorkflowFund[] = workflowFunds.reduce(
				(acc: IWorkflowFund[], curr: IWorkflowFund) => {
					const found: IWorkflowFundUpdate = data.find((item: IWorkflowFundUpdate) => item.id === curr.id);

					if (found) {
						curr = { ...curr, stateHistory: found.stateHistory };
					}

					acc.push(curr);
					return acc;
				},
				[]
			);

			yield put({
				type: actionTypes.GET_WORKFLOW_FUNDS_SUCCESS,
				workflowFunds: workflowFundsUpdated,
			});

			// Update move workflow fund state to indicate whether or not to show toaster
			const { newState, workflowFundId }: IMoveWorkflowFundState = yield select(moveWorkflowFundStateSlr);
			const toasterData: { showToaster: boolean; status: string } = data.reduce(
				(acc: { showToaster: boolean; status: string }, item: IWorkflowFundUpdate) => {
					const { id, stateHistory } = item;
					if (id === workflowFundId && stateHistory[`${newState}`].status) {
						acc = {
							showToaster: true,
							status: stateHistory[`${newState}`].status,
						};
					}
					return acc;
				},
				false
			);

			yield put({
				type: actionTypes.MOVE_WORKFLOW_FUND_STATE_SUCCESS,
				...toasterData,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_WORKFLOW_FUNDS_UPDATE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_WORKFLOW_FUNDS_UPDATE_FAILURE });
	}
};

export const getWorkflowFundsUpdateSaga = function* (): any {
	yield takeLatest(actionTypes.GET_WORKFLOW_FUNDS_UPDATE, getWorkflowFundsUpdate);
};

const addWorkflowFundNote = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.addWorkflowFundNoteApi, payload);
		const { status } = response;

		if (response.status === 201) {
			yield put({
				type: actionTypes.ADD_WORKFLOW_FUND_NOTE_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.ADD_WORKFLOW_FUND_NOTE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.ADD_WORKFLOW_FUND_NOTE_FAILURE });
	}
};

export const addWorkflowFundNoteSaga = function* (): any {
	yield takeLatest(actionTypes.ADD_WORKFLOW_FUND_NOTE, addWorkflowFundNote);
};

const getWorkflowFundNotes = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getWorkflowFundNotesApi, payload);
		const {
			status,
			data: { data },
		} = response;

		if (response.status === 200) {
			yield put({
				type: actionTypes.GET_WORKFLOW_FUND_NOTES_SUCCESS,
				notes: sortNotesByDateDesc(data),
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_WORKFLOW_FUND_NOTES_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_WORKFLOW_FUND_NOTES_FAILURE });
	}
};

export const getWorkflowFundNotesSaga = function* (): any {
	yield takeLatest(actionTypes.GET_WORKFLOW_FUND_NOTES, getWorkflowFundNotes);
};

const completeWorkflowFundNote = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.completeWorkflowFundNoteApi, payload);
		const { status } = response;

		if (response.status === 200) {
			yield put({
				type: actionTypes.COMPLETE_WORKFLOW_FUND_NOTE_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.COMPLETE_WORKFLOW_FUND_NOTE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.COMPLETE_WORKFLOW_FUND_NOTE_FAILURE });
	}
};

export const completeWorkflowFundNoteSaga = function* (): any {
	yield takeLatest(actionTypes.COMPLETE_WORKFLOW_FUND_NOTE, completeWorkflowFundNote);
};

const updateWorkflowPerson = function* ({ payload }: any): any {
	try {
		const { workflowId } = payload;
		const response: ResponseGeneratorType = yield call(api.updateWorkflowPersonApi, payload);
		const { status, data } = response;

		let newPerson = "";
		Object.keys(data).forEach((title) => {
			const { person } = data[title];
			newPerson = person;
		});

		if (status === 200) {
			const { workflowFunds }: IGetWorkflowFunds = yield select(getWorkflowFundsSlr);
			const updateWorkflowFunds = workflowFunds.map((data: IWorkflowFund) => {
				const { id } = data;
				if (id === workflowId) {
					return { ...data, person: newPerson };
				}
				return { ...data };
			});

			yield put({
				type: actionTypes.GET_WORKFLOW_FUNDS_SUCCESS,
				workflowFunds: updateWorkflowFunds,
			});
			yield put({
				type: actionTypes.UPDATE_WORKFLOW_PERSON_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.UPDATE_WORKFLOW_PERSON_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.UPDATE_WORKFLOW_PERSON_FAILURE });
	}
};

export const updateWorkflowPersonSaga = function* (): any {
	yield takeLatest(actionTypes.UPDATE_WORKFLOW_PERSON, updateWorkflowPerson);
};

const getWorkflows = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getWorkflowsApi);
		const { status, data } = response;

		if (response.status === 200) {
			yield put({
				type: actionTypes.GET_WORKFLOWS_SUCCESS,
				workflows: data.workflows,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_WORKFLOWS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_WORKFLOWS_FAILURE });
	}
};

export const getWorkflowsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_WORKFLOWS, getWorkflows);
};
