import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import {
	IGetWorkflowFundsPayload,
	IMoveWorkflowFundStatePayload,
	IGetWorkflowFundsUpdatePayload,
	IAddWorkflowFundNotePayload,
	IGetWorkflowFundNotesPayload,
	ICompleteWorkflowFundNotePayload,
	IUpdateWorkflowPersonPayload,
} from "../__redux/types";
import {
	GET_WORKFLOW_FUNDS,
	MOVE_WORKFLOW_FUND_STATE,
	GET_WORKFLOW_FUNDS_UPDATE,
	ADD_WORKFLOW_FUND_NOTE,
	GET_WORKFLOW_FUND_NOTES,
	COMPLETE_WORKFLOW_FUND_NOTE,
	UPDATE_WORKFLOW_PERSON,
	GET_WORKFLOWS,
} from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getWorkflowFundsApi = async ({
	workflowId,
	tag,
}: IGetWorkflowFundsPayload): Promise<AxiosResponse<any>> => {
	const url = GET_WORKFLOW_FUNDS.replace(":workflowId", `${workflowId}`).replace(":tag", `${tag}`);
	return axios.get(url).catch(errorHandler);
};

export const moveWorkflowFundStateApi = async ({
	workflowFundId,
	...rest
}: IMoveWorkflowFundStatePayload): Promise<AxiosResponse<any>> => {
	const url = MOVE_WORKFLOW_FUND_STATE.replace(":workflowFundId", `${workflowFundId}`);
	return axios.post(url, { ...rest }).catch(errorHandler);
};

export const getWorkflowFundsUpdateApi = async ({
	workflowId,
	tag,
}: IGetWorkflowFundsUpdatePayload): Promise<AxiosResponse<any>> => {
	const url = GET_WORKFLOW_FUNDS_UPDATE.replace(":workflowId", `${workflowId}`).replace(":tag", `${tag}`);
	return axios.get(url).catch(errorHandler);
};

export const addWorkflowFundNoteApi = async ({
	workflowFundId,
	...rest
}: IAddWorkflowFundNotePayload): Promise<AxiosResponse<any>> => {
	const url = ADD_WORKFLOW_FUND_NOTE.replace(":workflowFundId", `${workflowFundId}`);
	return axios.post(url, { ...rest }).catch(errorHandler);
};

export const getWorkflowFundNotesApi = async ({
	workflowFundId,
	...rest
}: IGetWorkflowFundNotesPayload): Promise<AxiosResponse<any>> => {
	const url = GET_WORKFLOW_FUND_NOTES.replace(":workflowFundId", `${workflowFundId}`);
	return axios.get(url, { ...rest }).catch(errorHandler);
};

export const completeWorkflowFundNoteApi = async ({
	noteId,
	...rest
}: ICompleteWorkflowFundNotePayload): Promise<AxiosResponse<any>> => {
	const url = COMPLETE_WORKFLOW_FUND_NOTE.replace(":noteId", `${noteId}`);
	return axios.put(url, { ...rest }).catch(errorHandler);
};

export const updateWorkflowPersonApi = async ({
	workflowId,
	adminID,
}: IUpdateWorkflowPersonPayload): Promise<AxiosResponse<any>> => {
	const url = UPDATE_WORKFLOW_PERSON.replace(":workflowFundId", `${workflowId}`);
	return axios.put(url, { person: adminID }).catch(errorHandler);
};

export const getWorkflowsApi = async (): Promise<AxiosResponse<any>> => {
	return axios.get(GET_WORKFLOWS).catch(errorHandler);
};
