import { UserOutlined } from "@ant-design/icons";
import Card from "antd/es/card";
import Col from "antd/es/col";
import Row from "antd/es/row";
import React, { Dispatch, SetStateAction } from "react";
import { GetFundDetailsType, MemberType } from "__pages/FundDetails/__redux/types";
import { Container } from "./style";

const optionalMembersCard = (members: Array<MemberType>): React.ReactNode[] => {
	const optionalCard: React.ReactNode[] = [];

	// Outer loop to create parent
	for (let i = members.length + 1; i < 4; i++) {
		optionalCard.push(
			<Col xs={24} xl={12} key={i}>
				<Card className="add-members--card">
					<UserOutlined className="add-members--icon disabled" />
					<p className="add-members--link disabled">Optional Member</p>
				</Card>
			</Col>
		);
	}
	return optionalCard;
};

type Props = {
	fund: GetFundDetailsType;
	setShowMemberForm: Dispatch<SetStateAction<boolean>>;
	setMemberId: Dispatch<SetStateAction<number>>;
};

const MemberCard: React.FC<Props> = ({ fund, setShowMemberForm, setMemberId }: Props) => {
	const { members } = fund;

	const onClickMember = (id: number): void => {
		setMemberId(id);
		setShowMemberForm((prev) => !prev);
	};

	return (
		<Container>
			<div className="add-members">
				<Row gutter={[16, 16]}>
					{members.map((member: MemberType, idx: number) => (
						<Col xs={24} xl={12} key={idx}>
							<Card
								hoverable
								className="add-members--card new-member-section"
								onClick={() => onClickMember(member.id)}
							>
								<UserOutlined className="add-members--icon" />
								<p className="add-members--link">{`${member.firstName} ${member.lastName}`}</p>
							</Card>
						</Col>
					))}

					{members.length < 4 && (
						<Col xs={24} xl={12}>
							<Card hoverable className="add-members--card" onClick={() => onClickMember(0)}>
								<UserOutlined className="add-members--icon" />
								<p className="add-members--link">Add Member</p>
							</Card>
						</Col>
					)}

					{optionalMembersCard(members)}
				</Row>
			</div>
		</Container>
	);
};

export default MemberCard;
