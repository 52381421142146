import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import {
	AddTemplatePayloadType,
	DeleteTemplatePayloadType,
	EditTemplatePayloadType,
	GetTemplateDetailsPayloadType,
} from "../__redux/types";
import { ADD_TEMPLATE, DELETE_TEMPLATE, EDIT_TEMPLATE, GET_TEMPLATES, GET_TEMPLATE_CATEGORIES } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const addTemplateApi = async (payload: AddTemplatePayloadType): Promise<AxiosResponse<any>> =>
	axios.post(`${ADD_TEMPLATE}/`, payload).catch(errorHandler);

export const editTemplateApi = async (payload: EditTemplatePayloadType): Promise<AxiosResponse<any>> => {
	const { id } = payload;

	return axios.put(`${EDIT_TEMPLATE}/${id}/`, payload).catch(errorHandler);
};

export const getTemplateCategoriesApi = async (): Promise<AxiosResponse<any>> =>
	axios.get(`${GET_TEMPLATE_CATEGORIES}`).catch(errorHandler);

export const getTemplatesApi = async (): Promise<AxiosResponse<any>> =>
	axios.get(`${GET_TEMPLATES}/`).catch(errorHandler);

export const deleteTemplateApi = async (payload: DeleteTemplatePayloadType): Promise<AxiosResponse<any>> =>
	axios.delete(`${DELETE_TEMPLATE}/${payload.id}/`).catch(errorHandler);

export const getTemplateDetailsApi = async (id: GetTemplateDetailsPayloadType): Promise<AxiosResponse<any>> =>
	axios.get(`${GET_TEMPLATES}/${id}/`).catch(errorHandler);
