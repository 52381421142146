import styled from "styled-components";

export const SpanStyled = styled.span`
	display: inline-block;
	color: inherit;
	font-style: normal;
	line-height: 0;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
`;
