import styled from "styled-components";

export const Container = styled.div`
	.fund-modal-body {
		.ant-steps {
			margin-bottom: 30px;
		}
		.form-name {
			display: none;
		}
		.ant-steps-item-title {
			font-size: 10px;
		}
		.ant-steps-item-icon {
			font-size: 10px;
		}
	}

	.steps-content {
		margin-top: 16px;
		background: transparent;
	}
`;
