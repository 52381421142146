import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { DownloadInvoicePayloadType, GetInvoicesPayloadType } from "../__redux/types";
import { DOWNLOAD_INVOICE, GET_INVOICES } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getInvoicesApi = async ({ id }: GetInvoicesPayloadType): Promise<AxiosResponse<any>> =>
	axios.get(`${GET_INVOICES}${id}/`).catch(errorHandler);

export const downloadInvoiceApi = async ({ id, index }: DownloadInvoicePayloadType): Promise<AxiosResponse<any>> => {
	const withId: string = DOWNLOAD_INVOICE.replace(":id", `${id}`);
	const withIndex: string = withId.replace(":index", `${index}`);

	return axios.get(withIndex, { responseType: "blob", timeout: 30000 }).catch(errorHandler);
};
