export const ADD_MEMBER = "ADD_MEMBER";
export const ADD_MEMBER_SUCCESS = "ADD_MEMBER_SUCCESS";
export const ADD_MEMBER_FAILURE = "ADD_MEMBER_FAILURE";

export const EDIT_MEMBER = "EDIT_MEMBER";
export const EDIT_MEMBER_SUCCESS = "EDIT_MEMBER_SUCCESS";
export const EDIT_MEMBER_FAILURE = "EDIT_MEMBER_FAILURE";

export const DELETE_MEMBER = "DELETE_MEMBER";
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";
export const DELETE_MEMBER_FAILURE = "DELETE_MEMBER_FAILURE";

export const ADD_MEMBER_RESET = "ADD_MEMBER_RESET";
export const EDIT_MEMBER_RESET = "EDIT_MEMBER_RESET";
export const DELETE_MEMBER_RESET = "DELETE_MEMBER_RESET";

export const EDIT_DIRECTOR_ID = "EDIT_DIRECTOR_ID";
export const EDIT_DIRECTOR_ID_SUCCESS = "EDIT_DIRECTOR_ID_SUCCESS";
export const EDIT_DIRECTOR_ID_FAILURE = "EDIT_DIRECTOR_ID_FAILURE";
export const EDIT_DIRECTOR_ID_RESET = "EDIT_DIRECTOR_ID_RESET";
