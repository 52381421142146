export const ADD_TEMPLATE = "ADD_TEMPLATE";
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS";
export const ADD_TEMPLATE_FAILURE = "ADD_TEMPLATE_FAILURE";
export const ADD_TEMPLATE_RESET = "ADD_TEMPLATE_RESET";

export const EDIT_TEMPLATE = "EDIT_TEMPLATE";
export const EDIT_TEMPLATE_SUCCESS = "EDIT_TEMPLATE_SUCCESS";
export const EDIT_TEMPLATE_FAILURE = "EDIT_TEMPLATE_FAILURE";
export const EDIT_TEMPLATE_RESET = "EDIT_TEMPLATE_RESET";

export const GET_TEMPLATE_CATEGORIES = "GET_TEMPLATE_CATEGORIES";
export const GET_TEMPLATE_CATEGORIES_SUCCESS = "GET_TEMPLATE_CATEGORIES_SUCCESS";
export const GET_TEMPLATE_CATEGORIES_FAILURE = "GET_TEMPLATE_CATEGORIES_FAILURE";
export const GET_TEMPLATE_CATEGORIES_RESET = "GET_TEMPLATE_CATEGORIES_RESET";

export const GET_TEMPLATES = "GET_TEMPLATES";
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_FAILURE = "GET_TEMPLATES_FAILURE";
export const GET_TEMPLATES_RESET = "GET_TEMPLATES_RESET";

export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_FAILURE = "DELETE_TEMPLATE_FAILURE";
export const DELETE_TEMPLATE_RESET = "DELETE_TEMPLATE_RESET";

export const GET_TEMPLATE_DETAILS = "GET_TEMPLATE_DETAILS";
export const GET_TEMPLATE_DETAILS_SUCCESS = "GET_TEMPLATE_DETAILS_SUCCESS";
export const GET_TEMPLATE_DETAILS_FAILURE = "GET_TEMPLATE_DETAILS_FAILURE";
export const GET_TEMPLATE_DETAILS_RESET = "GET_TEMPLATE_DETAILS_RESET";

export const GET_TEMPLATES_ON_SCROLL = "GET_TEMPLATES_ON_SCROLL";
export const GET_TEMPLATES_ON_SCROLL_SUCCESS = "GET_TEMPLATES_ON_SCROLL_SUCCESS";

export const FILTER_TEMPLATES = "FILTER_TEMPLATES";
export const FILTER_TEMPLATES_RESET = "FILTER_TEMPLATES_RESET";
