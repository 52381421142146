export const GET_WORKFLOW_FUNDS = "GET_WORKFLOW_FUNDS";
export const GET_WORKFLOW_FUNDS_SUCCESS = "GET_WORKFLOW_FUNDS_SUCCESS";
export const GET_WORKFLOW_FUNDS_FAILURE = "GET_WORKFLOW_FUNDS_FAILURE";
export const GET_WORKFLOW_FUNDS_RESET = "GET_WORKFLOW_FUNDS_RESET";

export const MOVE_WORKFLOW_FUND_STATE = "MOVE_WORKFLOW_FUND_STATE";
export const MOVE_WORKFLOW_FUND_STATE_SUCCESS = "MOVE_WORKFLOW_FUND_STATE_SUCCESS";
export const MOVE_WORKFLOW_FUND_STATE_FAILURE = "MOVE_WORKFLOW_FUND_STATE_FAILURE";
export const MOVE_WORKFLOW_FUND_STATE_RESET = "MOVE_WORKFLOW_FUND_STATE_RESET";

export const GET_WORKFLOW_FUNDS_UPDATE = "GET_WORKFLOW_FUNDS_UPDATE";
export const GET_WORKFLOW_FUNDS_UPDATE_SUCCESS = "GET_WORKFLOW_FUNDS_UPDATE_SUCCESS";
export const GET_WORKFLOW_FUNDS_UPDATE_FAILURE = "GET_WORKFLOW_FUNDS_UPDATE_FAILURE";
export const GET_WORKFLOW_FUNDS_UPDATE_RESET = "GET_WORKFLOW_FUNDS_UPDATE_RESET";

export const ADD_WORKFLOW_FUND_NOTE = "ADD_WORKFLOW_FUND_NOTE";
export const ADD_WORKFLOW_FUND_NOTE_SUCCESS = "ADD_WORKFLOW_FUND_NOTE_SUCCESS";
export const ADD_WORKFLOW_FUND_NOTE_FAILURE = "ADD_WORKFLOW_FUND_NOTE_FAILURE";
export const ADD_WORKFLOW_FUND_NOTE_RESET = "ADD_WORKFLOW_FUND_NOTE_RESET";

export const GET_WORKFLOW_FUND_NOTES = "GET_WORKFLOW_FUND_NOTES";
export const GET_WORKFLOW_FUND_NOTES_SUCCESS = "GET_WORKFLOW_FUND_NOTES_SUCCESS";
export const GET_WORKFLOW_FUND_NOTES_FAILURE = "GET_WORKFLOW_FUND_NOTES_FAILURE";
export const GET_WORKFLOW_FUND_NOTES_RESET = "GET_WORKFLOW_FUND_NOTES_RESET";

export const COMPLETE_WORKFLOW_FUND_NOTE = "COMPLETE_WORKFLOW_FUND_NOTE";
export const COMPLETE_WORKFLOW_FUND_NOTE_SUCCESS = "COMPLETE_WORKFLOW_FUND_NOTE_SUCCESS";
export const COMPLETE_WORKFLOW_FUND_NOTE_FAILURE = "COMPLETE_WORKFLOW_FUND_NOTE_FAILURE";
export const COMPLETE_WORKFLOW_FUND_NOTE_RESET = "COMPLETE_WORKFLOW_FUND_NOTE_RESET";

export const UPDATE_WORKFLOW_PERSON = "UPDATE_WORKFLOW_PERSON";
export const UPDATE_WORKFLOW_PERSON_SUCCESS = "UPDATE_WORKFLOW_PERSON_SUCCESS";
export const UPDATE_WORKFLOW_PERSON_FAILURE = "UPDATE_WORKFLOW_PERSON_FAILURE";
export const UPDATE_WORKFLOW_PERSON_RESET = "UPDATE_WORKFLOW_PERSON_RESET";

export const GET_WORKFLOWS = "UPDATE_WORKFLOWS";
export const GET_WORKFLOWS_SUCCESS = "GET_WORKFLOWS_SUCCESS";
export const GET_WORKFLOWS_FAILURE = "GET_WORKFLOWS_FAILURE";
export const GET_WORKFLOWS_RESET = "GET_WORKFLOWS_RESET";
