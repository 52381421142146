import styled from "styled-components";

export const Container = styled.div<any>`
	.suggestion-list {
		position: absolute;
		top: 40px;
		z-index: 100;
		width: ${(p) => (p.isLG ? "100%" : "155%")};
		background: ${(p: any) => p.theme.backgroundColor};
		border: 1px solid ${(p: any) => p.theme.dropdownBorderColor};
		box-shadow: ${(p) => p.theme.boxShadow};
		border-radius: ${(p) => p.theme.borderRadius};

		.suggestion-item {
			padding: 8px;
			text-align: left;
			background-color: ${(p: any) => p.theme.backgroundColor};
			cursor: pointer;
			border-bottom: 1px double ${(p: any) => p.theme.dropdownBorderColor};

			.anticon {
				vertical-align: -3px;
			}
		}

		.suggestion-item:nth-last-child(1) {
			border-bottom: none;
		}
	}

	form .form-content .hidden-address-fields {
		display: ${(p) => (p.hiddenFields ? "none" : "blocked")};
	}

	${(p) =>
		p.isDisabled &&
		`
            .ant-input {
                background: transparent;
                color: #000000d9;
            }
           
            .ant-select {
                .ant-select-selector {
                    background: transparent !important;
                    color: #000000d9 !important;
                }
    
                .ant-select-arrow .anticon {
                    display: none;
                }
            }
    
            .ant-picker {
                background: transparent !important;
                input {
                    color: #000000d9!important;
                }
                span{
                    display:none;
                }
            }

            .ant-checkbox-wrapper {
                background: transparent;
                input {
                    color: #000000d9;
                }

                span{
                    color: #000000d9;
                }
            }
        `}

	.form-footer {
		text-align: right;
		button:not(:last-child) {
			margin-right: 5px;
		}
	}
`;
