import styled from "styled-components";

export const SpanStyled = styled.span<any>`
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	display: flex;
	transition: ${(p) => p.theme.transition("background")};

	:hover,
	.active {
		background: #e6e6e6;
	}

	&.disabled:hover {
		background: transparent;
		cursor: not-allowed;

		.anticon {
			cursor: not-allowed;
		}
	}
`;
