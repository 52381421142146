import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import { downloadReportDefaultValue, DownloadReportActionTypes, DownloadReportType } from "./types";

export const downloadReportReducer = (
	state = downloadReportDefaultValue,
	action: DownloadReportActionTypes
): DownloadReportType => {
	switch (action.type) {
		case actionTypes.DOWNLOAD_REPORT:
			const downloadAction: any = action;
			return {
				...state,
				names: [...state.names, downloadAction.payload.name],
				isDownloadingReport: { ...state.isDownloadingReport, [downloadAction.payload.name]: true },
			};
		case actionTypes.DOWNLOAD_REPORT_SUCCESS:
			const successAction: any = action;
			return {
				...state,
				isDownloadingReport: { ...state.isDownloadingReport, [successAction.name]: false },
				isDownloadReportSuccess: { ...state.isDownloadReportSuccess, [successAction.name]: true },
				isDownloadReportError: { ...state.isDownloadReportError, [successAction.name]: false },
			};
		case actionTypes.DOWNLOAD_REPORT_FAILURE:
			const failureAction: any = action;
			return {
				...state,
				isDownloadingReport: { ...state.isDownloadingReport, [failureAction.name]: false },
				isDownloadReportSuccess: { ...state.isDownloadReportSuccess, [failureAction.name]: false },
				isDownloadReportError: { ...state.isDownloadReportError, [failureAction.name]: true },
			};
		case actionTypes.DOWNLOAD_REPORT_RESET:
			return {
				...state,
				...downloadReportDefaultValue,
			};
		default:
			return state;
	}
};

export const reportsReducer = combineReducers({
	downloadReport: downloadReportReducer,
});
