import Button from "antd/es/button";
import Col from "antd/es/col";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Row from "antd/es/row";
import Select from "antd/es/select";
import { FormInstance } from "antd/lib/form";
import React from "react";
import DropdownRender from "__common/DropdownRender";
import { FloatLabel } from "__common/FloatLabel";
import { StructureType } from "__pages/FundDetails/__redux/types";
import * as lang from "__utils/lang";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { API_ROOT } from "__utils/constants";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { debounce } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import cx from "classnames";
import { ObjectType } from "__utils/types";
import { toTitleCase } from "../../__utils/helpers";
import * as fundLang from "../../__utils/lang";
import { Container } from "./style";

const FUND_ORIGIN_CHOICES = ["New", "Existing"];
const FUND_TYPE_CHOICES = ["Individual", "Corporate"];
const JURISDICTION_CHOICES = ["TAS", "VIC", "NSW", "QLD", "NT", "SA", "WA", "ACT"];

const setDependentFields = (form: FormInstance): void => {
	const { getFieldValue, setFieldsValue } = form;

	let _acn: string = getFieldValue(fundLang.FUND_STRUCTURE_ACN_NAME);

	let _companyName: string = getFieldValue(fundLang.FUND_STRUCTURE_COMPANY_NAME_NAME);
	const fundType: string = getFieldValue(fundLang.FUND_STRUCTURE_TRUSTEE_NAME);
	const fundOrigin: string = getFieldValue(fundLang.FUND_STRUCTURE_ORIGIN_NAME);

	if (fundType !== "Corporate" || fundOrigin !== "Existing") {
		_acn = "";
	}

	if (fundType !== "Corporate") {
		_companyName = "";
	}

	setFieldsValue({
		acn: _acn,
		companyName: _companyName,
	});
};

type Props = {
	form: FormInstance;
	fundStructure: StructureType;
	isLoading: boolean;
	disableSubmitButton?: boolean;
	submitButtonLabel?: string;
	undoButtonLabel?: string;
	cancelButtonLabel?: string;
	isDisabled?: boolean;
	showABN?: boolean;
	showACN?: boolean;
	abnDisable?: boolean;
	acnDisable?: boolean;
	onValuesChange?: (values: any) => void;
	onFinish?: (values: any) => void;
	onUndo?: () => void;
	onCancel?: () => void;
	onTypeOrOriginOrAcnChange?: () => any;
};

const FundStructureForm: React.FC<Props> = ({
	form,
	fundStructure,
	isLoading,
	disableSubmitButton,
	submitButtonLabel,
	undoButtonLabel,
	cancelButtonLabel,
	isDisabled = false,
	showABN = false,
	showACN = false,
	abnDisable = false,
	acnDisable = false,
	onValuesChange,
	onFinish,
	onUndo,
	onCancel,
	onTypeOrOriginOrAcnChange,
}: Props) => {
	const { getFieldValue, setFieldsValue, setFields } = form;
	const { fundType, fundOrigin } = fundStructure;
	const axios = getAxiosInstance(API_ROOT);
	const [_fundType, setFundType] = React.useState(fundType);
	const [lookup, setLookup] = React.useState<ObjectType>({
		isCheckingCompany: false,
		isNotRegistered: false,
		isCheckingSuccess: false,
	});

	const typeOnChange = (): void => {
		setFundType(toTitleCase(getFieldValue(fundLang.FUND_STRUCTURE_TRUSTEE_NAME)));
		setDependentFields(form);
		if (onTypeOrOriginOrAcnChange) {
			onTypeOrOriginOrAcnChange();
		}
	};

	const companyNameLookUp = React.useCallback((): void => {
		const companyName = getFieldValue(fundLang.FUND_STRUCTURE_COMPANY_NAME_NAME);

		if (companyName) {
			setLookup({ isCheckingCompany: true, isNotRegistered: false });
			axios
				.get(`/registration/check_company_code/${companyName}/`)
				.then((res) => {
					const { status } = res;

					if (status === 200) {
						setLookup({ isCheckingCompany: false, isNotRegistered: true, isCheckingSuccess: true });
					}
				})
				.catch((e: any) => {
					errorHandler(e);
					const errorResponse = e.response.data.detail;

					setFields([
						{
							name: "asicCompanyName",
							errors: [errorResponse],
						},
					]);
					setLookup({ isCheckingCompany: false, isNotRegistered: false, isCheckingSuccess: true });
				});

			setFieldsValue({ asicCompanyName: `${companyName.toUpperCase()} PTY LTD` });
		}
	}, [axios, setFields, setFieldsValue, getFieldValue]);

	const tickIcon = (): React.ReactNode => {
		const getCompanyName = getFieldValue(fundLang.FUND_STRUCTURE_COMPANY_NAME_NAME);
		const { isCheckingCompany, isNotRegistered, isCheckingSuccess } = lookup;

		if (getCompanyName === "") return null;
		if (getCompanyName === undefined) return null;
		if (isCheckingCompany) {
			return <LoadingOutlined className="icon-loading" />;
		}
		if (isNotRegistered && isCheckingSuccess) {
			return <AiFillCheckCircle className="icon-check" />;
		}
		if (!isNotRegistered && isCheckingSuccess) {
			return <AiFillCloseCircle className="icon-close" />;
		}

		return null;
	};

	// use to set once the user stop typing
	const debouncedChangeHandler = React.useMemo(() => debounce(() => companyNameLookUp(), 750), [companyNameLookUp]);

	const onCompanyNameChange = (companyName: string): void => {
		setLookup({ isCheckingCompany: false, isNotRegistered: false, isCheckingSuccess: false });

		if (!companyName) {
			setFieldsValue({ asicCompanyName: "" });
			setLookup({
				isCheckingCompany: false,
				isNotRegistered: false,
				isCheckingSuccess: false,
			});
		} else {
			debouncedChangeHandler();
		}
	};

	React.useEffect(() => {
		if (_fundType === "Individual") {
			setFieldsValue({ asicCompanyName: `` });
		}
	}, [setFieldsValue, _fundType]);

	return (
		<Container isDisabled={isDisabled}>
			<Form
				onFinish={onFinish}
				onValuesChange={onValuesChange}
				initialValues={fundOrigin ? fundStructure : []}
				form={form}
			>
				<Row gutter={[16, 8]}>
					<Col xs={24} xl={12}>
						<FloatLabel
							label={fundLang.FUND_STRUCTURE_ORIGIN_LABEL}
							getValue={() => getFieldValue(fundLang.FUND_STRUCTURE_ORIGIN_NAME)}
						>
							<Form.Item
								name={fundLang.FUND_STRUCTURE_ORIGIN_NAME}
								rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
							>
								<Select
									getPopupContainer={(trigger: any) => trigger.parentElement}
									dropdownRender={(menu: any) => <DropdownRender>{menu}</DropdownRender>}
									disabled={isDisabled}
									onChange={onTypeOrOriginOrAcnChange}
									autoFocus={!fundOrigin}
									showAction={["click", "focus"]}
								>
									{FUND_ORIGIN_CHOICES.map((item: string) => (
										<Select.Option value={item} key={item}>
											{item}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</FloatLabel>
					</Col>

					<Col xs={24} xl={12}>
						<FloatLabel
							label={fundLang.FUND_STRUCTURE_TRUSTEE_LABEL}
							getValue={() => getFieldValue(fundLang.FUND_STRUCTURE_TRUSTEE_NAME)}
						>
							<Form.Item
								name={fundLang.FUND_STRUCTURE_TRUSTEE_NAME}
								rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
							>
								<Select
									getPopupContainer={(trigger: any) => trigger.parentElement}
									dropdownRender={(menu: any) => <DropdownRender>{menu}</DropdownRender>}
									onChange={typeOnChange}
									disabled={isDisabled}
									showAction={["click", "focus"]}
								>
									{FUND_TYPE_CHOICES.map((item: string) => (
										<Select.Option value={item} key={item}>
											{item}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</FloatLabel>
					</Col>
				</Row>

				<Row gutter={[16, 8]}>
					<Col xs={24} xl={12}>
						<FloatLabel
							label={fundLang.FUND_STRUCTURE_SMSF_NAME_LABEL}
							getValue={() => getFieldValue(fundLang.FUND_STRUCTURE_SMSF_NAME_NAME)}
						>
							<Form.Item
								name={fundLang.FUND_STRUCTURE_SMSF_NAME_NAME}
								rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
							>
								<Input disabled={isDisabled} autoComplete="off" />
							</Form.Item>
						</FloatLabel>
					</Col>

					<Col xs={24} xl={12}>
						<FloatLabel
							label={fundLang.FUND_STRUCTURE_JURISDICTION_LABEL}
							getValue={() => getFieldValue(fundLang.FUND_STRUCTURE_JURISDICTION_NAME)}
						>
							<Form.Item
								name={fundLang.FUND_STRUCTURE_JURISDICTION_NAME}
								rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
							>
								<Select
									getPopupContainer={(trigger: any) => trigger.parentElement}
									dropdownRender={(menu: any) => <DropdownRender>{menu}</DropdownRender>}
									disabled={isDisabled}
									showAction={["click", "focus"]}
									showSearch
								>
									{JURISDICTION_CHOICES.map((item: string) => (
										<Select.Option value={item} key={item}>
											{item}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</FloatLabel>
					</Col>
				</Row>

				<Row gutter={[16, 8]}>
					{showACN && (
						<Col xs={24} xl={12}>
							<FloatLabel
								label={fundLang.FUND_STRUCTURE_ACN_LABEL}
								getValue={() => getFieldValue(fundLang.FUND_STRUCTURE_ACN_NAME)}
							>
								<Form.Item
									name={fundLang.FUND_STRUCTURE_ACN_NAME}
									rules={[
										{
											min: 9,
											message: "ACN should be 9 digits only.",
										},
										{
											max: 9,
											message: "ACN should be 9 digits only.",
										},
										{
											required: false,
										},
									]}
								>
									<Input
										disabled={isDisabled || acnDisable}
										autoComplete="off"
										onChange={onTypeOrOriginOrAcnChange}
									/>
								</Form.Item>
							</FloatLabel>
						</Col>
					)}
					{showABN && (
						<Col xs={24} xl={12}>
							<FloatLabel
								label={fundLang.FUND_STRUCTURE_ABN_LABEL}
								getValue={() => getFieldValue(fundLang.FUND_STRUCTURE_ABN_NAME)}
							>
								<Form.Item
									name={fundLang.FUND_STRUCTURE_ABN_NAME}
									rules={[
										{
											min: 11,
											message: "ABN should be 11 digits only.",
										},
										{
											max: 11,
											message: "ABN should be 11 digits only.",
										},
										{
											required: false,
										},
									]}
								>
									<Input disabled={isDisabled || abnDisable} autoComplete="off" />
								</Form.Item>
							</FloatLabel>
						</Col>
					)}
				</Row>

				{_fundType === "Corporate" && (
					<Row gutter={[16, 8]}>
						<Col xs={24} xl={12}>
							<FloatLabel
								label={fundLang.FUND_STRUCTURE_COMPANY_NAME_LABEL}
								getValue={() => getFieldValue(fundLang.FUND_STRUCTURE_COMPANY_NAME_NAME)}
							>
								<Form.Item
									name={fundLang.FUND_STRUCTURE_COMPANY_NAME_NAME}
									rules={[{ required: false }]}
								>
									<Input
										disabled={isDisabled}
										autoComplete="off"
										onChange={(e) => onCompanyNameChange(e.target.value)}
									/>
								</Form.Item>
							</FloatLabel>
						</Col>

						<Col xs={24} xl={12}>
							<div
								className={cx("tick-icon", {
									"error-mode": !lookup.isNotRegistered && lookup.isCheckingSuccess,
								})}
							>
								{tickIcon()}
							</div>
							<FloatLabel
								label={fundLang.FUND_STRUCTURE_ASIC_REGISTERED_NAME_LABEL}
								getValue={() => getFieldValue(fundLang.FUND_STRUCTURE_ASIC_REGISTERED_NAME_NAME)}
							>
								<Form.Item
									name={fundLang.FUND_STRUCTURE_ASIC_REGISTERED_NAME_NAME}
									rules={[{ required: false }]}
								>
									<Input autoComplete="off" readOnly />
								</Form.Item>
							</FloatLabel>
						</Col>
					</Row>
				)}

				{!isDisabled && (
					<Row
						gutter={{
							xs: 8,
							sm: 16,
							md: 24,
							lg: 24,
						}}
					>
						<Col span={12} />
						<Col span={12}>
							<div className="fund-structure-form-buttons-wrapper">
								{onCancel && <Button onClick={onCancel}>{cancelButtonLabel || "Cancel"}</Button>}
								{onUndo && (
									<Button
										disabled={disableSubmitButton}
										onClick={() => {
											onUndo();

											setFundType(fundType);
										}}
									>
										{undoButtonLabel || "Undo"}
									</Button>
								)}
								{onFinish && (
									<Button
										type="primary"
										htmlType="submit"
										disabled={disableSubmitButton || isLoading}
										loading={isLoading}
									>
										{submitButtonLabel || "Save"}
									</Button>
								)}
							</div>
						</Col>
					</Row>
				)}
			</Form>
		</Container>
	);
};

export default FundStructureForm;
