export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS";
export const ADD_ACCOUNT_FAILURE = "ADD_ACCOUNT_FAILURE";
export const ADD_ACCOUNT_RESET = "ADD_ACCOUNT_RESET";

export const EDIT_ACCOUNT = "EDIT_ACCOUNT";
export const EDIT_ACCOUNT_SUCCESS = "EDIT_ACCOUNT_SUCCESS";
export const EDIT_ACCOUNT_FAILURE = "EDIT_ACCOUNT_FAILURE";
export const EDIT_ACCOUNT_RESET = "EDIT_ACCOUNT_RESET";

export const ARCHIVE_FUND_DETAILS = "ARCHIVE_FUND_DETAILS";
export const ARCHIVE_FUND_DETAILS_SUCCESS = "ARCHIVE_FUND_DETAILS_SUCCESS";
export const ARCHIVE_FUND_DETAILS_FAILURE = "ARCHIVE_FUND_DETAILS_FAILURE";
export const ARCHIVE_FUND_DETAILS_RESET = "ARCHIVE_FUND_DETAILS_RESET";

export const RESTORE_FUND_DETAILS = "RESTORE_FUND_DETAILS";
export const RESTORE_FUND_DETAILS_SUCCESS = "RESTORE_FUND_DETAILS_SUCCESS";
export const RESTORE_FUND_DETAILS_FAILURE = "RESTORE_FUND_DETAILS_FAILURE";
export const RESTORE_FUND_DETAILS_RESET = "RESTORE_FUND_DETAILS_RESET";

export const DELETE_FUND_DETAILS = "DELETE_FUND_DETAILS";
export const DELETE_FUND_DETAILS_SUCCESS = "DELETE_FUND_DETAILS_SUCCESS";
export const DELETE_FUND_DETAILS_FAILURE = "DELETE_FUND_DETAILS_FAILURE";
export const DELETE_FUND_DETAILS_RESET = "DELETE_FUND_DETAILS_RESET";

export const DEACTIVATE_FUND_DETAILS = "DEACTIVATE_FUND_DETAILS";
export const DEACTIVATE_FUND_DETAILS_SUCCESS = "DEACTIVATE_FUND_DETAILS_SUCCESS";
export const DEACTIVATE_FUND_DETAILS_FAILURE = "DEACTIVATE_FUND_DETAILS_FAILURE";
export const DEACTIVATE_FUND_DETAILS_RESET = "DEACTIVATE_FUND_DETAILS_RESET";

export const OVERRIDE_LOW_BALANCE_FLAG = "OVERRIDE_LOW_BALANCE_FLAG";
export const OVERRIDE_LOW_BALANCE_FLAG_SUCCESS = "OVERRIDE_LOW_BALANCE_FLAG_SUCCESS";
export const OVERRIDE_LOW_BALANCE_FLAG_FAILURE = "OVERRIDE_LOW_BALANCE_FLAG_FAILURE";
export const OVERRIDE_LOW_BALANCE_FLAG_RESET = "OVERRIDE_LOW_BALANCE_FLAG_RESET";

export const GET_LIVE_FUND_VALUE = "GET_LIVE_FUND_VALUE";
export const GET_LIVE_FUND_VALUE_SUCCESS = "GET_LIVE_FUND_VALUE_SUCCESS";
export const GET_LIVE_FUND_VALUE_FAILURE = "GET_LIVE_FUND_VALUE_FAILURE";
export const GET_LIVE_FUND_VALUE_RESET = "GET_LIVE_FUND_VALUE_RESET";
