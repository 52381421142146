import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { EditTagsPayloadType } from "../__redux/types";
import { EDIT_TAGS } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const editTagsApi = async ({ id, tags }: EditTagsPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = EDIT_TAGS.replace(":id", `${id}`);

	return axios.post(url, tags).catch(errorHandler);
};
