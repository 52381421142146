import React from "react";
import Spinner from "__common/Spinner";
import { Container } from "./style";

type Props = {
	isUserAuthVerfied: boolean;
};

const AuthLoader: React.FC<Props> = ({ isUserAuthVerfied }: Props) => (
	<Container>
		{isUserAuthVerfied && <div className="header" />}
		<div className="content">
			<Spinner fontSize={24} />
		</div>
	</Container>
);

export default AuthLoader;
