import cx from "classnames";
import React from "react";
import { LinkStyled, SpanStyled } from "./style";

type Props = {
	children: React.ReactNode;
	type?: string;
	to?: string;
	className?: string;
	disabled?: boolean;
	onClick?: (e: React.MouseEvent) => void;
	tooltip?: string;
	external?: boolean;
	propagateEvent?: boolean;
};

const Clickable: React.FC<Props> = ({
	onClick,
	className,
	disabled = false,
	to = "",
	type = "",
	children,
	tooltip = "",
	external = false,
	propagateEvent = false,
}: Props) => {
	const _className: string = cx(className, { disabled });

	const _onClick = (e: React.MouseEvent): void => {
		if (!propagateEvent) {
			e.stopPropagation();
		}
		if (!disabled && onClick) {
			onClick(e);
		}
	};

	if (type === "link") {
		if (external) {
			return (
				<a className={_className} href={to} title={tooltip}>
					{children}
				</a>
			);
		}
		return (
			<LinkStyled className={_className} to={to} title={tooltip}>
				{children}
			</LinkStyled>
		);
	}

	return (
		<SpanStyled className={_className} onClick={_onClick} title={tooltip}>
			{children}
		</SpanStyled>
	);
};

export default Clickable;
