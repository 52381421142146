import * as actionTypes from "./actionTypes";
import { EditTagsActionTypes, editTagsDefaultValue, EditTagsType } from "./types";

export const editTagsReducer = (state = editTagsDefaultValue, action: EditTagsActionTypes): EditTagsType => {
	switch (action.type) {
		case actionTypes.EDIT_TAGS:
			return {
				...state,
				isEditingTags: true,
			};
		case actionTypes.EDIT_TAGS_IN_UI:
			return {
				...state,
				isEditingTagsInUi: (action as any).payload,
				isEditingTags: false,
				isEditingTagsError: false,
				isEditingTagsSuccess: false,
			};
		case actionTypes.EDIT_TAGS_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isEditingTags: false,
				isEditingTagsInUi: false,
				isEditingTagsError: false,
				isEditingTagsSuccess: true,
			};
		case actionTypes.EDIT_TAGS_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isEditingTags: false,
				isEditingTagsInUi: false,
				isEditingTagsError: true,
				isEditingTagsSuccess: false,
			};
		case actionTypes.EDIT_TAGS_RESET:
			return {
				...state,
				...editTagsDefaultValue,
			};
		default:
			return state;
	}
};
