import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

import { AddRecurringPaymentActionTypes, AddRecurringPaymentType, addRecurringPaymentDefaultValue } from "./types";

export const addRecurringPaymentReducer = (
	state = addRecurringPaymentDefaultValue,
	action: AddRecurringPaymentActionTypes
): AddRecurringPaymentType => {
	switch (action.type) {
		case actionTypes.ADD_RECURRING_PAYMENT:
			return {
				...state,
				isAddingRecurringPayment: true,
			};
		case actionTypes.ADD_RECURRING_PAYMENT_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isAddingRecurringPayment: false,
				isAddRecurringPaymentSuccess: true,
				isAddRecurringPaymentError: false,
			};
		case actionTypes.ADD_RECURRING_PAYMENT_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isAddingRecurringPayment: false,
				isAddRecurringPaymentSuccess: false,
				isAddRecurringPaymentError: true,
			};

		case actionTypes.ADD_RECURRING_PAYMENT_RESET:
			return {
				...state,
				...addRecurringPaymentDefaultValue,
			};

		default:
			return state;
	}
};

export const recurringDebitsReducer = combineReducers({
	addRecurringPayment: addRecurringPaymentReducer,
});
