import styled from "styled-components";

type Props = {
	isDisabled: boolean;
	level: boolean;
};

export const Container = styled.div<Props>`
	.ant-input {
		border-radius: 0;
	}

	.ant-select {
		width: 100%;
	}

	${(p) =>
		p.isDisabled &&
		` 
        .ant-input:hover {
            border-color: rgba(255, 255, 255, 0.5) !important;
        }

    `}
	${(p) =>
		!p.level &&
		` 
        .brand-column {
            display: none;
        }

    `}
`;
