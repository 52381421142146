import { isEmpty } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import AuthLoader from "__layout/AuthLoader";
import NotAuthorized from "__pages/NotAuthorized";
import { userProfileSlr } from "__pages/User/__redux/selectors";
import { UserProfileType } from "__pages/User/__redux/types";
import { RouteType } from "__utils/types";

type Props = RouteType;

const PrivateRoute: React.FC<Props> = ({ accessLevels, path, routes, component: Component }: Props) => {
	const userProfile: UserProfileType = useSelector(userProfileSlr);
	const { isGetting } = userProfile;

	if (isGetting) {
		return <AuthLoader isUserAuthVerfied />;
	}

	return (
		<Route
			path={path}
			render={(props) => {
				if (isEmpty(userProfile)) {
					// Not logged in so redirect to login page with the return url
					return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
				}

				// Check if route is restricted by access level
				if (accessLevels?.length && accessLevels.indexOf(userProfile.level) === -1) {
					// Not authorized so redirect to default page
					return <NotAuthorized />;
				}

				// Authorized so return component
				return <Component {...props} routes={routes} />;
			}}
		/>
	);
};

export default PrivateRoute;
