// Re-usable style values
export const commonValues: { [key: string]: any } = {
	primaryColor: "#4538d1",
	textColor: "rgba(77, 71, 102, 1)",
	headingColor: "rgba(77, 71, 102, .75)",
	textColorSecondary: "rgba(22, 15, 113, 1)",
	textColorColorTertiary: "rgba(70, 56, 208, 1)",
	disabledTextColor: "rgba(0, 0, 0, .55)",
	backgroundColor: "#F5F5F5",
	menuItemActiveHoverColor: "#3b4755",
	borderColor: "#e6e6e6",
	borderRadius: "4px",
	animationDuration: "0.2s",
	iconBackgroundColor: "#66707b",
	transition: (props: string) => `${props || "all"} 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)`,
	dropdownBorderColor: "#d7d7d7",
	defaultFontColor: "rgba(0, 0, 0, 0.85);",
	defaultFontSize: "13px",
	boxShadow:
		"0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)",
	errorColor: "#ff4d4f",
};
