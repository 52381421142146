export const GET_FUND_DETAILS = "GET_FUND_DETAILS";
export const GET_FUND_DETAILS_SUCCESS = "GET_FUND_DETAILS_SUCCESS";
export const GET_FUND_DETAILS_FAILURE = "GET_FUND_DETAILS_FAILURE";
export const GET_FUND_DETAILS_RESET = "GET_FUND_DETAILS_RESET";
export const GET_FUND_DETAILS_CANCEL = "GET_FUND_DETAILS_CANCEL";

// For establish fund modal
export const GET_FUND_DETAILS_FOR_MODAL = "GET_FUND_DETAILS_FOR_MODAL";
export const GET_FUND_DETAILS_FOR_MODAL_SUCCESS = "GET_FUND_DETAILS_FOR_MODAL_SUCCESS";
export const GET_FUND_DETAILS_FOR_MODAL_FAILURE = "GET_FUND_DETAILS_FOR_MODAL_FAILURE";
export const GET_FUND_DETAILS_FOR_MODAL_RESET = "GET_FUND_DETAILS_FOR_MODAL_RESET";

export const CREATE_FUND = "CREATE_FUND";
export const CREATE_FUND_SUCCESS = "CREATE_FUND_SUCCESS";
export const CREATE_FUND_FAILURE = "CREATE_FUND_FAILURE";
export const CREATE_FUND_RESET = "CREATE_FUND_RESET";

export const SET_FUND_TO_RESUME = "SET_FUND_TO_RESUME";
export const SET_FUND_TO_RESUME_RESET = "SET_FUND_TO_RESUME_RESET";
