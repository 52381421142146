import {
	AddTemplateType,
	EditTemplateType,
	GetTemplateCategoriesType,
	GetTemplatesType,
	DeleteTemplateType,
	GetTemplateDetailsType,
} from "./types";

export const addTemplateSlr = (state: any): AddTemplateType => state.template.addTemplate;
export const editTemplateSlr = (state: any): EditTemplateType => state.template.editTemplate;
export const getTemplateCategoriesSlr = (state: any): GetTemplateCategoriesType => state.template.getTemplateCategories;
export const getTemplatesSlr = (state: any): GetTemplatesType => state.template.getTemplates;
export const deleteTemplateSlr = (state: any): DeleteTemplateType => state.template.deleteTemplate;
export const getTemplateDetailsSlr = (state: any): GetTemplateDetailsType => state.template.getTemplateDetails;
