import { LoadingOutlined } from "@ant-design/icons";
import Spin from "antd/es/spin";
import React from "react";
import { Container } from "./style";

type Props = {
	fontSize: number;
	className?: string;
};

const Spinner: React.FC<Props> = ({ fontSize, className, ...rest }: Props) => (
	<Container className={className}>
		<Spin indicator={<LoadingOutlined style={{ fontSize }} spin />} {...rest} />
	</Container>
);

export default React.memo(Spinner);
