import * as actionTypes from "./actionTypes";

/**
 * Download Report
 */

export type DownloadReportType = {
	readonly names: Array<string>;
	readonly isDownloadingReport: { [key: string]: boolean };
	readonly isDownloadReportSuccess: { [key: string]: boolean };
	readonly isDownloadReportError: { [key: string]: boolean };
	readonly downloadReportError: { [key: string]: string };
};

export const downloadReportDefaultValue: DownloadReportType = {
	names: [],
	isDownloadingReport: {},
	isDownloadReportSuccess: {},
	isDownloadReportError: {},
	downloadReportError: {},
};

export type DownloadReportPayloadType = {
	readonly name: string;
	readonly fileName: string;
	readonly url: string;
};

export type DownloadReportCtrType = {
	(payload: DownloadReportPayloadType): DownloadReportActionTypes;
};

type DownloadReportAction = {
	type: typeof actionTypes.DOWNLOAD_REPORT;
	payload: DownloadReportPayloadType;
};

type DownloadReportSuccessAction = {
	type: typeof actionTypes.DOWNLOAD_REPORT_SUCCESS;
};

type DownloadReportFailureAction = {
	type: typeof actionTypes.DOWNLOAD_REPORT_FAILURE;
};

type DownloadReportResetAction = {
	type: typeof actionTypes.DOWNLOAD_REPORT_RESET;
};

export type DownloadReportActionTypes =
	| DownloadReportAction
	| DownloadReportSuccessAction
	| DownloadReportFailureAction
	| DownloadReportResetAction;
