import { ObjectType, ApiErrorType } from "__utils/types";
import * as actionTypes from "./actionTypes";
import { TagsType } from "../FundPreviewPane/Account/Tags/AddRemoveTagsModal/__redux/types";

export type RequestSummaryDetailsType = {
	readonly title: string;
	readonly state: string;
	readonly requested: number;
	readonly completed: number;
	readonly alert: string;
};

export type RequestSummaryType = Array<RequestSummaryDetailsType>;

export type RequestStatusesType = { [key: string]: RequestSummaryType };

export type RequestStatusesDataType = {
	readonly oldHash: number;
	readonly hash: number;
	readonly requestStatuses: RequestStatusesType;
};

export type IAlertType = {
	[key: string]: {
		readonly name: string;
		readonly color: string;
		readonly url: string;
	};
};
export type IAlert = {
	readonly alertType: IAlertType;
	readonly color: string;
};

export type IStatus = "Active" | "Inactive" | "Archived";

export interface FundDetailsType extends ObjectType {
	readonly id: number;
	readonly status: IStatus;
	readonly fullName: string[];
	readonly fundCode: string;
	readonly fundName: string;
	readonly phone: string[];
	readonly email: string[];
	readonly brand: string;
	readonly workflow: string | null;
	readonly state: string | null;
	readonly origin: string;
	readonly type: string;
	readonly fundValue: number;
	readonly favourite: boolean;
	readonly resumable: boolean;
	readonly tags: TagsType;
	readonly requests: RequestSummaryType;
	readonly alert: IAlert;
	readonly classUrl: string;
	readonly hubspotUrl: string;
	readonly balanceIndicator: string;
	readonly hasTrust: boolean;
}

export type FundsType = Array<FundDetailsType>;

export type IFundsById = { [key: string]: FundDetailsType };

/**
 * Get funds
 */
export interface GetFundsType {
	readonly funds: FundsType; // All funds coming from db
	readonly byId: IFundsById; // For quick lookup
	readonly brands: string[];
	readonly gettingFunds: boolean;
	readonly isOnPoll: boolean;
	readonly newlyAdded?: FundsType;
	readonly newlyAddedById?: IFundsById;
	readonly withAnyFieldChanged?: FundsType; // Any change
	readonly withAnyFieldChangedById?: IFundsById; // Any change
	readonly withNewState?: FundsType;
	readonly getFundsFailure?: boolean;
}

export const getFundsDefaultValue: GetFundsType = {
	funds: [],
	byId: {},
	newlyAdded: [],
	withNewState: [],
	withAnyFieldChanged: [],
	brands: [],
	isOnPoll: false,
	gettingFunds: false,
	getFundsFailure: false,
};

export type GetFundsCtrType = {
	(): GetFundsActionTypes;
};

export type GetFundsResetCtrType = {
	(): GetFundsActionTypes;
};

export type GetFundsPayloadType = {
	readonly isOnPoll: boolean;
};

type GetFundsAction = {
	type: typeof actionTypes.GET_FUNDS;
	payload: GetFundsPayloadType;
};

type GetFundsSuccessAction = {
	type: typeof actionTypes.GET_FUNDS_SUCCESS;
};

type GetFundsFailureAction = {
	type: typeof actionTypes.GET_FUNDS_FAILURE;
};

type GetFundsResetAction = {
	type: typeof actionTypes.GET_FUNDS_RESET;
};

type GetFundsCancelAction = {
	type: typeof actionTypes.GET_FUNDS_CANCEL;
};

type GetFundsNoopAction = {
	type: typeof actionTypes.GET_FUNDS_NOOP;
};

export type GetFundsActionTypes =
	| GetFundsAction
	| GetFundsSuccessAction
	| GetFundsFailureAction
	| GetFundsResetAction
	| GetFundsCancelAction
	| GetFundsNoopAction;

/**
 * Delete fund
 */
export type DeleteFundType = {
	readonly id: number;
	readonly isDeleting: boolean;
	readonly isDeleteSuccess: boolean;
	readonly isDeleteError: boolean;
};

export const deleteFundDefaultValue: DeleteFundType = {
	id: 0,
	isDeleting: false,
	isDeleteSuccess: false,
	isDeleteError: false,
};

export type DeleteFundPayloadType = {
	id: number;
};

export type DeleteFund = {
	(payload: DeleteFundPayloadType): DeleteFundActionTypes;
};

export type DeleteFundAction = {
	type: typeof actionTypes.DELETE_FUND;
	payload: DeleteFundPayloadType;
};

export type DeleteFundSuccessAction = {
	type: typeof actionTypes.DELETE_FUND_SUCCESS;
};

export type DeleteFundFailureAction = {
	type: typeof actionTypes.DELETE_FUND_FAILURE;
};

export type DeleteFundResetAction = {
	type: typeof actionTypes.DELETE_FUND_RESET;
};

export type DeleteFundActionTypes =
	| DeleteFundAction
	| DeleteFundSuccessAction
	| DeleteFundFailureAction
	| DeleteFundResetAction;

/**
 * Archive fund
 */
export type ArchiveFundType = {
	readonly id: number;
	readonly isArchiving: boolean;
	readonly isArchiveSuccess: boolean;
	readonly isArchiveError: boolean;
};

export const archiveFundDefaultValue: ArchiveFundType = {
	id: 0,
	isArchiving: false,
	isArchiveSuccess: false,
	isArchiveError: false,
};

export type ArchiveFundPayloadType = {
	id: number;
};

export type ArchiveFund = {
	(payload: ArchiveFundPayloadType): ArchiveFundActionTypes;
};

export type ArchiveFundAction = {
	type: typeof actionTypes.ARCHIVE_FUND;
	payload: ArchiveFundPayloadType;
};

export type ArchiveFundSuccessAction = {
	type: typeof actionTypes.ARCHIVE_FUND_SUCCESS;
};

export type ArchiveFundFailureAction = {
	type: typeof actionTypes.ARCHIVE_FUND_FAILURE;
};

export type ArchiveFundResetAction = {
	type: typeof actionTypes.ARCHIVE_FUND_RESET;
};

export type ArchiveFundActionTypes =
	| ArchiveFundAction
	| ArchiveFundSuccessAction
	| ArchiveFundFailureAction
	| ArchiveFundResetAction;

/**
 * Set fund as favourite
 */

export type FavouriteFundType = {
	readonly id: number;
	readonly isError: boolean;
};

export const favouriteFundDefaultValue: FavouriteFundType = {
	id: 0,
	isError: false,
};

export type FavouriteFundPayloadType = {
	id: number;
	isFavourite: boolean;
};

export type FavouriteFund = {
	(payload: FavouriteFundPayloadType): FavouriteFundActionTypes;
};

export type FavouriteFundAction = {
	type: typeof actionTypes.FAVORITE_FUND;
	payload: FavouriteFundPayloadType;
};

export type FavouriteFundSucessAction = {
	type: typeof actionTypes.FAVORITE_FUND_SUCCESS;
};

export type FavouriteFundFailureAction = {
	type: typeof actionTypes.FAVORITE_FUND_FAILURE;
};

export type FavouriteFundActionTypes = FavouriteFundAction | FavouriteFundSucessAction | FavouriteFundFailureAction;

/**
 * Restore fund
 */
export type RestoreFundType = {
	readonly id: number;
	readonly isRestoring: boolean;
	readonly isRestoreSuccess: boolean;
	readonly isRestoreError: boolean;
};

export const restoreFundDefaultValue: RestoreFundType = {
	id: 0,
	isRestoring: false,
	isRestoreSuccess: false,
	isRestoreError: false,
};

export type RestoreFundPayloadType = {
	id: number;
};

export type RestoreFund = {
	(payload: RestoreFundPayloadType): RestoreFundActionTypes;
};

export type RestoreFundAction = {
	type: typeof actionTypes.RESTORE_FUND;
	payload: RestoreFundPayloadType;
};

export type RestoreFundSuccessAction = {
	type: typeof actionTypes.RESTORE_FUND_SUCCESS;
};

export type RestoreFundFailureAction = {
	type: typeof actionTypes.RESTORE_FUND_FAILURE;
};

export type RestoreFundResetAction = {
	type: typeof actionTypes.RESTORE_FUND_RESET;
};

export type RestoreFundActionTypes =
	| RestoreFundAction
	| RestoreFundSuccessAction
	| RestoreFundFailureAction
	| RestoreFundResetAction;

//  Deactivate fund

export type DeactivateFundType = {
	readonly id: number;
	readonly isDeactivating: boolean;
	readonly isDeactivateSuccess: boolean;
	readonly isDeactivateError: boolean;
};

export const deactivateFundDefaultValue: DeactivateFundType = {
	id: 0,
	isDeactivating: false,
	isDeactivateSuccess: false,
	isDeactivateError: false,
};

export type DeactivateFundPayloadType = {
	id: number;
};

export type DeactivateFund = {
	(payload: DeactivateFundPayloadType): DeactivateFundActionTypes;
};

export type DeactivateFundAction = {
	type: typeof actionTypes.DEACTIVATE_FUND;
	payload: DeactivateFundPayloadType;
};

export type DeactivateFundSuccessAction = {
	type: typeof actionTypes.DEACTIVATE_FUND_SUCCESS;
};

export type DeactivateFundFailureAction = {
	type: typeof actionTypes.DEACTIVATE_FUND_FAILURE;
};

export type DeactivateFundResetAction = {
	type: typeof actionTypes.DEACTIVATE_FUND_RESET;
};

export type DeactivateFundActionTypes =
	| DeactivateFundAction
	| DeactivateFundSuccessAction
	| DeactivateFundFailureAction
	| DeactivateFundResetAction;

/**
 * Get requests
 */
export interface GetRequestSummaryType {
	readonly requestStatusesData: RequestStatusesDataType;
	readonly isGettingRequestSummary: boolean;
	readonly isGetRequestSummarySuccess: boolean;
	readonly isGetRequestSummaryError: boolean;
}

export const getRequestSummaryDefaultValue: GetRequestSummaryType = {
	requestStatusesData: {
		oldHash: 0,
		hash: 0,
		requestStatuses: {},
	},
	isGettingRequestSummary: false,
	isGetRequestSummarySuccess: false,
	isGetRequestSummaryError: false,
};

export type GetRequestSummaryCtrType = {
	(): GetRequestSummaryActionTypes;
};

export type GetRequestSummaryResetCtrType = {
	(): GetRequestSummaryActionTypes;
};

type GetRequestSummaryAction = {
	type: typeof actionTypes.GET_REQUEST_SUMMARY;
};

type GetRequestSummarySuccessAction = {
	type: typeof actionTypes.GET_REQUEST_SUMMARY_SUCCESS;
};

type GetRequestSummaryFailureAction = {
	type: typeof actionTypes.GET_REQUEST_SUMMARY_FAILURE;
};

type GetRequestSummaryResetAction = {
	type: typeof actionTypes.GET_REQUEST_SUMMARY_RESET;
};

type GetRequestSummaryCancelAction = {
	type: typeof actionTypes.GET_REQUEST_SUMMARY_CANCEL;
};

export type GetRequestSummaryActionTypes =
	| GetRequestSummaryAction
	| GetRequestSummarySuccessAction
	| GetRequestSummaryFailureAction
	| GetRequestSummaryResetAction
	| GetRequestSummaryCancelAction;

/**
 * Get SMSF Complying funds count
 */
export type IGetSMSFComplyingFundsCount = {
	readonly smsfComplyingCount: number;
	readonly smsfNotComplyingCount: number;
	readonly superRolloverReceivedCount: number;
	readonly gettingSMFComplyingFundsCount: boolean;
	readonly getSMSFComplyingFundsCountSuccess: boolean;
	readonly getSMSFComplyingFundsCountFailure: boolean;
};

export const getSMSFComplyingFundsCountDefaultValue = {
	smsfComplyingCount: 0,
	smsfNotComplyingCount: 0,
	superRolloverReceivedCount: 0,
	gettingSMFComplyingFundsCount: false,
	getSMSFComplyingFundsCountSuccess: false,
	getSMSFComplyingFundsCountFailure: false,
};

export type IGetSMSFComplyingFundsCountCtr = {
	(): IGetSMSFComplyingFundsCountActions;
};

type IGetSMSFComplyingFundsCountAction = {
	type: typeof actionTypes.GET_SMSF_COMPLYING_FUNDS_COUNT;
};

type IGetSMSFComplyingFundsCountActionSuccess = {
	type: typeof actionTypes.GET_SMSF_COMPLYING_FUNDS_COUNT_SUCCESS;
};

type IGetSMSFComplyingFundsCountActionFailure = {
	type: typeof actionTypes.GET_SMSF_COMPLYING_FUNDS_COUNT;
};

export type IGetSMSFComplyingFundsCountActions =
	| IGetSMSFComplyingFundsCountAction
	| IGetSMSFComplyingFundsCountActionSuccess
	| IGetSMSFComplyingFundsCountActionFailure;

/**
 * Get fund user's name, email, and phones
 */

export type IFundsUserMeta = {
	[key: string]: {
		readonly phone: string[];
		readonly email: string[];
		readonly name: string[];
	};
};

export type IGetFundsUserMeta = {
	readonly fundUserMeta: IFundsUserMeta;
	readonly gettingFundsUserMeta: boolean;
	readonly getFundsUserMetaSuccess: boolean;
	readonly getFundsUserMetaFailure: boolean;
	readonly getFundsUserMetaError: ApiErrorType;
};

export const getFundsUserMetaDefaultValue: IGetFundsUserMeta = {
	fundUserMeta: {},
	gettingFundsUserMeta: false,
	getFundsUserMetaSuccess: false,
	getFundsUserMetaFailure: false,
	getFundsUserMetaError: {},
};

type IGetFundsUserMetaAction = {
	type: typeof actionTypes.GET_FUND_USER_META;
};

type IGetFundsUserMetaSuccessAction = {
	type: typeof actionTypes.GET_FUND_USER_META_SUCCESS;
};

type IGetFundsUserMetaFailureAction = {
	type: typeof actionTypes.GET_FUND_USER_META_FAILURE;
};

export type IGetFundsUserMetaActions =
	| IGetFundsUserMetaAction
	| IGetFundsUserMetaSuccessAction
	| IGetFundsUserMetaFailureAction;

/**
 * Get Tags
 */
export type IGetTags = {
	readonly tagList: string[];
	readonly gettingTags: boolean;
	readonly getTagsSuccess: boolean;
	readonly getTagsFailure: boolean;
};

export const getTagsDefaultValue = {
	tagList: [],
	gettingTags: false,
	getTagsSuccess: false,
	getTagsFailure: false,
};

export type IGetTagsCtr = {
	(): IGetTagsActions;
};

type IGetTagsAction = {
	type: typeof actionTypes.GET_TAGS;
};

type IGetTagsActionSuccess = {
	type: typeof actionTypes.GET_TAGS_SUCCESS;
};

type IGetTagsActionFailure = {
	type: typeof actionTypes.GET_TAGS_FAILURE;
};

type IGetTagsActionReset = {
	type: typeof actionTypes.GET_TAGS_RESET;
};

export type IGetTagsActions = IGetTagsAction | IGetTagsActionSuccess | IGetTagsActionFailure | IGetTagsActionReset;
