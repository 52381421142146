import {
	AddBrandType,
	EditAdminType,
	DeleteAdminType,
	AddAdminType,
	EditBrandType,
	GetAdminLevelsType,
	GetBrandsType,
} from "./types";

export const getBrandsSlr = (state: any): GetBrandsType => state.administration.getBrands;
export const getAdminLevelsSlr = (state: any): GetAdminLevelsType => state.administration.getAdminLevels;
// brand
export const addBrandSlr = (state: any): AddBrandType => state.administration.addBrand;
export const editBrandSlr = (state: any): EditBrandType => state.administration.editBrand;
// admin
export const addAdminSlr = (state: any): AddAdminType => state.administration.addAdmin;
export const deleteAdminSlr = (state: any): DeleteAdminType => state.administration.deleteAdmin;
export const editAdminSlr = (state: any): EditAdminType => state.administration.editAdmin;
