import { ApiErrorType, ObjectType } from "__utils/types";
import { DEFAULT_LOGO_HEIGHT } from "__layout/Header/__utils/constants";
import * as actionTypes from "./actionTypes";

/**
 *  admin type
 */
export interface AdminDetailsType extends ObjectType {
	readonly id: number;
	readonly firstName: string;
	readonly lastName: string;
	readonly email: string;
	readonly phone: string;
	readonly administratorBrand: number;
	readonly level: number;
}

export const defaultAdminItem: AdminDetailsType = {
	id: 0,
	firstName: "",
	lastName: "",
	email: "",
	phone: "",
	administratorBrand: 0,
	level: 0,
};

export type AdminsType = Array<AdminDetailsType>;

/**
 *  brand type
 */
export interface BrandDetailsType {
	readonly id: number;
	readonly brandType: string;
	readonly code: string;
	readonly title: string;
	readonly logo: string;
	readonly logoHeight: number;
	readonly darkMode: boolean;
	readonly notes: string;
	readonly defaultEmail: string;
	readonly administrators: AdminsType;
}

export const defaultBrandItem: BrandDetailsType = {
	id: 0,
	brandType: "",
	code: "",
	title: "",
	logo: "",
	notes: "",
	logoHeight: DEFAULT_LOGO_HEIGHT,
	darkMode: false,
	defaultEmail: "",
	administrators: [],
};

export type BrandsType = Array<BrandDetailsType>;

/**
 * Get brandsdata
 */

export type GetBrandsType = {
	readonly brands: BrandsType; // raw
	readonly filtered: BrandsType; // Filtered base
	readonly partial: BrandsType; // Accumulated each scroll
	readonly limit: number;
	readonly currPage: number;
	readonly numOfPages: number;
	readonly isGettingBrands: boolean;
	readonly isGetBrandsSuccess: boolean;
	readonly isGetBrandsError: boolean;
	readonly getBrandsError: ApiErrorType;
};

export const getBrandsDefaultValue: GetBrandsType = {
	brands: [],
	filtered: [],
	partial: [],
	limit: 35,
	currPage: 1,
	numOfPages: 999,
	isGettingBrands: false,
	isGetBrandsSuccess: false,
	isGetBrandsError: false,
	getBrandsError: {},
};

type GetBrandAction = {
	type: typeof actionTypes.GET_BRANDS;
};

type GetBrandSuccessAction = {
	type: typeof actionTypes.GET_BRANDS_SUCCESS;
};

type GetBrandFailureAction = {
	type: typeof actionTypes.GET_BRANDS_FAILURE;
};

type GetBrandResetAction = {
	type: typeof actionTypes.GET_BRANDS_RESET;
};

export type GetBrandActionTypes = GetBrandAction | GetBrandSuccessAction | GetBrandFailureAction | GetBrandResetAction;

/**
 * Get brands on scroll
 */

export type GetBrandsOnScrollPayloadType = {
	page: number;
};

export type GetBrandsOnScrollCtrType = {
	(payload: GetBrandsOnScrollPayloadType): GetBrandsOnScrollActionTypes;
};

type GetBrandsOnScrollAction = {
	type: typeof actionTypes.GET_BRANDS_ON_SCROLL;
	payload: GetBrandsOnScrollPayloadType;
};

type GetBrandsOnScrollSuccessAction = {
	type: typeof actionTypes.GET_BRANDS_ON_SCROLL_SUCCESS;
};

export type GetBrandsOnScrollActionTypes = GetBrandsOnScrollAction | GetBrandsOnScrollSuccessAction;

/**
 * Admin LEVEL
 */
export type AdminLevelDetailsType = {
	readonly Admin: number;
	readonly User: number;
};
export type AdminLevelsType = { [key: string]: Array<AdminLevelDetailsType> };

export type GetAdminLevelsType = {
	readonly adminLevels: AdminLevelsType;
	readonly isGettingAdminLevels: boolean;
	readonly isGetAdminLevelSuccess: boolean;
	readonly isGetAdminLevelError: boolean;
};

export const getAdminLevelsDefaultValue: GetAdminLevelsType = {
	adminLevels: {},
	isGettingAdminLevels: false,
	isGetAdminLevelSuccess: false,
	isGetAdminLevelError: false,
};

type GetAdminLevelsAction = {
	type: typeof actionTypes.GET_ADMIN_LEVELS;
};

type GetAdminLevelsSuccessAction = {
	type: typeof actionTypes.GET_ADMIN_LEVELS_SUCCESS;
};

type GetAdminLevelsFailureAction = {
	type: typeof actionTypes.GET_ADMIN_LEVELS_FAILURE;
};

type GetAdminLevelsResetAction = {
	type: typeof actionTypes.GET_ADMIN_LEVELS_RESET;
};

export type GetAdminLevelsActionTypes =
	| GetAdminLevelsAction
	| GetAdminLevelsSuccessAction
	| GetAdminLevelsFailureAction
	| GetAdminLevelsResetAction;

/**
 * Add Brand
 */
export type AddBrandType = {
	readonly isAddingBrand: boolean;
	readonly isAddBrandSuccess: boolean;
	readonly isAddBrandError: boolean;
	readonly addBrandError: ApiErrorType;
};

export const addBrandDefaultValue: AddBrandType = {
	isAddingBrand: false,
	isAddBrandSuccess: false,
	isAddBrandError: false,
	addBrandError: {},
};

export type AddBrandPayloadType = FormData;

type AddBrandAction = {
	type: typeof actionTypes.ADD_BRAND;
	payload: AddBrandPayloadType;
};

type AddBrandSuccessAction = {
	type: typeof actionTypes.ADD_BRAND_SUCCESS;
};

type AddBrandFailureAction = {
	type: typeof actionTypes.ADD_BRAND_FAILURE;
};

type AddBrandResetAction = {
	type: typeof actionTypes.ADD_BRAND_RESET;
};

export type AddBrandActionTypes = AddBrandAction | AddBrandSuccessAction | AddBrandFailureAction | AddBrandResetAction;

/**
 * Edit Brand
 */
export type EditBrandType = {
	readonly isEditingBrand: boolean;
	readonly isEditBrandSuccess: boolean;
	readonly isEditBrandError: boolean;
	readonly editBrandError: ApiErrorType;
};

export const editBrandDefaultValue: EditBrandType = {
	isEditingBrand: false,
	isEditBrandSuccess: false,
	isEditBrandError: false,
	editBrandError: {},
};

export type EditBrandPayloadType = {
	formData: FormData;
	id: number;
};

type EditBrandAction = {
	type: typeof actionTypes.EDIT_BRAND;
	payload: EditBrandPayloadType;
};

type EditBrandSuccessAction = {
	type: typeof actionTypes.EDIT_BRAND_SUCCESS;
};

type EditBrandFailureAction = {
	type: typeof actionTypes.EDIT_BRAND_FAILURE;
};

type EditBrandResetAction = {
	type: typeof actionTypes.EDIT_BRAND_RESET;
};

export type EditBrandActionTypes =
	| EditBrandAction
	| EditBrandSuccessAction
	| EditBrandFailureAction
	| EditBrandResetAction;

/**
 * Add admin
 */
export type AddAdminType = {
	readonly isAddingAdmin: boolean;
	readonly isAddAdminSuccess: boolean;
	readonly isAddAdminError: boolean;
	readonly addAdminError: ApiErrorType;
};

export const addAdminDefaultValue: AddAdminType = {
	isAddingAdmin: false,
	isAddAdminSuccess: false,
	isAddAdminError: false,
	addAdminError: {},
};

export type AdminPayloadType = AdminDetailsType;

type AddAdminAction = {
	type: typeof actionTypes.ADD_ADMIN;
	payload: AdminPayloadType;
};

type AddAdminSuccessAction = {
	type: typeof actionTypes.ADD_ADMIN_SUCCESS;
};

type AddAdminFailureAction = {
	type: typeof actionTypes.ADD_ADMIN_FAILURE;
};

type AddAdminResetAction = {
	type: typeof actionTypes.ADD_ADMIN_RESET;
};

export type AddAdminActionTypes = AddAdminAction | AddAdminSuccessAction | AddAdminFailureAction | AddAdminResetAction;

/**
 * Edit admin
 */

export type EditAdminType = {
	readonly isEditingAdmin: boolean;
	readonly isEditAdminSuccess: boolean;
	readonly isEditAdminError: boolean;
	readonly editAdminError: ApiErrorType;
};

export const editAdminDefaultValue: EditAdminType = {
	isEditingAdmin: false,
	isEditAdminSuccess: false,
	isEditAdminError: false,
	editAdminError: {},
};

type EditAdminAction = {
	type: typeof actionTypes.EDIT_ADMIN;
	payload: AdminPayloadType;
};

type EditAdminSuccessAction = {
	type: typeof actionTypes.EDIT_ADMIN_SUCCESS;
};

type EditAdminFailureAction = {
	type: typeof actionTypes.EDIT_ADMIN_FAILURE;
};

type EditAdminResetAction = {
	type: typeof actionTypes.EDIT_ADMIN_RESET;
};

export type EditAdminActionTypes =
	| EditAdminAction
	| EditAdminSuccessAction
	| EditAdminFailureAction
	| EditAdminResetAction;

/**
 * Delete admin
 */
export type DeleteAdminType = {
	readonly deleteId: number;
	readonly isDeletingAdmin: boolean;
	readonly isDeletingAdminSuccess: boolean;
	readonly isDeletingAdminError: boolean;
	readonly error: ApiErrorType;
};

export const deleteAdminDefaultValue: DeleteAdminType = {
	deleteId: 0,
	isDeletingAdmin: false,
	isDeletingAdminSuccess: false,
	isDeletingAdminError: false,
	error: {},
};

export type DeleteAdminPayloadType = {
	id: number;
	administratorBrand: number;
};

type DeleteAdminAction = {
	type: typeof actionTypes.DELETE_ADMIN;
	payload: DeleteAdminPayloadType;
};

type DeleteAdminSuccessAction = {
	type: typeof actionTypes.DELETE_ADMIN_SUCCESS;
};

type DeleteAdminFailureAction = {
	type: typeof actionTypes.DELETE_ADMIN_FAILURE;
};

type DeleteAdminResetAction = {
	type: typeof actionTypes.DELETE_ADMIN_RESET;
};

export type DeleteAminActionTypes =
	| DeleteAdminAction
	| DeleteAdminSuccessAction
	| DeleteAdminFailureAction
	| DeleteAdminResetAction;
