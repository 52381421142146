import Button from "antd/es/button";
import Typography from "antd/es/typography";
import React from "react";
import { IoCreate } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
	addAccountResetCtr,
	editAccountResetCtr,
} from "__pages/FundDetails/__pages/Account/AccountDetails/PrimaryAccount/__redux/actions";
import {
	addAccountSlr,
	editAccountSlr,
} from "__pages/FundDetails/__pages/Account/AccountDetails/PrimaryAccount/__redux/selectors";
import {
	AddAccountType,
	EditAccountType,
} from "__pages/FundDetails/__pages/Account/AccountDetails/PrimaryAccount/__redux/types";
import {
	addFundStructureResetCtr,
	editFundStructureResetCtr,
} from "__pages/FundDetails/__pages/Account/Fund/FundStructure/__redux/actions";
import {
	addFundStructureSlr,
	editFundStructureSlr,
} from "__pages/FundDetails/__pages/Account/Fund/FundStructure/__redux/selectors";
import {
	AddFundStructureType,
	EditFundStructureType,
} from "__pages/FundDetails/__pages/Account/Fund/FundStructure/__redux/types";
import {
	getFundDetailsForModalCtr,
	getFundDetailsForModalResetCtr,
	setFundToResumeResetCtr,
} from "__pages/FundDetails/__redux/actions";
import { getFundDetailsForModalSlr, setFundToResumeSlr } from "__pages/FundDetails/__redux/selectors";
import {
	fundStructureDefaultValue,
	GetFundDetailsType,
	SetFundToResumeType,
	StructureType,
} from "__pages/FundDetails/__redux/types";
import { BREAKPOINTS } from "__utils/constants";
import AntdifyIcon from "__common/AntdifyIcon";
import ClickableIcon from "__common/ClickableIcon";
import EstablishFundSteps from "./EstablishFundSteps";
import { Container, GlobalStyle, ModalStyled } from "./style";
import * as lang from "./__utils/lang";

const { Title } = Typography;

type Props = {
	disabled?: boolean;
};

const EstablishFundModal: React.FC<Props> = ({ disabled = false }: Props) => {
	const [showModal, setShowModal] = React.useState(false);
	const [step, setStep] = React.useState<number>(0);
	const dispatch = useDispatch();
	const getFundDetails: GetFundDetailsType = useSelector(getFundDetailsForModalSlr);
	const {
		account,
		members,
		fundStructure,
		fundStructure: { fundOrigin, fundType },
	} = getFundDetails;
	const setFundToResume: SetFundToResumeType = useSelector(setFundToResumeSlr);
	const { id: resumeId } = setFundToResume;
	// Selector for select/add/edit account
	const addAccount: AddAccountType = useSelector(addAccountSlr);
	const { isSuccess: isAddAccountSuccess, isCreating: isAddingAccount, isError: isAddAccountError } = addAccount;
	const editAccount: EditAccountType = useSelector(editAccountSlr);
	const { isSuccess: isEditAccountSuccess, isEditing: isEdittingAccount, isError: isEditAccountError } = editAccount;
	// Selector for add/edit Fund Structure
	const addFundStructure: AddFundStructureType = useSelector(addFundStructureSlr);
	const {
		isSuccess: isAddFundStructureSuccess,
		isAdding: isAddingFundStructure,
		isError: isAddFundStructureError,
	} = addFundStructure;
	const editFundStructure: EditFundStructureType = useSelector(editFundStructureSlr);
	const {
		isSuccess: isEditFundStructureSuccess,
		isEditing: isEdittingFundStructure,
		isError: isEditFundStructureError,
	} = editFundStructure;
	// button triggers Account
	const [isSubmitAddAccount, setSubmitAddAccount] = React.useState(false);
	const [isSubmitEditAccount, setSubmitEditAccount] = React.useState(false);
	// button triggers fund Structure
	const [isSubmitAddFundStructure, setSubmitAddFundStructure] = React.useState(false);
	const [isSubmitEditFundStructure, setSubmitEditFundStructure] = React.useState(false);
	// showing hiding member forms
	const [isShowMemberForm, setShowMemberForm] = React.useState<boolean>(false);

	const isXS: boolean = useMediaQuery({ query: BREAKPOINTS.XS });

	// fund structure
	const [fundStructureLocalValues, setFundStructureLocalValues] =
		React.useState<StructureType>(fundStructureDefaultValue);

	// check If resume fund
	React.useEffect(() => {
		if (resumeId !== 0) {
			setShowModal(true);
			dispatch(getFundDetailsForModalCtr({ id: resumeId }));
		}
	}, [resumeId, dispatch]);

	// Create Fund Account Success
	React.useEffect(() => {
		if (isAddAccountSuccess && !isAddingAccount) {
			dispatch(addAccountResetCtr());
			setSubmitAddAccount(false);
			setStep(1);
		}
	}, [isAddAccountSuccess, isAddingAccount, setStep, dispatch]);

	// Edit Fund Account Fund Success
	React.useEffect(() => {
		if (isEditAccountSuccess && !isEdittingAccount) {
			dispatch(editAccountResetCtr());
			setSubmitEditAccount(false);
			setStep(1);
		}
	}, [isEditAccountSuccess, isEdittingAccount, setStep, dispatch]);

	// Add Fund Structure Success
	React.useEffect(() => {
		if (isAddFundStructureSuccess && !isAddingFundStructure) {
			setSubmitAddFundStructure(false);
			setFundStructureLocalValues(fundStructureDefaultValue);
		}
	}, [isAddFundStructureSuccess, isAddingFundStructure, dispatch]);

	React.useEffect(() => {
		if (isAddFundStructureSuccess && !isAddingFundStructure) {
			dispatch(addFundStructureResetCtr());
			if (fundOrigin === "Existing") {
				setStep(3);
			} else {
				setStep(2);
			}
		}
	}, [fundOrigin, isAddFundStructureSuccess, isAddingFundStructure, dispatch]);

	// Edit Fund Structure Success
	React.useEffect(() => {
		if (isEditFundStructureSuccess && !isEdittingFundStructure) {
			setSubmitEditFundStructure(false);
		}
	}, [isEditFundStructureSuccess, isEdittingFundStructure]);

	React.useEffect(() => {
		if (isEditFundStructureSuccess && !isEdittingFundStructure) {
			dispatch(editFundStructureResetCtr());
			if (fundOrigin === "Existing") {
				setStep(3);
			} else {
				setStep(2);
			}
		}
	}, [fundOrigin, isEditFundStructureSuccess, isEdittingFundStructure, dispatch]);

	const onSubmit = (): void => {
		if (step === 0) {
			if (account.id === 0) {
				setSubmitAddAccount(true);
			} else {
				setSubmitEditAccount(true);
			}
		} else if (step === 1) {
			// Fund Structure
			if (fundStructure.fundOrigin === "") {
				setSubmitAddFundStructure(true);
			} else {
				setSubmitEditFundStructure(true);
			}
		} else if (step === 2) {
			setStep(3);
		} else {
			// last page
			setStep(4);
		}
	};

	const onCancel = (): void => {
		setTimeout(() => {
			setStep(0);
			dispatch(setFundToResumeResetCtr());
			dispatch(getFundDetailsForModalResetCtr());
			dispatch(addAccountResetCtr());
			dispatch(editAccountResetCtr());
			dispatch(addFundStructureResetCtr());
			dispatch(editFundStructureResetCtr());
		}, 50);
		setShowModal(false);
	};

	const onPrevious = (): void => {
		setStep((prev) => prev - 1);
	};

	const onShowModal = (): void => {
		if (!disabled) {
			setShowModal(true);
		}
	};

	const isDisabledNextBtn = (): boolean => {
		if (step < 2 || step > 2) return false;

		if (fundType === "Corporate" && members.length >= 1) {
			return false;
		}
		if (fundType === "Individual" && members.length >= 2) {
			return false;
		}

		return true;
	};

	const buttonsList = (): React.ReactNode => {
		if (step === 3 || isShowMemberForm) {
			return null;
		}
		return [
			<Button key="2" onClick={onCancel}>
				Cancel
			</Button>,
			<Button key="1" onClick={onPrevious} disabled={step === 0}>
				Previous
			</Button>,

			<Button
				key="3"
				onClick={onSubmit}
				type="primary"
				loading={
					(isAddingAccount && !isAddAccountError) ||
					(isEdittingAccount && !isEditAccountError) ||
					(isAddingFundStructure && !isAddFundStructureError) ||
					(isEdittingFundStructure && !isEditFundStructureError)
				}
				disabled={isDisabledNextBtn()}
			>
				Next
			</Button>,
		];
	};

	return (
		<Container>
			<GlobalStyle />
			{!isXS ? (
				<Button disabled={disabled || showModal} size="middle" onClick={onShowModal} type="primary">
					Create Fund
				</Button>
			) : (
				<ClickableIcon tooltip="Create Fund" className="clickable-fund">
					<AntdifyIcon className="header-icon create-fund">
						<IoCreate onClick={onShowModal} />
					</AntdifyIcon>
				</ClickableIcon>
			)}

			<ModalStyled
				getContainer={false}
				title={
					<Title level={4}>
						{resumeId === 0 ? lang.ESTABLISH_FUND_MODAL_TITLE : lang.RESUME_FUND_MODAL_TITLE}
					</Title>
				}
				visible={showModal}
				maskClosable={false}
				onCancel={onCancel}
				width={step === 2 || step === 3 ? 1000 : 650}
				closable={false}
				footer={buttonsList()}
				// forceRender={true}
				className="establish-fund-modal"
			>
				<EstablishFundSteps
					step={step}
					fund={getFundDetails}
					isShowMemberForm={isShowMemberForm}
					setShowMemberForm={setShowMemberForm}
					setStep={setStep}
					onCancel={onCancel}
					isSubmitAddAccount={isSubmitAddAccount}
					setSubmitAddAccount={setSubmitAddAccount}
					isSubmitEditAccount={isSubmitEditAccount}
					setSubmitEditAccount={setSubmitEditAccount}
					isSubmitAddFundStructure={isSubmitAddFundStructure}
					setSubmitAddFundStructure={setSubmitAddFundStructure}
					setSubmitEditFundStructure={setSubmitEditFundStructure}
					isSubmitEditFundStructure={isSubmitEditFundStructure}
					setFundStructureLocalValues={setFundStructureLocalValues}
					fundStructureLocalValues={fundStructureLocalValues}
				/>
			</ModalStyled>
		</Container>
	);
};

export default EstablishFundModal;
