import Result from "antd/es/result";
import React, { ErrorInfo } from "react";
import { errorHandler } from "__utils/helpers";
import { ObjectType } from "__utils/types";
import { Container } from "./style";

class ErrorBoundary extends React.Component<unknown, any> {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch = (error: Error, errorInfo: ErrorInfo): void => {
		errorHandler(error);
		errorHandler(errorInfo);
	};

	static getDerivedStateFromError = (): ObjectType => ({ hasError: true });

	render(): React.ReactNode {
		const { props, state } = this;
		if (state.hasError) {
			return (
				<Container>
					<Result status="error" title="Error" subTitle="Try reloading or going back to previous page." />
				</Container>
			);
		}

		return props.children;
	}
}

export default ErrorBoundary;
