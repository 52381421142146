import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import {
	DownloadInvoiceActionTypes,
	downloadInvoiceDefaultValue,
	DownloadInvoiceType,
	GetInvoicesActionTypes,
	getInvoicesDefaultValue,
	GetInvoicesType,
} from "./types";

export const getInvoicesReducer = (
	state = getInvoicesDefaultValue,
	action: GetInvoicesActionTypes
): GetInvoicesType => {
	switch (action.type) {
		case actionTypes.GET_INVOICES:
			return {
				...state,
				isGettingInvoices: true,
			};
		case actionTypes.GET_INVOICES_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingInvoices: false,
				isGetInvoicesSuccess: true,
				isGetInvoicesError: false,
			};
		case actionTypes.GET_INVOICES_FAILURE:
			return {
				...state,
				isGettingInvoices: false,
				isGetInvoicesSuccess: false,
				isGetInvoicesError: true,
			};

		default:
			return state;
	}
};

export const downloadInvoiceReducer = (
	state = downloadInvoiceDefaultValue,
	action: DownloadInvoiceActionTypes
): DownloadInvoiceType => {
	switch (action.type) {
		case actionTypes.DOWNLOAD_INVOICE:
			return {
				...state,
				index: (action as any).payload.index,
				isDownloadingInvoice: true,
			};
		case actionTypes.DOWNLOAD_INVOICE_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isDownloadingInvoice: false,
				isDownloadInvoiceSuccess: true,
				isDownloadInvoiceError: false,
			};

		case actionTypes.DOWNLOAD_INVOICE_FAILURE:
			return {
				...state,
				isDownloadingInvoice: false,
				isDownloadInvoiceSuccess: false,
				isDownloadInvoiceError: true,
			};

		default:
			return state;
	}
};

export const invoiceReducer = combineReducers({
	getInvoices: getInvoicesReducer,
	downloadInvoice: downloadInvoiceReducer,
});
