import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import {
	AddMemberActionTypes,
	addMemberDefaultValue,
	AddMemberType,
	DeleteMemberActionTypes,
	deleteMemberDefaultValue,
	DeleteMemberType,
	EditMemberActionTypes,
	editMemberDefaultValue,
	EditMemberType,
	editDirectorIDDefaultValue,
	IEditDirectorsActions,
	IEditDirectorsID,
} from "./types";

export const addMemberReducer = (state = addMemberDefaultValue, action: AddMemberActionTypes): AddMemberType => {
	switch (action.type) {
		case actionTypes.ADD_MEMBER:
			return {
				...state,
				newMemberEmail: (action as any).payload.body.email,
				isAddingMember: true,
				isAddMemberSuccess: false,
			};

		case actionTypes.ADD_MEMBER_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isAddMemberError: false,
				isAddMemberSuccess: true,
				isAddingMember: false,
				addError: {},
			};
		case actionTypes.ADD_MEMBER_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isAddingMember: false,
				isAddMemberError: true,
			};

		case actionTypes.ADD_MEMBER_RESET:
			return {
				...state,
				...addMemberDefaultValue,
			};
		default:
			return state;
	}
};

export const editMemberReducer = (state = editMemberDefaultValue, action: EditMemberActionTypes): EditMemberType => {
	switch (action.type) {
		case actionTypes.EDIT_MEMBER:
			return {
				...state,
				isEditingMember: true,
				isEditMemberSuccess: false,
			};

		case actionTypes.EDIT_MEMBER_SUCCESS:
			const { type: successType, ...successRest } = action;

			return {
				...state,
				...successRest,
				isEditMemberError: false,
				isEditMemberSuccess: true,
				isEditingMember: false,
				editError: {},
			};
		case actionTypes.EDIT_MEMBER_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isEditingMember: false,
				isEditMemberError: true,
			};

		case actionTypes.EDIT_MEMBER_RESET:
			return {
				...state,
				...editMemberDefaultValue,
			};
		default:
			return state;
	}
};

export const deleteMemberReducer = (
	state = deleteMemberDefaultValue,
	action: DeleteMemberActionTypes
): DeleteMemberType => {
	switch (action.type) {
		case actionTypes.DELETE_MEMBER:
			return {
				...state,
				deletingId: (action as any).payload.deletingId,
				isDeletingMember: true,
			};

		case actionTypes.DELETE_MEMBER_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isDeleteMemberError: false,
				isDeleteMemberSuccess: true,
				isDeletingMember: false,
			};
		case actionTypes.DELETE_MEMBER_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isDeletingMember: false,
				isDeleteMemberError: true,
			};

		case actionTypes.DELETE_MEMBER_RESET:
			return {
				...state,
				...deleteMemberDefaultValue,
			};
		default:
			return state;
	}
};

export const editDirectorIDReducer = (
	state = editDirectorIDDefaultValue,
	action: IEditDirectorsActions
): IEditDirectorsID => {
	switch (action.type) {
		case actionTypes.EDIT_DIRECTOR_ID:
			return {
				...state,
				editingId: (action as any).payload.editingId,
				isEditingDirectorID: true,
			};

		case actionTypes.EDIT_DIRECTOR_ID_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isEditingDirectorID: false,
				isEditDirectorIDSuccess: true,
				isEditDirectorIDFailed: false,
			};
		case actionTypes.EDIT_DIRECTOR_ID_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isEditingDirectorID: false,
				isEditDirectorIDSuccess: false,
				isEditDirectorIDFailed: true,
			};

		case actionTypes.EDIT_DIRECTOR_ID_RESET:
			return {
				...editDirectorIDDefaultValue,
			};
		default:
			return state;
	}
};

export const membersReducer = combineReducers({
	editMember: editMemberReducer,
	addMember: addMemberReducer,
	deleteMember: deleteMemberReducer,
	editDirectorID: editDirectorIDReducer,
});
