import React, { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "__common/Spinner";
import { getBrandsCtr } from "__pages/Administration/__redux/actions";
import { getBrandsSlr } from "__pages/Administration/__redux/selectors";
import { GetBrandsType } from "__pages/Administration/__redux/types";
import { AccountType, GetFundDetailsType } from "__pages/FundDetails/__redux/types";
import AddAccount from "./AccountForm/AddAccount";
import EditAccount from "./AccountForm/EditAccount";
import { Container } from "./style";

const accountForm = (
	account: AccountType,
	getbrands: GetBrandsType,
	isSubmitAddAccount: boolean,
	setSubmitAddAccount: Dispatch<SetStateAction<boolean>>,
	isSubmitEditAccount: boolean,
	setSubmitEditAccount: Dispatch<SetStateAction<boolean>>
): React.ReactNode => {
	if (account.id === 0) {
		return (
			<AddAccount
				account={account}
				getbrands={getbrands}
				isSubmitAddAccount={isSubmitAddAccount}
				setSubmitAddAccount={setSubmitAddAccount}
			/>
		);
	}

	return (
		<EditAccount
			account={account}
			getbrands={getbrands}
			isSubmitEditAccount={isSubmitEditAccount}
			setSubmitEditAccount={setSubmitEditAccount}
		/>
	);
};

type Props = {
	fund: GetFundDetailsType;
	isSubmitAddAccount: boolean;
	setSubmitAddAccount: Dispatch<SetStateAction<boolean>>;
	isSubmitEditAccount: boolean;
	setSubmitEditAccount: Dispatch<SetStateAction<boolean>>;
};

const Account: React.FC<Props> = ({
	fund,
	isSubmitAddAccount,
	setSubmitAddAccount,
	isSubmitEditAccount,
	setSubmitEditAccount,
}: Props) => {
	const dispatch = useDispatch();
	const { account, isGetting } = fund;
	const getbrands: GetBrandsType = useSelector(getBrandsSlr);

	// get brands
	React.useEffect(() => {
		dispatch(getBrandsCtr());
	}, [dispatch]);

	return (
		<Container>
			{isGetting ? (
				<div className="spinner-wrapper">
					<Spinner fontSize={24} />
				</div>
			) : (
				accountForm(
					account,
					getbrands,
					isSubmitAddAccount,
					setSubmitAddAccount,
					isSubmitEditAccount,
					setSubmitEditAccount
				)
			)}
		</Container>
	);
};

export default Account;
