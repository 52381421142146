import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { FETCH_USER_PROFILE, VERIFY_AUTH } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getUserProfileApi = async (): Promise<AxiosResponse<any>> =>
	axios.get(FETCH_USER_PROFILE).catch(errorHandler);

export const verifyUserAuthApi = async (): Promise<AxiosResponse<any>> => axios.get(VERIFY_AUTH).catch(errorHandler);
