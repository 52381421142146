import styled from "styled-components";

export const MobileHeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 15px;
	width: 100%;

	.left-section {
		img {
			margin-left: 24px;
			height: 41px;
		}
	}

	.right-section {
		display: flex;
		justify-content: end;

		.icons-list {
			.is-disabled:hover {
				cursor: no-drop;
			}
			.btn-bg :hover {
				color: #4538d1;
				path {
					stroke: #4538d1;
					opacity: 1;
				}
			}

			.header-icon {
				top: 4px;

				svg {
					font-size: 20px;
					color: #4d4766;

					path {
						stroke: #4d4766;
						opacity: 0.8;
					}
				}
				&.create-fund {
					svg {
						font-size: 25px;
						color: ${(p: any) => p.theme.primaryColor};
						margin-left: 12px;
						margin-bottom: 6px;
					}
				}
			}

			.ant-badge-count .ant-scroll-number-only span {
				font-size: 9px;
			}

			.admin-icon {
				svg {
					font-size: 18px;
				}
			}
		}
	}
`;

export const SessionWidget = styled.div`
	.icon-wrapper :hover {
		transition: all 0.5s;
		color: #4538d1;
	}
	.ant-dropdown-trigger {
		display: flex;
		cursor: pointer;
		height: 60px;

		.icon-wrapper {
			display: flex;
			align-items: center;
			.profile-icon {
				padding: 2px;
				.anticon-user {
					font-size: 18px;
				}
			}
		}

		> div {
			margin-left: 12px;
		}
	}

	&.dark-mode {
		.name-brand > div {
			color: #fff;
		}

		.ant-dropdown-link .anticon-caret-down {
			color: #fff;
		}
	}
`;
