import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import {
	GetActivitiesType,
	getActivitiesDefaultValue,
	GetActivitiesActionTypes,
	AddActivityNoteType,
	addActivityNoteDefaultValue,
	AddActivityNoteActionTypes,
} from "./types";

export const getActivitiesReducer = (
	state = getActivitiesDefaultValue,
	action: GetActivitiesActionTypes
): GetActivitiesType => {
	switch (action.type) {
		case actionTypes.GET_ACTIVITIES:
			return {
				...state,
				isGettingActivities: true,
			};
		case actionTypes.GET_ACTIVITIES_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isGetActivitiesError: false,
				isGetActivitiesSuccess: true,
				isGettingActivities: false,
			};
		case actionTypes.GET_ACTIVITIES_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isGetActivitiesError: true,
				isGetActivitiesSuccess: false,
				isGettingActivities: false,
				getActivitiesError: {},
			};
		case actionTypes.GET_ACTIVITIES_RESET:
			return {
				...state,
				...getActivitiesDefaultValue,
			};
		default:
			return state;
	}
};

export const addActivityNoteReducer = (
	state = addActivityNoteDefaultValue,
	action: AddActivityNoteActionTypes
): AddActivityNoteType => {
	switch (action.type) {
		case actionTypes.ADD_ACTIVITY_NOTE:
			return {
				...state,
				isAddingActivityNote: true,
			};
		case actionTypes.ADD_ACTIVITY_NOTE_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isAddingActivityNote: false,
				isAddActivityNoteSuccess: true,
				isAddActivityNoteError: false,
			};
		case actionTypes.ADD_ACTIVITY_NOTE_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isAddingActivityNote: false,
				isAddActivityNoteSuccess: false,
				isAddActivityNoteError: true,
				addActivityNoteError: {},
			};
		case actionTypes.ADD_ACTIVITY_NOTE_RESET:
			return {
				...state,
				...addActivityNoteDefaultValue,
			};
		default:
			return state;
	}
};

export const activityReducer = combineReducers({
	getActivities: getActivitiesReducer,
	addActivityNote: addActivityNoteReducer,
});
