import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { GetActivitiesPayloadType, AddActivityNotePayloadType } from "../__redux/types";
import { GET_ACTIVITIES, ADD_ACTIVITY_NOTE } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getActivitiesApi = async ({ hubSpotId }: GetActivitiesPayloadType): Promise<AxiosResponse<any>> =>
	axios.get(`${GET_ACTIVITIES}/${hubSpotId}/`).catch(errorHandler);

export const addActivityNoteApi = async ({
	hubSpotId,
	body,
}: AddActivityNotePayloadType): Promise<AxiosResponse<any>> =>
	axios.post(`${ADD_ACTIVITY_NOTE}/${hubSpotId}/`, { body }).catch(errorHandler);
