import { LoadingOutlined } from "@ant-design/icons";
import cx from "classnames";
import React from "react";
import Clickable from "__common/Clickable";
import IconButtonBg from "__common/IconButtonBg";

type Props = {
	children: React.ReactNode;
	type?: string;
	to?: string;
	className?: string;
	disabled?: boolean;
	active?: boolean;
	tooltip?: string;
	external?: boolean;
	loading?: boolean;
	padding?: number;
	propagateEvent?: boolean;
	onClick?: (e: React.MouseEvent) => void;
};

const ClickableIcon: React.FC<Props> = ({ children, padding, active, loading = false, ...rest }: Props) => (
	<Clickable {...rest}>
		<IconButtonBg className={cx(active)} padding={padding}>
			{loading ? <LoadingOutlined /> : children}
		</IconButtonBg>
	</Clickable>
);

export default React.memo(ClickableIcon);
