import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { CREATE_FUND, GET_FUND } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getFundApi = async ({ id }: any): Promise<AxiosResponse<any>> =>
	axios.get(`${GET_FUND}${id}/`).catch(errorHandler);

export const createFundApi = async ({ id, body }: any): Promise<AxiosResponse<any>> =>
	axios.post(`${CREATE_FUND}${id}/`, body).catch(errorHandler);
