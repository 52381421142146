import Modal from "antd/es/modal";
import React from "react";
import { DeleteModalContent } from "./style";

type Props = {
	text: string;
	icon: React.ReactNode;
	visible: boolean;
	onCancel: () => void;
	onOkay: () => void;
	loading?: boolean;
	okType?: any; // string doesn't work
	className?: string;
};

const ConfirmModal: React.FC<Props> = ({
	text,
	icon,
	visible,
	onCancel,
	onOkay,
	loading,
	okType = "danger",
	className,
}: Props) => (
	// {e.stopPropagation()} is necessary to prevent event bubbling which
	// trigger any events to this components parents causing weird behavior as a result
	<div onClick={(e) => e.stopPropagation()}>
		<Modal
			getContainer={false}
			visible={visible}
			maskClosable={false}
			okText="Yes"
			okType={okType}
			cancelText="No"
			onOk={onOkay}
			onCancel={onCancel}
			closable={false}
			forceRender
			width={415}
			confirmLoading={loading}
			className={`confirm-modal ${className}`}
		>
			<DeleteModalContent>
				<div className="icon">{icon}</div>
				<div>{text}</div>
			</DeleteModalContent>
		</Modal>
	</div>
);

export default ConfirmModal;
