import Form from "antd/es/form";
import React, { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import FundStructureForm from "__pages/FundDetails/__pages/Account/Fund/FundStructure/EditFundStructureModal/FundStructureForm";
import { editFundStructureCtr } from "__pages/FundDetails/__pages/Account/Fund/FundStructure/__redux/actions";
import { editFundStructureSlr } from "__pages/FundDetails/__pages/Account/Fund/FundStructure/__redux/selectors";
import { EditFundStructureType } from "__pages/FundDetails/__pages/Account/Fund/FundStructure/__redux/types";
import * as fundLang from "__pages/FundDetails/__pages/Account/Fund/FundStructure/__utils/lang";
import { GetFundDetailsType } from "__pages/FundDetails/__redux/types";
import { processErrors } from "__utils/helpers";

type Props = {
	fund: GetFundDetailsType;
	isSubmitEditFundStructure: boolean;
	setSubmitEditFundStructure: Dispatch<SetStateAction<boolean>>;
};

const EditFundStructure: React.FC<Props> = ({ fund, isSubmitEditFundStructure, setSubmitEditFundStructure }: Props) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { setFields, validateFields, getFieldValue } = form;
	const addFundStructure: EditFundStructureType = useSelector(editFundStructureSlr);
	const { isEditing, isError, error } = addFundStructure;
	const { account, fundStructure } = fund;
	const { id } = account;
	const { fundType } = fundStructure;

	const [showACN, setShowACN] = React.useState(false);
	const [showABN, setShowABN] = React.useState(false);

	React.useEffect(() => {
		if (isSubmitEditFundStructure) {
			validateFields()
				.then((values: any) => {
					const { asicCompanyName, ..._structure } = values;
					dispatch(editFundStructureCtr({ id, structure: _structure }));
				})
				.catch(() => {
					setSubmitEditFundStructure(false);
				});
		}
	}, [id, isSubmitEditFundStructure, validateFields, setSubmitEditFundStructure, dispatch]);

	React.useEffect(() => {
		if (isError) {
			setFields(processErrors(error));
			setSubmitEditFundStructure(false);
		}
	}, [isError, error, setFields, setSubmitEditFundStructure]);

	React.useEffect(() => {
		if (fundType === "Corporate") {
			setShowACN(true);
			setShowABN(true);
		} else if (fundType === "Individual") {
			setShowABN(true);
			setShowACN(false);
		}
	}, [fundType]);

	const onTypeOrOriginOrAcnChange = (): void => {
		const type = getFieldValue(fundLang.FUND_STRUCTURE_TRUSTEE_NAME);

		if (type === "Corporate") {
			setShowACN(true);
			setShowABN(true);
		} else if (type === "Individual") {
			setShowABN(true);
			setShowACN(false);
		}
	};

	return (
		<FundStructureForm
			form={form}
			isLoading={isEditing}
			fundStructure={fundStructure}
			showACN={showACN}
			showABN={showABN}
			onTypeOrOriginOrAcnChange={onTypeOrOriginOrAcnChange}
		/>
	);
};

export default EditFundStructure;
