import {
	GetDocumentsType,
	DeleteDocumentDetailsType,
	AddDocumentDetailsType,
	EditDocumentDetailsType,
	GetDocCategoriesType,
	GetDocumentDetailsType,
} from "./types";

export const getDocumentsSlr = (state: any): GetDocumentsType => state.document.getDocuments;
export const deleteDocumentSlr = (state: any): DeleteDocumentDetailsType => state.document.deleteDocument;
export const addDocumentSlr = (state: any): AddDocumentDetailsType => state.document.addDocument;
export const editDocumentSlr = (state: any): EditDocumentDetailsType => state.document.editDocument;
export const getDocCategoriesSlr = (state: any): GetDocCategoriesType => state.document.getDocCategories;
export const getDocumentDetailsSlr = (state: any): GetDocumentDetailsType => state.document.getDocumentDetails;
