import { ApiErrorType, ObjectType } from "__utils/types";

import { Dayjs } from "dayjs";
import { DocumentDetailsType } from "__pages/RequestDocuments/__redux/types";
import * as actionTypes from "./actionTypes";

export interface DocRequestDetailsType {
	readonly id: number;
	readonly title: string;
	readonly state: string;
	readonly description: string;
	readonly type: string;
	readonly dueDate: string | Dayjs;
	readonly updatedDate: string | Dayjs;
	readonly documents: Array<DocumentDetailsType>;
	readonly reminders: Array<string>;
	readonly movements: Array<string>;
	readonly compositeId: string;
}

export type SignerDetailsType = {
	readonly name: string;
	readonly status: string;
	readonly signingDate: string;
};
export interface SigningRequestDetailsType {
	readonly id: number;
	readonly title: string;
	readonly state: string;
	readonly description: string;
	readonly type: string;
	readonly envelopeId: string;
	readonly dueDate: string | Dayjs;
	readonly updatedDate: string | Dayjs;
	readonly signers: SignerDetailsType[];
	readonly compositeId: string;
}

export type DocSigningRequestDetailsType = DocRequestDetailsType & SigningRequestDetailsType;

export type DocRequestsType = DocSigningRequestDetailsType[];

/**
 * Get doc requests
 */

export type GetDocRequestsType = {
	readonly docRequests: DocRequestsType;
	readonly filtered: DocRequestsType; // Filtered base
	readonly partial: DocRequestsType; // Accumulated each scroll
	readonly limit: number;
	readonly currPage: number;
	readonly numOfPages: number;
	readonly isGettingDocRequests: boolean;
	readonly isGetDocRequestsSuccess: boolean;
	readonly isGetDocRequestsError: boolean;
	readonly getDocRequestsError: ApiErrorType;
};

export const getDocRequestsDefaultValue: GetDocRequestsType = {
	docRequests: [],
	filtered: [],
	partial: [],
	limit: 35,
	currPage: 1,
	numOfPages: 999,
	isGettingDocRequests: false,
	isGetDocRequestsSuccess: false,
	isGetDocRequestsError: false,
	getDocRequestsError: {},
};

export type GetDocRequestsPayloadType = {
	readonly memberId: number;
};

export type GetDocRequestsCtrType = {
	(payload: GetDocRequestsPayloadType): GetDocRequestsActionTypes;
};

type GetDocRequestsAction = {
	type: typeof actionTypes.GET_DOC_REQUESTS;
	payload: GetDocRequestsPayloadType;
};

type GetDocRequestsSuccessAction = {
	type: typeof actionTypes.GET_DOC_REQUESTS_SUCCESS;
};

type GetDocRequestsFailureAction = {
	type: typeof actionTypes.GET_DOC_REQUESTS_FAILURE;
};

type GetDocRequestsResetAction = {
	type: typeof actionTypes.GET_DOC_REQUESTS_RESET;
};

export type GetDocRequestsActionTypes =
	| GetDocRequestsAction
	| GetDocRequestsSuccessAction
	| GetDocRequestsFailureAction
	| GetDocRequestsResetAction;

/**
 * Get doc requests on scroll
 */

export type GetDocRequestsOnScrollPayloadType = {
	page: number;
};

export type GetDocRequestsOnScrollCtrType = {
	(payload: GetDocRequestsOnScrollPayloadType): GetDocRequestsOnScrollActionTypes;
};

type GetDocRequestsOnScrollAction = {
	type: typeof actionTypes.GET_DOC_REQUESTS_ON_SCROLL;
	payload: GetDocRequestsOnScrollPayloadType;
};

type GetDocRequestsOnScrollSuccessAction = {
	type: typeof actionTypes.GET_DOC_REQUESTS_ON_SCROLL_SUCCESS;
};

export type GetDocRequestsOnScrollActionTypes = GetDocRequestsOnScrollAction | GetDocRequestsOnScrollSuccessAction;

/**
 * Filter doc requests
 */

export type DocRequestsSearchFilterType = string;

export type DocRequestsFiltersType = {
	readonly searchFilter: DocRequestsSearchFilterType;
};

export interface FilterDocRequestsType {
	readonly filters: DocRequestsFiltersType;
	readonly isFiltering: boolean;
}

export const filterDocRequestsDefaultValue: FilterDocRequestsType = {
	filters: {
		searchFilter: "",
	},
	isFiltering: false,
};

export type FilterDocRequestsPayloadType = {
	filters: DocRequestsFiltersType;
	isFiltering: boolean;
};

export type FilterDocRequestsCtrType = {
	(payload: FilterDocRequestsPayloadType): FilterDocRequestsActionTypes;
};

type FilterDocRequestsAction = {
	type: typeof actionTypes.FILTER_DOC_REQUESTS;
	payload: FilterDocRequestsPayloadType;
};

type FilterDocRequestsResetAction = {
	type: typeof actionTypes.FILTER_DOC_REQUESTS_RESET;
};

export type FilterDocRequestsActionTypes = FilterDocRequestsAction | FilterDocRequestsResetAction;

/**
 * Get doc request details
 */

export type GetDocRequestDetailsType = {
	readonly docRequestDetails: DocRequestDetailsType;
	readonly fetchedDocRequestDetails: Array<number>; // Tracks which docRequests have been fetched
	readonly currRequestId: number;
	readonly isGettingDocRequestDetails: boolean;
	readonly isGetDocRequestDetailsSuccess: boolean;
	readonly isGetDocRequestDetailsError: boolean;
	readonly getDocRequestDetailsError: ApiErrorType;
};

export const getDocRequestDetailsDefaultValue: GetDocRequestDetailsType = {
	docRequestDetails: {
		id: 0,
		title: "",
		state: "",
		description: "",
		type: "",
		dueDate: "",
		updatedDate: "",
		documents: [],
		reminders: [],
		movements: [],
		compositeId: "",
	},
	fetchedDocRequestDetails: [],
	currRequestId: 0,
	isGettingDocRequestDetails: false,
	isGetDocRequestDetailsSuccess: false,
	isGetDocRequestDetailsError: false,
	getDocRequestDetailsError: {},
};

export type GetDocRequestDetailsPayloadType = {
	readonly memberId: number;
	readonly id: number;
};

export type GetDocRequestDetailsCtrType = {
	(payload: GetDocRequestDetailsPayloadType): GetDocRequestDetailsActionTypes;
};

type GetDocRequestDetailsAction = {
	type: typeof actionTypes.GET_DOC_REQUEST_DETAILS;
	payload: GetDocRequestDetailsPayloadType;
};

type GetDocRequestDetailsSuccessAction = {
	type: typeof actionTypes.GET_DOC_REQUEST_DETAILS_SUCCESS;
};

type GetDocRequestDetailsFailureAction = {
	type: typeof actionTypes.GET_DOC_REQUEST_DETAILS_FAILURE;
};

type GetDocRequestDetailsResetAction = {
	type: typeof actionTypes.GET_DOC_REQUEST_DETAILS_RESET;
};

export type GetDocRequestDetailsActionTypes =
	| GetDocRequestDetailsAction
	| GetDocRequestDetailsSuccessAction
	| GetDocRequestDetailsFailureAction
	| GetDocRequestDetailsResetAction;

/**
 * Get doc signing details
 */

export type GetSigningRequestDetailsType = {
	readonly signingRequestDetails: SigningRequestDetailsType;
	readonly fetchedSigners: Array<string>; // Tracks which signers have been fetched
	readonly currEnvelopeId: string;
	readonly isGettingSigningRequestDetails: boolean;
	readonly isGetSigningRequestDetailsSuccess: boolean;
	readonly isGetSigningRequestDetailsFailure: boolean;
	readonly getSigningRequestDetailsError: ApiErrorType;
};

export const getSigningRequestDetailsDefaultValue: GetSigningRequestDetailsType = {
	signingRequestDetails: {
		id: 0,
		title: "",
		state: "",
		description: "",
		envelopeId: "",
		type: "",
		dueDate: "",
		updatedDate: "",
		signers: [],
		compositeId: "",
	},
	fetchedSigners: [],
	currEnvelopeId: "",
	isGettingSigningRequestDetails: false,
	isGetSigningRequestDetailsSuccess: false,
	isGetSigningRequestDetailsFailure: false,
	getSigningRequestDetailsError: {},
};

export type GetSigningRequestDetailsPayloadType = {
	readonly memberId: number;
	readonly envelopeId: string;
};

export type GetSigningRequestDetailsCtrType = {
	(payload: GetSigningRequestDetailsPayloadType): GetSigningRequestDetailsActionTypes;
};

type GetSigningRequestDetailsAction = {
	type: typeof actionTypes.GET_SIGNING_REQUEST_DETAILS;
	payload: GetSigningRequestDetailsPayloadType;
};

type GetSigningRequestDetailsSuccessAction = {
	type: typeof actionTypes.GET_SIGNING_REQUEST_DETAILS_SUCCESS;
};

type GetSigningRequestDetailsFailureAction = {
	type: typeof actionTypes.GET_SIGNING_REQUEST_DETAILS_FAILURE;
};

type GetSigningRequestDetailsResetAction = {
	type: typeof actionTypes.GET_SIGNING_REQUEST_DETAILS_RESET;
};

export type GetSigningRequestDetailsActionTypes =
	| GetSigningRequestDetailsAction
	| GetSigningRequestDetailsSuccessAction
	| GetSigningRequestDetailsFailureAction
	| GetSigningRequestDetailsResetAction;

/**
 * Add doc request
 */

export type AddDocRequestType = {
	readonly isAddingDocRequest: boolean;
	readonly isAddDocRequestSuccess: boolean;
	readonly isAddDocRequestError: boolean;
	readonly addDocRequestError: ApiErrorType;
};

export const addDocRequestDefaultValue: AddDocRequestType = {
	isAddingDocRequest: false,
	isAddDocRequestSuccess: false,
	isAddDocRequestError: false,
	addDocRequestError: {},
};

export type AddDocRequestPayloadType = {
	readonly memberId: number;
	readonly title: string;
	readonly description: string;
	readonly documents: Array<DocumentDetailsType>;
	readonly dueDate?: string;
};

export type AddDocRequestCtrType = {
	(payload: AddDocRequestPayloadType): AddDocRequestActionTypes;
};

export type AddDocRequestReset = {
	(): AddDocRequestActionTypes;
};

type AddDocRequestAction = {
	type: typeof actionTypes.ADD_DOC_REQUEST;
	payload: AddDocRequestPayloadType;
};

type AddDocRequestSuccessAction = {
	type: typeof actionTypes.ADD_DOC_REQUEST_SUCCESS;
};

type AddDocRequestFailureAction = {
	type: typeof actionTypes.ADD_DOC_REQUEST_FAILURE;
};

type AddDocRequestResetAction = {
	type: typeof actionTypes.ADD_DOC_REQUEST_RESET;
};

export type AddDocRequestActionTypes =
	| AddDocRequestAction
	| AddDocRequestSuccessAction
	| AddDocRequestFailureAction
	| AddDocRequestResetAction;

/**
 * Edit doc request
 */
export type EditDocRequestType = {
	readonly action: string;
	readonly isEditingDocRequest: boolean;
	readonly isEditDocRequestSuccess: boolean;
	readonly isEditDocRequestError: boolean;
	readonly editDocRequestError: ApiErrorType;
};

export const editDocRequestDefaultValue: EditDocRequestType = {
	action: "save",
	isEditingDocRequest: false,
	isEditDocRequestSuccess: false,
	isEditDocRequestError: false,
	editDocRequestError: {},
};

export type EditDocRequestPayloadType = {
	readonly memberId: number;
	readonly id: number;
	readonly title: string;
	readonly description: string;
	readonly documents: Array<DocumentDetailsType>;
	readonly action: string;
	readonly dueDate?: string;
};

export type EditDocRequestCtrType = {
	(payload: EditDocRequestPayloadType): EditDocRequestActionTypes;
};

export type EditDocRequestReset = {
	(): EditDocRequestActionTypes;
};

type EditDocRequestAction = {
	type: typeof actionTypes.EDIT_DOC_REQUEST;
	payload: EditDocRequestPayloadType;
};

type EditDocRequestSuccessAction = {
	type: typeof actionTypes.EDIT_DOC_REQUEST_SUCCESS;
};

type EditDocRequestFailureAction = {
	type: typeof actionTypes.EDIT_DOC_REQUEST_FAILURE;
};

type EditDocRequestResetAction = {
	type: typeof actionTypes.EDIT_DOC_REQUEST_RESET;
};

export type EditDocRequestActionTypes =
	| EditDocRequestAction
	| EditDocRequestSuccessAction
	| EditDocRequestFailureAction
	| EditDocRequestResetAction;

/**
 * Get doc request' document
 */

export type DocRequestDocumentType = {
	readonly id: number;
	readonly title: string;
	readonly category: string;
	readonly information: string;
	readonly description: string;
	readonly isComplete: boolean;
	readonly files: Array<ObjectType>;
	readonly availabilityDate: string | Dayjs;
};

export type GetDocRequestDocumentType = {
	readonly docRequestDocument: DocRequestDocumentType;
	readonly isGettingDocRequestDocument: boolean;
	readonly isGetDocRequestDocumentSuccess: boolean;
	readonly isGetDocRequestDocumentError: boolean;
	readonly getDocRequestDocumentError: ApiErrorType;
};

export const getDocRequestDocumentDefaultValue: GetDocRequestDocumentType = {
	docRequestDocument: {
		id: 0,
		title: "",
		category: "",
		information: "",
		description: "",
		isComplete: false,
		files: [],
		availabilityDate: "",
	},
	isGettingDocRequestDocument: false,
	isGetDocRequestDocumentSuccess: false,
	isGetDocRequestDocumentError: false,
	getDocRequestDocumentError: {},
};

export type GetDocRequestDocumentPayloadType = {
	readonly requestId: number;
	readonly id: number;
};

export type GetDocRequestDocumentCtrType = {
	(payload: GetDocRequestDocumentPayloadType): GetDocRequestDocumentActionTypes;
};

type GetDocRequestDocumentAction = {
	type: typeof actionTypes.GET_DOC_REQUEST_DOCUMENT;
	payload: GetDocRequestDocumentPayloadType;
};

type GetDocRequestDocumentSuccessAction = {
	type: typeof actionTypes.GET_DOC_REQUEST_DOCUMENT_SUCCESS;
};

type GetDocRequestDocumentFailureAction = {
	type: typeof actionTypes.GET_DOC_REQUEST_DOCUMENT_FAILURE;
};

type GetDocRequestDocumentResetAction = {
	type: typeof actionTypes.GET_DOC_REQUEST_DOCUMENT_RESET;
};

export type GetDocRequestDocumentActionTypes =
	| GetDocRequestDocumentAction
	| GetDocRequestDocumentSuccessAction
	| GetDocRequestDocumentFailureAction
	| GetDocRequestDocumentResetAction;

/**
 * Set doc request to complete
 * DEPRECATED
 */

export type CompleteDocRequestType = {
	readonly isCompletingDocRequest: boolean;
	readonly isCompleteDocRequestSuccess: boolean;
	readonly isCompleteDocRequestError: boolean;
};

export const completeDocRequestDefaultValue: CompleteDocRequestType = {
	isCompletingDocRequest: false,
	isCompleteDocRequestSuccess: false,
	isCompleteDocRequestError: false,
};

export type CompleteDocRequestPayloadType = {
	readonly memberId: number;
	readonly requestId: number;
};

export type CompleteDocRequesttrType = {
	(payload: CompleteDocRequestPayloadType): CompleteDocRequestActionTypes;
};

type CompleteDocRequestAction = {
	type: typeof actionTypes.COMPLETE_DOC_REQUEST;
	payload: CompleteDocRequestPayloadType;
};

type CompleteDocRequestSuccessAction = {
	type: typeof actionTypes.COMPLETE_DOC_REQUEST_SUCCESS;
};

type CompleteDocRequestFailureAction = {
	type: typeof actionTypes.COMPLETE_DOC_REQUEST_FAILURE;
};

type CompleteDocRequestResetAction = {
	type: typeof actionTypes.COMPLETE_DOC_REQUEST_RESET;
};

export type CompleteDocRequestActionTypes =
	| CompleteDocRequestAction
	| CompleteDocRequestSuccessAction
	| CompleteDocRequestFailureAction
	| CompleteDocRequestResetAction;

/**
 * Move doc request state
 */

export type MoveDocRequestStateType = {
	readonly moveRequestId: number;
	readonly isMovingDocRequestState: boolean;
	readonly isMoveDocRequestStateSuccess: boolean;
	readonly isMoveDocRequestStateError: boolean;
};

export const moveDocRequestStateDefaultValue: MoveDocRequestStateType = {
	moveRequestId: 0,
	isMovingDocRequestState: false,
	isMoveDocRequestStateSuccess: false,
	isMoveDocRequestStateError: false,
};

export type MoveDocRequestStatePayloadType = {
	readonly memberId: number;
	readonly requestId: number;
	readonly state: string;
};

export type MoveDocRequestStateCtrType = {
	(payload: CompleteDocRequestPayloadType): MoveDocRequestStateActionTypes;
};

type MoveDocRequestStateAction = {
	type: typeof actionTypes.MOVE_DOC_REQUEST_STATE;
	payload: CompleteDocRequestPayloadType;
};

type MoveDocRequestStateSuccessAction = {
	type: typeof actionTypes.MOVE_DOC_REQUEST_STATE_SUCCESS;
};

type MoveDocRequestStateFailureAction = {
	type: typeof actionTypes.MOVE_DOC_REQUEST_STATE_FAILURE;
};

type MoveDocRequestStateResetAction = {
	type: typeof actionTypes.MOVE_DOC_REQUEST_STATE_RESET;
};

export type MoveDocRequestStateActionTypes =
	| MoveDocRequestStateAction
	| MoveDocRequestStateSuccessAction
	| MoveDocRequestStateFailureAction
	| MoveDocRequestStateResetAction;

/**
 * Set doc request' document to complete
 */

export type CompleteDocRequestDocumentType = {
	readonly isComplete: boolean;
	readonly requestId: number;
	readonly documentId: number;
	readonly isCompletingDocRequestDocument: boolean;
	readonly isCompleteDocRequestDocumentSuccess: boolean;
	readonly isCompleteDocRequestDocumentError: boolean;
};

export const completeDocRequestDocumentDefaultValue: CompleteDocRequestDocumentType = {
	isComplete: false,
	requestId: 0,
	documentId: 0,
	isCompletingDocRequestDocument: false,
	isCompleteDocRequestDocumentSuccess: false,
	isCompleteDocRequestDocumentError: false,
};

export type CompleteDocRequestDocumentPayloadType = {
	readonly memberId: number;
	readonly requestId: number;
	readonly documentId: number;
	readonly isComplete: boolean;
};

export type CompleteDocRequestDocumentCtrType = {
	(payload: CompleteDocRequestDocumentPayloadType): CompleteDocRequestDocumentActionTypes;
};

type CompleteDocRequestDocumentAction = {
	type: typeof actionTypes.COMPLETE_DOC_REQUEST_DOCUMENT;
	payload: CompleteDocRequestDocumentPayloadType;
};

type CompleteDocRequestDocumentSuccessAction = {
	type: typeof actionTypes.COMPLETE_DOC_REQUEST_DOCUMENT_SUCCESS;
};

type CompleteDocRequestDocumentFailureAction = {
	type: typeof actionTypes.COMPLETE_DOC_REQUEST_DOCUMENT_FAILURE;
};

type CompleteDocRequestDocumentResetAction = {
	type: typeof actionTypes.COMPLETE_DOC_REQUEST_DOCUMENT_RESET;
};

export type CompleteDocRequestDocumentActionTypes =
	| CompleteDocRequestDocumentAction
	| CompleteDocRequestDocumentSuccessAction
	| CompleteDocRequestDocumentFailureAction
	| CompleteDocRequestDocumentResetAction;

/**
 * Update docRequest's document's files
 */

export type UpdateDocRequestDocumentFilesPayloadType = {
	readonly docId: number;
	readonly requestId: number;
	readonly files: Array<ObjectType>;
};

export type UpdateDocRequestDocumentFilesPayloadCtrType = {
	(payload: UpdateDocRequestDocumentFilesPayloadType): UpdateDocRequestDocumentFilesActionTypes;
};

type UpdateDocRequestDocumentFilesAction = {
	type: typeof actionTypes.UPDATE_DOC_REQUEST_DOCUMENT_FILES;
	payload: UpdateDocRequestDocumentFilesPayloadType;
};

export type UpdateDocRequestDocumentFilesActionTypes = UpdateDocRequestDocumentFilesAction;

/**
 * RemoveDoc docs File
 */

export type RemoveDocFileType = {
	readonly fileKey: string;
	readonly isRemovingFile: boolean;
	readonly isRemoveFileSuccess: boolean;
	readonly isRemoveFileError: boolean;
};

export const removeDocFileDefaultValue: RemoveDocFileType = {
	fileKey: "",
	isRemovingFile: false,
	isRemoveFileSuccess: false,
	isRemoveFileError: false,
};

export type RemoveDocFilePayloadType = {
	readonly memberId: number;
	readonly docId: number;
	readonly key: string;
};

type RemoveDocFileAction = {
	type: typeof actionTypes.REMOVE_DOCUMENT_FILE;
	payload: RemoveDocFilePayloadType;
};

type RemoveDocFileSuccessAction = {
	type: typeof actionTypes.REMOVE_DOCUMENT_FILE_SUCCESS;
};

type RemoveDocFileFailureAction = {
	type: typeof actionTypes.REMOVE_DOCUMENT_FILE_FAILURE;
};

type RemoveDocFileResetAction = {
	type: typeof actionTypes.REMOVE_DOCUMENT_FILE_RESET;
};

export type RemoveDocFileActionTypes =
	| RemoveDocFileAction
	| RemoveDocFileResetAction
	| RemoveDocFileSuccessAction
	| RemoveDocFileFailureAction;

/**
 * Remind
 */

export type RemindDocRequestType = {
	readonly remindRequestId: number;
	readonly isRemindingDocRequest: boolean;
	readonly isRemindDocRequestSuccess: boolean;
	readonly isRemindDocRequestError: boolean;
};

export const remindDocRequestDefaultValue: RemindDocRequestType = {
	remindRequestId: 0,
	isRemindingDocRequest: false,
	isRemindDocRequestSuccess: false,
	isRemindDocRequestError: false,
};

export type RemindDocRequestPayloadType = {
	readonly memberId: number;
	readonly requestId: number;
};

type RemindDocRequestAction = {
	type: typeof actionTypes.REMIND_DOC_REQUEST;
	payload: RemindDocRequestPayloadType;
};

type RemindDocRequestSuccessAction = {
	type: typeof actionTypes.REMIND_DOC_REQUEST_SUCCESS;
};

type RemindDocRequestFailureAction = {
	type: typeof actionTypes.REMIND_DOC_REQUEST_FAILURE;
};

type RemindDocRequestResetAction = {
	type: typeof actionTypes.REMIND_DOC_REQUEST_RESET;
};

export type RemindDocRequestActionTypes =
	| RemindDocRequestAction
	| RemindDocRequestResetAction
	| RemindDocRequestSuccessAction
	| RemindDocRequestFailureAction;

/**
 * Remove Activity
 */

export type RemoveDocumentActivityType = {
	readonly documentId: number;
	readonly requestId: number;
	readonly isRemovingDocumentActivity: boolean;
	readonly isRemoveDocumentActivitySuccess: boolean;
	readonly isRemoveDocumentActivityError: boolean;
};

export const removeDocumentActivityDefaultValue: RemoveDocumentActivityType = {
	documentId: 0,
	requestId: 0,
	isRemovingDocumentActivity: false,
	isRemoveDocumentActivitySuccess: false,
	isRemoveDocumentActivityError: false,
};

export type RemoveDocumentActivityPayloadType = {
	readonly memberId: number;
	readonly requestId: number;
	readonly documentId: number;
};

type RemoveDocumentActivityAction = {
	type: typeof actionTypes.REMOVE_DOCUMENT_ACTIVITY;
	payload: RemoveDocumentActivityPayloadType;
};

type RemoveDocumentActivitySuccessAction = {
	type: typeof actionTypes.REMOVE_DOCUMENT_ACTIVITY_SUCCESS;
};

type RemoveDocumentActivityFailureAction = {
	type: typeof actionTypes.REMOVE_DOCUMENT_ACTIVITY_FAILURE;
};

type RemoveDocumentActivityResetAction = {
	type: typeof actionTypes.REMOVE_DOCUMENT_ACTIVITY_RESET;
};

export type RemoveDocumentActivityActionTypes =
	| RemoveDocumentActivityAction
	| RemoveDocumentActivityResetAction
	| RemoveDocumentActivitySuccessAction
	| RemoveDocumentActivityFailureAction;
