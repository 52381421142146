import * as actionTypes from "./actionTypes";

/**
 * Create fund
 */
export type AddAccountType = {
	readonly id: number;
	readonly firstName: string;
	readonly lastName: string;
	readonly email: string;
	readonly phone: string;
	readonly administratorBrand?: string;
	readonly isCreating: boolean;
	readonly isSuccess: boolean;
	readonly isError: boolean;
	readonly error: { [key: string]: Array<string> };
};

export const addAccountDefaultValue: AddAccountType = {
	id: 0,
	firstName: "",
	lastName: "",
	email: "",
	phone: "",
	administratorBrand: "",
	isCreating: false,
	isSuccess: false,
	isError: false,
	error: {},
};

export type AddAccountPayloadType = {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	administratorBrand?: string;
};

export type AddAccount = {
	(payload: AddAccountPayloadType): AddAccountActionTypes;
};

export type AddAccountReset = {
	(): AddAccountActionTypes;
};

type AddAccountAction = {
	type: typeof actionTypes.ADD_ACCOUNT;
	payload: AddAccountPayloadType;
};

type AddAccountSuccessAction = {
	type: typeof actionTypes.ADD_ACCOUNT_SUCCESS;
};

type AddAccountFailureAction = {
	type: typeof actionTypes.ADD_ACCOUNT_FAILURE;
};

type AddAccountResetAction = {
	type: typeof actionTypes.ADD_ACCOUNT_RESET;
};

export type AddAccountActionTypes =
	| AddAccountAction
	| AddAccountSuccessAction
	| AddAccountFailureAction
	| AddAccountResetAction;

/**
 * Edit fund
 */
export type EditAccountType = {
	readonly isEditing: boolean;
	readonly isSuccess: boolean;
	readonly isError: boolean;
	readonly error: { [key: string]: Array<string> };
};

export const editAccountDefaultValue: EditAccountType = {
	isEditing: false,
	isSuccess: false,
	isError: false,
	error: {},
};

export type EditAccountPayloadType = {
	id: number;
	body: {
		firstName: string;
		lastName: string;
		email: string;
		phone: string;
		brand?: string;
	};
};

export type EditAccount = {
	(payload: EditAccountPayloadType): EditAccountActionTypes;
};

export type EditAccountReset = {
	(): EditAccountActionTypes;
};

type EditAccountAction = {
	type: typeof actionTypes.EDIT_ACCOUNT;
	payload: EditAccountPayloadType;
};

type EditAccountSuccessAction = {
	type: typeof actionTypes.EDIT_ACCOUNT_SUCCESS;
};

type EditAccountFailureAction = {
	type: typeof actionTypes.EDIT_ACCOUNT_FAILURE;
};

type EditAccountResetAction = {
	type: typeof actionTypes.EDIT_ACCOUNT_RESET;
};

export type EditAccountActionTypes =
	| EditAccountAction
	| EditAccountSuccessAction
	| EditAccountFailureAction
	| EditAccountResetAction;

/**
 * Archive fund
 */
export type ArchiveFundDetailsType = {
	readonly id: number;
	readonly isArchiving: boolean;
	readonly isArchiveSuccess: boolean;
	readonly isArchiveError: boolean;
};

export const archiveFundDetailsDefaultValue: ArchiveFundDetailsType = {
	id: 0,
	isArchiving: false,
	isArchiveSuccess: false,
	isArchiveError: false,
};

export type ArchiveFundDetailsPayloadType = {
	id: number;
};

export type ArchiveFundDetails = {
	(payload: ArchiveFundDetailsPayloadType): ArchiveFundDetailsActionTypes;
};

export type ArchiveFundDetailsAction = {
	type: typeof actionTypes.ARCHIVE_FUND_DETAILS;
	payload: ArchiveFundDetailsPayloadType;
};

export type ArchiveFundDetailsSuccessAction = {
	type: typeof actionTypes.ARCHIVE_FUND_DETAILS_SUCCESS;
};

export type ArchiveFundDetailsFailureAction = {
	type: typeof actionTypes.ARCHIVE_FUND_DETAILS_FAILURE;
};

export type ArchiveFundDetailsResetAction = {
	type: typeof actionTypes.ARCHIVE_FUND_DETAILS_RESET;
};

export type ArchiveFundDetailsActionTypes =
	| ArchiveFundDetailsAction
	| ArchiveFundDetailsSuccessAction
	| ArchiveFundDetailsFailureAction
	| ArchiveFundDetailsResetAction;

/**
 * Restore fund
 */
export type RestoreFundDetailsType = {
	readonly id: number;
	readonly isRestoring: boolean;
	readonly isRestoreSuccess: boolean;
	readonly isRestoreError: boolean;
};

export const restoreFundDetailsDefaultValue: RestoreFundDetailsType = {
	id: 0,
	isRestoring: false,
	isRestoreSuccess: false,
	isRestoreError: false,
};

export type RestoreFundDetailsPayloadType = {
	id: number;
};

export type RestoreFundDetails = {
	(payload: RestoreFundDetailsPayloadType): RestoreFundDetailsActionTypes;
};

export type RestoreFundDetailsAction = {
	type: typeof actionTypes.RESTORE_FUND_DETAILS;
	payload: RestoreFundDetailsPayloadType;
};

export type RestoreFundDetailsSuccessAction = {
	type: typeof actionTypes.RESTORE_FUND_DETAILS_SUCCESS;
};

export type RestoreFundDetailsFailureAction = {
	type: typeof actionTypes.RESTORE_FUND_DETAILS_FAILURE;
};

export type RestoreFundDetailsResetAction = {
	type: typeof actionTypes.RESTORE_FUND_DETAILS_RESET;
};

export type RestoreFundDetailsActionTypes =
	| RestoreFundDetailsAction
	| RestoreFundDetailsSuccessAction
	| RestoreFundDetailsFailureAction
	| RestoreFundDetailsResetAction;

/**
 * Delete fund
 */
export type DeleteFundDetailsType = {
	readonly id: number;
	readonly isDeleting: boolean;
	readonly isDeleteSuccess: boolean;
	readonly isDeleteError: boolean;
};

export const deleteFundDetailsDefaultValue: DeleteFundDetailsType = {
	id: 0,
	isDeleting: false,
	isDeleteSuccess: false,
	isDeleteError: false,
};

export type DeleteFundDetailsPayloadType = {
	id: number;
};

export type DeleteFundDetails = {
	(payload: DeleteFundDetailsPayloadType): DeleteFundDetailsActionTypes;
};

export type DeleteFundDetailsAction = {
	type: typeof actionTypes.DELETE_FUND_DETAILS;
	payload: DeleteFundDetailsPayloadType;
};

export type DeleteFundDetailsSuccessAction = {
	type: typeof actionTypes.DELETE_FUND_DETAILS_SUCCESS;
};

export type DeleteFundDetailsFailureAction = {
	type: typeof actionTypes.DELETE_FUND_DETAILS_FAILURE;
};

export type DeleteFundDetailsResetAction = {
	type: typeof actionTypes.DELETE_FUND_DETAILS_RESET;
};

export type DeleteFundDetailsActionTypes =
	| DeleteFundDetailsAction
	| DeleteFundDetailsSuccessAction
	| DeleteFundDetailsFailureAction
	| DeleteFundDetailsResetAction;

/**
 * Deactivate fund
 */
export type DeactivateFundDetailsType = {
	readonly id: number;
	readonly isDeactivating: boolean;
	readonly isDeactivateSuccess: boolean;
	readonly isDeactivateError: boolean;
};

export const deactivateFundDetailsDefaultValue: DeactivateFundDetailsType = {
	id: 0,
	isDeactivating: false,
	isDeactivateSuccess: false,
	isDeactivateError: false,
};

export type DeactivateFundDetailsPayloadType = {
	id: number;
};

export type DeactivateFundDetails = {
	(payload: DeactivateFundDetailsPayloadType): DeactivateFundDetailsActionTypes;
};

export type DeactivateFundDetailsAction = {
	type: typeof actionTypes.DEACTIVATE_FUND_DETAILS;
	payload: DeactivateFundDetailsPayloadType;
};

export type DeactivateFundDetailsSuccessAction = {
	type: typeof actionTypes.DEACTIVATE_FUND_DETAILS_SUCCESS;
};

export type DeactivateFundDetailsFailureAction = {
	type: typeof actionTypes.DEACTIVATE_FUND_DETAILS_FAILURE;
};

export type DeactivateFundDetailsResetAction = {
	type: typeof actionTypes.DEACTIVATE_FUND_DETAILS_RESET;
};

export type DeactivateFundDetailsActionTypes =
	| DeactivateFundDetailsAction
	| DeactivateFundDetailsSuccessAction
	| DeactivateFundDetailsFailureAction
	| DeactivateFundDetailsResetAction;

// Low balance Flag Overide

export type OverrideLowBalanceFlagType = {
	readonly isOveridingLowBalanceFlag: boolean;
	readonly isOverideLowBalanceFlagSuccess: boolean;
	readonly isOverideLowBalanceFlagSuccessError: boolean;
};

export const overrideLowBalanceFlagDefaultValue: OverrideLowBalanceFlagType = {
	isOveridingLowBalanceFlag: false,
	isOverideLowBalanceFlagSuccess: false,
	isOverideLowBalanceFlagSuccessError: false,
};

export type OverrideLowBalanceFlagPayloadType = {
	readonly memberId: number;
};

export type OverrideLowBalanceFlagCtrType = {
	(payload: OverrideLowBalanceFlagPayloadType): OverrideLowBalanceFlagActionTypes;
};

type OverrideLowBalanceFlagAction = {
	type: typeof actionTypes.OVERRIDE_LOW_BALANCE_FLAG;
	payload: OverrideLowBalanceFlagPayloadType;
};

type OverrideLowBalanceFlagSuccessAction = {
	type: typeof actionTypes.OVERRIDE_LOW_BALANCE_FLAG_SUCCESS;
};

type OverrideLowBalanceFlagFailureAction = {
	type: typeof actionTypes.OVERRIDE_LOW_BALANCE_FLAG_FAILURE;
};

type OverrideLowBalanceFlagResetAction = {
	type: typeof actionTypes.OVERRIDE_LOW_BALANCE_FLAG_RESET;
};

export type OverrideLowBalanceFlagActionTypes =
	| OverrideLowBalanceFlagAction
	| OverrideLowBalanceFlagSuccessAction
	| OverrideLowBalanceFlagFailureAction
	| OverrideLowBalanceFlagResetAction;

// Get live fund value

export type AssetClassDetailsType = {
	readonly assetClassId: string;
	readonly assetClassName: string;
	readonly valueOfAssetsHeld: number;
	readonly marketValueOfAssetsHeld: number;
	readonly unsettledPositionOnAssetsHeld: number;
	readonly assetStrategyMinPercent: number;
	readonly assetStrategyMaxPercent: number;
	readonly assetStrategyOverallPercent: number;
};

export type AssetClassesType = AssetClassDetailsType[];

export type PortfolioAssetPositionsDetailsType = {
	readonly portfolioId: string;
	readonly portfolioName: string;
	readonly overallValue: number;
	readonly assetPercentagesInvalid: boolean;
	readonly assetStrategyEffectiveDate: string;
	readonly assetClassDetailList: AssetClassesType;
};

export type PortfolioAssetPositionsType = PortfolioAssetPositionsDetailsType[];

export type LiveFundValueType = {
	readonly fundCode: string;
	readonly fundName: string;
	readonly atDate: string;
	readonly portfolioAssetPositions: PortfolioAssetPositionsType;
};

export type GetLiveFundValueType = {
	readonly liveFundValue: LiveFundValueType;
	readonly isGettingLiveFundValue: boolean;
	readonly isGetLiveFundValueSuccess: boolean;
	readonly isGetLiveFundValueError: boolean;
};

export const getLiveFundValueDefaultValue: GetLiveFundValueType = {
	liveFundValue: {
		fundCode: "",
		fundName: "",
		atDate: "",
		portfolioAssetPositions: [],
	},
	isGettingLiveFundValue: false,
	isGetLiveFundValueSuccess: false,
	isGetLiveFundValueError: false,
};

export type GetLiveFundValueTypePayloadType = {
	readonly fundId: string;
};

export type GetLiveFundValueCtrType = {
	(payload: GetLiveFundValueTypePayloadType): GetLiveFundValueTypeActionTypes;
};

type GetLiveFundValueTypeAction = {
	type: typeof actionTypes.GET_LIVE_FUND_VALUE;
	payload: GetLiveFundValueTypePayloadType;
};

type GetLiveFundValueTypeSuccessAction = {
	type: typeof actionTypes.GET_LIVE_FUND_VALUE_SUCCESS;
};

type GetLiveFundValueTypeFailureAction = {
	type: typeof actionTypes.GET_LIVE_FUND_VALUE_FAILURE;
};

type GetLiveFundValueTypeResetAction = {
	type: typeof actionTypes.GET_LIVE_FUND_VALUE_RESET;
};

export type GetLiveFundValueTypeActionTypes =
	| GetLiveFundValueTypeAction
	| GetLiveFundValueTypeSuccessAction
	| GetLiveFundValueTypeFailureAction
	| GetLiveFundValueTypeResetAction;
