import { StructureType } from "__pages/FundDetails/__redux/types";

const getACN = ({ acn, fundType }: StructureType): string | undefined => {
	if (fundType === "Corporate") return acn;
	return "";
};

const getCompanyName = ({ companyName, fundType }: StructureType): string | undefined => {
	if (fundType === "Corporate") return companyName;
	return "";
};

export const prepareFundStructure = (fundStructure: StructureType): StructureType => {
	const _fundStructure = {
		...fundStructure,
		fundOrigin: fundStructure.fundOrigin,
		fundType: fundStructure.fundType,
		acn: getACN(fundStructure),
		abn: fundStructure.abn,
		companyName: getCompanyName(fundStructure),
		asicCompanyName: `${getCompanyName(fundStructure)?.toUpperCase()} PTY LTD`,
	};

	return _fundStructure;
};

export const toTitleCase = (value: string): string =>
	value.replace(/\w\S*/g, (txt: string) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
