import * as actionTypes from "./actionTypes";
import {
	AddAdminActionTypes,
	AddBrandActionTypes,
	AddBrandPayloadType,
	GetAdminLevelsActionTypes,
	AdminPayloadType,
	DeleteAdminPayloadType,
	DeleteAminActionTypes,
	EditAdminActionTypes,
	EditBrandActionTypes,
	EditBrandPayloadType,
	GetBrandActionTypes,
	GetBrandsOnScrollActionTypes,
	GetBrandsOnScrollPayloadType,
} from "./types";

// BRANDCTR

export const getBrandsCtr = (): GetBrandActionTypes => ({
	type: actionTypes.GET_BRANDS,
});

export const getBrandsResetCtr = (): GetBrandActionTypes => ({
	type: actionTypes.GET_BRANDS_RESET,
});

// Get brands on scroll
export const getBrandsOnScrollCtr = (payload: GetBrandsOnScrollPayloadType): GetBrandsOnScrollActionTypes => ({
	type: actionTypes.GET_BRANDS_ON_SCROLL,
	payload,
});

// ADMINLEVELCTR
export const getAdminLevelsCtr = (): GetAdminLevelsActionTypes => ({
	type: actionTypes.GET_ADMIN_LEVELS,
});

export const getAdminLevelsResetCtr = (): GetAdminLevelsActionTypes => ({
	type: actionTypes.GET_ADMIN_LEVELS_RESET,
});

// Add Brand
export const addBrandCtr = (payload: AddBrandPayloadType): AddBrandActionTypes => ({
	type: actionTypes.ADD_BRAND,
	payload,
});

// Reset Add Brand

export const addBrandResetCtr = (): AddBrandActionTypes => ({
	type: actionTypes.ADD_BRAND_RESET,
});

// Edit Brand
export const editBrandCtr = (payload: EditBrandPayloadType): EditBrandActionTypes => ({
	type: actionTypes.EDIT_BRAND,
	payload,
});

// Reset Edit Brand

export const editBrandResetCtr = (): EditBrandActionTypes => ({
	type: actionTypes.EDIT_BRAND_RESET,
});

// Add User
export const addAdminCtr = (payload: AdminPayloadType): AddAdminActionTypes => ({
	type: actionTypes.ADD_ADMIN,
	payload,
});

// Reset add Admin
export const addAdminResetCtr = (): AddAdminActionTypes => ({
	type: actionTypes.ADD_ADMIN_RESET,
});

// Edit Admin
export const editAdminCtr = (payload: AdminPayloadType): EditAdminActionTypes => ({
	type: actionTypes.EDIT_ADMIN,
	payload,
});
// Reset Edit Admin
export const editAdminResetCtr = (): EditAdminActionTypes => ({
	type: actionTypes.EDIT_ADMIN_RESET,
});

// delete Edit Admin
export const deleteAdminCtr = (payload: DeleteAdminPayloadType): DeleteAminActionTypes => ({
	type: actionTypes.DELETE_ADMIN,
	payload,
});

// reset Edit Admin

export const deleteAdminResetCtr = (): DeleteAminActionTypes => ({
	type: actionTypes.DELETE_ADMIN_RESET,
});
