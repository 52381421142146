import { ApiErrorType } from "__utils/types";
import * as actionTypes from "./actionTypes";

/**
 * MoveWorkflowFundDetails
 */
export type IMoveWorkflowFundDetailsGenerateDocsState = {
	readonly movingWorkflowFundDetailsGenerateDocsState: boolean;
	readonly moveWorkflowFundDetailsGenerateDocsStateSuccess: boolean;
	readonly moveWorkflowFundDetailsGenerateDocsStateFailure: boolean;
	readonly moveWorkflowFundDetailsGenerateDocsStateError: ApiErrorType;
};

export const moveWorkflowFundDetailsGenerateDocsStateDefaultValue: IMoveWorkflowFundDetailsGenerateDocsState = {
	movingWorkflowFundDetailsGenerateDocsState: false,
	moveWorkflowFundDetailsGenerateDocsStateSuccess: false,
	moveWorkflowFundDetailsGenerateDocsStateFailure: false,
	moveWorkflowFundDetailsGenerateDocsStateError: {},
};

export type IMoveWorkflowFundDetailsGenerateDocsStatePayload = {
	readonly member: number;
	readonly envelopeId: string;
};

export type MoveWorkflowFundDetailsGenerateDocsState = {
	(payload: IMoveWorkflowFundDetailsGenerateDocsStatePayload): IMoveWorkflowFundDetailsGenerateDocsStateActionTypes;
};

export type IMoveWorkflowFundDetailsGenerateDocsStateAction = {
	type: typeof actionTypes.MOVE_WORKFLOW_FUND_DETAILS_GENERATE_DOCS;
	payload: IMoveWorkflowFundDetailsGenerateDocsStatePayload;
};

export type IMoveWorkflowFundDetailsGenerateDocsStateSuccessAction = {
	type: typeof actionTypes.MOVE_WORKFLOW_FUND_DETAILS_GENERATE_DOCS_SUCCESS;
};

export type IMoveWorkflowFundDetailsGenerateDocsStateFailureAction = {
	type: typeof actionTypes.MOVE_WORKFLOW_FUND_DETAILS_GENERATE_DOCS_FAILURE;
};

export type IMoveWorkflowFundDetailsGenerateDocsStateResetAction = {
	type: typeof actionTypes.MOVE_WORKFLOW_FUND_DETAILS_GENERATE_DOCS_RESET;
};

export type IMoveWorkflowFundDetailsGenerateDocsStateActionTypes =
	| IMoveWorkflowFundDetailsGenerateDocsStateAction
	| IMoveWorkflowFundDetailsGenerateDocsStateSuccessAction
	| IMoveWorkflowFundDetailsGenerateDocsStateFailureAction
	| IMoveWorkflowFundDetailsGenerateDocsStateResetAction;
