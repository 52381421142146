import message from "antd/es/message";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import ErrorBoundary from "__common/ErrorBoundary";
import AuthLoader from "__layout/AuthLoader";
import PrivateRoute from "__layout/PrivateRoute";
import { getReminderCountCtr } from "__pages/ManageReminders/__redux/actions";
import NotFound from "__pages/NotFound";
import { getUserProfileCtr, verifyUserAuthCtr } from "__pages/User/__redux/actions";
import { userAuthSlr, userProfileSlr } from "__pages/User/__redux/selectors";
import { UserProfileType, VerifyUserAuthType } from "__pages/User/__redux/types";
import { commonValues } from "__styles/common_values";
import "__styles/custom_antd.css";
import { RouteType } from "__utils/types";
import routes from "./routes";
import { AntOverridesStyle, GlobalStyle } from "./__styles/global";
import { isUserAdmin } from "./__utils/helpers";

// Global config form toast message
message.config({ top: 0 });

type Props = unknown;

const App: React.FC<Props> = () => {
	const dispatch = useDispatch();
	const userAuth: VerifyUserAuthType = useSelector(userAuthSlr);
	const userProfile: UserProfileType = useSelector(userProfileSlr);
	const { isSuccess, isVerifying } = userAuth;
	const { isGetting, level, isSuccess: _isSuccess } = userProfile;

	// Get Reminder Count
	React.useEffect(() => {
		if (isUserAdmin(level) && _isSuccess) {
			dispatch(getReminderCountCtr());
		}
	}, [dispatch, level, _isSuccess]);

	// Verify user auth
	React.useEffect(() => {
		dispatch(verifyUserAuthCtr());
	}, [dispatch]);

	// Get user profile
	React.useEffect(() => {
		if (isSuccess) {
			dispatch(getUserProfileCtr());
		}
	}, [isSuccess, dispatch]);

	// Show loader while verifying auth
	if (isVerifying) {
		return <AuthLoader isUserAuthVerfied={false} />;
	}

	// Show loader while getting user profile
	if (isGetting) {
		return <AuthLoader isUserAuthVerfied />;
	}

	return (
		<Router>
			<ThemeProvider theme={commonValues}>
				<ErrorBoundary>
					<AntOverridesStyle />
					<GlobalStyle />
					<React.Suspense fallback={null}>
						<Switch>
							{routes.map((item: RouteType) => {
								if (item.isPrivate) {
									return <PrivateRoute {...item} />;
								}
								return <Route {...item} />;
							})}
							<Route>
								<NotFound />
							</Route>
						</Switch>
					</React.Suspense>
				</ErrorBoundary>
			</ThemeProvider>
		</Router>
	);
};

export default App;
