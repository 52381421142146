import React, { Dispatch, SetStateAction } from "react";
import { GetFundDetailsType, StructureType } from "__pages/FundDetails/__redux/types";
import AddFundStructure from "./FundStructureForm/AddFundStructure";
import EditFundStructure from "./FundStructureForm/EditFundStructure";
import { Container } from "./style";

type Props = {
	fund: GetFundDetailsType;
	isSubmitAddFundStructure: boolean;
	isSubmitEditFundStructure: boolean;
	setFundStructureLocalValues: Dispatch<SetStateAction<StructureType>>;
	fundStructureLocalValues: StructureType;
	setSubmitAddFundStructure: Dispatch<SetStateAction<boolean>>;
	setSubmitEditFundStructure: Dispatch<SetStateAction<boolean>>;
};

const Member: React.FC<Props> = ({
	fund,
	isSubmitAddFundStructure,
	isSubmitEditFundStructure,
	setFundStructureLocalValues,
	fundStructureLocalValues,
	setSubmitAddFundStructure,
	setSubmitEditFundStructure,
}: Props) => {
	const { fundStructure } = fund;
	return (
		<Container>
			{fundStructure.fundOrigin === "" ? (
				<AddFundStructure
					fund={fund}
					isSubmitAddFundStructure={isSubmitAddFundStructure}
					setFundStructureLocalValues={setFundStructureLocalValues}
					fundStructureLocalValues={fundStructureLocalValues}
					setSubmitAddFundStructure={setSubmitAddFundStructure}
				/>
			) : (
				<EditFundStructure
					fund={fund}
					isSubmitEditFundStructure={isSubmitEditFundStructure}
					setSubmitEditFundStructure={setSubmitEditFundStructure}
				/>
			)}
		</Container>
	);
};

export default Member;
