import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

import {
	AddAccountActionTypes,
	AddAccountType,
	ArchiveFundDetailsActionTypes,
	ArchiveFundDetailsType,
	DeleteFundDetailsActionTypes,
	DeleteFundDetailsType,
	EditAccountActionTypes,
	EditAccountType,
	GetLiveFundValueType,
	GetLiveFundValueTypeActionTypes,
	OverrideLowBalanceFlagActionTypes,
	OverrideLowBalanceFlagType,
	RestoreFundDetailsActionTypes,
	RestoreFundDetailsType,
	addAccountDefaultValue,
	archiveFundDetailsDefaultValue,
	deleteFundDetailsDefaultValue,
	editAccountDefaultValue,
	getLiveFundValueDefaultValue,
	overrideLowBalanceFlagDefaultValue,
	restoreFundDetailsDefaultValue,
	deactivateFundDetailsDefaultValue,
	DeactivateFundDetailsActionTypes,
	DeactivateFundDetailsType,
} from "./types";

export const editAccountReducer = (
	state = editAccountDefaultValue,
	action: EditAccountActionTypes
): EditAccountType => {
	switch (action.type) {
		case actionTypes.EDIT_ACCOUNT:
			return {
				...state,
				isEditing: true,
			};
		case actionTypes.EDIT_ACCOUNT_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isError: false,
				isSuccess: true,
				isEditing: false,
				error: {},
			};
		case actionTypes.EDIT_ACCOUNT_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isEditing: false,
				isError: true,
				isSuccess: false,
			};
		case actionTypes.EDIT_ACCOUNT_RESET:
			return {
				...state,
				...editAccountDefaultValue,
			};
		default:
			return state;
	}
};

export const addAccountReducer = (state = addAccountDefaultValue, action: AddAccountActionTypes): AddAccountType => {
	switch (action.type) {
		case actionTypes.ADD_ACCOUNT:
			return {
				...state,
				...(action as any).payload,
				isCreating: true,
			};
		case actionTypes.ADD_ACCOUNT_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isError: false,
				isSuccess: true,
				isCreating: false,
				error: {},
			};
		case actionTypes.ADD_ACCOUNT_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
			};
		case actionTypes.ADD_ACCOUNT_RESET:
			return {
				...state,
				...addAccountDefaultValue,
			};
		default:
			return state;
	}
};

export const archiveFundDetailsReducer = (
	state = archiveFundDetailsDefaultValue,
	action: ArchiveFundDetailsActionTypes
): ArchiveFundDetailsType => {
	switch (action.type) {
		case actionTypes.ARCHIVE_FUND_DETAILS:
			return {
				...state,
				id: (action as any).payload.id,
				isArchiving: true,
			};
		case actionTypes.ARCHIVE_FUND_DETAILS_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isArchiving: false,
				isArchiveSuccess: true,
				isArchiveError: false,
			};
		case actionTypes.ARCHIVE_FUND_DETAILS_FAILURE:
			return {
				...state,
				isArchiving: false,
				isArchiveError: true,
			};

		case actionTypes.ARCHIVE_FUND_DETAILS_RESET:
			return {
				...archiveFundDetailsDefaultValue,
			};
		default:
			return state;
	}
};

export const restoreFundDetailsReducer = (
	state = restoreFundDetailsDefaultValue,
	action: RestoreFundDetailsActionTypes
): RestoreFundDetailsType => {
	switch (action.type) {
		case actionTypes.RESTORE_FUND_DETAILS:
			return {
				...state,
				id: (action as any).payload.id,
				isRestoring: true,
			};
		case actionTypes.RESTORE_FUND_DETAILS_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isRestoring: false,
				isRestoreSuccess: true,
				isRestoreError: false,
			};
		case actionTypes.RESTORE_FUND_DETAILS_FAILURE:
			return {
				...state,
				isRestoring: false,
				isRestoreError: true,
			};

		case actionTypes.RESTORE_FUND_DETAILS_RESET:
			return {
				...restoreFundDetailsDefaultValue,
			};
		default:
			return state;
	}
};

export const deleteFundDetailsReducer = (
	state = deleteFundDetailsDefaultValue,
	action: DeleteFundDetailsActionTypes
): DeleteFundDetailsType => {
	switch (action.type) {
		case actionTypes.DELETE_FUND_DETAILS:
			return {
				...state,
				id: (action as any).payload.id,
				isDeleting: true,
			};
		case actionTypes.DELETE_FUND_DETAILS_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isDeleting: false,
				isDeleteSuccess: true,
				isDeleteError: false,
			};
		case actionTypes.DELETE_FUND_DETAILS_FAILURE:
			return {
				...state,
				isDeleting: false,
				isDeleteSuccess: false,
				isDeleteError: true,
			};
		case actionTypes.DELETE_FUND_DETAILS_RESET:
			return {
				...deleteFundDetailsDefaultValue,
			};
		default:
			return state;
	}
};

export const deactivateFundDetailsReducer = (
	state = deactivateFundDetailsDefaultValue,
	action: DeactivateFundDetailsActionTypes
): DeactivateFundDetailsType => {
	switch (action.type) {
		case actionTypes.DEACTIVATE_FUND_DETAILS:
			return {
				...state,
				id: (action as any).payload.id,
				isDeactivating: true,
			};
		case actionTypes.DEACTIVATE_FUND_DETAILS_SUCCESS:
			const { type: successType, ...successRest } = action;

			return {
				...state,
				...successRest,
				isDeactivating: false,
				isDeactivateSuccess: true,
				isDeactivateError: false,
			};
		case actionTypes.DEACTIVATE_FUND_DETAILS_FAILURE:
			return {
				...state,
				isDeactivating: false,
				isDeactivateSuccess: false,
				isDeactivateError: true,
			};
		case actionTypes.DEACTIVATE_FUND_DETAILS_RESET:
			return {
				...deactivateFundDetailsDefaultValue,
			};
		default:
			return state;
	}
};

export const overrideLowBalanceFlagReducer = (
	state = overrideLowBalanceFlagDefaultValue,
	action: OverrideLowBalanceFlagActionTypes
): OverrideLowBalanceFlagType => {
	switch (action.type) {
		case actionTypes.OVERRIDE_LOW_BALANCE_FLAG:
			return {
				...state,
				isOveridingLowBalanceFlag: true,
			};
		case actionTypes.OVERRIDE_LOW_BALANCE_FLAG_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isOverideLowBalanceFlagSuccessError: false,
				isOverideLowBalanceFlagSuccess: true,
				isOveridingLowBalanceFlag: false,
			};
		case actionTypes.OVERRIDE_LOW_BALANCE_FLAG_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isOveridingLowBalanceFlag: false,
				isOverideLowBalanceFlagSuccessError: true,
				isOverideLowBalanceFlagSuccess: false,
			};
		case actionTypes.OVERRIDE_LOW_BALANCE_FLAG_RESET:
			return {
				...state,
				...overrideLowBalanceFlagDefaultValue,
			};
		default:
			return state;
	}
};

export const getLiveFundValueReducer = (
	state = getLiveFundValueDefaultValue,
	action: GetLiveFundValueTypeActionTypes
): GetLiveFundValueType => {
	switch (action.type) {
		case actionTypes.GET_LIVE_FUND_VALUE:
			return {
				...state,
				isGettingLiveFundValue: true,
			};
		case actionTypes.GET_LIVE_FUND_VALUE_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isGettingLiveFundValue: false,
				isGetLiveFundValueSuccess: true,
				isGetLiveFundValueError: false,
			};
		case actionTypes.GET_LIVE_FUND_VALUE_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isGettingLiveFundValue: false,
				isGetLiveFundValueSuccess: false,
				isGetLiveFundValueError: true,
			};
		case actionTypes.GET_LIVE_FUND_VALUE_RESET:
			return {
				...state,
				...getLiveFundValueDefaultValue,
			};
		default:
			return state;
	}
};

export const accountDetailsReducer = combineReducers({
	editAccount: editAccountReducer,
	addAccount: addAccountReducer,
	archiveFundDetails: archiveFundDetailsReducer,
	restoreFundDetails: restoreFundDetailsReducer,
	deleteFundDetails: deleteFundDetailsReducer,
	deactivateFundDetails: deactivateFundDetailsReducer,
	overrideLowBalanceFlag: overrideLowBalanceFlagReducer,
	getLiveFundValue: getLiveFundValueReducer,
});
