export const GET_DOCUMENT_TREE = "GET_DOCUMENT_TREE";
export const GET_DOCUMENT_TREE_SUCCESS = "GET_DOCUMENT_TREE_SUCCESS";
export const GET_DOCUMENT_TREE_FAILURE = "GET_DOCUMENT_TREE_FAILURE";
export const GET_DOCUMENT_TREE_RESET = "GET_DOCUMENT_TREE_RESET";

export const DELETE_DOCUMENT_TREE_FILE = "DELETE_DOCUMENT_TREE_FILE";
export const DELETE_DOCUMENT_TREE_FILE_SUCESS = "DELETE_DOCUMENT_TREE_FILE_SUCESS";
export const DELETE_DOCUMENT_TREE_FILE_FAILURE = "DELETE_DOCUMENT_TREE_FILE_FAILURE";
export const DELETE_DOCUMENT_TREE_FILE_RESET = "DELETE_DOCUMENT_TREE_FILE_RESET";

export const DOWNLOAD_DOCUMENT = "DOWNLOAD_DOCUMENT";
export const DOWNLOAD_DOCUMENT_SUCCESS = "DOWNLOAD_DOCUMENT_SUCCESS";
export const DOWNLOAD_DOCUMENT_FAILURE = "DOWNLOAD_DOCUMENT_FAILURE";
export const DOWNLOAD_DOCUMENT_RESET = "DOWNLOAD_DOCUMENT_RESET";

export const GET_DOCS_FOLDER_DETAILS = "GET_DOCS_FOLDER_DETAILS";
export const GET_DOCS_FOLDER_DETAILS_SUCCESS = "GET_DOCS_FOLDER_DETAILS_SUCCESS";
export const GET_DOCS_FOLDER_DETAILS_FAILURE = "GET_DOCS_FOLDER_DETAILS_FAILURE";
export const GET_DOCS_FOLDER_DETAILS_RESET = "GET_DOCS_FOLDER_DETAILS_RESET";
