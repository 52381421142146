import Button from "antd/es/button";
import Checkbox from "antd/es/checkbox";
import message from "antd/es/message";
import Typography from "antd/es/typography";
import React, { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createFundCtr, createFundResetCtr } from "__pages/FundDetails/__redux/actions";
import { createFundSlr } from "__pages/FundDetails/__redux/selectors";
import { CreateFundType, GetFundDetailsType } from "__pages/FundDetails/__redux/types";
import Space from "antd/es/space";
import * as lang from "../../__utils/lang";
import { Container } from "./style";

const { Title, Paragraph } = Typography;

type Props = {
	fund: GetFundDetailsType;
	setStep: Dispatch<SetStateAction<number>>;
	onCancel: () => void;
};

const CreateMember: React.FC<Props> = ({ setStep, onCancel, fund }: Props) => {
	const dispatch = useDispatch();
	const [agree, setAgree] = React.useState<boolean>(false);
	const { fundStructure, account } = fund;
	const createFund: CreateFundType = useSelector(createFundSlr);

	const { isCreatingFundSuccess, isCreatingFund } = createFund;

	const onChange = (): void => {
		setAgree((prev) => !prev);
	};

	const onPrevious = (): void => {
		if (fundStructure.fundOrigin === "Existing") {
			setStep((prev) => prev - 2);
		} else {
			setStep((prev) => prev - 1);
		}
	};

	const onSubmit = (): void => {
		dispatch(createFundCtr({ id: account.id, body: { agreementCheckbox: agree } }));
	};

	React.useEffect(() => {
		if (isCreatingFundSuccess) {
			dispatch(createFundResetCtr());
			message.success(lang.SUBMIT_FUND_SUCCESS);
			setAgree(false);
			onCancel();
		}
	}, [dispatch, isCreatingFundSuccess, onCancel]);

	return (
		<Container>
			<div className="review-fund-body">
				<Typography>
					<Title level={4}>Terms and Conditions</Title>
					<Paragraph>
						I represent and warrant to Selfmade that I have the permission and authority to provide the
						clients details to Selfmade. I understand and agree that Selfmade has not recommended the
						establishment of a self-managed superannuation fund to either the Agent, their client(s) or to
						any trustee(s) or member(s) of the proposed SMSF. The agent represents and warrants to Selfmade
						that it has complied in all respects with its obligation within the Agent Portal Access
						Agreement.
					</Paragraph>
				</Typography>
				<div className="check-box">
					<Checkbox onChange={onChange} checked={agree}>
						Agree to terms and conditions
					</Checkbox>
				</div>
			</div>
			<div className="review-fund-footer">
				<Space>
					<Button onClick={onPrevious}>Previous</Button>
					<Button onClick={onCancel}>Continue Later</Button>

					<Button type="primary" onClick={onSubmit} disabled={!agree} loading={isCreatingFund}>
						Submit
					</Button>
				</Space>
			</div>
		</Container>
	);
};

export default CreateMember;
