import {
	IGetWorkflowFunds,
	IMoveWorkflowFundState,
	IGetWorkflowFundsUpdate,
	IAddWorkflowFundNote,
	IGetWorkflowFundNotes,
	ICompleteWorkflowFundNote,
	IUpdateWorkflowPerson,
	IAdministrator,
	IGetWorkflows,
} from "./types";

export const getWorkflowFundsSlr = (state: any): IGetWorkflowFunds =>
	state.workflowDashboard.workflowFundList.getWorkflowFunds;

export const getAdministrators = (state: any): IAdministrator[] =>
	state.workflowDashboard.workflowFundList.getWorkflowFunds.administrators;

export const moveWorkflowFundStateSlr = (state: any): IMoveWorkflowFundState =>
	state.workflowDashboard.workflowFundList.moveWorkflowFundState;

export const getWorkflowFundsUpdateSlr = (state: any): IGetWorkflowFundsUpdate =>
	state.workflowDashboard.workflowFundList.getWorkflowFundsUpdate;

export const addWorkflowFundNoteSlr = (state: any): IAddWorkflowFundNote =>
	state.workflowDashboard.workflowFundList.addWorkflowFundNote;

export const getWorkflowFundNotesSlr = (state: any): IGetWorkflowFundNotes =>
	state.workflowDashboard.workflowFundList.getWorkflowFundNotes;

export const completeWorkflowFundNoteSlr = (state: any): ICompleteWorkflowFundNote =>
	state.workflowDashboard.workflowFundList.completeWorkflowFundNote;

export const updateWorkflowPersonSlr = (state: any): IUpdateWorkflowPerson =>
	state.workflowDashboard.workflowFundList.updateWorkflowPerson;

export const getWorkflowsSlr = (state: any): IGetWorkflows => state.workflowDashboard.workflowFundList.getWorkflows;
