import axios, { AxiosInstance } from "axios";
import Fuse from "fuse.js";
import Cookies from "js-cookie";
import { ApiErrorType, ObjectType } from "__utils/types";

/**
 * Processes error from the API and structure in a way antd can easily
 * consume it as error
 * @param error
 */
export const processErrors = (error: ApiErrorType): any =>
	Object.keys(error).map((key: string) => ({
		name: key,
		errors: error[key],
	}));

/**
 * Handles API errors
 * @param error
 */
export const errorHandler = (error: any): any => {
	if (process.env.NODE_ENV === "development") {
		console.warn(error.config);
	}
	if (error.response) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		if (process.env.NODE_ENV === "development") {
			console.warn(error.response.data);
			console.warn(error.response.status);
			console.warn(error.response.headers);
		}
		return error.response;
	}
	if (error.request) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		if (process.env.NODE_ENV === "development") {
			console.warn(error.request);
		}
		return {
			data: { error: "Something went wrong." },
		};
	}
	// Something happened in setting up the request that triggered an Error
	if (process.env.NODE_ENV === "development") {
		console.warn("Error", error.message);
	}
	return {
		data: { error: "Something went wrong." },
	};
};

/**
 * Returns global axios instance
 * @param apiBaseUrl
 */
export const getAxiosInstance = (apiBaseUrl: string, contentType = "application/json"): AxiosInstance => {
	const axiosInstanceName = `${apiBaseUrl}_axiosInstance`;
	const instance: AxiosInstance = ((apiBaseUrl: string) =>
		axios.create({
			baseURL: apiBaseUrl,
			withCredentials: true,
			headers: {
				"X-CSRFToken": Cookies.get("csrftoken"),
				"Content-Type": contentType,
			},
		}))(apiBaseUrl);

	if ((window as any)[axiosInstanceName]) {
		return (window as any)[axiosInstanceName];
	}

	(window as any)[axiosInstanceName] = instance;

	return instance;
};

/**
 * Converts object to URL params
 * @param object
 */
export const objectToParams = (object: { [key: string]: any }): string => {
	let params = "";

	for (const key in object) {
		params += object[key];
	}

	return params;
};

/**
 * Traverses the DOM and determines whether the target parent node exists
 * @param node
 * @param className
 * @returns
 */
export const hasTargetParentNode = (node: HTMLElement, className: string): boolean => {
	const hasClass = (elem: HTMLElement, _className: string): boolean =>
		!!elem.className && !!elem.className.indexOf && elem.className.indexOf(_className) !== -1;

	while (node && !hasClass(node, className)) {
		node = node.parentNode as HTMLElement;
	}

	return !!node?.className;
};

/**
 * Search source based on the query given.
 * This is the generics version of search.
 * @param source
 * @param query
 * @param minMatchCharLength
 * @param threshold
 * @returns
 */
export const search = <T extends ObjectType>(
	source: T[],
	query: string,
	keys?: Array<string>,
	minMatchCharLength?: number,
	threshold?: number
): T[] => {
	let _keys: string[];
	if (keys?.length) {
		_keys = keys;
	} else if (source[0]) {
		_keys = Object.keys(source[0]);
	} else {
		_keys = [];
	}
	const fuse: any = new Fuse([...source], {
		keys: _keys,
		minMatchCharLength: minMatchCharLength || 1,
		threshold: threshold || 0.5,
	});

	const searchResult: Array<any> = fuse?.search(query);
	const result: T[] = (searchResult as unknown as T[]).map((item: T) => item.item);

	return result;
};

/**
 * Returns array of unique objects
 * @param arr
 * @param key
 * @returns
 */
export const uniqueObjects = <T extends ObjectType>(arr: T[], key: string): T[] => {
	if (arr === undefined || arr.length === 0) {
		return [];
	}

	return arr.reduce((acc: T[], current: T) => {
		const found = acc.find((item: T) => item[key] === current[key]);
		if (!found) {
			return acc.concat([current]);
		}
		return acc;
	}, []);
};

/**
 * Sorts array of objects
 * @param arr The haystack
 * @param key Object prop to compare
 * @param dir 1 for ascending 0 for descending
 * @returns
 */
export const sortObjects = <T extends ObjectType>(arr: T[], key: string, dir = 1): T[] =>
	arr.sort((a: T, b: T) => {
		if (typeof a[key] === "number") {
			if (dir) {
				return a[key] - b[key];
			}
			return b[key] - a[key];
		}
		if (dir) {
			return a[key].localeCompare(b[key]);
		}
		return -a[key].localeCompare(b[key]);
	});

/**
 * DEPRECATED. USE sortObjects instead
 * Sorts array of objects
 * @param arr
 * @param key
 * @param desc
 * @returns
 */
export const sort = <T extends ObjectType>(arr: T[], key: string, desc = true): T[] =>
	arr?.sort((a: T, b: T) => (desc ? -(a[key] < b[key]) : -(a[key] > b[key])));

/**
 * Strips HTML tags from given string
 * @param s
 * @returns
 */
export const stripHtmlTags = (s: string): string =>
	s
		.replace(/(<([^>]+)>)/gi, " ")
		.replace(/\s{2,}/gi, " ")
		.trim();

/**
 * Returns the element height including margins
 * @param element - element
 * @returns {number}
 */
export const outerHeight = (element: any): number => {
	if (!element) return 0;

	const height = element.offsetHeight;
	const style = window.getComputedStyle(element) as { [key: string]: any };

	return ["top", "bottom"]
		.map((side: string) => parseInt(style[`margin-${side}`]))
		.reduce((total, side) => total + side, height);
};

/**
 * Returns the element width including padding etc
 * @param element - element
 * @returns {number}
 */
export const outerWidth = (element: any): number => {
	if (!element) return 0;

	const width = element.offsetWidth;
	const style = window.getComputedStyle(element) as { [key: string]: any };

	return ["left", "right"]
		.map((side: string) => parseInt(style[`margin-${side}`]))
		.reduce((total, side) => total + side, width);
};

/**
 * Calculates and returns the value to be assigned as top value of the table spinner
 * @returns
 */
export const getSpinnerTop = (): number => {
	const _spinnerHeight = document.querySelector(".ant-spin")?.clientHeight as number;
	const top = (outerHeight(document.querySelector(".ant-table-empty")) - _spinnerHeight) / 2;
	if (Number.isNaN(top) || !_spinnerHeight) {
		return 0;
	}
	return top;
};

/**
 * Returns current state of a fund
 * @param states
 * @returns
 */
export const getCurrentState = (states: ObjectType[]): ObjectType | undefined =>
	states.find((state: ObjectType) => {
		const { currentState } = state;

		return currentState && currentState;
	});

/**
 * Returns is level is admin level
 */
export const isUserAdmin = (level: number): boolean => level <= 2;

/**
 * Converts objects in the array to type {text: '', value: ''}
 * @param categories
 * @returns
 */
export const prepareCategories = <T extends ObjectType>(categories: T[]): T[] =>
	categories.reduce((acc: any, curr: T) => {
		acc.push({ text: curr.name, value: curr.name });
		return acc;
	}, []);

/**
 * Converts file to base64
 * @param file
 * @returns
 */
export const file2Base64 = (file: File): Promise<string> =>
	new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader!.result!.toString());
		reader.onerror = (error) => reject(error);
	});

/**
 * Returns string stripped off of html tags
 * @param text
 * @returns
 */
export const getPureString = (text: string): string => stripHtmlTags(unescape(text)).trim();

export const formatAbn = (abn: any): string => {
	if (!abn) return "";

	const _abn = `${abn}`;
	const abnLen = [..._abn].length;
	const firstChunk = _abn.substring(0, 2);
	const restChunk = _abn.substring(2, abnLen);
	const targetChunks = Math.floor([...restChunk].length / 3);
	let chunks = "";
	let count = 0;

	[...restChunk].forEach((char: string) => {
		const chunksLen = chunks.split(" ").length;
		count++;

		if (count % 3 === 0 && chunksLen <= targetChunks) {
			chunks = `${chunks}${char} `;
		} else {
			chunks = `${chunks}${char}`;
		}
	});

	return `${firstChunk} ${chunks}`;
};

export const formatAcn = (acn: any): string => {
	if (!acn) return "";

	const _acn = `${acn}`;
	const targetChunks = Math.floor([..._acn].length / 3);
	let chunks = "";
	let count = 0;

	[..._acn].forEach((char: string) => {
		const chunksLen = chunks.split(" ").length;
		count++;

		if (count % 3 === 0 && chunksLen <= targetChunks) {
			chunks = `${chunks}${char} `;
		} else {
			chunks = `${chunks}${char}`;
		}
	});

	return chunks;
};

export const formatMobile = (mobile: any): string => {
	if (!mobile) return "";

	const _mobile = `${mobile}`;
	const mobileLen = [..._mobile].length;
	const firstChunk = _mobile.substring(0, 4);
	const restChunk = _mobile.substring(4, mobileLen);
	const targetChunks = Math.floor([...restChunk].length / 3);
	let chunks = "";
	let count = 0;

	[...restChunk].forEach((char: string) => {
		const chunksLen = chunks.split(" ").length;
		count++;

		if (count % 3 === 0 && chunksLen <= targetChunks) {
			chunks = `${chunks}${char} `;
		} else {
			chunks = `${chunks}${char}`;
		}
	});

	return `${firstChunk} ${chunks}`;
};

/**
 * Returns is level is advisers level
 */
export const isUserAdviser = (level: number): boolean => level === 3 || level === 4;
