import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import {
	AddDocumentActionTypes,
	addDocumentDefaultValue,
	AddDocumentDetailsType,
	DeleteDocumentActionTypes,
	deleteDocumentDefaultValue,
	DeleteDocumentDetailsType,
	DocumentActionTypes,
	documentDefaultValue,
	EditDocumentActionTypes,
	editDocumentDefaultValue,
	EditDocumentDetailsType,
	GetDocCategoriesActionTypes,
	getDocCategoriesDefaultValue,
	GetDocCategoriesType,
	GetDocumentDetailsType,
	GetDocumentsActionTypes,
	getDocumentsDefaultValue,
	GetDocumentsType,
} from "./types";

export const getDocumentDetailsReducer = (
	state = documentDefaultValue,
	action: DocumentActionTypes
): GetDocumentDetailsType => {
	switch (action.type) {
		case actionTypes.GET_DOCUMENT_DETAILS:
			return {
				...state,
				isGettingDocumentDetails: true,
			};
		case actionTypes.GET_DOCUMENT_DETAILS_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
			};

		case actionTypes.GET_DOCUMENT_DETAILS_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isGettingDocumentDetails: false,
				isGetDocumentDetailsSuccess: false,
				isGetDocumentDetailsError: true,
			};

		case actionTypes.GET_DOCUMENT_DETAILS_RESET:
			return {
				...documentDefaultValue,
			};

		default:
			return state;
	}
};

export const addDocumentReducer = (
	state = addDocumentDefaultValue,
	action: AddDocumentActionTypes
): AddDocumentDetailsType => {
	switch (action.type) {
		case actionTypes.ADD_DOCUMENT:
			return {
				...state,
				isAdding: true,
			};
		case actionTypes.ADD_DOCUMENT_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isAddError: false,
				isAddSuccess: true,
				isAdding: false,
				addError: {},
			};
		case actionTypes.ADD_DOCUMENT_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isAdding: false,
				isAddError: true,
				isAddSuccess: false,
			};
		case actionTypes.ADD_DOCUMENT_RESET:
			return {
				...state,
				...addDocumentDefaultValue,
			};
		default:
			return state;
	}
};

export const editDocumentReducer = (
	state = editDocumentDefaultValue,
	action: EditDocumentActionTypes
): EditDocumentDetailsType => {
	switch (action.type) {
		case actionTypes.EDIT_DOCUMENT:
			return {
				...state,
				isEditing: true,
			};
		case actionTypes.EDIT_DOCUMENT_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isEditError: false,
				isEditSuccess: true,
				isEditing: false,
				editError: {},
			};
		case actionTypes.EDIT_DOCUMENT_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isEditing: false,
				isEditError: true,
				isEditSuccess: false,
			};
		case actionTypes.EDIT_DOCUMENT_RESET:
			return {
				...state,
				...editDocumentDefaultValue,
			};
		default:
			return state;
	}
};

export const getDocCategoriesReducer = (
	state = getDocCategoriesDefaultValue,
	action: GetDocCategoriesActionTypes
): GetDocCategoriesType => {
	switch (action.type) {
		case actionTypes.GET_DOC_CATEGORIES:
			return {
				...state,
				isGetDocCategoriesError: true,
			};
		case actionTypes.GET_DOC_CATEGORIES_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isGetDocCategoriesError: false,
				isGetDocCategoriesSuccess: true,
				isGettingDocCategories: false,
				getDocCategoriesError: {},
			};
		case actionTypes.GET_DOC_CATEGORIES_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isGettingDocCategories: false,
				isGetDocCategoriesError: true,
				isGetDocCategoriesSuccess: false,
			};
		case actionTypes.GET_DOC_CATEGORIES_RESET:
			return {
				...state,
				...getDocCategoriesDefaultValue,
			};
		default:
			return state;
	}
};

export const getDocumentsReducer = (
	state = getDocumentsDefaultValue,
	action: GetDocumentsActionTypes
): GetDocumentsType => {
	switch (action.type) {
		case actionTypes.GET_DOCUMENTS:
			return {
				...state,
				isGettingDocuments: true,
			};
		case actionTypes.GET_DOCUMENTS_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isGetDocumentsError: false,
				isGetDocumentsSuccess: true,
				isGettingDocuments: false,
				getDocumentsError: {},
			};
		case actionTypes.GET_DOCUMENTS_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isGettingDocuments: false,
				isGetDocumentsError: true,
				isGetDocumentsSuccess: false,
			};
		case actionTypes.GET_DOCUMENTS_RESET:
			return {
				...state,
				...getDocumentsDefaultValue,
			};
		default:
			return state;
	}
};

export const deleteDocumentReducer = (
	state = deleteDocumentDefaultValue,
	action: DeleteDocumentActionTypes
): DeleteDocumentDetailsType => {
	switch (action.type) {
		case actionTypes.DELETE_DOCUMENT:
			return {
				...state,
				isDeletingDocument: true,
			};

		case actionTypes.DELETE_DOCUMENT_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isDeleteDocumentError: false,
				isDeleteDocumentSuccess: true,
				isDeletingDocument: false,
			};
		case actionTypes.DELETE_DOCUMENT_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isDeletingDocument: false,
				isDeleteDocumentError: true,
			};

		case actionTypes.DELETE_DOCUMENT_RESET:
			return {
				...state,
				...deleteDocumentDefaultValue,
			};
		default:
			return state;
	}
};

export const documentReducer = combineReducers({
	addDocument: addDocumentReducer,
	editDocument: editDocumentReducer,
	getDocumentDetails: getDocumentDetailsReducer,
	getDocuments: getDocumentsReducer,
	getDocCategories: getDocCategoriesReducer,
	deleteDocument: deleteDocumentReducer,
});
