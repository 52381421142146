import * as actionTypes from "./actionTypes";
import { GetUserProfileActionTypes, VerifyUserAuthActionTypes } from "./types";

export const getUserProfileCtr = (): GetUserProfileActionTypes => ({
	type: actionTypes.GET_USER_PROFILE,
});

export const verifyUserAuthCtr = (): VerifyUserAuthActionTypes => ({
	type: actionTypes.VERIFY_USER_AUTH,
});
