import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import {
	getClassDefaultValue,
	GetClassActionTypes,
	GetClassType,
	EditClassActionTypes,
	editClassDefaultValue,
	EditClassType,
} from "./types";

export const getClassReducer = (state = getClassDefaultValue, action: GetClassActionTypes): GetClassType => {
	switch (action.type) {
		case actionTypes.GET_CLASS:
			return {
				...state,
				isGettingClass: true,
			};
		case actionTypes.GET_CLASS_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isGetClassError: false,
				isGetClassSuccess: true,
				isGettingClass: false,
			};
		case actionTypes.GET_CLASS_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isGettingClass: false,
				isGetClassError: true,
				isGetClassSuccess: false,
			};
		case actionTypes.GET_CLASS_RESET:
			return {
				...state,
				...getClassDefaultValue,
			};
		default:
			return state;
	}
};

export const editClassReducer = (state = editClassDefaultValue, action: EditClassActionTypes): EditClassType => {
	switch (action.type) {
		case actionTypes.EDIT_CLASS:
			return {
				...state,
				isEditingClass: true,
			};
		case actionTypes.EDIT_CLASS_SUCCESS:
			return {
				...state,
				isEditClassError: false,
				isEditClassSuccess: true,
				isEditingClass: false,
			};
		case actionTypes.EDIT_CLASS_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isEditingClass: false,
				isEditClassError: false,
				isEditClassSuccess: false,
			};
		case actionTypes.EDIT_CLASS_RESET:
			return {
				...state,
				...editClassDefaultValue,
			};
		default:
			return state;
	}
};

export const accountingReducer = combineReducers({
	getClass: getClassReducer,
	editClass: editClassReducer,
});
