import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ResponseGeneratorType } from "__utils/types";
import fileDownload from "js-file-download";
import * as actionTypes from "./actionTypes";
import * as api from "../__api";

import {
	DocumentTreeFileType,
	DocumentTreeFolderType,
	DocumentTreeType,
	GetDocumentTreeType,
	DocumentTreeFileFolderType,
} from "./types";

import { getDocumentTreeSlr } from "./selectors";

const extractFilename = (raw: string): string => raw.split("=")[1];

const getDocumentTree = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getDocumentsApi, payload);
		const { status, data } = response;

		if (response.status === 200) {
			yield put({
				type: actionTypes.GET_DOCUMENT_TREE_SUCCESS,
				...data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_DOCUMENT_TREE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_DOCUMENT_TREE_FAILURE });
	}
};

export const getDocumentTreeSaga = function* (): any {
	yield takeLatest(actionTypes.GET_DOCUMENT_TREE, getDocumentTree);
};

const deleteDocumentTreeFile = function* ({ payload }: any): any {
	try {
		const { key } = payload;
		const response: ResponseGeneratorType = yield call(api.deleteDocumentTreeFileApi, payload);
		const { status } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.DELETE_DOCUMENT_TREE_FILE_SUCESS,
			});

			// Remove from state
			const { documentTree }: GetDocumentTreeType = yield select(getDocumentTreeSlr);
			const _documentTree: DocumentTreeType = documentTree.reduce(
				(acc: DocumentTreeType, curr: DocumentTreeFileType & DocumentTreeFolderType) => {
					if (Object.prototype.hasOwnProperty.call(curr, "files")) {
						const files = (curr as DocumentTreeFolderType).files.filter(
							(file: DocumentTreeFileType) => file.key !== key
						);
						acc.push({ ...curr, files });
					} else if (curr.key !== key) {
						acc.push(curr);
					}

					return acc;
				},
				[]
			);

			yield put({
				type: actionTypes.GET_DOCUMENT_TREE_SUCCESS,
				documentTree: _documentTree,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({
				type: actionTypes.DELETE_DOCUMENT_TREE_FILE_FAILURE,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.DELETE_DOCUMENT_TREE_FILE_FAILURE,
		});
	}
};

export const deleteDocumentTreeFileSaga = function* (): any {
	yield takeLatest(actionTypes.DELETE_DOCUMENT_TREE_FILE, deleteDocumentTreeFile);
};

const downloadDocument = function* (action: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.downloadDocumentApi, action.payload);
		const { status, data } = response;
		yield call(fileDownload, data, extractFilename(response.headers["content-disposition"]));

		if (status === 200) {
			yield put({
				type: actionTypes.DOWNLOAD_DOCUMENT_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.DOWNLOAD_DOCUMENT_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.DOWNLOAD_DOCUMENT_FAILURE });
	}
};

export const downloadDocumentSaga = function* (): any {
	yield takeEvery(actionTypes.DOWNLOAD_DOCUMENT, downloadDocument);
};

const getDocumentFolderDetails = function* ({ payload }: any): any {
	try {
		const { folderKey } = payload;
		const response: ResponseGeneratorType = yield call(api.getDocumentFolderDocumentDetailsApi, payload);
		const { status, data } = response;
		const { documentTree }: GetDocumentTreeType = yield select(getDocumentTreeSlr);
		if (response.status === 200) {
			yield put({
				type: actionTypes.GET_DOCS_FOLDER_DETAILS_SUCCESS,
			});

			const insertNewFundDetails = documentTree.map((document: DocumentTreeFileFolderType) => {
				const { key } = document;
				if (folderKey === key) {
					return { ...document, files: data.documentTree };
				}
				return { ...document };
			});
			yield put({
				type: actionTypes.GET_DOCUMENT_TREE_SUCCESS,
				documentTree: insertNewFundDetails,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_DOCS_FOLDER_DETAILS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_DOCS_FOLDER_DETAILS_FAILURE });
	}
};

export const getDocumentFolderDetailsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_DOCS_FOLDER_DETAILS, getDocumentFolderDetails);
};
