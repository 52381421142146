import { ResponseGeneratorType } from "__utils/types";
import { call, put, takeLatest, select } from "redux-saga/effects";

import * as actionTypes from "./actionTypes";
import * as api from "../__api";
import { getReminderDetailsSlr } from "./selectors";
import { IGetReminderDetails } from "./types";

const getReminderCount = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getRemindersCountAPI);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_REMINDERS_COUNT_SUCCESS,
				reminderCount: data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_REMINDERS_COUNT_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_REMINDERS_COUNT_FAILURE });
	}
};

export const getReminderCountSaga = function* (): any {
	yield takeLatest(actionTypes.GET_REMINDERS_COUNT, getReminderCount);
};

const getReminder = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getRemindersAPI);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_REMINDERS_SUCCESS,
				reminders: data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_REMINDERS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_REMINDERS_FAILURE });
	}
};

export const getReminderSaga = function* (): any {
	yield takeLatest(actionTypes.GET_REMINDERS, getReminder);
};

const getReminderDetails = function* ({ payload }: any): any {
	const getReminderDetails: IGetReminderDetails = yield select(getReminderDetailsSlr);
	const { reminderDetails: currReminderDetail } = getReminderDetails;
	try {
		const response: ResponseGeneratorType = yield call(api.getReminderDetailsAPI, payload);
		const { status, data } = response;

		if (status === 200) {
			const _data = [...currReminderDetail, data];
			yield put({
				type: actionTypes.GET_REMINDERS_DETAILS_SUCCESS,
				reminderDetails: _data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_REMINDERS_DETAILS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_REMINDERS_DETAILS_FAILURE });
	}
};

export const getReminderDetailsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_REMINDERS_DETAILS, getReminderDetails);
};

const releaseAllReminder = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.releaseAllReminderAPI);
		const { status } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.RELEASE_ALL_REMINDER_SUCCESS,
			});

			yield put({
				type: actionTypes.GET_REMINDERS_SUCCESS,
				reminders: [],
			});
			yield put({
				type: actionTypes.GET_REMINDERS_COUNT_SUCCESS,
				reminderCount: 0,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.RELEASE_ALL_REMINDER_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.RELEASE_ALL_REMINDER_FAILURE });
	}
};

export const releaseAllReminderSaga = function* (): any {
	yield takeLatest(actionTypes.RELEASE_ALL_REMINDER, releaseAllReminder);
};
