import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import {
	IGetWorkflowFunds,
	IGetWorkflowFundsActions,
	getWorkflowFundsDefaultValue,
	moveWorkflowFundStateDefaultValue,
	IMoveWorkflowFundState,
	IMoveWorkflowFundStateActions,
	IGetWorkflowFundsUpdateActions,
	getWorkflowFundsUpdateDefaultValue,
	IGetWorkflowFundsUpdate,
	addWorkflowFundNoteDefaultValue,
	IAddWorkflowFundNote,
	IAddWorkflowFundNoteActions,
	IGetWorkflowFundNotes,
	IGetWorkflowFundNotesActions,
	getWorkflowFundNotesDefaultValue,
	completeWorkflowFundNoteDefaultValue,
	ICompleteWorkflowFundNote,
	ICompleteWorkflowFundNoteActions,
	updateWorkflowPersonDefaultValue,
	IUpdateWorkflowPerson,
	IUpdateWorkflowPersonActions,
	getWorkflowDefaultValue,
	IGetWorkflowsActions,
	IGetWorkflows,
} from "./types";

export const getWorkflowFundsReducer = (
	state = getWorkflowFundsDefaultValue,
	action: IGetWorkflowFundsActions
): IGetWorkflowFunds => {
	switch (action.type) {
		case actionTypes.GET_WORKFLOW_FUNDS:
			return {
				...state,
				gettingWorkflowFunds: true,
				workflowId: action.payload.workflowId,
				tag: action.payload.tag,
				isOnPoll: action.payload.isOnPoll,
			};
		case actionTypes.GET_WORKFLOW_FUNDS_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				gettingWorkflowFunds: false,
				getWorkflowFundsSuccess: true,
				getWorkflowFundsFailure: false,
			};

		case actionTypes.GET_WORKFLOW_FUNDS_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				gettingWorkflowFunds: false,
				getWorkflowFundsSuccess: false,
				getWorkflowFundsFailure: true,
			};

		case actionTypes.GET_WORKFLOW_FUNDS_RESET:
			return {
				...getWorkflowFundsDefaultValue,
			};

		default:
			return state;
	}
};

export const moveWorkflowFundStateReducer = (
	state = moveWorkflowFundStateDefaultValue,
	action: IMoveWorkflowFundStateActions
): IMoveWorkflowFundState => {
	switch (action.type) {
		case actionTypes.MOVE_WORKFLOW_FUND_STATE:
			return {
				...state,
				movingWorkflowFundSate: true,
				newState: (action as any).payload.newState,
				status: (action as any).payload.status,
				workflowFundId: (action as any).payload.workflowFundId,
			};
		case actionTypes.MOVE_WORKFLOW_FUND_STATE_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				movingWorkflowFundSate: false,
				moveWorkflowFundStateSuccess: true,
				moveWorkflowFundStateFailure: false,
			};

		case actionTypes.MOVE_WORKFLOW_FUND_STATE_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				movingWorkflowFundSate: false,
				moveWorkflowFundStateSuccess: false,
				moveWorkflowFundStateFailure: true,
			};

		case actionTypes.MOVE_WORKFLOW_FUND_STATE_RESET:
			return {
				...moveWorkflowFundStateDefaultValue,
			};

		default:
			return state;
	}
};

export const getWorkflowFundsUpdateReducer = (
	state = getWorkflowFundsUpdateDefaultValue,
	action: IGetWorkflowFundsUpdateActions
): IGetWorkflowFundsUpdate => {
	switch (action.type) {
		case actionTypes.GET_WORKFLOW_FUNDS_UPDATE:
			return {
				...state,
				gettingWorkflowFundsUpdate: true,
			};
		case actionTypes.GET_WORKFLOW_FUNDS_UPDATE_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				gettingWorkflowFundsUpdate: false,
				getWorkflowFundsUpdateSuccess: true,
				getWorkflowFundsUpdateFailure: false,
			};

		case actionTypes.GET_WORKFLOW_FUNDS_UPDATE_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				gettingWorkflowFundsUpdate: false,
				getWorkflowFundsUpdateSuccess: false,
				getWorkflowFundsUpdateFailure: true,
			};

		case actionTypes.GET_WORKFLOW_FUNDS_UPDATE_RESET:
			return {
				...getWorkflowFundsUpdateDefaultValue,
			};

		default:
			return state;
	}
};

export const addWorkflowFundNoteReducer = (
	state = addWorkflowFundNoteDefaultValue,
	action: IAddWorkflowFundNoteActions
): IAddWorkflowFundNote => {
	switch (action.type) {
		case actionTypes.ADD_WORKFLOW_FUND_NOTE:
			return {
				...state,
				addingWorkflowFundNote: true,
			};
		case actionTypes.ADD_WORKFLOW_FUND_NOTE_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				addingWorkflowFundNote: false,
				addWorkflowFundNoteSuccess: true,
				addWorkflowFundNoteFailure: false,
			};

		case actionTypes.ADD_WORKFLOW_FUND_NOTE_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				addingWorkflowFundNote: false,
				addWorkflowFundNoteSuccess: false,
				addWorkflowFundNoteFailure: true,
			};

		case actionTypes.ADD_WORKFLOW_FUND_NOTE_RESET:
			return {
				...state,
				...addWorkflowFundNoteDefaultValue,
			};

		default:
			return state;
	}
};

export const getWorkflowFundNotesReducer = (
	state = getWorkflowFundNotesDefaultValue,
	action: IGetWorkflowFundNotesActions
): IGetWorkflowFundNotes => {
	switch (action.type) {
		case actionTypes.GET_WORKFLOW_FUND_NOTES:
			return {
				...state,
				gettingWorkflowFundNotes: true,
			};
		case actionTypes.GET_WORKFLOW_FUND_NOTES_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				gettingWorkflowFundNotes: false,
				getWorkflowFundNotesSuccess: true,
				getWorkflowFundNotesFailure: false,
			};

		case actionTypes.GET_WORKFLOW_FUND_NOTES_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				gettingWorkflowFundNotes: false,
				getWorkflowFundNotesSuccess: false,
				getWorkflowFundNotesFailure: true,
			};

		case actionTypes.GET_WORKFLOW_FUND_NOTES_RESET:
			return {
				...state,
				...getWorkflowFundNotesDefaultValue,
			};

		default:
			return state;
	}
};

export const completeWorkflowFundNoteReducer = (
	state = completeWorkflowFundNoteDefaultValue,
	action: ICompleteWorkflowFundNoteActions
): ICompleteWorkflowFundNote => {
	switch (action.type) {
		case actionTypes.COMPLETE_WORKFLOW_FUND_NOTE:
			return {
				...state,
				completingWorkflowFundNote: true,
				noteId: (action as any).payload.noteId,
			};
		case actionTypes.COMPLETE_WORKFLOW_FUND_NOTE_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				completingWorkflowFundNote: false,
				completeWorkflowFundNoteSuccess: true,
				completeWorkflowFundNoteFailure: false,
			};

		case actionTypes.COMPLETE_WORKFLOW_FUND_NOTE_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				completingWorkflowFundNote: false,
				completeWorkflowFundNoteSuccess: false,
				completeWorkflowFundNoteFailure: true,
			};

		case actionTypes.COMPLETE_WORKFLOW_FUND_NOTE_RESET:
			return {
				...state,
				...completeWorkflowFundNoteDefaultValue,
			};

		default:
			return state;
	}
};

export const updateWorkflowPersonReducer = (
	state = updateWorkflowPersonDefaultValue,
	action: IUpdateWorkflowPersonActions
): IUpdateWorkflowPerson => {
	switch (action.type) {
		case actionTypes.UPDATE_WORKFLOW_PERSON:
			return {
				...state,
				updatingWorkflowPerson: true,
			};
		case actionTypes.UPDATE_WORKFLOW_PERSON_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				updatingWorkflowPerson: false,
				updateWorkflowPersonSuccess: true,
				updateWorkflowPersonFailure: false,
			};

		case actionTypes.UPDATE_WORKFLOW_PERSON_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				updatingWorkflowPerson: false,
				updateWorkflowPersonSuccess: false,
				updateWorkflowPersonFailure: true,
			};

		case actionTypes.UPDATE_WORKFLOW_PERSON_RESET:
			return {
				...state,
				...updateWorkflowPersonDefaultValue,
			};

		default:
			return state;
	}
};

export const getWorkflowsReducer = (state = getWorkflowDefaultValue, action: IGetWorkflowsActions): IGetWorkflows => {
	switch (action.type) {
		case actionTypes.GET_WORKFLOWS:
			return {
				...state,
				gettingWorkflow: true,
			};
		case actionTypes.GET_WORKFLOWS_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				gettingWorkflow: false,
				getWorkflowSuccess: true,
				getWorkflowFailure: false,
			};

		case actionTypes.GET_WORKFLOWS_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				gettingWorkflow: false,
				getWorkflowSuccess: false,
				getWorkflowFailure: true,
			};

		case actionTypes.GET_WORKFLOWS_RESET:
			return {
				...state,
				...getWorkflowDefaultValue,
			};

		default:
			return state;
	}
};

export const workflowFundListReducer = combineReducers({
	getWorkflowFunds: getWorkflowFundsReducer,
	moveWorkflowFundState: moveWorkflowFundStateReducer,
	getWorkflowFundsUpdate: getWorkflowFundsUpdateReducer,
	addWorkflowFundNote: addWorkflowFundNoteReducer,
	getWorkflowFundNotes: getWorkflowFundNotesReducer,
	completeWorkflowFundNote: completeWorkflowFundNoteReducer,
	updateWorkflowPerson: updateWorkflowPersonReducer,
	getWorkflows: getWorkflowsReducer,
});
