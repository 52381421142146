import { call, put, takeLatest } from "redux-saga/effects";
import { ResponseGeneratorType } from "__utils/types";
import * as workflowFundDetailsActionTypes from "__common/Workflow/__redux/actionTypes";
import * as api from "__api";
import * as actionTypes from "./actionTypes";

const moveWorkflowFundDetailsGenerateDocs = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.moveWorkflowFundDetailsApi, payload);
		const { status, data } = response;

		if (status === 201) {
			yield put({
				type: workflowFundDetailsActionTypes.GET_WORKFLOW_UPDATE_SUCCESS,
				workflowUpdate: data,
			});

			yield put({
				type: actionTypes.MOVE_WORKFLOW_FUND_DETAILS_GENERATE_DOCS_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.MOVE_WORKFLOW_FUND_DETAILS_GENERATE_DOCS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.MOVE_WORKFLOW_FUND_DETAILS_GENERATE_DOCS_FAILURE });
	}
};

export const moveWorkflowFundDetailsGenerateDocsSaga = function* (): any {
	yield takeLatest(actionTypes.MOVE_WORKFLOW_FUND_DETAILS_GENERATE_DOCS, moveWorkflowFundDetailsGenerateDocs);
};
