import Steps from "antd/es/steps";
import React, { Dispatch, SetStateAction } from "react";
import { GetFundDetailsType, StructureType } from "__pages/FundDetails/__redux/types";
import { ObjectType } from "__utils/types";
import Account from "./Account";
import FundStructure from "./FundStructure";
import Members from "./Members";
import ReviewFund from "./ReviewFund";
import { Container } from "./style";

const { Step } = Steps;

type Props = {
	step: number;
	fund: GetFundDetailsType;
	isShowMemberForm: boolean;
	setShowMemberForm: Dispatch<SetStateAction<boolean>>;
	setStep: Dispatch<SetStateAction<number>>;
	onCancel: () => void;
	// Add Account | edit Account
	isSubmitAddAccount: boolean;
	setSubmitAddAccount: Dispatch<SetStateAction<boolean>>;
	isSubmitEditAccount: boolean;
	setSubmitEditAccount: Dispatch<SetStateAction<boolean>>;
	// add fundStructure | edit fundStructure
	isSubmitAddFundStructure: boolean;
	setSubmitAddFundStructure: Dispatch<SetStateAction<boolean>>;
	fundStructureLocalValues: StructureType;
	setFundStructureLocalValues: Dispatch<SetStateAction<StructureType>>;
	isSubmitEditFundStructure: boolean;
	setSubmitEditFundStructure: Dispatch<SetStateAction<boolean>>;
};

const EstablishFundForms: React.FC<Props> = ({
	step,
	fund,
	isShowMemberForm,
	setShowMemberForm,
	setStep,
	onCancel,
	// Add Account | edit Account
	isSubmitAddAccount,
	setSubmitAddAccount,
	isSubmitEditAccount,
	setSubmitEditAccount,
	// add fundStructure | edit fundStructure
	isSubmitAddFundStructure,
	setSubmitAddFundStructure,
	fundStructureLocalValues,
	setFundStructureLocalValues,
	isSubmitEditFundStructure,
	setSubmitEditFundStructure,
}: Props) => {
	const steps: Array<ObjectType> = [
		{
			title: "Create Account",
			content: (
				<Account
					fund={fund}
					isSubmitAddAccount={isSubmitAddAccount}
					setSubmitAddAccount={setSubmitAddAccount}
					isSubmitEditAccount={isSubmitEditAccount}
					setSubmitEditAccount={setSubmitEditAccount}
				/>
			),
		},
		{
			title: "Setup Fund",
			content: (
				<FundStructure
					fund={fund}
					isSubmitAddFundStructure={isSubmitAddFundStructure}
					setSubmitAddFundStructure={setSubmitAddFundStructure}
					isSubmitEditFundStructure={isSubmitEditFundStructure}
					fundStructureLocalValues={fundStructureLocalValues}
					setFundStructureLocalValues={setFundStructureLocalValues}
					setSubmitEditFundStructure={setSubmitEditFundStructure}
				/>
			),
		},
		{
			title: "Add Members",
			content: <Members fund={fund} isShowMemberForm={isShowMemberForm} setShowMemberForm={setShowMemberForm} />,
		},
		{
			title: "Review Fund",
			content: <ReviewFund setStep={setStep} onCancel={onCancel} fund={fund} />,
		},
	];

	return (
		<Container>
			<div className="fund-modal-body">
				<Steps size="small" current={step}>
					{steps.map((item) => (
						<Step key={item.title} title={item.title} />
					))}
				</Steps>
			</div>

			<div className="steps-content">{steps[step].content}</div>
		</Container>
	);
};

export default EstablishFundForms;
