export const DOC_REQUESTS = "/admin_requests";
export const GET_DOC_REQUESTS = `${DOC_REQUESTS}`;
export const GET_DOC_REQUEST_DETAILS = `${DOC_REQUESTS}`;
export const GET_SIGNING_REQUEST_DETAILS = "/document/admin-signing-details/:memberId/:envelopeId/";
export const GET_DOC_REQUEST_DOCUMENT = `${DOC_REQUESTS}`;
export const COMPETE_DOC_REQUEST_DOCUMENT = `${DOC_REQUESTS}`;
export const COMPETE_DOC_REQUEST = `${DOC_REQUESTS}`;
export const MOVE_DOC_REQUEST_STATE = `${DOC_REQUESTS}/:memberId/change_state/:requestId/`;
export const REMOVE_DOC_FILE = "/member-documents/:memberId/delete_document/:key/";
export const REMIND_DOC_REQUEST = "/admin_requests/:memberId/remind/:requestId/";
export const REMOVE_DOC_ACTIVITY = `${DOC_REQUESTS}/:memberId/remove_activity/:documentId/`;
