import { call, put, select, takeLatest } from "redux-saga/effects";
import { search, sortObjects } from "__utils/helpers";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";
import { getDocumentsSlr } from "./selectors";
import { DocumentDetailsType, DocumentsType, GetDocumentsType } from "./types";

const getDocumentDetails = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getDocumentDetailsApi, payload);
		const { status, data } = response;
		if (response.status === 200) {
			yield put({
				type: actionTypes.GET_DOCUMENT_DETAILS_SUCCESS,
				isGettingDocument: false,
				isGetDocumentSuccess: true,
				documentDetails: data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_DOCUMENT_DETAILS_FAILURE, getDocumentError: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_DOCUMENT_DETAILS_FAILURE });
	}
};

export const getDocumentDetailsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_DOCUMENT_DETAILS, getDocumentDetails);
};

const addDocument = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.addDocumentApi, payload);
		const { status, data } = response;

		if (status === 201) {
			const { limit, currPage, documents }: GetDocumentsType = yield select(getDocumentsSlr);
			const _documents: DocumentsType = sortObjects<DocumentDetailsType>([...documents, data], "id", 0);
			const partial: DocumentsType = _documents.slice(0, currPage * limit);

			yield put({
				type: actionTypes.GET_DOCUMENTS_SUCCESS,
				partial,
			});

			yield put({
				type: actionTypes.ADD_DOCUMENT_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.ADD_DOCUMENT_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.ADD_DOCUMENT_FAILURE });
	}
};

export const addDocumentSaga = function* (): any {
	yield takeLatest(actionTypes.ADD_DOCUMENT, addDocument);
};

const editDocument = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.editDocumentApi, payload);
		const { status, data } = response;

		if (status === 204) {
			yield put({
				type: actionTypes.EDIT_DOCUMENT_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.EDIT_DOCUMENT_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.EDIT_DOCUMENT_FAILURE });
	}
};

export const editDocumentSaga = function* (): any {
	yield takeLatest(actionTypes.EDIT_DOCUMENT, editDocument);
};

const getDocCategories = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getDocCategoriesApi);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_DOC_CATEGORIES_SUCCESS,
				docCategories: data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_DOC_CATEGORIES_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_DOC_CATEGORIES_FAILURE });
	}
};

export const getDocCategoriesSaga = function* (): any {
	yield takeLatest(actionTypes.GET_DOC_CATEGORIES, getDocCategories);
};

const getDocuments = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getDocumentsApi);
		const { status, data } = response;
		const { categories, documents } = data;

		if (status === 200) {
			const { limit }: GetDocumentsType = yield select(getDocumentsSlr);
			const numOfPages: number = Math.ceil(documents.length / limit);
			const _documents: DocumentsType = sortObjects<DocumentDetailsType>(documents, "id", 0);
			const partial: DocumentsType = _documents.slice(0, 1 * limit);

			yield put({
				type: actionTypes.GET_DOCUMENTS_SUCCESS,
				documents,
				partial,
				numOfPages,
				categories,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_DOCUMENTS_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_DOCUMENTS_FAILURE });
	}
};

export const getDocumentsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_DOCUMENTS, getDocuments);
};

const getDocumentsOnScroll = function* ({ payload }: any): any {
	try {
		const { page } = payload;
		const { documents, numOfPages, limit }: GetDocumentsType = yield select(getDocumentsSlr);

		if (page <= numOfPages) {
			const _documents: DocumentsType = sortObjects<DocumentDetailsType>(documents, "id", 0);
			const partial: DocumentsType = _documents.slice(0, page * limit);

			yield put({
				type: actionTypes.GET_DOCUMENTS_SUCCESS,
				partial,
				numOfPages,
				currPage: page,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.GET_DOCUMENTS_FAILURE,
		});
	}
};

export const getDocumentsOnScrollSaga = function* (): any {
	yield takeLatest(actionTypes.GET_DOCUMENTS_ON_SCROLL, getDocumentsOnScroll);
};

const deleteDocument = function* (action: any): any {
	const { payload } = action;
	try {
		const response: ResponseGeneratorType = yield call(api.deleteDocumentApi, payload);
		const { status } = response; // data

		if (status === 200) {
			const getDocument: GetDocumentsType = yield select(getDocumentsSlr);
			const { documents, partial, filtered } = getDocument;

			const _documents = documents.filter((obj: DocumentDetailsType) => obj.id !== payload.id);
			const _filtered = filtered.filter((obj: DocumentDetailsType) => obj.id !== payload.id);
			const _partial = partial.filter((obj: DocumentDetailsType) => obj.id !== payload.id);

			yield put({
				type: actionTypes.GET_DOCUMENTS_SUCCESS,
				partial: _partial,
				filtered: _filtered,
				documents: _documents,
			});

			yield put({
				type: actionTypes.DELETE_DOCUMENT_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			// errorDeleting
			yield put({
				type: actionTypes.DELETE_DOCUMENT_FAILURE,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.DELETE_DOCUMENT_FAILURE,
		});
	}
};

export const deleteDocumentSaga = function* (): any {
	yield takeLatest(actionTypes.DELETE_DOCUMENT, deleteDocument);
};

const filterDocuments = function* ({ payload }: any): any {
	try {
		const { filters } = payload;
		const { documents, currPage, limit }: GetDocumentsType = yield select(getDocumentsSlr);

		const result: DocumentsType = search(documents, filters.searchFilter);
		const _docRequests: DocumentsType = filters.searchFilter ? result : documents;
		const partial: DocumentsType = _docRequests.slice(0, currPage * limit);

		yield put({
			type: actionTypes.GET_DOCUMENTS_SUCCESS,
			partial,
		});
	} catch (error) {
		yield put({
			type: actionTypes.GET_DOCUMENTS_FAILURE,
		});
	}
};

export const filterDocumentsSaga = function* (): any {
	yield takeLatest(actionTypes.FILTER_DOCUMENTS, filterDocuments);
};
