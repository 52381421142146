import React, { Dispatch, SetStateAction } from "react";
import { GetFundDetailsType } from "__pages/FundDetails/__redux/types";
import MemberCardList from "./MemberCardList";
import MemberForm from "./MemberForm";
import { Container } from "./style";

type Props = {
	fund: GetFundDetailsType;
	isShowMemberForm: boolean;
	setShowMemberForm: Dispatch<SetStateAction<boolean>>;
};

const CreateMember: React.FC<Props> = ({ fund, isShowMemberForm, setShowMemberForm }: Props) => {
	const [memberId, setMemberId] = React.useState<number>(0);

	return (
		<Container showHide={isShowMemberForm}>
			{!isShowMemberForm ? (
				<MemberCardList fund={fund} setShowMemberForm={setShowMemberForm} setMemberId={setMemberId} />
			) : (
				<div className="ant-collapse-item ant-collapse-item-active">
					<MemberForm fund={fund} memberId={memberId} setShowMemberForm={setShowMemberForm} />
				</div>
			)}
		</Container>
	);
};

export default CreateMember;
