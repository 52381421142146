import * as actionTypes from "./actionTypes";

/**
 * Set new state
 */

export type SetNewStateType = {
	readonly memberId: number;
	readonly isSettingNewState: boolean;
	readonly isSetNewStateSuccess: boolean;
	readonly isSetNewStateError: boolean;
};

export const setNewStateDefaultValue: SetNewStateType = {
	memberId: 0,
	isSettingNewState: false,
	isSetNewStateSuccess: false,
	isSetNewStateError: false,
};

export type SetNewStatePayloadType = {
	readonly memberId: number;
	readonly newState: string;
};

export type SetNewState = {
	(payload: SetNewStatePayloadType): SetNewStateActionTypes;
};

export type SetNewStateReset = {
	(): SetNewStateActionTypes;
};

export type SetNewStateAction = {
	type: typeof actionTypes.SET_NEW_STATE;
	payload: SetNewStatePayloadType;
};

export type SetNewStateSuccessAction = {
	type: typeof actionTypes.SET_NEW_STATE_SUCCESS;
};

export type SetNewStateFailureAction = {
	type: typeof actionTypes.SET_NEW_STATE_FAILURE;
};

export type SetNewStateResetAction = {
	type: typeof actionTypes.SET_NEW_STATE_RESET;
};

export type SetNewStateActionTypes =
	| SetNewStateSuccessAction
	| SetNewStateFailureAction
	| SetNewStateAction
	| SetNewStateResetAction;

/**
 * Get states
 */

export type GetFundStatesType = {
	readonly memberId: number;
	readonly isGettingFundStates: boolean;
	readonly isGetFundStatesSuccess: boolean;
	readonly isGetFundStatesError: boolean;
};

export const getFundStatesDefaultValue: GetFundStatesType = {
	memberId: 0,
	isGettingFundStates: false,
	isGetFundStatesSuccess: false,
	isGetFundStatesError: false,
};

export type GetFundStatesPayloadType = {
	readonly memberId: number;
};

export type GetFundStatesCtrType = {
	(payload: GetFundStatesPayloadType): GetFundStatesActionTypes;
};

export type GetFundStatesAction = {
	type: typeof actionTypes.GET_FUND_STATES;
	payload: GetFundStatesPayloadType;
};

export type GetFundStatesSuccessAction = {
	type: typeof actionTypes.GET_FUND_STATES_SUCCESS;
};

export type GetFundStatesFailureAction = {
	type: typeof actionTypes.GET_FUND_STATES_FAILURE;
};

export type GetFundStatesActionTypes = GetFundStatesAction | GetFundStatesSuccessAction | GetFundStatesFailureAction;

/**
 * Update item in fund list
 */

export type UpdateItemInFundlistPayloadType = {
	readonly memberId: number;
	readonly newState: string;
};

export type UpdateItemInFundlistCtrType = {
	(payload: GetFundStatesPayloadType): GetFundStatesActionTypes;
};

export type UpdateItemInFundlistAction = {
	type: typeof actionTypes.UPDATE_ITEM_IN_FUNDLIST;
	payload: UpdateItemInFundlistPayloadType;
};

export type UpdateItemInFundlistActionTypes = UpdateItemInFundlistAction;
