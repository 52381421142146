import * as actionTypes from "./actionTypes";
import {
	moveWorkflowDashboardGenerateDocsStateDefaultValue,
	IMoveWorkflowDashboardGenerateDocsState,
	IMoveWorkflowDashboardGenerateDocsStateActionTypes,
} from "./types";

export const moveWorkflowDashboardGenerateDocsStateReducer = (
	state = moveWorkflowDashboardGenerateDocsStateDefaultValue,
	action: IMoveWorkflowDashboardGenerateDocsStateActionTypes
): IMoveWorkflowDashboardGenerateDocsState => {
	switch (action.type) {
		case actionTypes.MOVE_WORKFLOW_DASHBOARD_GENERATE_DOCS:
			return {
				...state,
				movingWorkflowDashboardGenerateDocsState: true,
			};
		case actionTypes.MOVE_WORKFLOW_DASHBOARD_GENERATE_DOCS_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				movingWorkflowDashboardGenerateDocsState: false,
				moveWorkflowDashboardGenerateDocsStateSuccess: true,
				moveWorkflowDashboardGenerateDocsStateFailure: false,
				moveWorkflowDashboardGenerateDocsStateError: {},
			};
		case actionTypes.MOVE_WORKFLOW_DASHBOARD_GENERATE_DOCS_FAILURE:
			return {
				...state,
				movingWorkflowDashboardGenerateDocsState: false,
				moveWorkflowDashboardGenerateDocsStateSuccess: false,
				moveWorkflowDashboardGenerateDocsStateFailure: true,
				moveWorkflowDashboardGenerateDocsStateError: {},
			};
		case actionTypes.MOVE_WORKFLOW_DASHBOARD_GENERATE_DOCS_RESET:
			return {
				...state,
				...moveWorkflowDashboardGenerateDocsStateDefaultValue,
			};
		default:
			return state;
	}
};
