import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import { SetHeaderPreviewActionTypes, SetHeaderPreviewType, setHeaderPreviewDefaultValue } from "./types";

export const setHeaderPreviewReducer = (
	state = setHeaderPreviewDefaultValue,
	action: SetHeaderPreviewActionTypes
): SetHeaderPreviewType => {
	switch (action.type) {
		case actionTypes.SET_HEADER_PREVIEW:
			return {
				...state,
				darkMode: (action as any).payload.darkMode,
				logo: (action as any).payload.logo,
				logoHeight: (action as any).payload.logoHeight,
				previewOn: (action as any).payload.previewOn,
			};
		case actionTypes.SET_HEADER_PREVIEW_RESET:
			return {
				...state,
				...setHeaderPreviewDefaultValue,
			};
		default:
			return state;
	}
};

export const headerReducer = combineReducers({
	setHeaderPreview: setHeaderPreviewReducer,
});
