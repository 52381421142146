import * as docRequestActionTypes from "__pages/FundDetails/__pages/Requests/__redux/actionTypes";

import { DocRequestsType, GetDocRequestsType } from "__pages/FundDetails/__pages/Requests/__redux/types";
import { ResponseGeneratorType } from "__utils/types";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { getDocRequestsSlr } from "__pages/FundDetails/__pages/Requests/__redux/selectors";

import * as api from "../__api";
import * as actionTypes from "./actionTypes";

const addDocuSignEnvelope = function* (action: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.addDocuSignEnvelopeApi, action.payload);
		const { status, data } = response;

		if (status === 201) {
			yield put({
				type: actionTypes.ADD_DOCUSIGN_ENVELOPE_SUCCESS,
			});

			const { docRequests, limit }: GetDocRequestsType = yield select(getDocRequestsSlr);
			const _docRequests: DocRequestsType = [data, ...docRequests];
			const numOfPages: number = Math.ceil(_docRequests.length / limit);
			const partial: DocRequestsType = _docRequests.slice(0, 1 * limit);

			yield put({
				type: docRequestActionTypes.GET_DOC_REQUESTS_SUCCESS,
				docRequests: data,
				partial,
				numOfPages,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.ADD_DOCUSIGN_ENVELOPE_FAILURE, error: {} });
		}
	} catch (error) {
		yield put({ type: actionTypes.ADD_DOCUSIGN_ENVELOPE_FAILURE });
	}
};

export const addDocuSignEnvelopeSaga = function* (): any {
	yield takeLatest(actionTypes.ADD_DOCUSIGN_ENVELOPE, addDocuSignEnvelope);
};
