export const CREATE_FUND_MODAL_TITLE = "Create Fund";
export const FIRST_NAME_LABEL = "First Name";
export const LAST_NAME_LABEL = "Last Name";
export const EMAIL_LABEL = "Email";
export const PHONE_LABEL = "Phone";
export const BRAND_LABEL = "Brand";

export const PHONE_MAX_LEN_ERROR = "Ensure this field has no more than 10 characters.";
export const PHONE_TYPE_ERROR = "Ensure this field has numbers only";

export const FUND_CREATED_MESSAGE = "Fund created successfully";
