import { ApiErrorType } from "__utils/types";
import * as actionTypes from "./actionTypes";

export type DocumentTreeFileType = {
	readonly key: string;
	readonly type: string;
	readonly name: string;
	readonly dirType: string;
	readonly id?: number;
	readonly category?: string;
	readonly createdDate?: string;
	readonly envelopeId?: string;
};

export type DocumentTreeFolderType = {
	readonly key: string;
	readonly type: string;
	readonly name: string;
	readonly folderKey: string;
	readonly envelopeId: string | undefined;
	readonly files: Array<DocumentTreeFileType>;
};

export type DocumentTreeFileFolderType = DocumentTreeFileType & DocumentTreeFolderType;

export type DocumentTreeType = Array<DocumentTreeFileFolderType>;

/**
 * Get documents
 */

export type GetDocumentTreeType = {
	readonly documentTree: DocumentTreeType;
	readonly isGettingDocumentTree: boolean;
	readonly isGetDocumentTreeSuccess: boolean;
	readonly isGetDocumentTreeError: boolean;
};

export const getDocumentTreeDefaultValue: GetDocumentTreeType = {
	documentTree: [],
	isGettingDocumentTree: false,
	isGetDocumentTreeSuccess: false,
	isGetDocumentTreeError: false,
};

export type GetDocumentTreePayloadType = {
	id: string;
};

export type GetDocumentTreeCtrType = {
	(payload: GetDocumentTreePayloadType): GetDocumentTreeActionTypes;
};

type GetDocumentTreeAction = {
	type: typeof actionTypes.GET_DOCUMENT_TREE;
	payload: GetDocumentTreePayloadType;
};

type GetDocumentTreeSuccessAction = {
	type: typeof actionTypes.GET_DOCUMENT_TREE_SUCCESS;
};

type GetDocumentTreeFailureAction = {
	type: typeof actionTypes.GET_DOCUMENT_TREE_FAILURE;
};

type GetDocumentTreeResetAction = {
	type: typeof actionTypes.GET_DOCUMENT_TREE_RESET;
};

export type GetDocumentTreeActionTypes =
	| GetDocumentTreeAction
	| GetDocumentTreeSuccessAction
	| GetDocumentTreeFailureAction
	| GetDocumentTreeResetAction;

/**
 * DeleteDoc docs File
 */

export type DeleteDocumentTreeFileType = {
	readonly fileKey: string;
	readonly isDeletingDocumentTreeFile: boolean;
	readonly isDeleteDocumentTreeFileSuccess: boolean;
	readonly isDeleteDocumentTreeFileError: boolean;
};

export const deleteDocumentTreeFileDefaultValue: DeleteDocumentTreeFileType = {
	fileKey: "",
	isDeletingDocumentTreeFile: false,
	isDeleteDocumentTreeFileSuccess: false,
	isDeleteDocumentTreeFileError: false,
};

export type DeleteDocumentTreeFilePayloadType = {
	readonly memberId: number;
	readonly key: string;
};

type DeleteDocumentTreeFileAction = {
	type: typeof actionTypes.DELETE_DOCUMENT_TREE_FILE;
	payload: DeleteDocumentTreeFilePayloadType;
};

type DeleteDocumentTreeFileSuccessAction = {
	type: typeof actionTypes.DELETE_DOCUMENT_TREE_FILE_SUCESS;
	payload: DeleteDocumentTreeFilePayloadType;
};

type DeleteDocumentTreeFileFailureAction = {
	type: typeof actionTypes.DELETE_DOCUMENT_TREE_FILE_FAILURE;
	payload: DeleteDocumentTreeFilePayloadType;
};

type DeleteDocumentTreeFileResetAction = {
	type: typeof actionTypes.DELETE_DOCUMENT_TREE_FILE_RESET;
};

export type DeleteDocumentTreeFileActionTypes =
	| DeleteDocumentTreeFileAction
	| DeleteDocumentTreeFileSuccessAction
	| DeleteDocumentTreeFileFailureAction
	| DeleteDocumentTreeFileResetAction;

/**
 * Download Document
 */

export type DownloadDocumentType = {
	readonly key: string;
	readonly isDownloadingDocument: boolean;
	readonly isDownloadDocumentSuccess: boolean;
	readonly isDownloadDocumentError: boolean;
	readonly downloadDocumentError: ApiErrorType;
};

export const downloadDocumentDefaultValue: DownloadDocumentType = {
	key: "",
	isDownloadingDocument: false,
	isDownloadDocumentSuccess: false,
	isDownloadDocumentError: false,
	downloadDocumentError: {},
};

export type DownloadDocumentPayloadType = {
	readonly memberId: number;
	readonly key: string;
};

export type DownloadDocumentCtrType = {
	(payload: DownloadDocumentPayloadType): DownloadDocumentActionTypes;
};

type DownloadDocumentAction = {
	type: typeof actionTypes.DOWNLOAD_DOCUMENT;
	payload: DownloadDocumentPayloadType;
};

type DownloadDocumentSuccessAction = {
	type: typeof actionTypes.DOWNLOAD_DOCUMENT_SUCCESS;
};

type DownloadDocumentFailureAction = {
	type: typeof actionTypes.DOWNLOAD_DOCUMENT_FAILURE;
};

type DownloadDocumentResetAction = {
	type: typeof actionTypes.DOWNLOAD_DOCUMENT_RESET;
};

export type DownloadDocumentActionTypes =
	| DownloadDocumentAction
	| DownloadDocumentSuccessAction
	| DownloadDocumentFailureAction
	| DownloadDocumentResetAction;

/**
 * Get Documents Folder Details
 */

export type GetDocsFolderDetailsType = {
	readonly folderKey: string;
	readonly memberId: string;
	readonly isGettingDocsFolderDetails: boolean;
	readonly isGetDocsFolderDetailsSuccess: boolean;
	readonly isGetDocsFolderDetailsError: boolean;
};

export const getDocsFolderDetailsDefaultValue: GetDocsFolderDetailsType = {
	folderKey: "",
	memberId: "",
	isGettingDocsFolderDetails: false,
	isGetDocsFolderDetailsSuccess: false,
	isGetDocsFolderDetailsError: false,
};

export type GetDocsFolderDetailsPayloadType = {
	folderKey: string;
	memberId: string;
};

export type GetDocsFolderDetails = {
	(payload: GetDocsFolderDetailsPayloadType): GetDocsFolderDetailsActionTypes;
};

type GetDocsFolderDetailsAction = {
	type: typeof actionTypes.GET_DOCS_FOLDER_DETAILS;
	payload: GetDocsFolderDetailsPayloadType;
};

type GetDocsFolderDetailsSuccessAction = {
	type: typeof actionTypes.GET_DOCS_FOLDER_DETAILS_SUCCESS;
};

type GetDocsFolderDetailsFailureAction = {
	type: typeof actionTypes.GET_DOCS_FOLDER_DETAILS_FAILURE;
};

type GetDocsFolderDetailsResetAction = {
	type: typeof actionTypes.GET_DOCS_FOLDER_DETAILS_RESET;
};

export type GetDocsFolderDetailsActionTypes =
	| GetDocsFolderDetailsAction
	| GetDocsFolderDetailsSuccessAction
	| GetDocsFolderDetailsFailureAction
	| GetDocsFolderDetailsResetAction;
