import { ObjectType } from "__utils/types";
import { occupationDict } from "./constants";

export const getPlaces = (res: any): ObjectType => {
	const componentForm: ObjectType = {
		street_number: "short_name", // Street number
		route: "long_name", // Address
		locality: "long_name", // Suburb
		administrative_area_level_1: "short_name", // State
		postal_code: "short_name", // Postcode
		country: "short_name", // 2 char country code
	};

	const cleanedAddress: ObjectType = {};

	for (const key of Object.keys(res)) {
		const place: ObjectType = res[parseInt(key)];
		if (place) {
			for (let i = 0; i < place.address_components.length; i++) {
				const type: string = place.address_components[i].types[0];
				const val: string = place.address_components[i][componentForm[type]];
				if (componentForm[type]) {
					switch (type) {
						case "street_number":
							cleanedAddress.address = val;
							cleanedAddress.streetNumber = val;
							break;
						case "route":
							if (cleanedAddress.address) {
								cleanedAddress.address = `${cleanedAddress.address} ${val}`;
							} else {
								cleanedAddress.address = val;
							}
							cleanedAddress.streetOnly = val;
							break;
						case "locality":
							cleanedAddress.suburb = val;
							break;
						case "administrative_area_level_1":
							cleanedAddress.state = val;
							break;
						case "postal_code":
							cleanedAddress.postCode = val;
							break;
						case "country":
							cleanedAddress.country = val;
							break;
						default:
							cleanedAddress.postCode = "";
							break;
					}
				}
			}
		}
	}

	return cleanedAddress;
};

export const getOccupations = (): string[] => Object.keys(occupationDict);

export const getOccupationSubTypes = (type: string): string[] => occupationDict[type];

export const getSuggestionsStyle = (active: boolean): ObjectType => {
	if (active) {
		return {
			backgroundColor: "#ddd",
		};
	}
	return {};
};
