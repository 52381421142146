import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { AddPaymentPayloadType } from "../__redux/types";
import { ADD_PAYMENT } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const addPaymentApi = async ({ id, ...rest }: AddPaymentPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = ADD_PAYMENT.replace(":id", `${id}`);

	return axios.post(url, rest).catch(errorHandler);
};
