import cx from "classnames";
import React from "react";
import { Container } from "./style";

type Props = {
	arrowStyle?: { [key: string]: any };
	wrapperStyle?: { [key: string]: any };
	position?: string;
};

const DropdownArrow: React.FC<Props> = ({
	arrowStyle,
	wrapperStyle,
	position = "top",
}: Props): React.ReactElement<any, any> | null => {
	return (
		<Container style={wrapperStyle} className="drop-down-arrow">
			<div
				className={cx("arrow", { "arrow-up": position === "top", "arrow-down": position === "bottom" })}
				style={arrowStyle}
			/>
		</Container>
	);
};

export default DropdownArrow;
