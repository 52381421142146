import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
	body, html {
		background: #F5F5F5;
	}

    ::-webkit-scrollbar {
        width: 8px;
		height: 8px;
        z-index: 1;
    }

    ::-webkit-scrollbar-track {
        background: none;
    }

    ::-webkit-scrollbar-thumb {
        position: absolute;
        background: rgba(0, 0, 0, 0.4);
        cursor: pointer;
        user-select: none;
    }

    .rc-virtual-list-scrollbar-thumb {
        border-radius: 0 !important;
        background: rgba(0, 0, 0, 0.4) !important;
    }

	.ant-checkbox-inner {
		border-radius: 2px !important;
	}
`;

export const AntOverridesStyle = createGlobalStyle`
    #root > div {
        position: relative;
    }

    .ant-layout-sider {
        height: 100vh;
    }

    .ant-layout {
        background: #f5f5f5;
    }

    .ant-menu-dark.ant-menu-horizontal {
        background: transparent;
    }

    .ant-modal-mask {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.75);
    }

    .ant-modal-body {
        padding: 25px !important;
    }

    .ant-breadcrumb {
        font-size: 16px;
        padding-bottom: 25px;

        span:not(:last-of-type) :hover{
            color:#4538d1 ;
            cursor:pointer ;
        }
    }

    .ant-table-pagination.ant-pagination {
        margin: 16px 0;
    }  

    .ant-select-dropdown {
        overflow: visible !important;
        top: 43.9993px  !important;
        border: 1px solid #d7d7d7;
        z-index: 100;
    }

    .ant-table-body {
        overflow-y: overlay !important; // Forces the scrollbar to overlay
    }

    .ant-modal-header h4 {
        margin-bottom: 0;
        color: #160f71;
    }

    .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
        color: rgba(0, 0, 0, 0.55);
        background: transparent;
    }
`;
