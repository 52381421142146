import { call, put, select, takeLatest } from "redux-saga/effects";
import * as getFundActionTypes from "__pages/FundDetails/__redux/actionTypes";
import { getFundDetailsSlr } from "__pages/FundDetails/__redux/selectors";
import { GetFundDetailsType } from "__pages/FundDetails/__redux/types";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";

const editBankDetails = function* ({ payload }: any): any {
	try {
		const fund: GetFundDetailsType = yield select(getFundDetailsSlr);

		const response: ResponseGeneratorType = yield call(api.editBankDetailsApi, payload);

		const { data, status } = response;

		if (status === 200) {
			const { applicationId, accountName, bsb, accountNumber } = payload;

			const data: GetFundDetailsType = {
				...fund,
				bankDetails: {
					applicationId,
					accountName,
					bsb,
					accountNumber,
				},
			};

			// save new Bank Details state
			yield put({
				type: getFundActionTypes.GET_FUND_DETAILS_SUCCESS,
				...data,
			});

			yield put({
				type: actionTypes.EDIT_BANK_DETAILS_SUCCESS,
				isEditing: false,
			});
		} else {
			yield put({ type: actionTypes.EDIT_BANK_DETAILS_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.EDIT_BANK_DETAILS_FAILURE });
	}
};

export function* editBankDetailsSaga(): any {
	yield takeLatest(actionTypes.EDIT_BANK_DETAILS, editBankDetails);
}
