import Layout from "antd/es/layout";
import styled, { createGlobalStyle } from "styled-components";
import { headerStyle } from "__styles/common";

const { Header } = Layout;

export const HeaderStyled = styled(Header)`
	${headerStyle}
	transition: ${(p) => p.theme.transition};

	&.dark-mode {
		background: #0c042e;
	}

	.ant-menu-item-active:hover,
	.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
		background-color: #ddd;
	}

	.ant-menu.ant-menu-dark .ant-menu-item-selected,
	.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
		background-color: #ddd;
	}

	.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
	.ant-menu-dark .ant-menu-item-selected .anticon {
		color: rgba(77, 71, 102, 1);
	}

	.ant-menu-dark .ant-menu-item:hover > a,
	.ant-menu-dark .ant-menu-item-active > a,
	.ant-menu-dark .ant-menu-submenu-active > a,
	.ant-menu-dark .ant-menu-submenu-open > a,
	.ant-menu-dark .ant-menu-submenu-selected > a,
	.ant-menu-dark .ant-menu-submenu-title:hover > a,
	.ant-menu-dark .ant-menu-item:hover > span > a,
	.ant-menu-dark .ant-menu-item-active > span > a,
	.ant-menu-dark .ant-menu-submenu-active > span > a,
	.ant-menu-dark .ant-menu-submenu-open > span > a,
	.ant-menu-dark .ant-menu-submenu-selected > span > a,
	.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
		color: rgba(77, 71, 102, 1);
	}

	.header-button {
		padding: 0;
	}

	.header-icon {
		font-size: 20px;
		padding: 0;
		margin: 0;
		color: rgba(77, 71, 102, 1);
	}
`;

export const GlobalStyle = createGlobalStyle`

    .request-dropdown {
        top: 45px !important;
        position:fixed;
    }

    .session-dropdown {
        top: 47px !important;
        left: auto !important;
        right: 24px !important;
				min-width: 100px !important;
        position:fixed;
        
    }

		
		.session-dropdown-mobile {
		  	top: 47px !important;
        left: auto !important;
        right: 8px !important;
        min-width: 100px !important;
        position:fixed;
			 .ant-dropdown-arrow {
    		right: 20px;
			 }
		}


    .requests-dropdown-menu,
    .session-dropdown-menu {
        padding: 7px 0;
       
        .ant-dropdown-menu-item {
            padding: 5px 15px;

						.anticon-logout{
							
							padding-right: 15px;
						}
        }
    }

`;
