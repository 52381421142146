import {
	AddDocRequestType,
	EditDocRequestType,
	GetDocRequestDetailsType,
	GetSigningRequestDetailsType,
	GetDocRequestsType,
	GetDocRequestDocumentType,
	CompleteDocRequestDocumentType,
	CompleteDocRequestType,
	MoveDocRequestStateType,
	RemoveDocFileType,
	RemoveDocumentActivityType,
	RemindDocRequestType,
} from "./types";

export const addDocRequestSlr = (state: any): AddDocRequestType => state.fundDetails.docRequest.addDocRequest;
export const editDocRequestSlr = (state: any): EditDocRequestType => state.fundDetails.docRequest.editDocRequest;
export const getDocRequestDetailsSlr = (state: any): GetDocRequestDetailsType =>
	state.fundDetails.docRequest.getDocRequestDetails;
export const getSigningRequestDetailsSlr = (state: any): GetSigningRequestDetailsType =>
	state.fundDetails.docRequest.getSigningRequestDetails;
export const getDocRequestsSlr = (state: any): GetDocRequestsType => state.fundDetails.docRequest.getDocRequests;
export const getDocRequestDocumentSlr = (state: any): GetDocRequestDocumentType =>
	state.fundDetails.docRequest.getDocRequestDocument;
export const completeDocRequestDocumentSlr = (state: any): CompleteDocRequestDocumentType =>
	state.fundDetails.docRequest.completeDocRequestDocument;
export const completeDocRequestSlr = (state: any): CompleteDocRequestType =>
	state.fundDetails.docRequest.completeDocRequest;
export const moveDocRequestStateSlr = (state: any): MoveDocRequestStateType =>
	state.fundDetails.docRequest.moveDocRequestState;
export const removeDocFileSlr = (state: any): RemoveDocFileType => state.fundDetails.docRequest.removeDocFile;
export const remindDocRequestSlr = (state: any): RemindDocRequestType => state.fundDetails.docRequest.remindDocRequest;
export const removeDocumentActivitySlr = (state: any): RemoveDocumentActivityType =>
	state.fundDetails.docRequest.removeDocumentActivity;
