import { combineReducers } from "redux";
import { accountDetailsReducer } from "./AccountDetails/PrimaryAccount/__redux/reducers";
import { activityReducer } from "./ActivityDrawer/Activity/__redux/reducers";
import { bankDetailsReducer } from "./AccountDetails/BankDetails/__redux/reducers";
import { accountingReducer } from "./AccountDetails/ClassID/__redux/reducers";
import { trustsReducer } from "./AccountDetails/Trust/__redux/reducers";

export const accountReducer = combineReducers({
	accountDetails: accountDetailsReducer,
	activity: activityReducer,
	bankDetails: bankDetailsReducer,
	accounting: accountingReducer,
	trusts: trustsReducer,
});
