import { ObjectType } from "__utils/types";
import * as actionTypes from "./actionTypes";
import { ITrust } from "../../../../../__redux/types";

/**
 * Add Trust
 */

export type IAddTrust = {
	readonly isAddingTrust: boolean;
	readonly isAddTrustSuccess: boolean;
	readonly isAddTrustFailed: boolean;
	readonly isAddTrustError: ObjectType;
};

export const addTrustDefaultValue: IAddTrust = {
	isAddingTrust: false,
	isAddTrustSuccess: false,
	isAddTrustFailed: false,
	isAddTrustError: {},
};

export type IAddTrustPayload = {
	memberId: number;
	trust: ITrust;
};

type IAddTrustAction = {
	type: typeof actionTypes.ADD_TRUST;
	payload: IAddTrustPayload;
};

type IAddTrustSuccessAction = {
	type: typeof actionTypes.ADD_TRUST_SUCCESS;
};

type IAddTrustFailureAction = {
	type: typeof actionTypes.ADD_TRUST_FAILURE;
};

type IAddTrustResetAction = {
	type: typeof actionTypes.ADD_TRUST_RESET;
};

export type IAddTrustActions =
	| IAddTrustAction
	| IAddTrustSuccessAction
	| IAddTrustFailureAction
	| IAddTrustResetAction
	| IAddTrustResetAction;

/**
 * Update Trust
 */

export type IEditTrust = {
	readonly isEditingTrust: boolean;
	readonly isEditTrustSuccess: boolean;
	readonly isEditTrustFailed: boolean;
	readonly isEditTrustError: ObjectType;
};

export const editTrustDefaultValue: IEditTrust = {
	isEditingTrust: false,
	isEditTrustSuccess: false,
	isEditTrustFailed: false,
	isEditTrustError: {},
};

export type IEditTrustPayload = {
	memberId: number;
	trustId: number;
	trust: ITrust;
};

type IEditTrustAction = {
	type: typeof actionTypes.EDIT_TRUST;
	payload: IEditTrustPayload;
};

type IEditTrustSuccessAction = {
	type: typeof actionTypes.EDIT_TRUST_SUCCESS;
};

type IEditTrustFailureAction = {
	type: typeof actionTypes.EDIT_TRUST_FAILURE;
};

type IEditTrustResetAction = {
	type: typeof actionTypes.EDIT_TRUST_RESET;
};

export type IEditTrustActions =
	| IEditTrustAction
	| IEditTrustSuccessAction
	| IEditTrustFailureAction
	| IEditTrustResetAction;

/**
 * delete Trust
 */

export type IDeleteTrust = {
	readonly isDeletingTrust: boolean;
	readonly isDeleteTrustSuccess: boolean;
	readonly isDeleteTrustError: boolean;
};

export const deleteTrustDefaultValue: IDeleteTrust = {
	isDeletingTrust: false,
	isDeleteTrustSuccess: false,
	isDeleteTrustError: false,
};

export type IDeleteTrustPayload = {
	memberId: number;
	trustId: number;
};

type IDeleteTrustAction = {
	type: typeof actionTypes.DELETE_TRUST;
	payload: IDeleteTrustPayload;
};

type IDeleteTrustSuccessAction = {
	type: typeof actionTypes.DELETE_TRUST_SUCCESS;
};

type IDeleteTrustFailureAction = {
	type: typeof actionTypes.DELETE_TRUST_FAILURE;
};

type IDeleteTrustResetAction = {
	type: typeof actionTypes.DELETE_TRUST_RESET;
};

export type IDeleteTrustActions =
	| IDeleteTrustAction
	| IDeleteTrustSuccessAction
	| IDeleteTrustFailureAction
	| IDeleteTrustResetAction;
