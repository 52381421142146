import { call, put, takeLatest } from "redux-saga/effects";
import * as fundDetailsActionTypes from "__pages/FundDetails/__redux/actionTypes";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";

// sends fund structure to add to data on api
const addFundStructure = function* ({ payload }: any): any {
	try {
		// get whole fund
		const payloadFundStructure = {
			id: payload.id,
			structure: {
				...payload.structure,
				fundType: payload.structure.fundType.toUpperCase(),
				fundOrigin: payload.structure.fundOrigin.toUpperCase(),
			},
		};

		// update fund structure on api
		const response: ResponseGeneratorType = yield call(api.addFundStructureApi, payloadFundStructure);
		const { status, data } = response;
		if (status === 201) {
			// save the latest Establish fund data
			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_FOR_MODAL_SUCCESS,
				...data,
			});

			yield put({
				type: actionTypes.ADD_FUND_STRUCTURE_SUCCESS,
				isAdding: false,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.ADD_FUND_STRUCTURE_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.ADD_FUND_STRUCTURE_FAILURE });
	}
};

export function* addFundStructureSaga(): any {
	yield takeLatest(actionTypes.ADD_FUND_STRUCTURE, addFundStructure);
}

// sends fund structure to edit to data on api
const editFundStructure = function* ({ payload }: any): any {
	try {
		const { structure } = payload;
		const { fundOrigin, fundType, acn, abn } = structure;
		// update Origin and Type to uppercase for request
		const _fundOrigin: string = fundOrigin;
		const _fundType: string = fundType;
		const _acn: string | null = acn || null;
		const _abn: string | null = abn || null;

		const _payload = {
			...payload,
			structure: {
				...structure,
				fundOrigin: _fundOrigin.toUpperCase(),
				acn: _acn,
				fundType: _fundType.toUpperCase(),
				abn: _abn,
			},
		};

		// update fund structure on api
		const response: ResponseGeneratorType = yield call(api.editFundStructureApi, _payload);
		const { status, data } = response;

		if (status === 201) {
			// save the latest Establish fund data
			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_FOR_MODAL_SUCCESS,
				...data,
			});
			// to update fund details
			yield put({
				type: fundDetailsActionTypes.GET_FUND_DETAILS_SUCCESS,
				...data,
			});

			yield put({
				type: actionTypes.EDIT_FUND_STRUCTURE_SUCCESS,
				isEditing: false,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.EDIT_FUND_STRUCTURE_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.EDIT_FUND_STRUCTURE_FAILURE });
	}
};

export function* editFundStructureSaga(): any {
	yield takeLatest(actionTypes.EDIT_FUND_STRUCTURE, editFundStructure);
}
